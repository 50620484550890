import { format } from 'date-fns'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { UnderlineLink } from 'components/links'
import { H5Overflow } from 'components/styledFonts'
import { BlueLabel, WhiteLabel } from 'components/styledLabels'
import Thumbnail from 'components/Thumbnail'
import { useFeedbacksForTop } from 'hooks/feedback'
import ComponentBase from './ComponentBase'

const LabelArea = ({ children }) => {
    return (
        <Stack
            direction="row"
            columnGap={1}
            rowGap={0.5}
            flexWrap="wrap"
        >
            {children}
        </Stack>
    )
}

const Feedback = ({ isRow }) => {
    const data = useFeedbacksForTop()
    const row = useMediaQuery((theme) => theme.breakpoints.between("sm", "md")) || isRow

    return (
        <ComponentBase
            title="フィードバック"
            buttons={[{ label : 'フィードバック一覧', to : '/post/list/feedback' }]}
        >
            {!!data?.length && (
            <Box
                display="grid"
                rowGap={5}
                columnGap={6}
                gridTemplateColumns={row ?  "1fr 1fr" : "1fr" }
            >
                {data.map((d, i) => (
                <Stack
                    key={i}
                    gap={1}
                    sx={{
                    }}
                >
                    <LabelArea>
                        {d.公開 &&
                        <BlueLabel>フィードバックあり</BlueLabel>
                        }
                        <WhiteLabel>{d.type}</WhiteLabel>
                    </LabelArea>
                    <Stack
                        direction="row"
                        gap={1}
                    >
                        <Thumbnail
                            src={d.サムネイル}
                            newly={d.公開 && d.未読}
                            to={d.to}
                        />
                        <H5Overflow
                            sx={{ WebkitLineClamp : 4 }}
                        >
                            <UnderlineLink
                                to={d.to}
                            >
                                {d.タイトル}
                            </UnderlineLink>
                        </H5Overflow>
                    </Stack>
                    <LabelArea>
                        <WhiteLabel>公開日：{d.公開日 ? format(d.公開日, 'yyyy/MM/dd HH:mm') : ""}</WhiteLabel>
                        <WhiteLabel>更新日：{d.更新日 ? format(d.更新日, 'yyyy/MM/dd HH:mm') : ""}</WhiteLabel>
                        <WhiteLabel>更新者：{d.更新者}</WhiteLabel>
                    </LabelArea>
                </Stack>
                ))}
            </Box>
            )}
        </ComponentBase>
    )
}

export default Feedback

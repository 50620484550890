import { Link, useBeforeUnload } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack } from '@mui/material'
import Block from 'components/Block'
import { EditButton, DraftButton, PostButton } from 'components/styledButtons'
import { H1, H2, H5 } from 'components/styledFonts'
import S3Image from 'components/S3Image'
import Unregistered from 'components/Unregistered'
import { messages } from 'config/messages'
import { useInquiryMutaion } from 'hooks/mutation/inquiry'
import { useCompanyInfo, useFeedbackable } from 'hooks/company'
import { useFeedbackedCount } from 'hooks/feedback'
import { messageAtom } from 'store/commonAtoms'
import Editor from './Editor'
import Preview from './Preview'


export const files = ['メインイメージ', 'イメージ1', 'イメージ2', 'イメージ3', 'イメージ4', '動画']

export const defaultValues = {
    タイトル : '',
    リード文 : '',
    本文 : '',
    動画URL : '',
    検索キーワード : '',
    問い合わせ担当者_上書き : '',
    問い合わせ担当者部署_上書き : '',
    投稿日時 : '',
    フィードバック依頼 : false,
    フィードバックNo : '',
    draft : false,
    ...files.reduce((o, f) => ({ ...o, ...{ [f] : null, [`${f}オリジナルファイル名`] : '', [`${f}キャプション`] : '' } }), {}),
}

const schema = yup.object().shape({
    タイトル : yup
        .string()
        .required(messages.REQUIRED)
        .max(100, messages.WORD_COUNT_OVER(100))
    ,
    リード文 : yup
        .string()
        .required(messages.REQUIRED)
        .max(400, messages.WORD_COUNT_OVER(400))
    ,
    メインイメージ : yup
        .object()
        .nullable()
        .test('メインイメージ', messages.SELECTION_REQUIRED, (value) => value?.name)
,
    本文 : yup
        .string()
        .required(messages.REQUIRED)
        .max(4000, messages.WORD_COUNT_OVER(4000))
    ,
    検索キーワード : yup
        .string()
        .max(200, messages.WORD_COUNT_OVER(200))
    ,
    投稿日時 : yup
        .date()
        .nullable()
        .transform((v, o) => !!o ? v : null)
        .required(messages.REQUIRED)
    ,
})

const links = {
    '社長ストーリー' : '/inquiry/story',
    'こんな会社' : '/inquiry/intoroduction',
    'イチオシ！' : '/inquiry/recommendation',
}

const LinkBlock = ({ category, data }) => {

    return (
        <Block
            height="100%"
        >
            <Stack
                justifyContent="space-between"
                height="100%"
            >
                <Stack
                    gap={1}
                    pb={1.5}
                >
                    <H2>{category}</H2>
                    {!data &&
                    <Unregistered mt={0} />
                    }
                    {data &&
                    <Stack
                        direction="row"
                        gap={1}
                        px={1}
                        width="100%"
                    >
                        <Box
                            width={100}
                            height={65}
                            flexShrink={0}
                        >
                            <S3Image
                                url={data.サムネイル}
                            />
                        </Box>
                        <H5
                        >{data.タイトル}</H5>
                    </Stack>
                    }
                </Stack>
                <Stack
                    alignItems="center"
                >
                    <EditButton
                        component={Link}
                        to={links[category]}
                    >
                        {data ? "編集" : "作成"}
                    </EditButton>
                </Stack>
            </Stack>
        </Block>
    )
}

const PreviewEditor = ({ category, data, leftCategory, leftData, rightCategory, rightData }) => {
    const { control, setValue, handleSubmit, watch, getValues } = useForm({ defaultValues : defaultValues, resolver : yupResolver(schema) })
    const { mutate } = useInquiryMutaion()
    const { data: feedbackable } = useFeedbackable()
    const { data: company } = useCompanyInfo()
    const { data: count } = useFeedbackedCount(company)
    const [, setMsg] = useAtom(messageAtom)

    useBeforeUnload((e) => {
        e.preventDefault()
    })

    const onSubmit = async (form) => {
        setValue('draft', false)
        if (form.フィードバック依頼 && !form.フィードバックNo) {
            // 契約期間内のフィードバック依頼数チェック
            if (count >= company.フィードバック提供回数) {
                setMsg({ type : 'error', text : messages.ERR_FEEDBACK_LIMIT_OVER })
                return
            }
            // 現在日時がフィードバック可能月以外の場合、エラーとする
            if (!feedbackable.split(/、/).map(d => Number(d.replace(/月/, ''))).includes(new Date().getMonth() + 1)) {
                setMsg({ type : 'error', text : messages.FEEDBACK_NOT_APPLICABLE })
                return            
            }
        }

        mutate({
            form: form,
            id: data?.$id,
            inquiryNo: data?.企業探訪No,
            categoryName: category,
        })
    }

    const SaveDraft = async () => {
        setValue('draft', true)
        mutate({
            form: getValues(),
            id: data?.$id,
            inquiryNo: data?.企業探訪No,
            categoryName: category,
        })
    }

    return (
        <Stack
            gap={1.5}
        >
            <Stack
                component="form"
                gap={2}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Stack
                    sx={{
                        flexDirection : "row",
                        justifyContent : "space-between",
                        alignItems : "center",
                    }}
                >
                    <H1>企業探訪 {category}</H1>
                    <Stack
                        sx={{
                            flexDirection : { sm : "row", xs : "column" },
                            alignItems : "center",
                        }}
                        gap={1}
                    >
                        <DraftButton label="下書き保存" name="draft" type="button" onClick={SaveDraft} />
                        <PostButton label="投稿" name="post" />
                    </Stack>
                </Stack>
                <Stack
                    gap={1.5}
                    sx={{
                        flexDirection : { sm : "row", xs : "column" },
                    }}
                >
                    <Box 
                        sx={{ width : { sm : "65%", xs : "100%" } }}
                    >
                        <Preview
                            watch={watch}
                        />
                    </Box>
                    <Box sx={{ width : { sm : "35%", xs : "100%" } }}>
                        <Editor
                            category={category}
                            items={defaultValues}
                            control={control}
                            data={data}
                            setValue={setValue}
                            watch={watch}
                        />
                    </Box>
                </Stack>
            </Stack>
            <Stack
                width="100%"
                alignItems="center"
            >
                <Stack
                    gap={2}
                    sx={{
                        flexDirection : { sm : "row", xs : "column" },
                        width : { sm : "65%", xs : "100%" },
                        wordBreak : "break-word",
                    }}
                >
                    <Box sx={{ width : { sm : "50%", xs : "100%" } }}>
                        <LinkBlock
                            category={leftCategory}
                            data={leftData}
                        />
                    </Box>
                    <Box sx={{ width : { sm : "50%", xs : "100%" } }}>
                        <LinkBlock
                            category={rightCategory}
                            data={rightData}
                        />
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default PreviewEditor

import useMyMutation from './useMyMutation'
import { API } from 'lib/api'
import { apiName } from 'config/api'

export const useProgramMutaion = () => {
    return useMyMutation({
        mutationFn: async (param) => {
            const ret = await API.post(apiName, '/items/addApplicant', { body : param })
            return ret
        },
        queryKey: ['program'],
    })
}
import { Box, Stack } from '@mui/material'
import { H1 } from 'components/styledFonts'


const PageTitleAndButtons = ({ title, buttons }) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            gap={4}
        >
            <H1>{title}</H1>
            <Stack
                sx={{ flexDirection : { sm : "row", xs : "column" } }}
                columnGap={2}
                rowGap={1}
            >
                {buttons?.map((b, i) => (
                <Box key={i}>{b}</Box>
                ))}
            </Stack>
        </Stack>
    )
}

export default PageTitleAndButtons

import { useParams } from 'react-router-dom'
import { Stack, Divider } from '@mui/material'
import { H2, H4, H5, Body } from 'components/styledFonts'
import Block from 'components/Block'
import PostedPicture, { isPicture } from 'components/PostedPicture'
import TwoColumns from 'components/TwoColumns'
import { useResearchById } from 'hooks/research'


const Answers = ({ request, entry, fromNetamoto }) => {
    const count = fromNetamoto ? 5 : 20

    return [...Array(count).keys()].map((n, i) => {
        const q = `質問${n + 1}${fromNetamoto ? '_ネタもとからの質問' : ''}`
        const a = `回答${n + 1}${fromNetamoto ? '_ネタもとからの質問' : ''}`

        if (!request[q]) return null
        return (
            <Stack
                key={i}
                gap={1}
            >
                <H5>{request[q]}</H5>
                <Body
                    whiteSpace="pre-wrap"
                >
                    {entry[a]}
                </Body>
            </Stack>
        )
    })
}


const Preview = ({ entry }) => {
    const { requestNo } = useParams()
    const { data : request } = useResearchById(requestNo)

    return (
        <Block
            sx={{
                minHeight : 600,
                py : 3,
            }}
        >
            <Stack
                sx={{
                    px : { mobile : 2, xs : 0.5 }
                }}
            >
                {(entry && request) &&
                <Stack
                    alignItems="flex-start"
                    maxWidth={870}
                    gap={4}
                >
                    {(entry.結果 && request.レビュー) &&
                    <Stack
                        gap={2}
                        width="100%"
                    >
                        <H2>結果</H2>
                        <Body
                            whiteSpace="pre-wrap"
                        >
                            {request.レビュー}
                        </Body>
                        <Divider />
                    </Stack>
                    }
                    <H2>{entry.タイトル}</H2>
                    {entry.メイン画像 &&
                    <PostedPicture
                        data={entry.メイン画像}
                        caption={entry.メイン画像キャプション}
                    />
                    }
                    <Answers
                        request={request}
                        entry={entry}
                    />
                    {[...Array(5).keys()].filter(n => request[`質問${n + 1}_ネタもとからの質問`]).length > 0 &&
                    <Stack>
                        <H4>ネタもとからの質問</H4>
                        <Body>※この回答はメディアの方へは反映されません。</Body>
                    </Stack>
                    }
                    <Answers
                        request={request}
                        entry={entry}
                        fromNetamoto
                    />
                    {(isPicture(entry.画像1) || isPicture(entry.画像2) || isPicture(entry.画像3) || isPicture(entry.画像4)) &&
                    <Stack
                        alignItems="center"
                        width="100%"
                        px={1}
                    >
                        <TwoColumns
                            gap={6}
                            breakpoint="pad"
                        >
                        {[...Array(4).keys()].map(i => (
                            <PostedPicture
                                key={i}
                                data={entry[`画像${i + 1}`]}
                                caption={entry[`画像${i + 1}キャプション`]}
                                maxWidth={399}
                                maxHeight={266}
                            />
                        ))}
                        </TwoColumns>
                    </Stack>
                    }
                    {entry.PDF &&
                    <Body pt={3}>添付PDF：{entry.PDFオリジナルファイル名}</Body>
                    }
                </Stack>
                }
            </Stack>
        </Block>
    )
}

export default Preview

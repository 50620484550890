import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Pagination, Stack } from '@mui/material'
import {
    TabsUnstyled as Tabs,
    TabsListUnstyled as TabList,
    TabPanelUnstyled as TabPanel,
} from '@mui/base'
import { H1, H2, H5 } from 'components/styledFonts'
import { EntryMessageButton } from 'components/styledButtons'
import { useClientMessages, useSupportMessages, useReadFlags } from 'hooks/message'
import Block from 'components/Block'
import Scroll from 'components/Scroll'
import ContactTab from './ContactTab'
import SupportTab from './SupportTab'
import History from './History'
import Entry from './Entry'

const Message = () => {
    const [page, setPage] = useState(1)
    const { id } = useParams()
    const { data : messages } = useClientMessages()
    const { data : support } = useSupportMessages()
    const [value, setValue] = useState(0)
    const [scroll, setScroll] = useState(false)
    const flags = useReadFlags()
    const navi = useNavigate()

    const handleChange = (e, p) => {
        navi(`/messages/${messages[(p - 1) * max]?.媒体担当者ID}`)
    }

    useEffect(() => {
        if (!messages || !support) return

        if (id) {
            if (id === "support") {
                setValue(9999)
            }
            else {
                const index = messages.findIndex(m => m.媒体担当者ID === id)
                setPage(Math.floor(index / max) + 1)
                setValue(id)
            }
        }
        else {
            setValue(messages[0]?.媒体担当者ID || 0)
            setPage(1)
        }
    }, [messages, support, id])

    const max = 5
    const startIndex = (page - 1) * max

    return (
        <Stack
            gap={2}
        >
            <H1>メッセージボックス</H1>
            <Tabs
                value={value}
                onChange={(e, v) => {navi(v === 9999 ? `/messages/support` : `/messages/${v}`)}}
            >
                <Stack
                    direction="row"
                    gap={1.5}
                >
                    <Stack
                        width={300}
                        flexShrink={0}
                    >
                        <H2>コンタクト</H2>
                        <TabList>
                            <Stack gap={1.5}>
                                {messages?.slice(startIndex, startIndex + max).map((d, i) => (
                                <ContactTab
                                    key={i}
                                    value={d.媒体担当者ID}
                                    count={flags?.[d.$id]?.filter(f => f).length}
                                    date={d.日時}
                                    name={`${d.媒体名} ${d.媒体担当者_姓} ${d.媒体担当者_名}`}
                                    text={d.回答テーブル[d.回答テーブル.length - 1].回答内容}
                                />
                                ))}
                                <Stack alignItems="center">
                                    {messages &&
                                    <Pagination
                                        count={Math.floor(messages.length / max) + (messages.length % max ? 1 : 0)}
                                        variant="outlined"
                                        shape="rounded"
                                        onChange={handleChange}
                                        page={page}
                                    />}
                                </Stack>
                                <SupportTab
                                    count={flags?.[support?.$id]?.some(f => f)}
                                    value={9999}
                                />
                            </Stack>
                        </TabList>
                    </Stack>
                    <Stack
                        minHeight={300}
                        flexGrow={1}
                    >
                        <Block
                            sx={{ pt: 1 }}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={2}
                                pb={2}
                            >
                                <H2>メッセージ履歴</H2>
                                {!!messages?.length &&
                                <EntryMessageButton
                                    label="メッセージ新規作成"
                                    onClick={() => setScroll(true)}
                                />
                                }
                            </Stack>
                            {!messages?.length && value !== 9999 &&
                            <H5>メッセージ履歴はありません</H5>
                            }
                            {messages?.map((d, i) => (
                            <TabPanel
                                key={i}
                                value={d.媒体担当者ID}
                            >
                                <History
                                    messages={d}
                                />
                            </TabPanel>
                            ))}
                            <TabPanel
                                value={9999}
                            >
                                <History
                                    messages={support}
                                />
                            </TabPanel>
                        </Block>
                        {messages?.map((d, i) => (
                        <TabPanel
                            key={i}
                            value={d.媒体担当者ID}
                        >
                            <Entry
                                entryMessage={d}
                            />
                        </TabPanel>
                        ))}
                        <TabPanel
                            value={9999}
                        >
                            <Entry
                                entryMessage={support}
                            />
                        </TabPanel>
                        <Scroll scroll={scroll} setScroll={setScroll} block="end" />
                    </Stack>
                </Stack>
            </Tabs>
        </Stack>
    )
}

export default Message

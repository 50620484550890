import { Button, Divider, Stack, useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Block from 'components/Block'
import CategorySelect from 'components/CategorySelect'
import FeedbackCheckbox from 'components/FeedbackCheckbox'
import { SelectAttachment, SelectMovie, SelectPDF } from 'components/FileUploader'
import GenreSelect from 'components/GenreSelect'
import {
    CharacterLimitField,
    DatePicker,
    ImageCaption,
    LabeledInputField,
    RadioGroupBase,
    Required,
    TextField,
} from 'components/Inputs'
import InquiryUserInput from 'components/InquiryUserInput'
import PostButtonSet from 'components/PostButtonSet'
import WysiwygEditor from 'components/WysiwygEditor'
import { Body, H1 } from 'components/styledFonts'
import { featureCategory } from 'config/options'
import { useAccounts } from 'hooks/account'
import { usePressReleaseByNo } from 'hooks/pressrelease'
import { defaultValues, maxLength, rowText, rowFiles } from './PressReleaseSubmissionBase'

const PressReleaseHtmlSubmission = () => {
    const { getValues, setValue, handleSubmit, watch, control, onSubmit, saveDraft } = useFormContext()
    const { no } = useParams()
    const { data } = usePressReleaseByNo(no)
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))
    const { data : accountData } = useAccounts()

    const handleDelete = (i) => {
        setValue(`メイン画像${i + 1}`, null)
        setValue(`メイン画像${i + 1}キャプション`, '')
        setValue(`メイン画像${i + 1}オリジナルファイル名`, '')
        setValue(`本文${i + 1}`, '')
        setValue(`画像${i + 1}`, null)
        setValue(`画像${i + 1}キャプション`, '')
        setValue(`画像${i + 1}オリジナルファイル名`, '')
        setValue(`動画${i + 1}`, null)
        setValue(`動画${i + 1}オリジナルファイル名`, '')
        setValue(`動画${i + 1}URL`, '')
        setValue('コンテンツ数', i)
    }

    // Trumbowygで入力した内容をsubmit直前にformに反映
    const preSubmit = e => {
        [...Array(4).keys()].forEach(i => {
            const $this = window.$(`#本文${i + 1}`)
            if ($this.parent().find('.trumbowyg-viewHTML-button').hasClass('trumbowyg-active')) {
                const html = $this.prev().val()
                setValue(`本文${i + 1}`, html || '')
                $this.empty().html(html)
            }
            else {
                setValue(`本文${i + 1}`, $this.html() || '')
            }
        })
    }


    // 編集画面だった場合、useFormのdefaultValueに既存レコードの値を入力する処理
    useEffect(() => {
        if (!data || !accountData) {
            setValue("PDF入稿", false)
            return 
        }
        if (getValues("リリースNo")) return
        Object.keys(defaultValues).forEach(n => setValue(n, data[n]))
        let contentIndex = [...Array(4).keys()]
            .findLastIndex((c) => rowText.some(r => data[r.replace(":no", `${c + 1}`)]) || rowFiles.some(r => data[r.replace(":no", `${c + 1}`)]?.url))
        if (contentIndex === -1) contentIndex = 0
        setValue("コンテンツ数", contentIndex + 1)
        setValue("リリースNo", no)
        setValue("PDF入稿", false)
        const inquiryUserId = accountData.find(d => d.姓 + d.名 === data.問い合わせ担当者_上書き) 
        setValue("問い合わせ担当者_上書き", inquiryUserId?.企業担当者ID || "")
    }, [data, accountData, no, setValue, getValues])

    return (
        <Stack
            gap={2}
        >
            <H1>{`プレスリリース${watch("リリースNo") ? "編集" : "新規作成"}（HTML入稿）`}</H1>
            <Block>
                <Body sx={{ pb : 2 }}><Required />がついている項目は必須です</Body>
                <Stack
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    gap={4}
                    pb={2}
                    sx={{ 
                        pl : { mobile : 2, xs : 0 },
                        pr : { mobile : 8, xs : 0 },
                    }}
                >
                    <CharacterLimitField
                        label="タイトル文"
                        name="タイトル"
                        limit={maxLength.タイトル}
                        control={control}
                        required
                        multiline
                        disableEnter
                        watch={watch("タイトル")}
                    />
                    <CharacterLimitField
                        name="リード文"
                        limit={maxLength.リード文}
                        control={control}
                        required
                        multiline
                        minRows={3}
                        watch={watch("リード文")}
                    />
                    {[...Array(4).keys()].slice(0, watch('コンテンツ数')).map((c, i, a) => 
                    <Stack
                        key={i}
                        gap={4}
                    >
                        <LabeledInputField
                            label={`メイン画像${i + 1}`}
                            note={i === 0 && "※一覧ページでサムネイルとしても表示されます。"}
                            required={i === 0}
                        >
                            <ImageCaption
                                name={`メイン画像${i + 1}`}
                                captionName={`メイン画像${i + 1}キャプション`}
                                pict={watch(`メイン画像${i + 1}`)}
                                control={control}
                                setValue={setValue}
                            />
                        </LabeledInputField>
                        <LabeledInputField
                            label={`本文${i + 1}`}
                            note="※iframeタグは使用出来ません。"
                            required={i === 0}
                        >
                            <WysiwygEditor
                                name={`本文${i + 1}`}
                                control={control}
                                initValue={watch(`本文${i + 1}`)}
                                limit={maxLength.本文}
                            />
                        </LabeledInputField>
                        <LabeledInputField
                            label="画像 / 動画"
                            note="※画像は最大5MB、動画は最大50MBまでアップロード可能です。"
                        >
                            <RadioGroupBase
                                value={watch(`アップロード方法${i + 1}`)}
                                onChange={e => setValue(`アップロード方法${i + 1}`, e.target.value)}
                                row={!isMobile}
                                sx={{
                                    columnGap : 0,
                                }}
                                list={[
                                    { label : '画像をアップロード', value : '画像をアップロード', 
                                    disabled : !!(
                                        watch(`動画${i + 1}`)?.url || watch(`動画${i + 1}`)?.blob
                                        || watch(`動画${i + 1}URL`)
                                    ) },
                                    { label : '動画をアップロード', value : '動画をアップロード',
                                    disabled : !!(
                                        watch(`画像${i + 1}`)?.url || watch(`画像${i + 1}`)?.blob 
                                        || watch(`動画${i + 1}URL`)
                                    ) },
                                    { label : '動画の埋め込みタグを入力', value : '動画の埋め込みタグを入力',
                                    disabled : !!(
                                        watch(`画像${i + 1}`)?.url || watch(`画像${i + 1}`)?.blob
                                        || watch(`動画${i + 1}`)?.url || watch(`動画${i + 1}`)?.blob
                                    ) }
                                ]}
                            />
                            {watch(`アップロード方法${i + 1}`) === "画像をアップロード" &&
                            <ImageCaption
                                name={`画像${i + 1}`}
                                captionName={`画像${i + 1}キャプション`}
                                pict={watch(`画像${i + 1}`)}
                                control={control}
                                setValue={setValue}
                            />      
                            }
                            {watch(`アップロード方法${i + 1}`) === "動画をアップロード" &&
                            <SelectMovie
                                name={`動画${i + 1}`}
                                file={watch(`動画${i + 1}`)}
                                control={control}
                                setValue={setValue}
                                showName
                                removable
                            />      
                            }
                            {watch(`アップロード方法${i + 1}`) === "動画の埋め込みタグを入力" && <>
                            <TextField
                                name={`動画${i + 1}URL`}
                                control={control}
                                watch={watch(`動画${i + 1}URL`)}
                                placeholder="YouTube動画の埋め込みタグを貼り付けてください。"
                            />
                            <Body>{"YouTube動画の「共有メニュー > 埋め込む」から取得できる、埋め込み用タグをコピーアンドペーストしてください。"}</Body>
                            </>}
                        </LabeledInputField>
                        {(i > 0 && watch("コンテンツ数") === i + 1) && 
                        <Stack
                            width="100%"
                            alignItems="flex-start"
                            gap={2}
                        >
                            <Divider sx={{ width : "100%" }} />
                            <Button
                                variant="outlined"
                                component="label"
                                color="grey800"
                                onClick={() => handleDelete(i)}
                            >
                                - コンテンツを削除する
                            </Button>
                        </Stack>}
                        {(i < 3 && watch("コンテンツ数") ===  i + 1) && 
                        <Stack
                            width="100%"
                            alignItems="center"
                            gap={2}
                        >
                            <Divider sx={{ width : "100%" }} />
                            <Button
                                variant="outlined"
                                component="label"
                                color="grey800"
                                onClick={() => {setValue('コンテンツ数', i + 2)}}
                            >
                                + コンテンツを追加する
                            </Button>
                            <Body>※コンテンツは最大3つまで追加可能です。</Body>
                        </Stack>
                        } 
                    </Stack>
                    )
                    }
                    <GenreSelect control={control} />
                    <CategorySelect control={control} setValue={setValue} pressRelease />
                    <LabeledInputField
                        label="資料添付"
                    >
                        <Stack
                            gap={1}
                        >
                            <SelectAttachment
                                name="添付資料1"
                                label="資料添付"
                                file={watch('添付資料1')}
                                removable
                                control={control}
                                setValue={setValue}
                                showName
                            />
                            <SelectPDF
                                name="PDF"
                                label="PDF添付"
                                file={watch('PDF')}
                                removable
                                control={control}
                                setValue={setValue}
                                showName
                            />
                        </Stack>
                    </LabeledInputField>
                    <InquiryUserInput
                        control={control}
                        setValue={setValue}
                        note="企業情報設定と異なる方を問い合わせ担当者にする際は入力してください"
                    />
                    <LabeledInputField
                        label="リリース公開日時"
                        required
                    >
                        <DatePicker
                            name="リリース日時"
                            control={control}
                            showTimeInput
                        />
                    </LabeledInputField>
                    {featureCategory.some(c => c === watch("カテゴリ")) &&
                    <LabeledInputField
                        label="リリース公開期限"
                        required
                        note={'※ 公開期限を設定すると、指定した日時まで記事が公開されます。\n※「読者プレゼント」「記者発表会」を選択した場合は必須です。'}
                    >
                        <DatePicker
                            name="公開期限日"
                            control={control}
                            showTimeInput
                        />
                    </LabeledInputField>
                    }
                    <FeedbackCheckbox
                        name={"フィードバック依頼"}
                        disabled={!!watch("フィードバックNo")}
                        control={control}
                    />
                </Stack>
            </Block>
            <PostButtonSet
                handleSubmit={handleSubmit}
                watch={watch}
                saveDraft={saveDraft}
                isFeedback={watch("フィードバック依頼") && !watch("フィードバックNo")}
                pressRelease
                preSubmit={preSubmit}
            />
        </Stack>
    )
}

export default PressReleaseHtmlSubmission

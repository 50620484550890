import { Body } from 'components/styledFonts'
import {
    LabeledInputField,
    TextField,
    Select,
} from 'components/Inputs'
import { Stack } from '@mui/material'
import { useAccounts } from 'hooks/account'

const InquiryUserInput = ({ note, setValue, control, direction="row" }) => {
    const { data : accountData } = useAccounts()

    return (
        <LabeledInputField
            label="問い合わせ担当者"
            note={note}
        >
            <Stack
                columnGap={10}
                rowGap={1}
                sx={{
                    flexDirection: { sm: direction, xs: "column" },
                }}
            >
                <Stack
                    width={250}
                >
                    <Body>担当者名</Body>
                    <Select
                        name="問い合わせ担当者_上書き"
                        control={control}
                        list={[{ label : '--------', value : '' }].concat(accountData?.map(p => ({ label : p.姓 + p.名, value : p.企業担当者ID })) || [])}
                        handleChange={e => {
                            const ad = accountData.find(a => a.企業担当者ID === e.target.value)
                            setValue("問い合わせ担当者部署_上書き", ad?.所属部署 + ad?.役職 || '')
                        }}
                    />
                </Stack>
                <Stack
                    width={250}
                >
                    <Body>部署・役職</Body>
                    <TextField
                        name="問い合わせ担当者部署_上書き"
                        control={control}
                    />
                </Stack>
            </Stack>
        </LabeledInputField>
    )
}

export default InquiryUserInput

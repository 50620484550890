import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api'
import { apiName } from 'config/api'
import { useAllPressReleaseOnlyFeedback } from './pressrelease'
import { useAllNewsLetterOnlyFeedback } from './newsletter'
import { useInquiriesListOnlyFeedback } from './inquiry'
import { useEffect, useState } from 'react'
import { useDynamoItem } from './dynamo'

const getFeedback = async () => {
    const feedback = (await API.get(apiName, "/items/getFeedback", {})).map(d => ({
        ...d,
        メディア公開日時 : d.メディア公開日時 ? new Date(d.メディア公開日時) : "",
        クライアント公開日時 : d.クライアント公開日時 ? new Date(d.クライアント公開日時) : "",
        作成日時 : d.作成日時 ? new Date(d.作成日時) : "",
        to : d.企業探訪No ? `/inquiry/feedback/${d.フィードバックNo}` :
            d.リリースNo ? `/pressrelease/${d.リリースNo}/feedback` :
            d.ネタNo ? `/newsletter/${d.ネタNo}/feedback` : "",
    })).sort((a, b) => a.作成日時 - b.作成日時)
    return feedback
}

export const useAllFeedbacks = () => {
    return useQuery(['feedback'], getFeedback)
}

export const useAllFeedbackList = () => {
    const { data : pressrelease } = useAllPressReleaseOnlyFeedback()
    const { data : newsletter } = useAllNewsLetterOnlyFeedback()
    const { data : feedback } = useAllFeedbacks()
    const { data : inquiry } = useInquiriesListOnlyFeedback()
    const { data : dynamo, isSuccess } = useDynamoItem('既読フィードバック')
    const [list, setList] = useState([])

    useEffect(() => {
        if (!pressrelease || !newsletter || !inquiry || !feedback) return
        setList(feedback.map(d => {
            const targetData = d.リリースNo ? pressrelease.find(p => p.フィードバックNo === d.フィードバックNo) :
                d.ネタNo ? newsletter.find(p => p.フィードバックNo === d.フィードバックNo) :
                d.企業探訪No ? inquiry.find(p => p.企業探訪No === d.企業探訪No) : {}
            const midoku = isSuccess ? !dynamo?.some(l => l === d.フィードバックNo) : false
            return {
                ...targetData,
                ...d,
                未読 : midoku,
                公開 : d.クライアント公開 || d.却下
            }
        }))
    }, [pressrelease, newsletter, inquiry, feedback, dynamo, isSuccess])

    return list
}


export const useNewFeedbackCount = () => {
    const list = useAllFeedbackList()

    return list?.filter(l => l.未読 && l.公開).length
}

export const useFeedbackByNo = (no) => {
    return useQuery(['feedback'], getFeedback, {
        select : (data) => data.find(d => d.フィードバックNo === no)
    })
}

export const usePreviousNext = (no) => {
    const list = useAllFeedbackList()

    const targetIndex = list.findIndex(d => {
        if (d.type === "企業探訪") return d.企業探訪No === no
        if (d.type === "ニュースレター") return d.ネタNo === no
        if (d.type === "プレスリリース") return d.リリースNo === no
        return false
    })

    return {
        data : [
            list[targetIndex - 1],
            list[targetIndex + 1]
        ] 
    }
}

export const useFeedbacksForTop = () => {
    const data = useAllFeedbackList()

    return data?.sort((a, b) => b.作成日時 - a.作成日時).slice(0, 2)
}

export const useFeedbackedCount = (company) => {

    return useQuery(['feedback'], getFeedback, {
        enabled : !!company,
        select : (data) => data
            .filter(d => !d.却下)
            .length
    })
}

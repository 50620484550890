import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Stack, Modal, IconButton, Fade } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { H2 } from 'components/styledFonts'
import { useAtom } from 'jotai'
import { LabeledTextField } from 'components/Inputs'
import { BlueContainedButton } from 'components/styledButtons'
import { mlGroupNameModalAtom } from 'pages/medialist/MediaMain'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { messages } from 'config/messages'
import { useGroupName } from 'hooks/media'
import { useMediaMutaion } from 'hooks/mutation/media'

const nums = [...Array(10).keys()].map(n => n + 1)

const defaultValues = nums.reduce((o, n) => ({ ...o, [`グループ${n}`] : '' }), {})

const schema = yup.object().shape({
    グループ1: yup.string().max(12, messages.WORD_COUNT_OVER(12)),
    グループ2: yup.string().max(12, messages.WORD_COUNT_OVER(12)),
    グループ3: yup.string().max(12, messages.WORD_COUNT_OVER(12)),
    グループ4: yup.string().max(12, messages.WORD_COUNT_OVER(12)),
    グループ5: yup.string().max(12, messages.WORD_COUNT_OVER(12)),
    グループ6: yup.string().max(12, messages.WORD_COUNT_OVER(12)),
    グループ7: yup.string().max(12, messages.WORD_COUNT_OVER(12)),
    グループ8: yup.string().max(12, messages.WORD_COUNT_OVER(12)),
    グループ9: yup.string().max(12, messages.WORD_COUNT_OVER(12)),
    グループ10: yup.string().max(12, messages.WORD_COUNT_OVER(12)),
})


const GroupNameModal = () => {
    const { control, setValue, handleSubmit } = useForm({ defaultValues : defaultValues, resolver : yupResolver(schema)  })
    const [open, setOpen] = useAtom(mlGroupNameModalAtom)
    const { data } = useGroupName() 
    const { mutate : mutateMedia } = useMediaMutaion()
    
    useEffect(() => {
        if (!data || !open) return

        Object.keys(defaultValues).forEach((n, i) => {
            setValue(n, data.groups[i+1])
        })
    }, [data, setValue, open])

    const onSubmit = async (d) => {
        mutateMedia({
            groupData : d,
            revision : data.$revision || '' 
        })
        setOpen(false)
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Fade in={open} timeout={1000}>
                <Stack
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    bgcolor="#fff"
                    sx={{
                        width: { mobile: "60%", xs: "90%" },
                    }}
                    maxWidth={600}
                    maxHeight="90%"
                    pt={3}
                    pb={4}
                    pl={3}
                    pr={2}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        pb={1}
                        mt={-1}
                    >
                        <H2>グループ名 設定</H2>
                        <IconButton
                            sx={{ mt : -1 }}
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        gap={2}
                        overflow="auto"
                        pb={2}
                    >
                        {nums.map(n => (
                        <Stack
                            key={n}
                            direction="row"
                            alignItems="center"
                            pr={2}
                        >
                            <LabeledTextField
                                name={`グループ${n}`}
                                control={control}
                                fullWidth
                            />
                        </Stack>
                        ))}
                    </Stack>
                    <Stack
                        pt={1}
                        alignItems="center"
                    >
                        <BlueContainedButton
                            type="submit"
                            label="登録"
                        />
                    </Stack>
                </Stack>
            </Fade>
        </Modal>
    )
}

export default GroupNameModal

import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { format } from 'date-fns'
import { useProgramDetail } from 'hooks/program'
import { Body, Body2, H1, H2, H5 } from 'components/styledFonts'
import { LinkButtonLarge, BlueContainedButton } from 'components/styledButtons'
import Block from 'components/Block'
import Scroll from 'components/Scroll'
import Reservations from './Reservations'
import AddApplicant from './AddApplicant'
import { useGoogleMapUrl } from 'hooks/variousHooks'

const Content = ({ subtitle, children }) => {
    return (
        <Stack
            gap={1}
        >
            <H5>{subtitle}</H5>
            <Body2
                whiteSpace="pre-wrap"
            >
                {children}
            </Body2>
        </Stack>
    )
}

const ProgramList = () => {
    const { id } = useParams()
    const { data } = useProgramDetail(id)
    const [top, setTop] = useState(false)
    const [add, setAdd] = useState(false)
    const { data: url } = useGoogleMapUrl()


    return (
        <Stack
            gap={2}
        >
            <Stack mt={-2}>
                <Scroll scroll={top} setScroll={setTop} />
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                gap={4}
            >
                <H1>プログラム詳細</H1>
                {!data?.申込不要 && data?.受付中 &&
                <BlueContainedButton
                    label="申し込み"
                    onClick={() => setAdd(true)}
                />
                }
            </Stack>
            <Block
                sx={{ minHeight : 600 }}
            >
                {data &&
                <Stack
                    gap={3}
                    px={1}
                >
                    <Stack
                        pt={1}
                        gap={1}
                    >
                        <Body
                            sx={{ color : grey[400] }}
                        >
                            {`${format(data.開場日時, 'yyyy年MM月dd日 HH:mm')}開場／${format(data.開始日時, 'HH:mm')}開始(終了予定時刻${format(data.終了予定日時, 'HH:mm')})`}
                        </Body>
                        <H2>{data.セミナー名}</H2>
                    </Stack>
                    <Content subtitle="内容">
                        {data.内容}
                    </Content>
                    <Content subtitle="対象">
                        {data.対象者}
                    </Content>
                    {!data.申込不要 &&
                    <Content subtitle="定員">
                        <Stack>
                            <Body>{`先着${data.定員}名様限定`}</Body>
                            <Body>{data.定員備考}</Body>
                        </Stack>
                    </Content>
                    }
                    <Content subtitle="スピーカー">
                        {data.スピーカー見出し}
                    </Content>
                    <Content subtitle="会場">
                        {`${data.会場名}\n${data.会場住所 || ''}${data.会場場所_建物名 ? ` ${data.会場場所_建物名}` : ''}`}
                    </Content>
                    {data.会場住所 &&
                    <Box
                        sx={{
                            position : "relative",
                            pt : "35%",
                            height : 0,
                            '& iframe' : {
                                position : "absolute",
                                top : 0,
                                left : 0,
                                width : "100%",
                                height : "100%",
                                //border : 0,
                            },
                        }}
                    >
                        <Box
                            component="iframe"
                            src={url + data.会場住所}
                            width={600}
                            height={450}
                        />
                    </Box>
                    }
                    <Content subtitle="アクセス">
                        {data.アクセス}
                    </Content>
                </Stack>
                }
            </Block>
            {!data?.申込不要 && data?.受付中 &&
            <Reservations data={data} />
            }
            <Stack mt={-2}>
                <Scroll scroll={add} setScroll={setAdd} />
            </Stack>
            {!data?.申込不要 && data?.受付中 &&
            <AddApplicant data={data} />
            }
            <Stack
                alignItems="center"
                pt={3}
            >
                <Stack
                    sx={{
                        flexDirection : { md : "row", xs : "column" },
                        gap : 3,
                    }}
                >
                    <LinkButtonLarge
                        component={Link}
                        to="/program/list"
                    >
                        プログラム一覧
                    </LinkButtonLarge>
                    <LinkButtonLarge
                        component={Link}
                        to="/program/history"
                    >
                        申し込み履歴
                    </LinkButtonLarge>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ProgramList

export const messages = {
    //------------------------------------------------------------
    // ヴァリデーションメッセージ
    //------------------------------------------------------------
    REQUIRED: '入力してください。',
    MAILADDRESS_VALIDATION : 'メールアドレス形式で入力してください。',
    PASSWORD_VALIDATION : 'パスワードは英小文字・英大文字・数字・記号を含む8文字以上で設定してください。',
    PASSWORD_MATCH_FAILED : '異なるパスワードが入力されています。',
    DUPLICATED_MAILADDRESS : '既に登録されています。',
    INPUT_NUMBER_HYPHEN: '半角数字もしくはハイフンで入力してください。',
    POSTALCODE_VALIDATION : '郵便番号として正しくありません。',
    SELECTION_REQUIRED: '選択してください。',
    WORD_COUNT_OVER: (n) => `${n}文字以内で入力してください。`,
    INPUT_NUMBER: '半角数字で入力してください。',
    CORRECT_URL: '正しいURLを入力してください。',
    UNMATCH_FILE_TYPE: (type) => `${type}ファイルではありません。`,
    FILESIZE_OVER: 'ファイルサイズが上限を超えています。',
    ENTER_MORE: (n) => `${n}以上を入力してください。`,
    ENTER_LESS: (n) => `${n}以下を入力してください。`,
    NO_DATA: (n) => `作成した${n}はありません。`,

    //------------------------------------------------------------
    // 画面固有メッセージ
    //------------------------------------------------------------
    // エラー画面
    403: 'このページへのアクセス権限がありません。',
    404: 'ページが見つかりません。',
    ERR_DEFAULT : '障害が発生しました。管理者にお問い合わせください。',

    // フィードバック関連
    FEEDBACK_NOT_APPLICABLE: '今月はフィードバック依頼対象月ではないため申請できません。',

    // 個人設定画面
    PASSWORD_UPDATE_FAILED : 'エラーが発生しました。',

    // メディアリスト画面
    MEDIALIST_SELECT_ZERO : '送信先をチェックして下さい。',
    MEDIALIST_SELECT_OVER : '同時に送信可能な宛先は50件までです。',

    // トップページ画面
    TOP_MYPAGE_NOT_EXIST : '企業情報設定が未完了です。企業情報の入力をお願いいたします。',

    // プレスリリース登録関連画面
    ERR_PAYLOAD_TOO_LARGE : '送信できるデータの上限を超えています。内容を修正してください。',

    //------------------------------------------------------------
    // API実行結果メッセージ
    //------------------------------------------------------------
    // 成功
    OK_POST_SUCCESS : '登録しました。',
    OK_DRAFT_SUCCESS : '下書きを保存しました。',
    OK_TEMPORARY_SUCCESS : '一時保存しました。',
    OK_UPDATE_SUCCESS : '更新しました。',
    OK_DELETE_SUCCESS : '削除しました。',

    // エラー
    ERR_REGISTRATION_FAILED : 'データの登録に失敗しました。管理者にお問い合わせください。',
    GAIA_CO02 : '既に変更されております。再読み込みをして再度実行して下さい。',

    // アカウント管理画面
    ERR_MAILADDRESS_EXISTS : '該当のメールアドレスは既にアカウントが存在しているため作成できません。',

    // プログラム詳細画面
    ERR_CAPACITY_LIMIT : '定員に達しているため申し込みできません。',
    ERR_CAPACITY_INDIV_LIMIT : '1社定員に達しているため申し込みできません。',

    // フィードバック関連
    ERR_FEEDBACK_REQUESTED: '既にフィードバック依頼済みです。',
    ERR_FEEDBACK_LIMIT_OVER: '上限に達しているため、これ以上フィードバック依頼を行えません。',

    // エントリー関連
    ERR_ENTRY_LIMIT: '上限に達しているため、これ以上エントリーできません。',
    ERR_CLOSED_RESEARCH: '締切日時を過ぎているため、エントリーできません。',
}

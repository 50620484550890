import { red, grey } from '@mui/material/colors'
import { Box, Stack } from '@mui/material'
import { H2, Body, Lead, SmallBody } from 'components/styledFonts'
import Block from 'components/Block'
import { LV, Section, Strong, formatDate } from './Request'


const RequestComponent = ({ data }) => {
    return (
        <Stack
            gap={4}
        >
            <Block>
                <Stack
                    alignItems="flex-start"
                    gap={1.5}
                    sx={{ pl: { sm: 2, xs: 0 } }}
                >
                    <Box
                        bgcolor={grey[200]}
                        py={0.5}
                        px={1}
                    >
                        <Body>{data.媒体名_企業公開用}</Body>
                    </Box>
                    <H2>{data.リクエストタイトル}<Lead component="span" fontWeight={400}> を募集中！</Lead></H2>
                    <Stack
                        direction="row"
                        flexWrap="wrap"
                        columnGap={3.5}
                        rowGap={1}
                    >
                        <LV label="募集開始" value={formatDate(data.募集開始日時_通常用)} />
                        <LV label="締切" value={formatDate(data.募集締切日時)} />
                        <LV label="掲載予定" value={data.掲載予定日未定 ? "未定" : data.掲載予定日} />
                        <LV label="取材予定" value={data.取材予定日未定 ? "未定" : data.取材予定日} />
                    </Stack>
                </Stack>
            </Block>
            <Block>
                <SmallBody
                    sx={{
                        color: red[400],
                        pl: { sm: 2, xs: 0 },
                        pb: 2,
                        lineHeight: 2,
                    }}
                >
                    リサーチマッチング成立後、記事掲載のお話になった場合は、<Strong>事前の原稿確認・掲載後の修正は基本できません。</Strong><br />

                    広告とは異なり、メディア側が無償で自社記事として作成するコンテンツとなりますため、編集権限はメディア側のみとなります。<br />
                    <Strong>ご理解の上、エントリーをお願いいたします。</Strong>
                </SmallBody>
            </Block>
            <Section
                title="媒体の概要"
                value={data.媒体概要_連携用}
            />
            <Section
                title="詳細"
                value={data.詳細_クライアント公開用}
            />
        </Stack>
    )
}

export default RequestComponent

import PreviewEditor from './PreviewEditor'
import { useIntroduction, useRecommendation, useStory } from 'hooks/inquiry'


const EditStory = () => {
    const { data : story } = useStory()
    const { data : intoro } = useIntroduction()
    const { data : recomm } = useRecommendation()

    return (
        <PreviewEditor
            category="社長ストーリー"
            data={story}
            leftCategory="こんな会社"
            leftData={intoro}
            rightCategory="イチオシ！"
            rightData={recomm}
        />
    )
}

export default EditStory

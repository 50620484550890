import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Pagination, Stack } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Body, H5 } from 'components/styledFonts'


export const AccordionDetail = ({ label, value, labelWidth="30%" }) => (
    <Stack
        direction="row"
        width="100%"
    >
        <Body
            width="30%"
            flexShrink={0}
        >
            {label}
        </Body>
        <Body>：</Body>
        <Body width="70%" sx={{ wordBreak : "break-all" }}>
            {value}
        </Body>
    </Stack>
)

export const Accordion = ({ data, keyName, summaryName, detail }) => {
    const [expanded, setExpanded] = useState(false)

    const handleChangeExpand = m => (e, isExpanded) => {
        setExpanded(isExpanded ? m : false)
    }

    return data.map(d => (
        <MuiAccordion
            key={d[keyName]}
            expanded={expanded === d[keyName]}
            onChange={handleChangeExpand(d[keyName])}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Stack
                    pr={1}
                >
                    <H5>{d[summaryName]}</H5>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                {detail(d)}
            </AccordionDetails>
        </MuiAccordion>
    ))
}

export const PaginationAccordion = ({ data, keyName, summaryName, summary, detail, max=20, defaultValue, calcPage }) => {
    const [page, setPage] = useState(1)
    const [expanded, setExpanded] = useState(defaultValue)
    const navi = useNavigate()

    useEffect(() => {
        if (calcPage) {
            setPage(calcPage())
        } else {
            setPage(1)
        }
    }, [data, calcPage])

    const handleChange = (e, p) => {
        setPage(p)
        window.scrollTo({
            top : 0,
            behavior : 'smooth',
        })
    }

    const handleChangeExpand = (m) => (e, isExpanded) => {
        if (calcPage) {navi(`/messages/${m}`)}
        setExpanded(isExpanded ? m : false)
    }

    const startIndex = (page - 1) * max

    return (
        <Stack
            alignItems="center"
        >
            {data && (<>
            <Stack
                width="100%"
                gap={0}
            >
                {data.slice(startIndex, startIndex + max).map((d, i) => (
                <MuiAccordion
                    key={d[keyName]}
                    expanded={expanded === d[keyName]}
                    onChange={handleChangeExpand(d[keyName])}
                    TransitionProps={{ unmountOnExit : true }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Stack
                            pr={1}
                        >
                            {summary ?
                            summary(d)
                            :
                            <H5>{d[summaryName]}</H5>
                            }
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        {detail(d, i)}
                    </AccordionDetails>
                </MuiAccordion>
                ))}
            </Stack>
            <Pagination
                count={Math.floor(data.length / max) + (data.length % max ? 1 : 0)}
                variant="outlined"
                shape="rounded"
                page={page}
                siblingCount={0}
                onChange={handleChange}
                sx={{ pt : 3 }}
            />
            </>)}
        </Stack>
    )
}


import { getUnixTime } from 'date-fns'
import { useNavigate } from 'react-router'
import { apiName } from 'config/api'
import { useAccounts } from 'hooks/account'
import { useLoginUser } from 'hooks/loginuser'
import { useS3Config } from 'hooks/variousHooks'
import { API } from 'lib/api'
import { uploadS3 } from 'lib/common'
import { files as fileNames } from 'pages/inquiry/PreviewEditor'
import useMyMutation from './useMyMutation'

export const useInquiryMutaion = () => {
    const { data: user } = useLoginUser()
    const { data: accounts } = useAccounts()
    const { data: s3Config } = useS3Config()
    const navi = useNavigate()
    const category = { 社長ストーリー: 'story', こんな会社: 'introduction', 'イチオシ！': 'recommendation' }

    return useMyMutation({
        mutationFn: async (params) => {
            const form = params.form
            fileNames.filter(d => !form[d]?.blob).forEach(d => form[d] = form[d]?.url || '')

            // S3操作
            const files = fileNames
                .filter(d => form[d]?.blob)
                .reduce((o, d) => ({ ...o, [d] : form[d].blob }), {})
            if (Object.keys(files).length) {
                const prefix = `${user.企業ID}/${category[params.categoryName]}/${getUnixTime(new Date())}`
                // S3にアップロードしデータアクセス時のS3-URLを取得
                const s3Urls = await uploadS3(files, prefix, s3Config)
                Object.keys(s3Urls).forEach(d => form[d] = s3Urls[d])
            }

            // フォーム情報をkintone用に加工
            const tempAccount = accounts.find(c => c.企業担当者ID === form.問い合わせ担当者_上書き)
            form.問い合わせ担当者_上書き = tempAccount?.姓 + tempAccount?.名 || ""
            form.企業ID = user.企業ID
            form.更新者_データ連携用 = user.姓 + user.名
            form.カテゴリ = params.categoryName
            form.公開日 = form.投稿日時

            // 企業探訪登録更新APIの呼び出し
            const ret = await API.post(apiName, "/items/postInquiry", {
                body: {
                    requestFeedback: form.フィードバック依頼 && !form.draft && !form.フィードバックNo,
                    id: params.id,
                    inquiryNo: params.inquiryNo,
                    record: form,
                }
            })

            return ret
        },
        queryKey: ['inquiry', 'feedback'],
        onSettledFn : (ret) => {
            if (/^OK_POST/.test(ret.result)) navi('/inquiry/top')
        },
    })

}

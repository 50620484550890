import { useEffect, useMemo } from 'react' 
import { Link, useBeforeUnload, useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { Stack } from '@mui/material'
import { red } from '@mui/material/colors'
import Block from 'components/Block'
import { BlueOutlinedButton, LinkButtonLarge } from 'components/styledButtons'
import { H1, H4, H5 } from 'components/styledFonts'
import TwoColumns from 'components/TwoColumns'
import { useAccounts } from 'hooks/account'
import AttachedFiles from 'pages/post/AttachedFiles'
import Company from 'pages/post/Company'
import { fileNameArray } from './NewsLetterSubmissionBase'
import NewsLetterDetail from './NewsLetterDetail'

const buttonSize = {
    width: 240,
    height: 44,
}

const NewsLetterPreview = () => {
    const { getValues, watch, handleSubmit, onSubmit } = useFormContext()
    const navi = useNavigate()
    const { data : accountData } = useAccounts()

    useEffect(() => {
        if (!getValues('ネタたねタイトル')) navi('/post/list/newsletter')
    }, [getValues, navi])

    useBeforeUnload((e) => {
        e.preventDefault()
    })

    const overWrite = useMemo(() => {
        const overWriteUser = accountData?.find(d => d.企業担当者ID === watch("問い合わせ担当者_上書き"))
        return {
            問い合わせ担当者_上書き : overWriteUser?.姓 + overWriteUser?.名,
            問い合わせ担当者部署_上書き : watch("問い合わせ担当者部署_上書き")
        }
    }, [accountData, watch])

    return (
        <Stack
            gap={2}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                pb={2}
            >
                <H1>情報発信 詳細</H1>
                <BlueOutlinedButton
                    label="メディアリスト"
                    component={Link}
                    to="/medialist"
                    sx={{
                        width : { sm : 280, xs : 200 },
                        height : 44,
                    }}
                />

            </Stack>
            <TwoColumns
                ratio="8fr 4fr"
            >
                <NewsLetterDetail data={getValues()} />
                <Stack
                    alignItems="center"
                    gap={2}
                    sx={{ alignSelf : "start" }}
                >
                    <Block>
                        <H4
                            sx={{
                                pt : 0.5,
                                color : red[400],
                            }}
                        >
                            プレビューを表示中です。
                        </H4>
                        <H5
                            sx={{color : red[400]}}
                        >
                            他のページへ移動すると保存していないデータは消去されます。
                        </H5>
                    </Block>
                    <Company 
                        overWrite={overWrite}
                    />
                    <AttachedFiles files={fileNameArray.map(c => getValues(c)).filter(c => c)} />
                </Stack>
            </TwoColumns>
            <Stack
                gap={2}
                pt={2}
                pb={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <LinkButtonLarge
                    onClick={() => navi(-1)}
                >
                    作成画面に戻る
                </LinkButtonLarge>
                <BlueOutlinedButton
                    label="投稿"
                    type="submit"
                    sx={buttonSize}
                >
                    投稿
                </BlueOutlinedButton>
            </Stack>
        </Stack>
    )
}

export default NewsLetterPreview

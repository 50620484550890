import { Auth } from 'aws-amplify'
import awsconfig from 'aws-exports'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import beautify from 'js-beautify'


export const createS3Client = async () => {

    // トークン取得（更新）
    const cs = await Auth.currentSession()

    // S3Client生成
    const cognitoId = cs.idToken.payload.iss.replace('https://', '')
    return new S3Client({
        region : awsconfig.aws_project_region,
        credentials : fromCognitoIdentityPool({
            clientConfig : { region : awsconfig.aws_project_region },
            identityPoolId : awsconfig.aws_cognito_identity_pool_id,
            logins : {
                [cognitoId] : cs.idToken.jwtToken,
            },
        }),
    })
}

export const uploadS3 = async (files, prefix, s3Config) => {

    const s3 = await createS3Client()

    // S3のアップロードkey
    const keys = Object.keys(files).reduce((o, d, i) => ({ ...o, [d] : `${prefix}/${new Date().getTime()}${i}` }), {})

    // S3へアップロード
    await Promise.all(Object.keys(files).map(d => s3.send(new PutObjectCommand({
        Bucket: s3Config.bucketName,
        Key: keys[d],
        Body: files[d],
    }))))

    // データアクセス時のS3-URLを返却
    Object.keys(keys).forEach(d => keys[d] = `${s3Config.pathPrefix}/${keys[d]}`)
    return keys
}

// kintone -> HTMLエディタ表示時の整形処理
export const htmlBeautify = s => {
    return beautify.html(s)
        .replace(/<br \/>/g, '<br>')
        .replace(/&#x([0-9a-f]+);/ig, (m, $1) => String.fromCharCode(`0x${$1}`))
        .replace(/&#([0-9]+);/ig, (m, $1) => String.fromCharCode($1))
}

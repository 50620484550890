import { Link } from 'react-router-dom'
import { Box, Button, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { grey, indigo } from '@mui/material/colors'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { Body, H1, H4, Lead } from 'components/styledFonts'
import Block from 'components/Block'

const s3path = 'https://s3-ap-northeast-1.amazonaws.com/test.netamoto.jp/doc'


const data = [
    {
        タイトル : '新製品発売',
        説明 : '新製品発売時に作成するプレスリリースです。ただ「発売します」というだけではニュースになりません。従来品や他社製品との違いをアピールします。発売前に発信することをお勧めします。',
        url : `${s3path}/product.docx`,
    },
    {
        タイトル : '既存製品売上好調',
        説明 : '既存製品の売上が好調だということを知らせるプレスリリースです。具体的な数値で示しながら、その要因を解説します。既存製品を知らなかった報道関係者へ再度アピールできるチャンスです。',
        url : `${s3path}/sales.docx`,
    },
    {
        タイトル : '読者プレゼント提供',
        説明 : '新製品発売時などひとつでも多くの掲載を獲得するための、雑誌などの読者プレゼントコーナー向けのプレスリリースです。景品表示法により媒体毎に条件も違うため、価格なども必ず記載してください。',
        url : `${s3path}/present.docx`,
    },
    {
        タイトル : 'イベント開催告知',
        説明 : 'イベント開催時や展示会出展時に、事前の告知掲載や取材誘致のために発信するプレスリリースです。何のために開催するのか、どのようなことが行われるのかなど、具体的に記載してアピールします。',
        url : `${s3path}/event.docx`,
    },
    {
        タイトル : 'アンケート調査結果発表',
        説明 : '自社調べのアンケート調査をまとめたプレスリリースです。客観的データを使用して世間の問題点やトレンド、状況などを示すことで、自社の製品やサービスなどにも注目してもらいやすい環境をつくります。',
        url : `${s3path}/research.docx`,
    },
    {
        タイトル : '訂正リリース',
        説明 : '発信済のプレスリリースに間違いがあった場合には、訂正リリースが必要です。既に記事化を進めている報道関係者がいるかもしれないため、どこが間違っていたのかを示し、正しい情報を伝えます。',
        url : `${s3path}/correction.docx`,
    },
]

const Explanation = styled(Box)({
    fontSize : 13,
    color : grey[500],
    lineHeight : 1.4,
    minHeight : 130,
})

const WordTemplates = () => {
    return (
        <Stack
            gap={2}
        >
            <H1>プレスリリース テンプレート</H1>
            <Body>プレスリリースの雛形（Word形式）をダウンロードできます。</Body>
            <Stack
                flexWrap="wrap"
                gap={2}
                px={2}
                sx={{
                    flexDirection : { mobile : "row", xs : "column" },
                    alignItems : { mobile : "stretch", xs : "center" },
                }}
            >
                {data.map((d, i) => (
                <Button
                    key={i}
                    component={Link}
                    to={d.url}
                    disableRipple
                    disableFocusRipple
                    sx={{ color : '#000', p : 0 }}
                >
                    <Block
                        sx={{
                            maxWidth : 282,
                            '&:hover' : {
                                opacity : 0.8,
                            },
                            py : 1.5,
                            px : 2.5,
                        }}
                    >
                        <Stack
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Stack
                                alignItems="center"
                                gap={1.5}
                            >
                                <H4>{d.タイトル}</H4>
                                <Explanation>
                                    {d.説明}
                                </Explanation>
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                            >
                                <SaveAltIcon sx={{ color : indigo[800] }} />
                                <Lead sx={{ color : indigo[800] }}>ダウンロード</Lead>
                            </Stack>
                        </Stack>
                    </Block>
                </Button>
                ))}
            </Stack>
        </Stack>
    )
}

export default WordTemplates


import { Link, useLocation } from 'react-router-dom'
import { Stack, Modal, IconButton, Fade } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { H2 } from 'components/styledFonts'
import { useAtom } from 'jotai'
import { BlueOutlinedButton, LinkButtonMiddle } from 'components/styledButtons'
import { submissionModalAtom } from 'store/commonAtoms'

const buttonSize = {
    width: 180,
    height: 40,
}

const SubmissionMethodModal = () => {

    const loc = useLocation()
    const [open, setOpen] = useAtom(submissionModalAtom)

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Fade in={open} timeout={1000}>
                <Stack
                    bgcolor="#fff"
                    sx={{
                        width: { mobile: 480, xs: 320 },
                    }}
                    pt={3}
                    pb={4}
                    px={4}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <H2>{loc.pathname === '/post/list/pressrelease' ? "プレスリリース" : "ニュースレター"}新規作成</H2>
                        <IconButton
                            sx={{ mt: -3, mr: -3 }}
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        alignItems="center"
                        pt={3}
                        gap={4}
                    >
                        <Stack
                            sx={{
                                flexDirection: { mobile: "row", xs: "column" },
                            }}
                            columnGap={4}
                            rowGap={4}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <BlueOutlinedButton
                                label="テキスト(HTML)入稿"
                                component={Link}
                                to={/pressrelease/.test(loc.pathname) ? "/pressrelease/submission/html" : "/newsletter/submission/html"}
                                sx={buttonSize}
                                onClick={() => setOpen(false)}
                            />
                            <BlueOutlinedButton
                                label="PDF入稿"
                                component={Link}
                                to={/pressrelease/.test(loc.pathname) ? "/pressrelease/submission/pdf" : "/newsletter/submission/pdf"}
                                sx={buttonSize}
                                onClick={() => setOpen(false)}
                            />
                        </Stack>
                        <LinkButtonMiddle
                            onClick={() => setOpen(false)}
                            sx={buttonSize}
                        >
                            キャンセル
                        </LinkButtonMiddle>
                    </Stack>
                </Stack>
            </Fade>
        </Modal>
    )
}

export default SubmissionMethodModal

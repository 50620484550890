import { blueGrey } from '@mui/material/colors'

export const WebIcon = (props) => (
    <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill={blueGrey[400]}
        {...props}
    >
        <path
            d="M19.892 19.198c-.755-.51-1.581-.94-2.462-1.283.593-1.656.931-3.527.978-5.454h4.268a10.646 10.646 0 0 1-2.784 6.737ZM1.324 12.46h4.499c.045 1.897.374 3.74.95 5.376a12.67 12.67 0 0 0-2.665 1.36 10.646 10.646 0 0 1-2.784-6.736Zm2.75-7.622a12.65 12.65 0 0 0 2.68 1.376C6.217 7.76 5.9 9.486 5.83 11.267H1.34a10.646 10.646 0 0 1 2.735-6.428Zm8.523 6.428V7.129a15.13 15.13 0 0 0 3.723-.604c.511 1.44.816 3.061.885 4.742h-4.608Zm0 5.664v-4.47h4.616c-.046 1.798-.361 3.537-.912 5.068a15.136 15.136 0 0 0-3.704-.598Zm2.974 2.248c-.808 1.507-1.842 2.594-2.974 3.148v-4.2a13.96 13.96 0 0 1 3.246.512c-.087.184-.177.365-.272.54Zm1.41-.159c.724.282 1.407.628 2.037 1.032a10.67 10.67 0 0 1-4.384 2.306c.749-.678 1.423-1.559 1.99-2.614.125-.234.244-.476.357-.724Zm-9.374.724c.587 1.094 1.29 2 2.071 2.688a10.664 10.664 0 0 1-4.696-2.38c.686-.44 1.436-.811 2.233-1.106.123.274.254.54.392.798Zm3.796-1.618v4.08c-1.041-.583-1.99-1.623-2.744-3.027a11.455 11.455 0 0 1-.298-.596c.97-.26 1.993-.414 3.042-.457Zm0-5.665v4.47a15.181 15.181 0 0 0-3.495.538c-.537-1.516-.846-3.233-.891-5.008h4.386Zm0-5.332v4.138H7.026c.067-1.657.364-3.257.863-4.682a15.18 15.18 0 0 0 3.514.544ZM8.659 4.82c.754-1.404 1.703-2.444 2.744-3.027v4.14a14.006 14.006 0 0 1-3.066-.464c.102-.222.21-.44.322-.65Zm-1.467.284c-.804-.3-1.559-.675-2.25-1.122a10.665 10.665 0 0 1 4.736-2.415c-.78.687-1.484 1.594-2.071 2.688-.147.273-.285.557-.415.849Zm9.431-.85c-.566-1.054-1.24-1.935-1.989-2.613a10.672 10.672 0 0 1 4.423 2.34 11.48 11.48 0 0 1-2.052 1.048c-.12-.265-.247-.524-.382-.774Zm-4.026-2.582c1.132.554 2.166 1.641 2.974 3.148.104.192.202.39.296.593a13.95 13.95 0 0 1-3.27.52v-4.26Zm10.064 9.594h-4.262c-.07-1.81-.397-3.565-.95-5.13a12.579 12.579 0 0 0 2.477-1.298 10.646 10.646 0 0 1 2.735 6.428ZM12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0Z"
            fill="#7B89A1"
            fillRule="evenodd"
        />
    </svg>
)

export const MagazineIcon = (props) => (
    <svg
        viewBox="0 0 400 480.31"
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill={blueGrey[400]}
        {...props}
    >
        <title>{"icon_magazine"}</title>
        <path d="M254.81 136.92a17.17 17.17 0 0 1-14.33 19.49l-153.26 22.4a16.67 16.67 0 0 1-2.42.18 17.25 17.25 0 0 1-2.38-34.27l153.26-22.39a17 17 0 0 1 19.13 14.6ZM370.7 450.46H222.42L328.16 436a11.41 11.41 0 0 0 9.84-11.37V70.42h32.7ZM29.3 70.61l279.43-38.13v376.08L29.3 446.69Zm356.06-30H338V11.48A11.38 11.38 0 0 0 326.78 0a11.2 11.2 0 0 0-1.42.09L9.87 43.15A11.41 11.41 0 0 0 0 54.54v413.15a11.38 11.38 0 0 0 11.25 11.48 14.36 14.36 0 0 0 5.61 1.14h368.49A14.79 14.79 0 0 0 400 465.38V55.5a14.79 14.79 0 0 0-14.65-14.93Z" />
    </svg>
)

export const NewspaperIcon = (props) => (
    <svg
        viewBox="0 0 450 409.41"
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill={blueGrey[400]}
        {...props}
    >
        <title>{"icon_newspaper"}</title>
        <path d="M315.7 196.83a12.24 12.24 0 0 0-12.1-12.37h-92.4a12.38 12.38 0 0 0 0 24.74h92.4a12.24 12.24 0 0 0 12.1-12.37Zm0-61.86a12.24 12.24 0 0 0-12.1-12.37h-92.4a12.38 12.38 0 0 0 0 24.74h92.4A12.24 12.24 0 0 0 315.7 135ZM82.5 198.52h63.8v-65.24H82.5Zm75.9 24.74h-88a12.24 12.24 0 0 1-12.1-12.37v-90a12.24 12.24 0 0 1 12.1-12.37h88a12.24 12.24 0 0 1 12.1 12.37v90a12.24 12.24 0 0 1-12.1 12.37Zm157.3 102.92a12.24 12.24 0 0 0-12.1-12.37H70.4a12.38 12.38 0 0 0 0 24.74h233.2a12.24 12.24 0 0 0 12.1-12.37Zm0-58.49a12.24 12.24 0 0 0-12.1-12.37H70.4a12.38 12.38 0 0 0 0 24.74h233.2a12.24 12.24 0 0 0 12.1-12.37Zm0-192.33A12.24 12.24 0 0 0 303.6 63H70.4a12.38 12.38 0 0 0 0 24.74h233.2a12.24 12.24 0 0 0 12.1-12.37ZM28.6 29.24V361a18.93 18.93 0 0 0 18.7 19.12h355.4A18.94 18.94 0 0 0 421.4 361V85.48H374v220a5.57 5.57 0 0 0 5.5 5.62h2.2a14.63 14.63 0 0 1 0 29.24h-2.2c-18.8 0-34.1-15.64-34.1-34.87V29.24Zm374.1 380.17H47.3C21.22 409.41 0 387.71 0 361V14.62A14.46 14.46 0 0 1 14.3 0h345.4A14.46 14.46 0 0 1 374 14.62v41.62h61.7A14.46 14.46 0 0 1 450 70.86V361c0 26.67-21.22 48.36-47.3 48.36Z" />
    </svg>
)

export const RadioIcon = (props) => (
    <svg
        viewBox="0 0 450 334.99"
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill={blueGrey[400]}
        {...props}
    >
        <title>{"icon_radio"}</title>
        <path d="M27.46 166.16a202.76 202.76 0 0 1 57-141.73 14.24 14.24 0 0 0-.12-19.83 13.53 13.53 0 0 0-19.42.13C23 48 0 105.34 0 166.16c0 62.34 24 120.69 67.71 164.3a13.53 13.53 0 0 0 19.42-.22 14.24 14.24 0 0 0-.22-19.83c-38.34-38.29-59.45-89.52-59.45-144.25m357.09-162a13.53 13.53 0 0 0-19.42-.06 14.24 14.24 0 0 0-.06 19.84c37.05 38.06 57.46 88.58 57.46 142.24 0 55-21.29 106.41-59.95 144.75a14.24 14.24 0 0 0-.29 19.83 13.53 13.53 0 0 0 19.42.29c44-43.67 68.28-102.22 68.28-164.87 0-61.13-23.24-118.67-65.45-162M90.45 168.86a137.38 137.38 0 0 1 40.66-98.25 14.24 14.24 0 0 0 .27-19.83 13.53 13.53 0 0 0-19.38-.29 167.52 167.52 0 0 0-1.17 235.56 13.53 13.53 0 0 0 19.42-.08 14.24 14.24 0 0 0-.08-19.84 137.55 137.55 0 0 1-39.69-97.28M337.24 50.49a13.53 13.53 0 0 0-19.42.28 14.24 14.24 0 0 0 .27 19.83 139.05 139.05 0 0 1 1 195.53A14.24 14.24 0 0 0 319 286a13.53 13.53 0 0 0 19.42.08 167.53 167.53 0 0 0-1.17-235.56M176.32 96.67a13.53 13.53 0 0 0-19.41-.49 101.77 101.77 0 0 0-1.25 144.12 13.53 13.53 0 0 0 19.42-.14 14.24 14.24 0 0 0-.14-19.83 73.3 73.3 0 0 1 .91-103.83 14.24 14.24 0 0 0 .48-19.83m116-.49a13.53 13.53 0 0 0-19.41.49 14.24 14.24 0 0 0 .48 19.83 73.3 73.3 0 0 1 .91 103.83 14.24 14.24 0 0 0-.14 19.83 13.53 13.53 0 0 0 19.42.14 101.77 101.77 0 0 0-1.29-144.12m-36 72.68c0 17.88-14.19 32.37-31.69 32.37s-31.69-14.49-31.69-32.37 14.19-32.37 31.69-32.37 31.69 14.49 31.69 32.37" />
    </svg>
)

export const TvIcon = (props) => (
    <svg
        viewBox="0 0 90 80.24"
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill={blueGrey[400]}
        {...props}
    >
        <title>{"icon_tv"}</title>
        <path d="M86.71 0H3.29A3.33 3.33 0 0 0 0 3.36v60.52a3.33 3.33 0 0 0 3.29 3.36h28v6.28h-6.92A1.33 1.33 0 0 0 23 74.86v4a1.33 1.33 0 0 0 1.32 1.35h41.31A1.33 1.33 0 0 0 67 78.9v-4a1.33 1.33 0 0 0-1.32-1.35h-6.96v-6.31h28A3.33 3.33 0 0 0 90 63.88V3.36A3.33 3.33 0 0 0 86.71 0Zm-3.3 60.52H6.59V6.72h76.82Z" />
    </svg>
)


import { Link, useParams } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { H1, H2, H5 } from 'components/styledFonts'
import { EditButton, LinkButtonLarge } from 'components/styledButtons'
import Block from 'components/Block'
import Unregistered from 'components/Unregistered'
import S3Image from 'components/S3Image'
import Detail from './Detail'
import { useIntroduction, useRecommendation, useStory } from 'hooks/inquiry'
import FeedbackResult from 'components/FeedbackResult'
import { useFeedbackByNo } from 'hooks/feedback'
import TwoColumns from 'components/TwoColumns'


const links = {
    '社長ストーリー' : '/inquiry/story',
    'こんな会社' : '/inquiry/intoroduction',
    'イチオシ！' : '/inquiry/recommendation',
}

const selectInquiry = (category, story, intoro, recomm) => {
    return category === "社長ストーリー" 
        ? story
        : category === "こんな会社"
            ? intoro
            : recomm
}

const LinkBlock = ({ category, data }) => {

    return (
        <Block
            height="100%"
        >
            <Stack
                justifyContent="space-between"
                height="100%"
            >
                <Stack
                    gap={1}
                    pb={1.5}
                >
                    <H2>{category}</H2>
                    {!data &&
                    <Unregistered mt={0} />
                    }
                    {data &&
                    <Stack
                        direction="row"
                        gap={1}
                        px={1}
                        width="100%"
                    >
                        <Box
                            width={100}
                            height={65}
                            flexShrink={0}
                        >
                            <S3Image
                                url={data.サムネイル}
                            />
                        </Box>
                        <H5
                        >{data.タイトル}</H5>
                    </Stack>
                    }
                </Stack>
                <Stack
                    alignItems="center"
                >
                    <EditButton
                        component={Link}
                        to={links[category]}
                    >
                        {data ? "編集" : "作成"}
                    </EditButton>
                </Stack>
            </Stack>
        </Block>
    )
}

const InquiryFeedback = () => {
    const { fbno } = useParams()
    const { data : fb } = useFeedbackByNo(fbno)
    const { data : intoro } = useIntroduction()
    const { data : recomm } = useRecommendation()
    const { data : story } = useStory()

    return (
        <Stack
            gap={1.5}
        >
            <Stack
                gap={2}
            >
                <H1>企業探訪 {fb?.企業探訪カテゴリ}</H1>
                <TwoColumns
                    ratio="8fr 4fr"
                >
                    <Detail
                        data={selectInquiry(fb?.企業探訪カテゴリ, story, intoro, recomm)}
                    />
                    <Stack
                        gap={2}
                    >
                        <FeedbackResult fbno={fbno}/>
                        <Stack
                            sx={{ width : { sm : "60%", xs : "70%" } }}
                            alignSelf='center'
                        >
                            <LinkButtonLarge
                                component={Link}
                                to={links[fb?.企業探訪カテゴリ]}
                            >
                                {fb?.企業探訪カテゴリ}編集
                            </LinkButtonLarge>
                        </Stack>
                    </Stack>
                </TwoColumns>
            </Stack>
            <Stack
                width="100%"
                alignItems="center"
            >
                <Stack
                    gap={2}
                    sx={{
                        flexDirection : { sm : "row", xs : "column" },
                        width : { sm : "65%", xs : "100%" },
                    }}
                >
                    <Box sx={{ width : { sm : "50%", xs : "100%" } }}>
                        <LinkBlock
                            category={fb?.企業探訪カテゴリ === "社長ストーリー" ? "こんな会社" : "社長ストーリー"}
                            data={fb?.企業探訪カテゴリ === "社長ストーリー" ? intoro : story}
                        />
                    </Box>
                    <Box sx={{ width : { sm : "50%", xs : "100%" } }}>
                        <LinkBlock
                            category={fb?.企業探訪カテゴリ === "イチオシ！" ? "こんな会社" : "イチオシ！"}
                            data={fb?.企業探訪カテゴリ === "イチオシ！" ? intoro : recomm}
                        />
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default InquiryFeedback

import { useQuery } from '@tanstack/react-query'
import { API } from "lib/api"
import { apiName } from "config/api"

const getData = async () => {
    const ret = await API.get(apiName, "/items/getAccounts", {})
    return ret.map(d => ({...d, 姓名 : `${d.姓} ${d.名}`}))
}

export const useAccounts = () => {
    return useQuery(['account'], getData)
}
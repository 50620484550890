import { Alert, Box, Stack } from '@mui/material'
import Block from 'components/Block'
import { H2 } from 'components/styledFonts'
import { BlockButton } from 'components/styledButtons'

const ComponentBase = ({ title, count, buttons=[], grow, children }) => {
    return (
        <Block>
            <Stack
                height="100%"
                gap={2}
            >
                {title &&
                <H2>{title}</H2>
                }
                <Box
                    flexGrow={grow ? 1 : 0}
                    px={1.3}
                    sx={{
                        "@media screen and (max-width:480px)": {
                            px : 0,
                        },
                    }}
                >
                    {!children &&
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        p={4}
                    >
                        <Alert
                            variant="outlined"
                            severity="info"
                        >
                            {title}はありません
                        </Alert>
                    </Stack>
                    }
                    {children}
                </Box>
                {buttons.length > 0 &&
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    gap={1}
                    pr={0.5}
                >
                    {buttons.map((b, i) => (
                    <BlockButton key={i} to={b.to} label={b.label} />
                    ))}
                </Stack>
                }
            </Stack>
        </Block>
    )
}

export default ComponentBase

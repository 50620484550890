import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAtom } from 'jotai'
import { Pagination, Stack } from '@mui/material'
import TableList from 'components/TableList'
import { searchPageAtom } from 'store/commonAtoms'

const TablePaginationList = ({ headers, data, max=20 }) => {
    const [page, setPage] = useAtom(searchPageAtom)
    const { pathname } = useLocation()

    useEffect(() => {
        if (!/research/.test(pathname)) setPage(1)
    }, [data, pathname, setPage])

    const handleChange = (e, p) => {
        setPage(p)
        window.scrollTo({
            top : 0,
            behavior : 'smooth',
        })
    }

    const startIndex = (page - 1) * max

    return (
        <Stack
            alignItems="center"
        >
            <TableList
                headers={headers}
                data={data?.slice(startIndex, startIndex + max)}
            />
            {data &&
            <Pagination
                count={Math.floor(data.length / max) + (data.length % max ? 1 : 0)}
                variant="outlined"
                shape="rounded"
                page={page}
                defaultPage={1}
                onChange={handleChange}
                sx={{ pt : 3 }}
            />
            }
        </Stack>
    )
}

export default TablePaginationList

import { Outlet } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { messages } from 'config/messages'

export const files = ['メイン画像', '画像1', '画像2', '画像3', '画像4', 'PDF']

export const defaultValues = {
    $id : null,
    $revision : null,
    エントリーNo : null,
    タイトル : '',
    下書き : false,
    問い合わせ担当者_上書き : '',
    問い合わせ担当者部署_上書き : '',
    ...files.reduce((o, f) => {
        o[f] = null 
        o[`${f}オリジナルファイル名`] = ''
        if (/画像/.test(f)) o[`${f}キャプション`] = ''
        return o
    }, {}),
    ...[...Array(20).keys()].reduce((o, n) => ({ ...o, [`回答${n + 1}`] : '' }), {}), 
    ...[...Array(5).keys()].reduce((o, n) => ({ ...o, [`回答${n + 1}_ネタもとからの質問`] : '' }), {}), 
}

const yupSchema = {
    タイトル : yup
        .string()
        .required(messages.REQUIRED)
    ,
    メイン画像 : yup
        .object()
        .required(messages.SELECTION_REQUIRED)
    ,
    質問: yup.array(),
    ネタもとからの質問: yup.array(),
}

;[...Array(20).keys()].forEach(q => {
    yupSchema[`回答${q + 1}`] = yup
    .string().when('質問',{
        is: (質問) => 質問.includes(q + 1),
        then: (schema) => schema.required(messages.REQUIRED)
    })
})

;[...Array(5).keys()].forEach(q => {
    yupSchema[`回答${q + 1}_ネタもとからの質問`] = yup
    .string().when('ネタもとからの質問',{
        is: (ネタもとからの質問) => ネタもとからの質問.includes(q + 1),
        then: (schema) => schema.required(messages.REQUIRED)
    })
})

const schema = yup.object().shape(yupSchema)

const ResearchEntryBase = () => {
    const methods = useForm({ defaultValues : defaultValues, resolver : yupResolver(schema) })

    return (
        <FormProvider {...methods}>
            <Outlet />
        </FormProvider>
    )
}

export default ResearchEntryBase

import { Link } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { H1 } from 'components/styledFonts'
import { LinkButtonMiddle } from 'components/styledButtons'
import Block from 'components/Block'
import ProgramListTable from './ProgramListTable'
import { useProgramList, seminarTypes } from 'hooks/program'

const ProgramList = () => {
    const { data } = useProgramList()
    console.log(data)

    return (
        <Stack
            gap={2}
        >
            <H1>プログラム申込</H1>
            <Block
                sx={{ px : { pad : 3, xs : 1 } }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Box mt={1}>
                        <LinkButtonMiddle
                            component={Link}
                            to="/program/history"
                        >
                            申し込み履歴
                        </LinkButtonMiddle>
                    </Box>
                </Stack>
                <Box
                    my={1}
                    borderBottom={`1px solid ${grey[400]}`}
                />
                <Stack
                    gap={2.5}
                >
                    {seminarTypes.map(t => (
                    <ProgramListTable
                        key={t.label}
                        title={t.label}
                        data={data?.[t.label]}
                    />
                    ))}
                </Stack>
            </Block>
        </Stack>
    )
}

export default ProgramList

import { format } from 'date-fns'
import { Stack, useMediaQuery } from '@mui/material'
import { H5Overflow } from 'components/styledFonts'
import { BlueLabel, DraftLabel, GreyLabel, WhiteLabel } from 'components/styledLabels'
import { UnderlineLink } from 'components/links'
import Thumbnail from 'components/Thumbnail'
import PaginationList from 'components/PaginationList'
import { red } from '@mui/material/colors'
import ReportIcon from '@mui/icons-material/Report'

const ymdhm = d => format(d, 'yyyy/MM/dd HH:mm')

const PostList = ({ data }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))

    return (
        <PaginationList
            max={10}
            gap={5}
            data={data?.map((d, i) => (
                <Stack
                    key={i}
                    gap={1}
                >
                    <Stack
                        direction="row"
                        gap={1}
                        flexWrap="wrap"
                    >
                        {d.却下 &&
                        <ReportIcon sx={{ color : red[400] }}/>
                        }
                        {d.クライアント公開 &&
                        <BlueLabel>フィードバックあり</BlueLabel>
                        }
                        <WhiteLabel>{d.type}</WhiteLabel>
                        {d.下書き &&
                        <DraftLabel>下書き</DraftLabel>
                        }
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        gap={1}
                    >
                        <Thumbnail
                            src={d.サムネイル}
                            midoku={d.却下 && d.未読}
                            newly={d.クライアント公開 && d.未読}
                            to={d.to}
                        />
                        <Stack
                            justifyContent="space-between"
                            gap={1}
                            minHeight={64}
                        >
                            <H5Overflow>
                                <UnderlineLink
                                    to={d.to}
                                >
                                    {d.タイトル || "（タイトルなし）"}
                                </UnderlineLink>
                            </H5Overflow>
                            <Stack
                                direction="row"
                                gap={1}
                                flexWrap="wrap"
                            >
                                {d.公開日 &&
                                <GreyLabel>{`公開日：${d.公開日 ? ymdhm(d.公開日) : ""}`}</GreyLabel>
                                }
                                {!isMobile && (<>
                                <GreyLabel>{`更新日：${d.更新日 ? ymdhm(d.更新日) : ""}`}</GreyLabel>
                                <GreyLabel>{`更新者：${d.更新者}`}</GreyLabel>
                                </>)}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            ))}
        />
    )
}

export default PostList

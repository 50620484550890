import { useHref } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { grey, red } from '@mui/material/colors'
import { useNewFeedbackCount } from 'hooks/feedback'
import { useNewMessageCount } from 'hooks/message'
import { H5 } from 'components/styledFonts'

const NotificationTag = ({target, count}) => {
    if (!count) return null

    return (
        <Box
            bgcolor="#fff"
            borderRadius={1}
            py={0.3}
            px={1}
        >
            <H5
                color={red[500]}
            >
                {target}が{count}件届いています
            </H5>
        </Box>
    )
}

const Notification = () => {
    const href = useHref()
    const fcnt = useNewFeedbackCount()
    const mcnt = useNewMessageCount()

    if ((href !== '/') || (!fcnt && !mcnt)) return null

    return (
        <Stack
            alignItems="center"
            bgcolor={grey[300]}
            px={1}
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                columnGap={2}
                rowGap={1}
                py={2}
                sx={{
                    "@media screen  and (max-width:500px)" : {
                        flexDirection : "column",
                        py : 1,
                    },
                }}
            >
                <H5>ネタもとからの通知</H5>
                {fcnt > 0 &&
                <NotificationTag
                    target="フィードバック"
                    count={fcnt}
                />
                }
                {mcnt > 0 &&
                <NotificationTag target="メッセージ" count={mcnt} />
                }
            </Stack>
        </Stack>
    )
}

export default Notification

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { isBefore } from 'date-fns'
import { useAtom, useSetAtom } from 'jotai'
import { styled } from '@mui/material/styles'
import { Box, Divider as MuiDivider, Stack, useMediaQuery } from '@mui/material'
import { grey } from '@mui/material/colors'
import { format } from 'date-fns'
import { H1, H5, Body, Body2, SmallBody, StyledLink } from 'components/styledFonts'
import { StatusWhiteButton, StatusBlueButton } from 'components/styledButtons'
import { MagazineIcon, NewspaperIcon, RadioIcon, TvIcon, WebIcon } from 'components/svgIcons'
import { FlagCheck } from 'components/styledChecks'
import { AccordionDetail, PaginationAccordion } from 'components/Accordion'
import Block from 'components/Block'
import TablePaginationList from 'components/TablePaginationList'
import { CheckboxGroup, SearchWord, Select } from 'components/Inputs'
import flagIcon from 'img/icon_flag.png'
import { useAllResearches } from 'hooks/research'
import { useDynamoItem } from 'hooks/dynamo'
import { useDynamoMutation } from 'hooks/mutation/useDynamoMutation'
import { searchCondAtom, searchPageAtom, spinnerAtom } from 'store/commonAtoms'


const Divider = styled(MuiDivider)({
    paddingTop : 8,
    marginBottom : 8,
    borderColor : grey[300],
})

export const defaultValues = {
    検索ワード : "",
    媒体 : [],
    ステータス : [],
    チェック済 : [],
    並び順 : "募集締切日時",
}

const mediumList = ['TV', '新聞', '雑誌', 'ラジオ', 'WEB']
const statusList = [ '下書き', 'エントリー完了', 'マッチング成立', 'マッチング不成立' ]
const sortList = [
    { label : '締切まで長い順', value : '募集締切日時' },
    { label : '新着順', value : '募集開始日時_通常用' },
]

const headers = [
    { label : 'チェック', sx : { pr : 0} },
    { label : 'リサーチ', sx : { width : 200 } },
    { label : '媒体', sx : { width : 200 } },
    { label : '募集開始日', sx : {} },
    { label : '締切日時', sx : {} },
    { label : 'ステータス', sx : {} },
]

const CheckboxFilter = ({ label, control, list }) => (
    <Stack
        direction="row"
        alignItems="center"
        gap={2}
    >
        <H5 sx={{ flexShrink : 0 }}>{label}</H5>
        <CheckboxGroup name={label} control={control} list={list} />
    </Stack>
)

export const Medium = ({ type, name }) => {
    const icon =
        type === 'TV' ? <TvIcon /> :
        type === '新聞' ? <NewspaperIcon /> :
        type === '雑誌' ? <MagazineIcon /> :
        type === 'ラジオ' ? <RadioIcon /> :
        type === 'WEB' ? <WebIcon /> : null

    return (
        <Stack
            direction="row"
            gap={1}
        >
            {icon}
            {name}
        </Stack>
    )
}

const formatDate = d => d ? format(d, 'yy年MM月dd日 HH:mm') : ''

const Status = ({ reqNo, entry, expired }) => {
    if (entry.下書き) {
        if (expired) return <SmallBody>エントリーを締め切りました</SmallBody>
        return <SmallBody>下書き</SmallBody>
    }

    if (entry.結果) {
        return (
            <StyledLink
                sx={{ fontSize : 13 }}
                to={`/research/${reqNo}/${entry.エントリーNo}`}
            >
                {`マッチング${entry.結果}`}
            </StyledLink>
        )
    }

    return <SmallBody>エントリー完了</SmallBody>
}

const EditButton = ({ reqNo, entry, expired }) => {
    if (entry.結果) return null

    const params = `${reqNo}/${entry.エントリーNo}`

    if (expired) {
        return <StatusWhiteButton label={entry.下書き ? "下書き" : "プレビュー"} to={`/research/${params}`} />
    }
    return <StatusWhiteButton label="編集" to={`/research/entry/${params}`} />
}

export const EntryStatus = ({ data, isPad }) => {
    const expired = isBefore(data.募集締切日時, new Date())

    if (data.エントリー.length === 0) {
        if (expired) return null
        return <StatusBlueButton label="エントリーする" to={`/research/entry/${data.リクエストNo}`} />
    }
    // 1つのリクエストに対して3エントリーまで可能
    const reentry = !expired && (data.エントリー.length < 3)
    return (
        <Stack
            alignItems="flex-start"
            py={0.25}
        >
            {data.エントリー.map((e, i) => (
            <Stack
                key={i}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                >
                    <Stack
                        width={110}
                    >
                        <Status entry={e} expired={expired} reqNo={data.リクエストNo} />
                        <EditButton entry={e} expired={expired} reqNo={data.リクエストNo} />
                    </Stack>
                    {!isPad && (<>
                    <Body2>{formatDate(e.更新日_データ連携用)}</Body2>
                    <Body2>{e.更新者_データ連携用}</Body2>
                    </>)}
                </Stack>
                {(data.エントリー.length - 1 !== i || reentry) &&
                <Divider sx={{ width : "100%" }}/>
                }
            </Stack>
            ))}
            {reentry &&
            <StatusBlueButton label="追加エントリー" to={`/research/entry/${data.リクエストNo}`} />
            }
        </Stack>
    )
}

const ResearchList = () => {
    const isPad = useMediaQuery((theme) => theme.breakpoints.down('pad'))
    const { pathname } = useLocation()
    const navi = useNavigate()
    const [cond, setCond] = useAtom(searchCondAtom)
    const setPage = useSetAtom(searchPageAtom)
    const { control, getValues, setValue } = useForm({ defaultValues : cond })
    const { data, isLoading } = useAllResearches()
    const [list, setList] = useState([])
    const { data: dynamoData } = useDynamoItem("リサーチ")
    const { mutate } = useDynamoMutation("リサーチ")
    const setSpinner = useSetAtom(spinnerAtom)

    useEffect(() => {
        setSpinner(isLoading)
    }, [isLoading, setSpinner])

    useEffect(() => {
        if (/research$/.test(pathname)) {
            setPage(1)
            setCond(defaultValues)
            Object.keys(defaultValues).forEach(c => setValue(c, defaultValues[c]))
            navi('/research/list')
        }
    }, [setCond, navi, pathname, setValue, setPage])

    useEffect(() => {
        setList(data
            ?.filter(d => {
                if (cond.検索ワード) {
                    const regExp = new RegExp(cond.検索ワード)
                    return [
                        d.タイトル_クライアント公開用,
                        d.詳細_クライアント公開用,
                        d.媒体概要_連携用,
                        d.媒体名_企業公開用
                    ].some(e => regExp.test(e))
                }
                return true
            })
            .filter(d => {
                if (cond.媒体.length) {
                    if (!cond.媒体.includes(d.媒体種類)) return false
                }
                return true
            })
            .filter(d => {
                if (cond.ステータス.length) {
                    if (!d.エントリー.length) return false
                    if (cond.ステータス.includes('下書き')) {
                        if (d.エントリー.some(e => e.下書き)) return true
                    }
                    if (cond.ステータス.includes('エントリー完了')) {
                        if (d.エントリー.some(e => !e.下書き)) return true
                    }
                    if (cond.ステータス.includes('マッチング成立')) {
                        if (d.エントリー.some(e => e.結果 === '成立')) return true
                    }
                    if (cond.ステータス.includes('マッチング不成立')) {
                        if (d.エントリー.some(e => e.結果 === '不成立')) return true
                    }
                    return false
                }
                return true
            })
            .filter(d => {
                if (cond.チェック済.length) {
                    return !!dynamoData?.some(r => r === d.リクエストNo)
                }
                return true
            })
            .sort((a, b) => {
                if (a[cond.並び順] > b[cond.並び順]) return -1
                if (a[cond.並び順] < b[cond.並び順]) return 1
                return 0
            })
        )
    }, [cond, data, dynamoData])

    const handleCheck = e => {
        const data = list.find(d => d.リクエストNo === e.target.parentNode.dataset.id)
        // DynamoDBへチェック内容を反映する
        if (e.target.checked) {
            mutate({ content: [...new Set([...dynamoData || [], data.リクエストNo])] })
        } else {
            mutate({ content: dynamoData?.filter(a => a !== data.リクエストNo) })
        }
        setList([...list])
    }

    return (
        <Stack
            gap={2}
        >
            <H1>リサーチ 一覧</H1>
            <Box>
                <Body>・リサーチは各メディアの記者個人よりよせられているものです。各メディアへの直接のお問い合わせはお控えください。</Body>
                <Body>・エントリーによりマッチングが成立した場合、取材や掲載をお断りすることは原則できません。必ず対応可能であることをご確認の上、エントリーしてください。</Body>
            </Box>
            <Block
                sx={{ pad : { px : 3 }, xs : { px : 0 } }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Stack>
                        <SearchWord 
                            value={cond.検索ワード}
                            handleChange={e => {
                                setValue('検索ワード', e.target.value)
                                setCond({ ...cond, '検索ワード': e.target.value})
                                setPage(1)
                            }}
                            placeholder="キーワードを入力"
                        />
                        <Stack
                            onChange={() => {
                                setCond(getValues())
                                setPage(1)
                            }}
                        >
                            <CheckboxFilter label="媒体" control={control} list={mediumList} />
                            <CheckboxFilter label="ステータス" control={control} list={statusList} />
                            <Stack
                                direction="row"
                                alignItems="center"
                                pt={1}
                                gap={2}
                            >
                                <Box width={150}>
                                    <Select
                                        name="並び順"
                                        control={control}
                                        list={sortList}
                                        handleChange={() => {
                                            setCond(getValues())
                                            setPage(1)
                                        }}
                                    />
                                </Box>
                                <CheckboxGroup name="チェック済" control={control} list={['チェック']} />
                                <Stack
                                    ml={-4}
                                    display={{ sm : "flex", xs : "none" }}
                                    direction="row"
                                >
                                    <Body>（</Body>
                                    <Box
                                        component="img"
                                        width={12}
                                        height={16}
                                        src={flagIcon}
                                    />
                                    <Body>）済みのみ表示</Body>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Box
                    my={1}
                    borderBottom={`1px solid ${grey[400]}`}
                />
                {!isPad &&
                <TablePaginationList
                    headers={headers}
                    data={
                        list?.map(d => ({
                            チェック : <FlagCheck onChange={handleCheck} checked={!!dynamoData?.some(r => r === d.リクエストNo)} data-id={d.リクエストNo} />,
                            リサーチ : <StyledLink to={`/research/${d.リクエストNo}`}>{d.リクエストタイトル}</StyledLink>,
                            媒体 : <Medium type={d.媒体種類} name={d.媒体名_企業公開用} />,
                            募集開始日 : <Body2>{formatDate(d.募集開始日時_通常用)}</Body2>,
                            締切日時 : <Body2>{formatDate(d.募集締切日時)}</Body2>,
                            ステータス : <EntryStatus data={d} />,
                        })) 
                    }
                />
                }
                {isPad &&
                <PaginationAccordion
                    data={list}
                    keyName="リクエストNo"
                    summaryName="タイトル_クライアント公開用"
                    detail={d => (
                        <Stack>
                            <Stack
                                alignItems="flex-end"
                                width="100%"
                            >
                                <FlagCheck onChange={handleCheck} checked={!!dynamoData?.some(r => r === d.リクエストNo)} data-id={d.リクエストNo} /> 
                            </Stack>
                            <AccordionDetail label="媒体" value={<Medium type={d.媒体種類} name={d.媒体名_企業公開用} />} />
                            <AccordionDetail label="募集開始日" value={formatDate(d.募集開始日時_通常用)} />
                            <AccordionDetail label="締切日時" value={formatDate(d.募集締切日時)} />
                            <AccordionDetail label="ステータス" value={<EntryStatus data={d} isPad />} />
                        </Stack>
                    )}
                >
                </PaginationAccordion>
                }
            </Block>
        </Stack>
    )
}

export default ResearchList

import { Stack } from '@mui/material'
import { useAllFeedbackList } from 'hooks/feedback'
import PostList from './PostList'
import NoData from './NoData'

const FeedbackList = () => {
    const list = useAllFeedbackList()

    if (list.length === 0) return <NoData title='フィードバック' />

    return (
        <Stack
            pt={4}
            pl={1}
            pr={2}
        >
            <PostList
                data={list}
            />
        </Stack>
    )
}

export default FeedbackList

import { useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { useResearchByNo } from 'hooks/research'
import RequestComponent from './RequestComponent'


const NewRequest = () => {
    const { requestNo } = useParams()
    const { data } = useResearchByNo(requestNo)

    if (!data) return <Stack minHeight={700} /> 

    return <RequestComponent data={data} />
}

export default NewRequest

import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import ReactPlayer from 'react-player'

const AbsolutePlayer = styled(ReactPlayer)({
    position : "absolute",
    top : 0,
    left : 0,
})

export const isMovie = d => {
    if (!d) return false
    if (d.url || /^https/.test(d) || /^<iframe/.test(d)) return true
    if (d.blob) return true
    return false
}

const ResponsivePlayer = ({ data, ...props   }) => {
    const [movie, setMovie] = useState(null)
    useEffect(() => {
        if (!data || !data.blob || /^https/.test(data) || /^<iframe/.test(data)) return
        (async () => {
            const load = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = e => {
                        resolve(e.target.result)
                    }
                    reader.readAsDataURL(file)
                })
            }

            setMovie(await load(data.blob))
        })()
    }, [data])

    if (!isMovie(data)) return null

    return (<>
        {/s3-ap-northeast-1/.test(data.url || data) ?
        <ReactPlayer
            controls
            url={data.url || data}
            width="100%"
            height="100%"
        />
        :
        <Box
            position="relative"
            pt="56.25%"
        >
            <AbsolutePlayer
                controls
                url={movie || data.url || data}
                width="100%"
                height="100%"
                {...props}
            />
        </Box>
        }
    </>)
}
export default ResponsivePlayer

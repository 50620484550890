import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useAtom, useAtomValue } from 'jotai'
import { selectedDataAtom, mlEditModalAtom } from 'pages/medialist/MediaMain'
import { format } from 'date-fns'
import { Box, Stack, Modal, IconButton, Fade } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Body, H2 } from 'components/styledFonts'
import { StarCheck } from 'components/styledChecks'
import { CheckboxGroup, DatePicker, LabeledInputField, LabeledTextField, Required, Select, TextField } from 'components/Inputs'
import { BlueContainedButton } from 'components/styledButtons'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { messages } from 'config/messages'
import { prefectures } from 'config/options'
import { useAllMedias, useMergedMedias, useGroupName } from 'hooks/media'
import { useMediaMutaion } from 'hooks/mutation/media'
import { useDynamoItem } from 'hooks/dynamo'
import { useDynamoMutation } from 'hooks/mutation/useDynamoMutation'

const defaultValues = {
    姓 : "",
    名 : "",
    媒体種類 : "",
    会社名 : "",
    媒体名 : "",
    役職名 : "",
    部署名 : "",
    TEL : "",
    FAX : "",
    メール : "",
    携帯電話 : "",
    URL : "",
    郵便番号 : "",
    都道府県 : "東京都",
    市区郡 : "",
    町名番地 : "",
    登録日 : "",
    アプローチ日 : "",
    グループ : [],
    備考 : "",
    $id : "",
    $revision : "",
    id : "",
    リスト種類 : "",
}

const mediaTypes = [
    { label : '--------', value : '' },
    { label : '新聞', value : '新聞' },
    { label : '雑誌', value : '雑誌' },
    { label : 'TV', value : 'TV' },
    { label : 'ラジオ', value : 'ラジオ' },
    { label : 'WEB', value : 'WEB' },
    { label : 'その他', value : 'その他' },
]

const Name = ({ name, control, required, disabled }) => (
    <Stack
        direction="row"
        alignItems="flex-start"
        gap={1}
    >
        <Stack
            direction="row"
            py={1}
        >
            <Body>{name}</Body>
            {required &&
            <Required />
            }
        </Stack>
        <Stack>
            <TextField name={name} control={control} sx={{ maxWidth : 150 }} disabled={disabled} />
        </Stack>
    </Stack>
)

const MediaListEditModal = () => {
    const [open, setOpen] = useAtom(mlEditModalAtom)
    const selectedData = useAtomValue(selectedDataAtom)
    const [edit, setEdit] = useState(false)
    const [favorite, setFavorite] = useState(false)
    const { data : dynamoData } = useDynamoItem("メディアリストお気に入り")
    const { mutate } = useDynamoMutation("メディアリストお気に入り")
    const { mutate : mutateMedia } = useMediaMutaion()
    const { data : allMedias } = useAllMedias()
    const { data : mergedMedias } = useMergedMedias()
    const { data : groups } = useGroupName()
    const schema = yup.object().shape({
        姓: yup.string().required(messages.REQUIRED),
        メール: yup
            .string()
            .required(messages.REQUIRED)
            .matches(/^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, {
                message: messages.MAILADDRESS_VALIDATION,
            })
            .test('emailDup', messages.DUPLICATED_MAILADDRESS, (email) => {
                const isDuplicate = mergedMedias
                    .filter((m) => m.メール !== selectedData?.メール)
                    .some((m) => m.メール === email)
                return !isDuplicate
            }),
        TEL: yup.string().matches(/^[0-9-]*$/, { message: messages.INPUT_NUMBER_HYPHEN }),
        FAX: yup.string().matches(/^[0-9-]*$/, { message: messages.INPUT_NUMBER_HYPHEN }),
        郵便番号: yup.string().matches(/^(\d{3}-\d{4}|\d{7}|)$/, { message: messages.POSTALCODE_VALIDATION }),
    })
    const { control, setValue, handleSubmit } = useForm({ defaultValues : defaultValues, resolver : yupResolver(schema) })
    
    useEffect(() => {
        if (!selectedData) return

        // ネタもと作成の場合は「お気に入り, アプローチ日, グループ, 備考」のみ変更可能
        setEdit(selectedData.リスト種類 === 'ネタもと作成')

        // お気に入り
        setFavorite(dynamoData?.some(m => m === selectedData.メール))

        Object.keys(defaultValues).forEach((n) => {
            setValue(n, selectedData[n] || '')
        })

    }, [selectedData, dynamoData, setValue])

    const onSubmit = async (modifiedData) => {
        // レコードIdがある場合には行ID取得、ない場合は行IDの取得なし
        const mediaList = allMedias.find(a => a.$id === modifiedData.$id)?.メディアリスト.map(m => ({ id : m.id })) || []
        
        // 編集データをAPI用に加工
        const mediaData = {
            ...modifiedData,
            グループ : modifiedData.グループ ? modifiedData.グループ.filter(g => g).sort((a, b) => a - b).join(',') : '',
            登録日 : format(modifiedData.登録日 || new Date(), 'yyyy-MM-dd'),
            アプローチ日 : modifiedData.アプローチ日 ? format(modifiedData.アプローチ日, 'yyyy-MM-dd') : null,
        }

        // サブテーブルに使用するキーのみ残る
        ;['id', '$revision', '$id', 'リスト種類'].forEach(k => delete mediaData[k])

        if (modifiedData.id) { // 編集の場合（行IDあり）、編集データを追加
            mediaList.find(m => m.id === modifiedData.id).value = mediaData
        } else { // 新規作成の場合（行IDなし）
            mediaList.push({ value : mediaData })
        }

        mutateMedia({
            mediaList,
            リスト種類 : modifiedData.リスト種類,
            revision : modifiedData.$revision
        })

        // お気に入りをdynamoに保存
        if (favorite) mutate({ content : [...new Set([...dynamoData || [], modifiedData.メール])] })
        else mutate({ content : (dynamoData || []).filter(a => a !== modifiedData.メール) })

        setOpen(false)
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Fade in={open} timeout={1000}>
                <Stack
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    bgcolor="#fff"
                    sx={{
                        width: { mobile: "60%", xs: "90%" },
                    }}
                    maxWidth={800}
                    maxHeight="90%"
                    pt={3}
                    pb={4}
                    pl={3}
                    pr={1}
                    gap={1}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        pb={1}
                        mt={-1}
                    >
                        <H2>メディアリスト設定</H2>
                        <IconButton
                            sx={{ mt : -1, mr : -1 }}
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        gap={2}
                        overflow="auto"
                        pr={2}
                    >
                        <LabeledInputField
                            label="名前（姓名）"
                        >
                            <Stack
                                direction="row"
                                alignItems="flex-start"
                                sx={{ gap : { mobile : 4, xs : 2 } }}
                            >
                                <Name name="姓" control={control} required disabled={edit} />
                                <Name name="名" control={control} disabled={edit} />
                            </Stack>
                        </LabeledInputField>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                        <LabeledInputField
                            label="媒体種類"
                        >
                            <Box width={200}>
                                <Select name="媒体種類" control={control} list={mediaTypes} disabled={edit} />
                            </Box>
                        </LabeledInputField>
                            <LabeledInputField
                                label="お気に入り"
                            >
                                <StarCheck
                                    checked={favorite}
                                    onChange={e => {setFavorite(e.target.checked)}}
                                />
                            </LabeledInputField>
                        </Stack>
                        <LabeledTextField name="会社名" control={control} disabled={edit} />
                        <LabeledTextField name="媒体名" control={control} disabled={edit} />
                        <LabeledTextField name="部署名" control={control} disabled={edit} />
                        <LabeledTextField name="役職名" control={control} disabled={edit} />
                        <Stack
                            columnGap={4}
                            rowGap={2}
                            sx={{ flexDirection : { mobile : "row", xs : "column" } }}
                        >
                            <LabeledTextField name="TEL" control={control} sx={{ width : 200 }} disabled={edit} />
                            <LabeledTextField name="携帯電話" control={control} sx={{ width : 200 }} disabled={edit} />
                            <LabeledTextField name="FAX" control={control} sx={{ width : 200 }} disabled={edit} />
                        </Stack>
                        <LabeledTextField name="メール" control={control} required disabled={edit} />
                        <LabeledTextField name="URL" control={control} disabled={edit} />
                        <Stack
                            direction="row"
                            columnGap={4}
                        >
                            <LabeledTextField name="郵便番号" control={control} sx={{ width : 100 }} disabled={edit} />
                            <LabeledInputField
                                label="都道府県"
                            >
                                <Box width={150}>
                                    <Select name="都道府県" control={control} list={prefectures} disabled={edit} />
                                </Box>
                            </LabeledInputField>
                        </Stack>
                        <LabeledTextField name="市区郡" control={control} disabled={edit} />
                        <LabeledTextField name="町名番地" label="町名・番地" control={control} disabled={edit} />
                        <Stack
                            direction="row"
                            gap={4}
                        >
                            <LabeledInputField
                                label="アプローチ日"
                            >
                                <DatePicker name="アプローチ日" control={control} />
                            </LabeledInputField>
                            <LabeledInputField
                                label="登録日"
                            >
                                <Body
                                    sx={{ pt : 0.75 }}
                                >
                                    {selectedData?.登録日 
                                    ? format(selectedData.登録日, 'yyyy/MM/dd') 
                                    : format(new Date(), 'yyyy/MM/dd')}
                                </Body>
                            </LabeledInputField>
                        </Stack>
                        <LabeledInputField
                            label="グループ"
                        >
                            <Stack pl={0.75}>
                                <CheckboxGroup
                                    name="グループ"
                                    control={control}
                                    list={groups?.groups?.map((g, i) => ({ label : g, value : i + '' })).filter(gl => gl.label)}
                                    sx={{
                                        width : { mobile : "26%", xs : "53%" },
                                        mr : 0,
                                    }}
                                />
                            </Stack>
                        </LabeledInputField>

                        <LabeledTextField name="備考" control={control} multiline minRows={3} />
                    </Stack>
                    <Stack
                        pt={2}
                        alignItems="center"
                    >
                        <BlueContainedButton
                            type="submit"
                            label="登録"
                        />
                    </Stack>
                </Stack>
            </Fade>
        </Modal>
    )
}

export default MediaListEditModal

import { saveAs } from 'file-saver'
import iconv from 'iconv-lite'
import { format, isDate } from 'date-fns'
import { BlueContainedButton } from 'components/styledButtons'

export const CsvButton = ({ headers, dataList, label, fileName }) => {
    const downloadCSV = (headers, dataList, fileName) => {
        const idx = headers.findIndex(h => h === '登録日')

        const csvData = [headers]
            .concat(dataList)
            .map((r, i) => {
                // 1回目の処理で登録日の値がDate型がString型に上書きされるため、2回以上処理を行う場合登録日項目のフォーマット処理をスキップする
                if (i > 0 && isDate(r[idx])) r[idx] = format(r[idx], 'yyyy/MM/dd')
                return r.join(',')
            })
            .join('\n')

        // Shift JISで文字コードの変換
        const encodedData = iconv.encode(csvData, 'Shift_JIS')
        const blob = new Blob([encodedData], { type: 'text/csv;charset=Shift_JIS' })
        saveAs(blob, fileName)
    }
    return (
        <BlueContainedButton 
            label={label} 
            onClick={() => downloadCSV(headers, dataList, fileName)} 
        />
    )
}

export default CsvButton

import { apiName } from 'config/api'
import { useLoginUser } from 'hooks/loginuser'
import { useS3Config } from 'hooks/variousHooks'
import { API } from 'lib/api'
import { uploadS3 } from 'lib/common'
import useMyMutation from './useMyMutation'

const fileNames = ['広報担当者写真', '会社ロゴ']

export const useCompanyMutaion = () => {

    const { data: user } = useLoginUser()
    const { data: s3Config } = useS3Config()

    return useMyMutation({
        mutationFn: async (param) => {

            const form = { ...param }

            // S3操作
            const files = fileNames
                .filter(d => form?.[d]?.blob)
                .reduce((o, d) => ({ ...o, [d] : form[d].blob }), {})
            if (Object.keys(files).length) {
                const prefix = `${user.企業ID}/setting/company`
                // S3にアップロードしデータアクセス時のS3-URLを取得
                const s3Urls = await uploadS3(files, prefix, s3Config)
                Object.keys(s3Urls).forEach(d => form[d] = s3Urls[d])
            }

            const ret = await API.post(apiName, '/items/postcompany', { body: form })
            return ret
        },
        queryKey: ['company'],
        to: '/settings/company'
    })
}

import { Box, Stack } from '@mui/material'
import { format } from 'date-fns'
import { Body, H3Underline, StyledLink } from 'components/styledFonts'
import { EntryProgramButton } from 'components/styledButtons'
import { Accordion, AccordionDetail } from 'components/Accordion'
import { Link } from 'react-router-dom'


const ProgramListTableForPad = ({ title, data }) => {

    if (!data || data.length === 0) return null

    return (
        <Stack>
            <Box
                sx={{ py : 2 }}
            >
                <H3Underline>
                    {title}
                </H3Underline>
            </Box>

            <Accordion
                data={data}
                keyName="セミナーID"
                summaryName="セミナー名"
                detail={d => (
                    <Stack
                        gap={1}
                    >
                        <AccordionDetail
                            label="開催日時"
                            value={
                                <StyledLink
                                    to={`/program/detail/${d.セミナーID}`}
                                >
                                    {`${format(d.開始日時, 'yyyy年MM月dd日 HH:mm')}～${format(d.終了予定日時, 'HH:mm')}`}
                                </StyledLink>
                            }
                        />
                        <AccordionDetail label="会場" value={d.会場名} />
                        <AccordionDetail
                            label="参加予定者"
                            value={
                                <Stack
                                    direction="row"
                                    flexWrap="wrap"
                                    columnGap={2}
                                    rowGap={0.25}
                                >
                                    {d.自社申込者.map((u, i) => (
                                    <Body key={i}>{u.参加者氏名}</Body>
                                    ))}
                                </Stack>
                            }
                        />
                        <AccordionDetail
                            label="受付状況"
                            value={
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                >
                                    {d.受付状況}
                                    {d.受付状況 === '受付中' &&
                                    <EntryProgramButton 
                                        to={`/program/detail/${d.セミナーID}`}
                                        component={Link}
                                    />
                                    }
                                </Stack>
                            }
                        />
                    </Stack>
                )}
            />
        </Stack>
    )
}

export default ProgramListTableForPad

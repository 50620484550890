import { Auth } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Divider, Menu as MuiMenu, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'


const Menu = styled(MuiMenu)({
    '& .MuiList-root' : {
        paddingTop : 0,
        paddingBottom : 0,
    },
    '& .MuiButtonBase-root + .MuiDivider-root' : {
        margin : 0,
    },
})


const MenuList = ({ menu, menuEl, setMenuEl, ...props }) => {
    const queryClient = useQueryClient()

    return (
        <Menu
            open={!!menuEl}
            anchorEl={menuEl}
            onClose={() => setMenuEl(null)}
            {...props}
        >
            {menu.map((m, i) => (
            <Box key={i}>
                <MenuItem
                    component={Link}
                    to={m.p}
                    target={m.l === '操作マニュアル' ? "_blank" :"_self"}
                    state={m.state}
                    sx={{
                        py : 1.5,
                        pl : m.nest ? 4 : 2.5,
                        width : 260,
                        bgcolor : m.nest ? 'grey.50' : 'inherit',
                    }}
                    onClick={async () => {
                        if (m.l === 'ログアウト') {
                            queryClient.invalidateQueries()
                            await Auth.signOut().catch(e => console.log(e))
                        }
                        else {
                            setMenuEl(null)}
                        }
                    }
                >
                    {m.l}
                </MenuItem>
                {i !== menu.length - 1 ? <Divider /> : null}
            </Box>
            ))}
        </Menu>
    )
}

export default MenuList

import { Stack } from '@mui/material'
import { Body, Lead } from 'components/styledFonts'
import PostedPicture from 'components/PostedPicture'
import ResponsivePlayer from 'components/ResponsivePlayer'

const NewsLetterDetailText = ({ data }) => {

    if (!data) return

    const nums = [1, 2, 3, 4].filter(n => data?.[`イメージ${n}`]?.name)
    const answerNums = [...Array(10).keys()].filter(n => data[`回答${n + 1}`])

    return (
        <Stack
            gap={4}
        >
            {data.リード文 &&
            <Lead
                whiteSpace="pre-wrap"
            >{data.リード文}</Lead>
            }
            <Stack
                gap={4}
            >
                <PostedPicture
                    data={data[`メインイメージ`]}
                    caption={data[`メインイメージキャプション`]}
                />
                {answerNums.map(i => 
                <Stack key={i}>
                    <Body whiteSpace="pre-wrap">{data[`質問${i + 1}`]}</Body>
                    <Body whiteSpace="pre-wrap">{data[`回答${i + 1}`]}</Body>
                </Stack>)}
                {nums.map(i => 
                <PostedPicture
                    key={i}
                    data={data[`イメージ${i}`]}
                    caption={data[`イメージ${i}キャプション`]}
                />)
                }
                {data[`動画`]?.name &&
                <Stack
                    pb={1}
                    sx={{
                        px: { mobile: 2, xs: 0 }
                    }}
                >
                    <ResponsivePlayer
                        data={data[`動画`]}
                    />
                </Stack>
                }
                {data[`動画URL`] &&
                <Stack
                    pb={1}
                    sx={{
                        px: { mobile: 2, xs: 0 }
                    }}
                >
                    <ResponsivePlayer
                        data={data[`動画URL`]}
                    />
                </Stack>
                }
            </Stack>
        </Stack>
    )
}

export default NewsLetterDetailText

import { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { Body, H2, H5 } from 'components/styledFonts'
import { SelectMovie } from 'components/FileUploader'
import { CharacterLimitField, DatePicker, TextField, Required, ImageCaption, RadioGroupBase } from 'components/Inputs'
import Block from 'components/Block'
import { defaultValues, files } from './PreviewEditor'
import { useFeedbackable } from 'hooks/company'
import FeedbackCheckbox from 'components/FeedbackCheckbox'
import InquiryUserInput from 'components/InquiryUserInput'
import { useAccounts } from 'hooks/account'


const Editor = ({ category, data, control, setValue, watch }) => {
    const [method, setMethod] = useState('file')
    const { data: feedbackable } = useFeedbackable()
    const { data: accountData } = useAccounts()

    useEffect(() => {
        if (!data || !accountData) return

        Object.keys(defaultValues).forEach(n => setValue(n, watch(n) || data[n]))
        files.filter(n => watch(n) && !watch(n).hasOwnProperty('name')).forEach(n => setValue(n, { url: watch(n), name: watch(`${n}オリジナルファイル名`) }))
        setMethod(data.動画URL ? 'url' : 'file')
        const inquiryUser = accountData.find(d => d.姓 + d.名 === data.問い合わせ担当者_上書き) 
        setValue("問い合わせ担当者_上書き", inquiryUser?.企業担当者ID || "")

    }, [data, accountData, setValue, watch])

    return (
        <Block
            height="100%"
        >
            <Stack
                gap={2}
            >
                <H2>{category}　編集</H2>
                <CharacterLimitField
                    name="タイトル"
                    limit={100}
                    control={control}
                    required
                    multiline
                    disableEnter
                    watch={watch('タイトル')}
                />
                <CharacterLimitField
                    name="リード文"
                    limit={400}
                    control={control}
                    required
                    multiline
                    watch={watch('リード文')}
                />
                <Stack
                    gap={1}
                >
                    <H5>メイン画像<Required /></H5>
                    <Body>※一覧のページでサムネイルとしても表示されます</Body>
                    <ImageCaption
                        name="メインイメージ"
                        captionName="メインイメージキャプション"
                        pict={watch('メインイメージ')}
                        control={control}
                        setValue={setValue}
                    />
                </Stack>
                <CharacterLimitField
                    name="本文"
                    limit={4000}
                    control={control}
                    required
                    multiline
                    watch={watch('本文')}
                />
                <Stack gap={1}>
                    <H5>画像（最大4つまで掲載可）</H5>
                    <Stack gap={3}>
                        {[1, 2, 3, 4].map(n => (
                        <ImageCaption
                            key={n}
                            name={`イメージ${n}`}
                            captionName={`イメージ${n}キャプション`}
                            pict={watch(`イメージ${n}`)}
                            control={control}
                            setValue={setValue}
                        />
                        ))}
                    </Stack>
                </Stack>
                <Stack>
                    <H5>動画</H5>
                    <Body>※動画は最大50MBまでアップロード可能です</Body>
                    <RadioGroupBase
                        value={method}
                        onChange={e => setMethod(e.target.value)}
                        list={[
                            { label : '動画をアップロード', value : 'file', disabled : !!watch('動画URL') },
                            { label : '動画の埋め込みURLを入力', value : 'url', disabled : !!(watch('動画')?.url || watch('動画')?.blob) }
                        ]}
                    />
                    {method === 'file' &&
                    <SelectMovie
                        name="動画"
                        control={control}
                        file={watch('動画')}
                        setValue={setValue}
                        showName
                        removable
                    />
                    }
                    {method === 'url' &&
                    <TextField name="動画URL" control={control} placeholder="埋め込みURLを入力してください。"></TextField>
                    }
                </Stack>
                <Stack>
                    <CharacterLimitField 
                        name="検索キーワード"
                        control={control}
                        limit={200}
                        watch={watch('検索キーワード')}
                        remarks={`※スペース区切り含め200文字以内　${category}の内容に関連のあるワードのみ入力してください`}
                    />
                </Stack>
                <Stack>
                    <InquiryUserInput
                        control={control}
                        setValue={setValue}
                        note={'※企業情報設定と異なる方を問い合わせ担当者にする際は入力してください'}
                        direction="colomun"
                    />
                </Stack>
                <Stack>
                    <H5>投稿日時<Required /></H5>
                    <DatePicker
                        name="投稿日時"
                        control={control}
                        showTimeInput
                    />
                </Stack>
                {category === "社長ストーリー" && feedbackable &&
                <FeedbackCheckbox
                    name="フィードバック依頼"
                    control={control}
                    disabled={!!data?.フィードバックNo}
                    inquiry
                />
                }
            </Stack>
        </Block>
    )
}

export default Editor

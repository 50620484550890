import { useQuery } from '@tanstack/react-query'
import { isAfter, isWithinInterval } from 'date-fns'

import { API } from 'lib/api'
import { apiName } from 'config/api'
import { useCompanyInfo } from './company'
import { isBefore } from 'date-fns'
import { useAccounts } from './account'
import { useEffect, useState } from 'react'
import { useStreamingList } from './streaming'
import { getKintoneFile } from './variousHooks'


const getData = async (company) => {
    const ret = (await API.post(apiName, "/items/getProgram", { body: { company: company } })).map(d => ({
        ...d,
        ...Object.keys(d).filter(f => /日時/.test(f)).reduce((o, f) => ({ ...o, [f]: d[f] ? new Date(d[f]) : "" }), {}),
        開催日: new Date(`${d.開催日} 00:00`),
    }))
    return ret
}


// トップページに表示するセミナー種類
export const typesForTop = ['交流会', '経営者講座', '経営者交流会', '勉強会']

export const seminarTypes = [
    { label : '交流会'                      , types : ['交流会'] },
    { label : '経営者向けメディアセミナー'  , types : ['経営者講座'] },
    { label : '経営者ビジネス交流会'        , types : ['経営者交流会'] },
    { label : '勉強会'                      , types : ['勉強会', '勉強会(2年目以降)', '特別講座'] },
    { label : '経営PR戦略講座'              , types : ['経営PR戦略講座'] },
    { label : 'メディアセミナー'            , types : ['メディアセミナー'] },
]


// トップページ用のプログラム情報抽出
export const useProgramsForTop = () => {
    const { data: company } = useCompanyInfo()
    return useQuery(['program'], () => getData(company), {
        enabled: !!company,
        select : (data) => data
            .filter(d => isAfter(d.終了予定日時, new Date()) && d.カテゴリ !== "メディアセミナー")
            .sort((a, b) => b.マイページ公開日時 - a.マイページ公開日時)
            .reduce((o, d) => {
                if (!o[d.カテゴリ]) o[d.カテゴリ] = []
                if (o[d.カテゴリ].length < 3) o[d.カテゴリ].push(d)
                return o
            }, {})
    })
}

// プログラム一覧画面
export const useProgramList = () => {
    const { data: company } = useCompanyInfo()
    return useQuery(['program'], () => getData(company), {
        enabled: !!company,
        select : (data) => data
            .filter(d => isAfter(d.開始日時, new Date()))
            .map(d => ({
                ...d,
                自社申込者: d.自社申込者.filter(f => f.状況 === '申込'),
                受付状況: d.申込不要 ? "申込不要"
                    : d.申込締切日時 <= new Date() ? "締切"
                    : d.募集定員 <= d.全申込者数 ? "定員終了"
                    : d._1社の定員 <= d.自社申込者.length ? "1社定員終了"
                    : "受付中",
            }))
            .sort((a, b) => a.開始日時 - b.開始日時)
            .reduce((o, d) => {
                if (!o[d.カテゴリ]) o[d.カテゴリ] = []
                o[d.カテゴリ].push(d)
                return o
            }, {})
    })
}


// トップページ用のメディアセミナー情報抽出
export const useMediaSeminarList = () => {
    const { data: company } = useCompanyInfo()
    return useQuery(['program'], () => getData(company), {
        enabled: !!company,
        select : (data) => data
            .filter(d => isAfter(d.終了予定日時, new Date()) && d.カテゴリ === 'メディアセミナー')
            .sort((a, b) => a.開始日時 - b.開始日時)
            .slice(0, 2)
    })
}

// トップページ用のメディアセミナー情報に動画ストリーミング管理情報を付加
export const useMediaSeminarForTop = () => {
    const { data: seminars } = useMediaSeminarList()
    const { data: videos } = useStreamingList()
    const [mediaseminars, setMediaseminars] = useState(null)

    useEffect(() => {
        if (!seminars?.length || !videos) return

        // 現在ライブ配信中のダイジェスト版ではないメディアセミナーを動画ストリーミング管理より取得
        const ms = videos
            .flatMap(d => d.data)
            .filter(d => d.カテゴリ === 'メディアセミナー')
            .flatMap(d => d.list)
            .filter(d => !/ダイジェスト/.test(d.タイトル))
            .find(d => isWithinInterval(new Date(), { start: d.ライブ配信開始日時, end: d.ライブ配信終了日時 }))

        if (ms) seminars[0].動画 = ms
        setMediaseminars(seminars)
    }, [seminars, videos])
    
    return mediaseminars
}

// プログラム詳細
export const useProgramDetail = (id) => {
    const { data: company } = useCompanyInfo()
    return useQuery(['program'], () => getData(company), {
        enabled: !!company,
        select : (data) => {
            const d = data.find(d => d.セミナーID === id)
            const addr = `${d.会場場所_都道府県 || ''}${d.会場場所_市区郡 || ''}${d.会場場所_町名番地 || ''}`

            return {
                ...d,
                受付中 : isBefore(new Date(), d.申込締切日時),
                会場住所 : (addr && !/^--/.test(addr)) ? addr : '',
            }
        },
    })
}

// プログラム詳細 予約確認一覧
export const useReservations = (id) => {
    const { data } = useProgramDetail(id)
    const { data: accounts } = useAccounts()
    const [reservations, setReservations] = useState([])

    useEffect(() => {
        if (!data || !accounts) return

        const ln = new Intl.Collator('ja')
        const arr = data.自社申込者
            .map(d => ({
                ...d,
                リンク: d.状況 === '取消' ? '申し込み' : 'キャンセル',
            }))
            .concat(accounts
                .filter(d => d.アカウント発行済み && !d.アカウント無効化)
                .filter(d => !data.自社申込者.some(f => f.メールアドレス === d.メール))
                .map(d => ({
                    企業担当者ID: d.企業担当者ID,
                    参加者氏名: `${d.姓} ${d.名}`,
                    部署 : d.所属部署,
                    役職 : d.役職,
                    メールアドレス : d.メール,
                    状況 : '',
                    リンク: '申し込み',
                }))
            )
            .sort((a, b) => ln.compare(a.参加者氏名, b.参加者氏名))
        setReservations(arr)
    }, [data, accounts])

    return reservations
}

// プログラム申込履歴
export const useApplicationHistory = () => {
    const { data: company } = useCompanyInfo()
    return useQuery(['program'], () => getData(company), {
        enabled: !!company,
        select : (data) => data
            .filter(d => d.自社申込者.length > 0)
            .map(d => ({
                ...d,
                受付状況: d.申込不要 ? "申込不要"
                    : d.申込締切日時 <= new Date() ? "終了"
                    : d.募集定員 <= d.全申込者数 ? "定員終了"
                    : d._1社の定員 <= d.自社申込者.length ? "1社定員終了"
                    : "参加受付中",
        }))
            .sort((a, b) => b.開始日時 - a.開始日時)
    })
}

export const useMediaSeminarThumbnail = (fileKey) => {
    return useQuery(['mediaseminarThumbnail'], () => getKintoneFile(fileKey), {
        enabled: !!fileKey,
    })
}


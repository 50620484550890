import { Link as Links } from 'react-router-dom'
import { Link, Stack, useMediaQuery } from '@mui/material'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { format, isAfter, isBefore, isSameDay } from 'date-fns'
import { Body, H1 } from 'components/styledFonts'
import { EntryProgramButton, LinkButtonMiddle } from 'components/styledButtons'
import { StyledLink } from 'components/styledFonts'
import { PaginationAccordion, AccordionDetail } from 'components/Accordion'
import Block from 'components/Block'
import TablePaginationList from 'components/TablePaginationList'
import { useApplicationHistory } from 'hooks/program'
import { grey } from '@mui/material/colors'

const headers = [
    { label : 'プログラム', sx : { width : 340 } },
    { label : 'オンライン', sx : {} },
    { label : '開催日時', sx : { width : 160 } },
    { label : '会場', sx : { width : 200 } },
    { label : '受付状況', sx : {} },
    { label : '参加者', sx : {} },
    { label : 'ステータス', sx : {} },
    { label : '資料DL', sx : {} },
]

const isShowing = (d, n) => isBefore(new Date(d[`資料表示開始日時${n}`]), new Date()) && isAfter(new Date(d[`資料表示終了日時${n}`]), new Date())

const Multi = ({ data, name }) => (
    <Stack>
    {data.自社申込者.map((d, i) => (
        <Body
            key={i}
        >
            {name === '状況' ? `${d.状況}${d.状況 === '申込' ? '済み' : ''}` : d[name]}
        </Body>
    ))}
    </Stack>
)

const Multi2 = ({ data, name }) => (
    <Stack
        width={170}
    >
    {data.自社申込者.map((d, i) => (
        <Stack
            key={i}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Body>{d.参加者氏名}</Body>
            <Body>{`${d.状況}${d.状況 === '申込' ? '済み' : ''}`}</Body>
        </Stack>
    ))}
    </Stack>
)

const EventDate = ({ data }) => (
    <Stack>
        <Body>{format(data.開始日時, 'yyyy年MM月dd日')}</Body>
        <Body>{`${format(data.開始日時, 'HH:mm')}～${format(data.終了予定日時, 'HH:mm')}`}</Body>
    </Stack>
)

const DownloadLinks = ({ data }) => (
    <Stack
        direction="row"
        sx={{ gap : { pad : 0, xs : 2 } }}
    >
        {[1, 2, 3].map((n, i) => (data[`資料名${n}`] && isShowing(data, n) &&
        <Link
            key={i}
            component={Links}
            to={data[`資料名${n}`]}
            target="_blank"
            color={grey[700]}
        >
            <SaveAltIcon />
        </Link>
        ))}
    </Stack>
)

const ApplicationHistory = () => {
    const { data } = useApplicationHistory()
    const isPad = useMediaQuery((theme) => theme.breakpoints.down('pad'))

    return (
        <Stack>
            <Stack
                direction="row"
                alignItems="center"
                gap={4}
                pb={3}
            >
                <H1>プログラム 申込履歴</H1>
                <LinkButtonMiddle
                    component={Links}
                    to="/program/list"
                >
                    プログラム一覧
                </LinkButtonMiddle>
            </Stack>
            <Block
                //sx={{ px : { pad : 3, xs : 1 } }}
            >
                {(data && !isPad) &&
                <TablePaginationList
                    headers={headers}
                    data={data.map((d, i) => ({
                        プログラム :
                            <StyledLink to={`/program/detail/${d.セミナーID}`}>
                                {d.セミナー名}
                            </StyledLink>
                        ,
                        オンライン : (<>
                            {d.URL && isSameDay(d.開始日時, new Date()) &&
                            <EntryProgramButton 
                                label="入室"
                                to={d.URL}
                                component={Links}
                                target="_blank"
                            />
                            }
                        </>),
                        開催日時 : <EventDate data={d} />,
                        会場 : d.会場名,
                        受付状況 : d.受付状況,
                        参加者 : <Multi data={d} name="参加者氏名" />,
                        ステータス : <Multi data={d} name="状況" />,
                        資料DL : <DownloadLinks data={d} />
                    }))}
                />
                }
                {(data && isPad) && 
                <PaginationAccordion
                    data={data}
                    keyName="セミナーID"
                    summaryName="セミナー名"
                    detail={d => (
                        <Stack
                            gap={0.5}
                        >
                            <AccordionDetail
                                label="開催日時"
                                value={
                                    <StyledLink to={`/program/detail/${d.セミナーID}`}>
                                        <EventDate data={d} />
                                    </StyledLink>
                                }
                            />
                            <AccordionDetail label="オンライン" value={d.URL && isSameDay(d.開始日時, new Date()) &&
                                <EntryProgramButton 
                                    label="入室"
                                    to={d.URL}
                                    component={Links}
                                    target="_blank"
                                />} 
                            />
                            <AccordionDetail label="会場" value={d.会場名} />
                            <AccordionDetail label="受付状況" value={d.受付状況} />
                            <AccordionDetail label="参加者" value={<Multi2 data={d} />} />
                            <AccordionDetail label="資料DL" value={<DownloadLinks data={d} />} />
                        </Stack>
                    )}
                />
                }
            </Block>
        </Stack>
    )
}

export default ApplicationHistory

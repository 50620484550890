import { Box, Collapse, Divider, Stack } from '@mui/material'
import { Body, H5 } from 'components/styledFonts'
import { EditButton, EditSubmitButton } from 'components/styledButtons'


export const LabelValue = ({ label, value, labelWidth = 130 }) => {
    return (
        <Stack direction="row" alignItems="center" width="100%">
            <Body
                width={labelWidth}
                flexShrink={0}
            >
                {label}：
            </Body>
            <Body
                sx={{
                    wordBreak: "break-all",
                }}
            >
                {value}
            </Body>
        </Stack>
    )
}

const Settings = ({ category, currents, editor, labelWidth, onSubmit, uneditable, edit, setEdit }) => {
    return (<>
        <Stack
            sx={{
                flexDirection: { sm: "row", xs: "column" },
                justifyContent: "space-between",
            }}
        >
            <Stack
                justifyContent="center"
                rowGap={1}
                sx={{
                    width: { sm: "25%", xs: "100%" },
                }}
            >
                <H5>{category}</H5>
            </Stack>
            <Box
                sx={{
                    width: { sm: "75%", xs: "100%" },
                }}
            >
                <Collapse in={!edit} timeout={400}>
                    <Stack
                        sx={{
                            flexDirection: { sm: "row", xs: "column" },
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Stack
                            width="100%"
                            gap={1}
                        >
                            {currents.filter(v => v?.value || !v?.hidden).map((v, i) => (
                                <Box key={i}>
                                    {v.label ?
                                        <LabelValue
                                            label={v.label}
                                            value={v.value}
                                            labelWidth={labelWidth}
                                        />
                                        :
                                        v.value
                                    }
                                </Box>
                            ))}
                        </Stack>
                        {!uneditable &&
                            <Stack
                                alignItems="center"
                                sx={{
                                    pt: { sm: 0, xs: 2 },
                                    pl: { sm: 3, xs: 0 },
                                }}
                            >
                                <EditButton
                                    onClick={() => setEdit(true)}
                                >
                                    変更
                                </EditButton>
                            </Stack>
                        }
                    </Stack>
                </Collapse>
                <Collapse in={edit && !uneditable}>
                    <Stack
                        component="form"
                        onSubmit={onSubmit}
                    >
                        {editor}
                        <Stack
                            alignItems="end"
                        >
                            <Stack
                                direction="row"
                                gap={1}
                                sx={{ pt: { sm: 0, xs: 2 } }}
                            >
                                <EditButton
                                    onClick={() => setEdit(false)}
                                >
                                    キャンセル
                                </EditButton>
                                <EditSubmitButton label="変更" type="submit" />
                            </Stack>
                        </Stack>
                    </Stack>
                </Collapse>
            </Box>
        </Stack>
        <Divider />
    </>)
}

export default Settings

import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { useAtom, useAtomValue } from 'jotai'
import { deleteDialogAtom, selectedDataAtom } from 'pages/medialist/MediaMain'
import { useAllMedias } from 'hooks/media'
import { useDynamoItem } from 'hooks/dynamo'
import { useMediaMutaion } from 'hooks/mutation/media'
import { useDynamoMutation } from 'hooks/mutation/useDynamoMutation'


export const DeleteDialog = () => {
    const [open, setOpen] = useAtom(deleteDialogAtom)
    const selectedData = useAtomValue(selectedDataAtom)

    const { data : allMedias } = useAllMedias()
    const { data : dynamoData } = useDynamoItem("メディアリストお気に入り")
    const { mutate } = useDynamoMutation("メディアリストお気に入り")
    const { mutate : mutateMedia } = useMediaMutaion()

    const handleDelete = () => {
        const mediaList = allMedias.find(a => a.$id === selectedData.$id).メディアリスト

        mediaList.forEach((row, i) => {
            if (row.id === selectedData.id) {
                mediaList.splice(i, 1)
            }
        })
        mutateMedia({
            mediaList : mediaList.map(m => ({ "id" : m.id })),
            revision : selectedData.$revision
        })
        // お気に入り設定の削除
        mutate({ content : (dynamoData || []).filter(a => a !== selectedData.メール) })

        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogContent>
                <DialogContentText>{`${selectedData.姓} ${selectedData.名}様の情報を削除します。よろしいですか？`} </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} variant="outlined" sx={{ minWidth : 100 }}>キャンセル</Button>
                <Button onClick={() => handleDelete()} variant="contained" sx={{ minWidth : 100 }}>削除</Button>
            </DialogActions>
        </Dialog>
    )
}
    
export default DeleteDialog

import { createBrowserRouter } from 'react-router-dom'
import AccountMain from 'pages/account/AccountMain'
import ApplicationHistory from 'pages/program/ApplicationHistory'
import Company from 'pages/settings/Company'
import EditingEntryPreview from 'pages/research/EditingEntryPreview'
import EditIntoroduction from 'pages/inquiry/EditIntoroduction'
import EditRecommendation from 'pages/inquiry/EditRecommendation'
import EditStory from 'pages/inquiry/EditStory'
import EntryPreview from 'pages/research/EntryPreview'
import ErrorBoundary from 'pages/base/ErrorBoundary'
import FeedbackList from 'pages/post/FeedbackList'
import InquiryTop from 'pages/inquiry/InquiryTop'
import MediaListMain from 'pages/medialist/MediaListMain'
import MessageMain from 'pages/message/MessageMain'
import NewsLetterPdfSubmission from 'pages/newsletter/NewsLetterPdfSubmission'
import NewsLetterList from 'pages/post/NewsLetterList'
import NewsLetterTemplates from 'pages/post/NewsLetterTemplates'
import PageBase from 'pages/base/PageBase'
import Personal from 'pages/settings/Personal'
import PostListBase from 'pages/post/PostListBase'
import PressReleaseDetailBase from 'pages/pressrelease/PressReleaseDetailBase'
import PressReleaseList from 'pages/post/PressReleaseList'
import PressReleaseSubmissionBase from 'pages/pressrelease/PressReleaseSubmissionBase'
import PressReleaseHtmlSubmission from 'pages/pressrelease/PressReleaseHtmlSubmission'
import PressReleasePdfSubmission from 'pages/pressrelease/PressReleasePdfSubmission'
import PressReleasePreview from 'pages/pressrelease/PressReleasePreview'
import ProgramDetail from 'pages/program/ProgramDetail'
import ProgramList from 'pages/program/ProgramList'
import PRAnalysis from 'pages/pranalysis/PRAnalysis'
import PRAnalysisHistory from 'pages/pranalysis/PRAnalysisHistory'
import ReportList from 'pages/report/ReportList'
import ResearchDetail from 'pages/research/ResearchDetail'
import ResearchEntry from 'pages/research/ResearchEntry'
import ResearchEntryBase from 'pages/research/ResearchEntryBase'
import ResearchList from 'pages/research/ResearchList'
import Top from 'pages/top/Top'
import MovieTop from 'pages/movie/MovieTop'
import WordTemplates from 'pages/post/WordTemplates'
import InquiryFeedback from 'pages/inquiry/InquiryFeedback'
import NewsLetterDetailBase from 'pages/newsletter/NewsLetterDetailBase'
import NewsLetterSubmissionBase from 'pages/newsletter/NewsLetterSubmissionBase'
import NewsLetterHtmlSubmission from 'pages/newsletter/NewsLetterHtmlSubmission'
import NewsLetterPreview from 'pages/newsletter/NewsLetterPreview'
import NewsLetterTextSubmission from 'pages/newsletter/NewsLetterTextSubmission'

const router = createBrowserRouter([
    {
        path : '/',
        element : <PageBase />,
        errorElement : <ErrorBoundary />,
        children : [
            {
                index : true,
                element : <Top />,
            },

            {
                path : 'test',
                element : <div></div>
            },


            // リサーチ
            {
                path : 'research',
                children : [
                    {
                        path : '',
                        element : <ResearchList />,
                    },
                    {
                        path : 'list',
                        element : <ResearchList />,
                    },
                    {
                        path : 'entry',
                        element : <ResearchEntryBase />,
                        children  : [
                            {
                                path : ':requestNo',
                                element : <ResearchEntry isNewEntry={true} />
                            },
                            {
                                path : ':requestNo/preview',
                                element : <EditingEntryPreview />
                            },
                            {
                                path : ':requestNo/:entryNo',
                                element : <ResearchEntry />
                            },
                        ],
                    },
                    {
                        path : ':requestNo',
                        element : <ResearchDetail />,
                    },
                    {
                        path : ':requestNo/:entryNo',
                        element : <EntryPreview />,
                    },
                ]
            },

            // メディアリスト
            {
                path : 'medialist',
                element : <MediaListMain />,
            },

            // プログラム
            {
                path : 'program',
                children : [
                    {
                        path : 'list',
                        element : <ProgramList />,
                    },
                    {
                        path : 'detail/:id',
                        element : <ProgramDetail />,
                    },
                    {
                        path : 'history',
                        element : <ApplicationHistory />,
                    },
                ],
            },

            // 動画
            {
                path : 'movie/list',
                element : <MovieTop />,
            },

            // 情報発信
            {
                path : 'post/list',
                element : <PostListBase />,
                children : [
                    {
                        path : 'feedback',
                        element : <FeedbackList />,
                    },
                    {
                        path : 'pressrelease',
                        element : <PressReleaseList />,
                    },
                    {
                        path : 'newsletter',
                        element : <NewsLetterList />,
                    },
                ],
            },
            {
                path : 'post/wordtemplate',
                element : <WordTemplates />
            },
            {
                path : 'post/newslettertemplate',
                element : <NewsLetterTemplates />
            },

            // プレスリリース
            {
                path : 'pressrelease/:no',
                element : <PressReleaseDetailBase />,
            },
            {
                path : 'pressrelease/:no/feedback',
                element : <PressReleaseDetailBase feedback={true}/>,
            },
            {
                path : 'pressrelease/submission',
                element : <PressReleaseSubmissionBase />,
                children : [
                    {
                        path : 'html',
                        element : <PressReleaseHtmlSubmission />,
                    },
                    {
                        path : 'html/:no',
                        element : <PressReleaseHtmlSubmission />,
                    },
                    {
                        path : 'pdf',
                        element : <PressReleasePdfSubmission />,
                    },
                    {
                        path : 'pdf/:no',
                        element : <PressReleasePdfSubmission />,
                    },
                    {
                        path : 'preview',
                        element : <PressReleasePreview />,
                    },
                ]
            },

            // ニュースレター
            {
                path : 'newsletter/:no',
                element : <NewsLetterDetailBase />,
            },
            {
                path : 'newsletter/:no/feedback',
                element : <NewsLetterDetailBase feedback={true}/>,
            },
            {
                path : 'newsletter/submission',
                element : <NewsLetterSubmissionBase />,
                children : [
                    {
                        path : 'html',
                        element : <NewsLetterHtmlSubmission />,
                    },
                    {
                        path : 'html/:no',
                        element : <NewsLetterHtmlSubmission />,
                    },
                    {
                        path : 'text/new/:themeId',
                        element : <NewsLetterTextSubmission />,
                    },
                    {
                        path : 'text/:no',
                        element : <NewsLetterTextSubmission />,
                    },
                    {
                        path : 'pdf',
                        element : <NewsLetterPdfSubmission />,
                    },
                    {
                        path : 'pdf/:no',
                        element : <NewsLetterPdfSubmission />,
                    },
                    {
                        path : 'pdf/new/:themeId',
                        element : <NewsLetterPdfSubmission />,
                    },
                    {
                        path : 'preview',
                        element : <NewsLetterPreview />,
                    },
                ]
            },
            // メッセージ
            {
                path : 'messages',
                element : <MessageMain />,
            },
            {
                path : 'messages/:id',
                element : <MessageMain />,
            },

            // レポート
            {
                path : 'report/list',
                element : <ReportList />,
            },

            // PR活動診断
            {
                path : 'pranalysis',
                element : <PRAnalysis />,
            },

            // PR活動診断
            {
                path : 'pranalysis/history',
                element : <PRAnalysisHistory />,
            },

            // アカウント管理
            {
                path : 'account',
                element : <AccountMain />,
            },

            // 企業情報設定
            {
                path : 'settings/company',
                element : <Company />,
            },

            // 個人設定
            {
                path : 'settings/personal',
                element : <Personal />,
            },

            // 企業探訪設定
            {
                path : 'inquiry/top',
                element : <InquiryTop />,
            },
            {
                path : 'inquiry/story',
                element : <EditStory />,
            },
            {
                path : 'inquiry/feedback/:fbno',
                element : <InquiryFeedback />,
            },
            {
                path : 'inquiry/intoroduction',
                element : <EditIntoroduction />,
            },
            {
                path : 'inquiry/recommendation',
                element : <EditRecommendation />,
            },
            
        ],
    },
])

export default router

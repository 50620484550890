import { useState } from 'react'
import { useController } from 'react-hook-form'
import { Button, FormHelperText, IconButton, Stack } from '@mui/material'
import { red } from '@mui/material/colors'
import CancelIcon from '@mui/icons-material/Cancel'
import { Body } from 'components/styledFonts'
import { messages } from 'config/messages'


const IMAGE_MAX = 5 * 1024 * 1024
const VIDEO_MAX = 50 * 1024 * 1024

const checkSize = f => {
    if (!f) return true
    if (/video/.test(f.type)) {
        if (f.size > VIDEO_MAX) return false
    }
    else {
        if (f.size > IMAGE_MAX) return false
    }
    return true
}

const checkType = (f, a) => {
    if (!f || a === "*") return true
    const as = a.split(',')
    return as.some(d => {
        if (/\/\*$/.test(d)) return f.type.startsWith(d.replace(/\*/, ''))
        else return f.type === d
    })
}


const SelectFile = ({ name, control, file, setValue, showName, removable, label, accept="*", hidden, message, ...props }) => {
    const { fieldState : { error } } = useController({ name, control })
    const [sizeover, setSizeover] = useState(false)
    const [invalidType, setInvalidType] = useState(false)

    const handleChange = async e => {
        setValue(name, null)
        setValue(`${name}キャプション`, '')
        setValue(`${name}オリジナルファイル名`, '')
        const file = e.target.files[0]
        if (!checkSize(file)) {
            setSizeover(true)
        }
        else if (!checkType(file, accept)) {
            setInvalidType(true)
        }
        else {
            setSizeover(false)
            setInvalidType(false)
            setValue(name, { name : file?.name, blob : file })
            setValue(`${name}オリジナルファイル名`, file?.name)
        }
    }

    const isError = error && (!error?.ref?.value || !error?.ref?.value?.name)

    return (
        <Stack
            direction="row"
            alignItems="center"
            gap={1}
            display={hidden ? "none" : "flex"}
        >
            <Button
                variant="outlined"
                component="label"
                color="grey800"
                sx={{
                    width : 120,
                    py : 1,
                    px : 0,
                    borderColor : isError ? red[700] : 'rgba(66, 66, 66, 0.5)',
                }}
                {...props}
                onChange={handleChange}
                onClick={e => e.target.value = ''}
            >
                {label}
                <input type="file" hidden accept={accept} />
            </Button>
            {(isError || sizeover || invalidType) &&
            <FormHelperText error>{sizeover ? messages.FILESIZE_OVER : invalidType ? message : error.message}</FormHelperText>
            }
            {(showName && file) &&
            <Body
                width={140}
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
            >
                {file.name}
            </Body>
            }
            {(file?.name && removable) &&
            <IconButton
                onClick={() => {
                    setValue(name, null)
                    setValue(`${name}キャプション`, '')
                    setValue(`${name}オリジナルファイル名`, '')
                }}
            >
                <CancelIcon color="grey400" />
            </IconButton>
            }
        </Stack>
    )
}

export const SelectImage = ({ label="ファイルを選択", ...props }) => {
    return <SelectFile label={label} accept="image/*" message={messages.UNMATCH_FILE_TYPE('画像')} {...props} />
}

export const SelectMovie = ({ label="動画を選択", ...props }) => {
    return <SelectFile label={label} accept="video/mp4,video/webm" message={messages.UNMATCH_FILE_TYPE('動画')} {...props} />
}

export const SelectPDF = ({ label="添付", ...props }) => {
    return <SelectFile label={label} accept="application/pdf" message={messages.UNMATCH_FILE_TYPE('PDF')} {...props} />
}

export const SelectAttachment = ({ label="添付", ...props }) => {
    return <SelectFile label={label} accept="application/pdf,application/zip,application/x-zip-compressed,image/*" message={messages.UNMATCH_FILE_TYPE('PDF, ZIP, 画像')} {...props} />
}


import { useState } from 'react'
import { Stack } from '@mui/material'
import { red } from '@mui/material/colors'
import { H4 } from 'components/styledFonts'
import { BlueContainedButton } from 'components/styledButtons'
import PageTitleAndButtons from 'components/PageTitleAndButtons'
import Scroll from 'components/Scroll'
import Request from './Request'
import Entry from './Entry'
import CheckButton from './CheckButton'
import NewRequest from './NewRequest'
import NewEntry from './NewEntry'


const H4Red = ({ children }) => (
    <H4
        component="span"
        sx={{
            color : red[400],
        }}
    >
        {children}
    </H4>
)


const ResearchEntry = ({ isNewEntry }) => {
    const [scroll, setScroll] = useState(false)

    return (
        <Stack
            gap={2}
        >
            <PageTitleAndButtons
                title="リサーチ 詳細・エントリー"
                buttons={[
                    <BlueContainedButton
                        label="エントリー"
                        onClick={() => setScroll(true)}
                    />,
                    <CheckButton />
                ]}
            />
            <Stack>
                <H4>・リサーチは各メディアの記者個人より寄せられているものです。<H4Red>各メディアへの直接のお問い合わせはお控えください</H4Red>。</H4>
                <H4>・エントリーによりマッチングが成立した場合、<H4Red>取材や掲載をお断りすることは原則できません</H4Red>。必ず対応可能であることをご確認の上、エントリーしてください。</H4>
            </Stack>
            <Stack
                gap={4}
            >
                {isNewEntry ?
                <NewRequest />
                :
                <Request />
                }
                <Scroll scroll={scroll} setScroll={setScroll} />
                {isNewEntry ?
                <NewEntry />
                :
                <Entry />
                }
            </Stack>
        </Stack>
    )
}

export default ResearchEntry

import { Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { format } from 'date-fns'
import { Body, H2, H4, H5 } from 'components/styledFonts'
import { CountBadge } from 'components/styledLabels'
import Block from 'components/Block'
import { useFeedbackByNo } from 'hooks/feedback'
import { LinkButtonLarge } from 'components/styledButtons'
import { useDynamoItem } from 'hooks/dynamo'
import { useDynamoMutation } from 'hooks/mutation/useDynamoMutation'
import { useEffect, useState } from 'react'


const border = `solid 1px ${grey[400]}`

const LV = ({ label, value, last }) => (
    <Stack
        direction="row"
        borderBottom={last ? border : 0}
    >
        <Body
            sx={{
                width : 150,
                flexShrink : 0,
                py : 0.5,
                px : 1,
                borderTop : border,
                borderLeft : border,
                bgcolor : grey[100],
            }}
        >
            {label}
        </Body>
        <Body
            sx={{
                width : "100%",
                py : 0.5,
                px : 1,
                borderTop : border,
                borderLeft : border,
                borderRight : border,
            }}
        >
            {value}
        </Body>
    </Stack>
)

const TV = ({ title, value }) => (
    <Stack>
        <H5>{title}</H5>
        <Body
            sx={{ whiteSpace : "pre-wrap" }}
        >
            {value}
        </Body>
    </Stack>
)

const FeedbackResult = ({ fbno } ) => {
    const { data : fb } = useFeedbackByNo(fbno)
    const { data : dynamoData = [], isSuccess } = useDynamoItem('既読フィードバック')
    const { mutate } = useDynamoMutation('既読フィードバック')
    const [newly, setNewly] = useState(isSuccess && !dynamoData?.includes(fbno))

    useEffect(() => {
        if (!fb || (!fb.クライアント公開 && !fb.却下) || !isSuccess) return
        if (!dynamoData?.includes(fbno)) {
            const timeout = setTimeout(() => {
                setNewly(false)
                mutate({ content : [...new Set([...dynamoData || [], fbno])] })
            }, 2000)
            return () => clearTimeout(timeout)
        }
    }, [fb, isSuccess, dynamoData, fbno, mutate])

    if (!fb) return null

    return (
        <Stack
            gap={2}
        >
            <Block>
                <Stack
                    gap={2}
                >
                    {(!fb.却下 && !fb.クライアント公開) ?
                    <Stack
                        gap={2}
                    >
                        <H4>フィードバックはまだされておりません。</H4>
                    </Stack>
                    : <>
                    {fb.却下 &&
                    <Stack
                        gap={2}
                    >
                        <H4>フィードバック依頼ができませんでした</H4>
                        <Stack>
                            <H5>理由</H5>
                            <Body whiteSpace="pre-wrap">{fb.却下理由テンプレート内容}</Body>
                            <Body whiteSpace="pre-wrap">{fb.却下理由}</Body>
                        </Stack>
                    </Stack>
                    }
                    {!fb.却下 && <>
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={2}
                    >
                        <H2>フィードバック</H2>
                        {newly &&
                        <CountBadge>NEW</CountBadge>
                        }
                    </Stack>
                    <Stack
                        gap={3}
                        sx={{ wordBreak : "break-word" }}
                    >
                        <Stack>
                            <H5>レビュアーのプロフィール</H5>
                            <Stack>
                                <LV label="メディアカテゴリー" value={fb.メディアカテゴリー} />
                                <LV label="メディアジャンル" value={fb.メディアジャンル} />
                                <LV label="媒体名" value={fb.媒体名} />
                                <LV label="ステータス" value={fb.担当者ステータス} />
                                <LV label="職種" value={fb.職種} last />
                            </Stack>
                        </Stack>
                        <Stack>
                            <H5>総評</H5>
                            <Stack>
                                <LV label="見出し" value={fb.総評_見出し} />
                                <LV label="文章・構成" value={fb.総評_文章構成} />
                                <LV label="ネタ切り口" value={fb.総評_ネタ切り口} />
                                <LV label="社会性／時事性" value={fb.総評_社会性時事性} />
                                <LV label="エビデンス" value={fb.総評_エビデンス} last />
                            </Stack>
                        </Stack>
                        <TV title="①担当メディアで取り上げる可能性" value={fb.可能性} />
                        <TV title="②興味を惹かれた点" value={fb.興味を惹かれた点} />
                        <TV title="③興味を持てなかった点" value={fb.興味を持てなかった点} />
                        <TV title="④欲しい追加情報や素材、確認したい事項" value={fb.欲しい追加情報} />
                        <TV title="⑤メディアに取り上げられるためのアドバイス" value={fb.アドバイス} />
                        <TV title="⑥その他・所感など" value={fb.その他} />
                        <TV title="公開日時" value={fb.クライアント公開日時 ? format(fb.クライアント公開日時, 'yyyy/MM/dd HH:mm') : ""} />
                    </Stack>
                    </>}
                    </>}
                </Stack>
            </Block>
            <Stack
                gap={2}
                sx={{ width : { sm : "60%", xs : "70%" } }}
                alignSelf='center'
            >
                <LinkButtonLarge
                    component={Link}
                    to="/post/list/feedback"
                >
                    フィードバック一覧
                </LinkButtonLarge>
            </Stack>
        </Stack>
    )
}

export default FeedbackResult

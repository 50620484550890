import useOptimismMutation from './useOptimismMutation'
import { apiName } from 'config/api'
import { API } from 'aws-amplify'

export const useDynamoMutation = (item, queryKey, invalidateKey) => {
    return useOptimismMutation({
        mutationFn : async (param) => {
            const tmpParam = { ...param, item : item }
            const ret = await API.post(apiName, '/items/postDynamo', { body : tmpParam })
            return ret
        },
        queryKey : [queryKey || 'dynamo'],
        invalidateKey : invalidateKey,
        item : item
    })
}

import { format } from 'date-fns'
import { Stack } from '@mui/material'
import ComponentBase from './ComponentBase'
import { H3Underline, H5 } from 'components/styledFonts'
import { WhiteLabel } from 'components/styledLabels'
import { EntryButton } from 'components/styledButtons'
import { seminarTypes, useProgramsForTop } from 'hooks/program'
import { Link } from 'react-router-dom'



const Program = () => {
    const { data } = useProgramsForTop()

    return (
        <ComponentBase
            buttons={[{ label : 'プログラム一覧', to : '/program/list' }]}
        >
            {data && (
            <Stack
                direction="row"
                gap={2}
                sx={{
                    flexDirection : { sm : "row", xs : "column" },
                    gap : { sm : 4, xs : 2 },
                }}
            >
                <Stack
                    flexGrow={1}
                    gap={4}
                >
                    {seminarTypes.map(s => s.label).filter(t => data[t]).map(t => (
                    <Stack
                        key={t}
                        gap={1}
                    >
                        <H3Underline>{t}</H3Underline>
                        <Stack
                            gap={2}
                        >
                            {data[t].slice(0, 3).map((d, i) =>
                            <Stack
                                key={i}
                                gap={0.25}
                            >
                                <H5 key={i}>{d.セミナー名}</H5>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    flexWrap="wrap"
                                    gap={1}
                                >
                                    <WhiteLabel>{`開催日：${format(d.開催日, 'MM月dd日')}`}</WhiteLabel>
                                    <WhiteLabel>{`締切：${d.申込不要 ? "申込み不要" : format(d.申込締切日時, 'MM月dd日 HH:mm')}`}</WhiteLabel>
                                    <EntryButton
                                        component={Link}
                                        to={`/program/detail/${d.セミナーID}`}
                                    >
                                        詳細
                                    </EntryButton>
                                </Stack>
                            </Stack>
                            )}
                        </Stack>
                    </Stack>
                    ))}
                </Stack>
            </Stack>
            )}
        </ComponentBase>
    )
}

export default Program

import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { Stack } from '@mui/material'
import { LinkButtonLarge } from 'components/styledButtons'
import PageTitleAndButtons from 'components/PageTitleAndButtons'
import { useAllResearches } from 'hooks/research'
import { spinnerAtom } from 'store/commonAtoms'
import Request from './Request'
import CheckButton from './CheckButton'


const ResearchDetail = () => {
    const { isLoading } = useAllResearches()
    const setSpinner = useSetAtom(spinnerAtom)

    useEffect(() => {
        setSpinner(isLoading)
    }, [isLoading, setSpinner])

    return (
        <Stack
            gap={2}
        >
            <PageTitleAndButtons
                title="リサーチ詳細"
                buttons={[
                    <CheckButton />
                ]}
            />
            <Request />
            <Stack
                alignItems="center"
                pt={2}
            >
                <LinkButtonLarge
                    component={Link}
                    to="/research/list"
                >
                    一覧に戻る
                </LinkButtonLarge>
            </Stack>
        </Stack>
    )
}

export default ResearchDetail

import { Stack } from '@mui/material'
import { format } from 'date-fns'
import { H2, Body } from 'components/styledFonts'
import { GreyLabel } from 'components/styledLabels'
import Block from 'components/Block'
import PostedPicture from 'components/PostedPicture'
import ResponsivePlayer from 'components/ResponsivePlayer'


const Detail = ({ data }) => {

    return (
        <Block
            height="100%"
        >
            <Stack
                sx={{ 
                    px : { sm : 1, xs : 0 },
                    wordBreak : "break-word",
                }}
                gap={4}
                alignItems="flex-start"
            >
                <Stack
                    gap={1}
                    alignItems='flex-start'
                >
                    <H2>{data?.タイトル}</H2>
                    {data?.投稿日時 &&
                    <GreyLabel>{format(data?.投稿日時, 'yyyy/MM/dd HH:mm')}</GreyLabel>
                    }
                </Stack>
                <Body whiteSpace="pre-wrap">{data?.リード文}</Body>
                <PostedPicture
                    data={data?.メインイメージ}
                    caption={data?.メインイメージキャプション}
                />
                <Body whiteSpace="pre-wrap">{data?.本文}</Body>
                {(data?.動画 || data?.動画URL) &&
                <ResponsivePlayer
                    data={data?.動画 || data?.動画URL}
                />
                }
                {data && [1, 2, 3, 4].map(n => (
                <PostedPicture
                    key={n}
                    data={data[`イメージ${n}`]}
                    caption={data[`イメージ${n}キャプション`]}
                />
                ))}
            </Stack>
        </Block>
    )
}

export default Detail

import useMyMutation from './useMyMutation'
import { API } from 'lib/api'
import { apiName } from 'config/api'

export const useAccountMutaion = () => {
    return useMyMutation({
        mutationFn : async (param) => {
            const apiAddress = param.hasOwnProperty('アカウント無効化')? '/items/switchAccountActivation' : '/items/addAccount' // アカウント更新/登録
            const ret = await API.post(apiName, apiAddress, { body : param })
            return ret
        },
        queryKey : ['account'],
        to: '/account'
    })
}
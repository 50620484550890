import { Box, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { blue, grey, lightGreen } from '@mui/material/colors'
import { usePRBarChart } from 'hooks/pranalysis'

const barHeight = (total, narrow) => total ? 36 : narrow ? 12 : 8
const mq480 = "@media screen and (max-width:480px)"

const Label = ({ label, total, narrow }) => {
    const conf = (total) => {
        if (total) return {
            fontSize : 16,
            fontWeight : 700,
            color : grey[700],
        }
        return {
            fontSize : 12,
            color : grey[600],
        }
    }

    if (total && narrow) return null

    return (
        <Box
            width={140}
            textAlign={narrow ? "left" : "right"}
            sx={{
                ...conf(total),
            }}
        >
            {label}
        </Box>
    )
}

const Bar = ({ rate, total, narrow }) => {
    return (
        <Box
            flexGrow={1}
            position="relative"
            height={barHeight(total)}
        >
            <Box
                position="absolute"
                width="100%"
                height={barHeight(total, narrow)}
                bgcolor={grey[200]}
            />
            <Box
                position="absolute"
                width={`${rate}%`}
                height={barHeight(total, narrow)}
                bgcolor={total ? blue[500] : lightGreen.A200}
            />
            {(total && narrow) &&
                <Box
                    position="absolute"
                    color="#fff"
                    fontSize={16}
                    fontWeight={700}
                    top={6}
                    left={16}
                >
                    合計
                </Box>
            }
        </Box>
    )
}

const Score = ({ numerator, denominator, total, narrow }) => {

    const conf = (total) => {
        if (total) return {
            numerator : {
                fontSize : 30,
                fontWeight : 700,
                color : blue[500],
                height : 34,
                [mq480] : {
                    fontSize : 18,
                    height : 20,
                },
            },
            denominator : {
                fontSize : 18,
                fontWeight : 700,
                color : grey[400],
                mb : -0.5,
                pl : 4,
                [mq480] : {
                    fontSize : 12,
                    mb : 0,
                    pl : 2.5,
                },
            },
        }
        return {
            numerator : {
                fontSize : 18,
                fontWeight : 700,
                color : grey[500],
            },
            denominator : {
                fontSize : 12,
                fontWeight : 700,
                color : grey[400],
                mb : -0.2,
            },
        }
    }

    return (
        <Box
            position="relative"
            width={120}
            height={barHeight(total)}
            sx={{
                [mq480] : {
                    width : 75,
                },
            }}
        >
            <Stack
                direction={total ? "column" : "row"}
                alignItems={total ? "flex-start" : "center"}
                position="absolute"
                top={total ?  -15 : -10}
                sx={{
                    [mq480] : {
                        top : total ? -2 : -7,
                    },
                }}
            >
                <Box sx={conf(total).numerator}>{numerator}</Box>
                <Box sx={conf(total).denominator}>／{denominator}点</Box>
            </Stack>
        </Box>
    )
}

const BarChart = ({ label, numerator, denominator, total, narrow }) => {
    if (narrow) {
        return (
            <Stack
                gap={0.25}
            >
                <Label
                    label={label}
                    total={total}
                    narrow
                />
                <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                >
                    <Bar
                        rate={Math.round(numerator / denominator * 100)}
                        total={total}
                        narrow
                    />
                    <Score
                        numerator={numerator}
                        denominator={denominator}
                        total={total}
                        narrow
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack
            direction="row"
            gap={1}
            alignItems="center"
        >
            <Label
                label={label}
                total={total}
            />
            <Bar
                rate={Math.round(numerator / denominator * 100)}
                total={total}
            />
            <Score
                numerator={numerator}
                denominator={denominator}
                total={total}
            />
        </Stack>
    )
}


const PRBarChart = ({ narrow, targetPrIndex }) => {
    const theme = useTheme()
    const downmobile = useMediaQuery(theme.breakpoints.down('mobile'))
    const { data } = usePRBarChart(targetPrIndex)

    if (!data) return null

    narrow = narrow || downmobile

    return (
        <Stack
            gap={narrow ? 1.75 : 1.5}
            pb={1}
            pl={narrow ? 2 : 0}
        >
            <BarChart
                label="合計"
                numerator={data.pranalysis.PR活動診断ポイント || 0}
                denominator={data.pranalysis.PR活動診断配点 || 30}
                total
                narrow={narrow}
            />
            {data.titles.map((t, i) => {
            return (
                <BarChart
                    key={i}
                    label={t ? `${t.num}. ${t.title}` : ''}
                    numerator={data.pranalysis[t.title]?.合計点数 || 0}
                    denominator={data.pranalysis[t.title]?.合計配点 || 10}
                    narrow={narrow}
                />
            )
            })}
        </Stack>
    )
}

export default PRBarChart

import { useAllNewsLetter } from 'hooks/newsletter'
import ConditionalList from './ConditionalList'
import NoData from './NoData'
import { newsListCond } from 'store/commonAtoms'

const NewsLetterList = () => {
    const { data } = useAllNewsLetter()

    if (data?.length === 0) return <NoData title='ニュースレター' />

    return <ConditionalList data={data} listCond={newsListCond} />
}

export default NewsLetterList

import React from 'react'
import { TabUnstyled, TabsListUnstyled as TabList } from '@mui/base'
import { styled } from '@mui/material/styles'

const Tab = styled(TabUnstyled)({
    position: 'relative',
    padding: '10px 30px',
    transition: '0.3s',
    cursor: 'pointer',
    marginRight: 4,
    border: 'none',
    background: '#a19fa0',
    color: '#fff',
    fontWeight: 700,
    '&:hover': {
        opacity: 0.7,
    },
    '&.Mui-selected::before': {
        content: '""',
        position: 'absolute',
        bottom: '-10px',
        left: '50%',
        transform: 'translateX(-50%)',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
    },
    '&.Mui-selected.基礎': {
        background: '#ffa500',
    },
    '&.Mui-selected.基礎::before': {
        borderTop: '10px solid #ffa500',
    },
    '&.Mui-selected.応用': {
        background: '#40e0d0',
    },
    '&.Mui-selected.応用::before': {
        borderTop: '10px solid #40e0d0',
    },
    '&.Mui-selected.実践': {
        background: '#32cd32',
    },
    '&.Mui-selected.実践::before': {
        borderTop: '10px solid #32cd32',
    },
})

const VideoTab = ({ data }) => {

    return (
        <TabList>
            {data.map((d, i) => (
            <Tab
                key={d.タブ}
                value={i}
                className={d.タブ}>
                {d.タブ}
            </Tab>
            ))}
        </TabList>)
}

export default VideoTab

import { styled } from '@mui/material/styles'
import { Divider as MuiDivider, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import InquiryItem from 'components/InquiryItem'
import { useIntroduction, useRecommendation, useStory } from 'hooks/inquiry'

const Divider = styled(MuiDivider)({
    borderColor : grey[400],
})


const Inquiries = () => {
    const { data : intoro } = useIntroduction()
    const { data : recomm } = useRecommendation()
    const { data : story } = useStory()

    return (
        <Stack
            py={2}
            gap={4}
        >
            <InquiryItem
                category="社長ストーリー"
                data={story}
                link="/inquiry/story"
            />
            <Divider />
            <InquiryItem
                category="こんな会社"
                data={intoro}
                link="/inquiry/intoroduction"
            />
            <Divider />
            <InquiryItem
                category="イチオシ！"
                data={recomm}
                link="/inquiry/recommendation"
            />
        </Stack>
    )
}

export default Inquiries

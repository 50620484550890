import { API as AWSAPI } from 'aws-amplify'
import axios from 'axios'

export const API = {
    get : async (apiname, path, params) => {
        if (process.env.REACT_APP_MODE === "local") {
            const key = Object.keys(localStorage).find(k => /userData/.test(k))
            const attr = localStorage.getItem(key)

            const ret = await axios({
                url : `http://localhost:3800${path}`,
                headers : {
                    'x-userattr' : attr,
                },
            })

            return ret.data
        }
        else {
            return AWSAPI.get(apiname, path, params)
        }
    },
    post : async (apiname, path, params) => {
        if (process.env.REACT_APP_MODE === "local") {
            const key = Object.keys(localStorage).find(k => /userData/.test(k))
            const attr = localStorage.getItem(key)

            const ret = await axios({
                method : 'post',
                url : `http://localhost:3800${path}`,
                headers : {
                    'x-userattr' : attr,
                },
                data : params.body,
                responseType : params.responseType || 'json',
            })

            return ret.data
        }
        else {
            return AWSAPI.post(apiname, path, params)
        }
    },
}



import { Link } from 'react-router-dom'
import { Alert, Box, Stack } from '@mui/material'
import { lightBlue, orange } from '@mui/material/colors'
import { messages } from 'config/messages'
import { Body, H5 } from 'components/styledFonts'
import { useAllInformation } from 'hooks/information'
import { useCompanyInfo } from 'hooks/company'

const Warning = () => (
    <Alert
        severity="warning"
        variant="outlined"
        sx={{
            alignItems : 'flex-start',
            bgcolor : orange[50],
        }}
    >
        <Stack
            gap={1}
        >
            <H5
                color={orange[600]}
                sx={{
                    whiteSpace : 'pre-wrap',
                }}
            >
                {messages.TOP_MYPAGE_NOT_EXIST}
            </H5>
            <Body
                component={Link}
                sx={{
                    color : orange[600],
                    wordBreak : 'break-all',
                }}
                to="/settings/company"
            >
                企業情報設定画面へ
            </Body>
        </Stack>
    </Alert>
)

const Information = () => {
    const { data : info } = useAllInformation()
    const { data : company } = useCompanyInfo()

    if (!info?.length && company?.$id ) return null

    return (
        <Stack
            width="100%"
            alignItems="center"
            mt={-3}
            mb={1.25}
        >
            <Stack
                gap={1.25}
                sx={{
                    width : { sm : "67%", xs : "100%" },
                }}
            >
                {company && !company.$id &&
                <Warning />
                }
                {info?.map((m, i) => (
                <Box
                    key={i}
                    flex={1}
                >
                    <Alert
                        severity="info"
                        variant="outlined"
                        sx={{
                            alignItems : 'flex-start',
                            bgcolor : '#fff',
                        }}
                    >
                        <H5
                            sx={{
                                color : lightBlue[700],
                                mb : 1,
                            }}
                        >
                            {m.タイトル}
                        </H5>
                        <Stack
                            gap={1}
                        >
                            <Body
                                sx={{
                                    color : lightBlue[700],
                                    whiteSpace : 'pre-wrap',
                                }}
                            >
                                {m.本文}
                            </Body>
                            <Body
                                component={Link}
                                sx={{
                                    color : lightBlue[700],
                                    '& a' : {
                                        color : 'inherit',
                                    },
                                    wordBreak : 'break-all',
                                }}
                                to={m.URL}
                                target='_blank'
                            >
                                {m.URL}
                            </Body>
                        </Stack>
                    </Alert>
                </Box>
                ))}
            </Stack>
        </Stack>
    )

}

export default Information

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Stack } from '@mui/material' 
import { useLoginUser } from 'hooks/loginuser'
import { H5, Body } from 'components/styledFonts'
import { RadioGroup, } from 'components/Inputs'
import Settings from './Settings'
import { usePersonalMutaion } from 'hooks/mutation/personal'
import { useNavigate } from 'react-router'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const selectValues =[{ label: '受け取る', value: true },{ label: '受け取らない', value: false }]

const defaultValues = {
    メール通知設定 : true,
}

const PersonalNotification = () => {
    const { control, setValue, handleSubmit } = useForm({ defaultValues : defaultValues })
    const { data } = useLoginUser()
    const { mutate } = usePersonalMutaion()
    const navi = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        if (!data) return

        Object.keys(defaultValues).forEach(n => {
            setValue(n, data[n])
        })

    }, [data, setValue]) 

    const onSubmit = async (submitData) => {
        // value値は文字列にキャストされるので、booleanに戻す
        const value = JSON.parse(submitData.メール通知設定)

        if (data.メール通知設定 !== value) {
            mutate({
                メール通知設定 : value,
                id : data.$id,
                revision : data.$revision
            })
        } else {
            navi('/settings/personal')
        }
        setEdit(false)
    }

    if (!data) return null
    
    return (
        <Settings
            category="メール通知設定"
            currents={[
                { value : <Body>{selectValues.find(l => l.value === data.メール通知設定).label}</Body> }
            ]}
            editor={
                <Stack
                    columnGap={4}
                    rowGap={2}
                    pb={1}
                    maxWidth={500}
                >
                    <Stack>
                        {!isMobile && 
                        <H5>メール通知設定</H5>
                        }
                        <RadioGroup 
                            name="メール通知設定" 
                            control={control} 
                            list={selectValues} 
                            row 
                        />
                    </Stack>
                </Stack>
            }
            edit={edit}
            setEdit={setEdit}
            onSubmit={handleSubmit(onSubmit)}
        />
    )
}

export default PersonalNotification

import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api'
import { apiName } from 'config/api'
import { useDynamoItem } from 'hooks/dynamo'
import { useEffect, useState } from 'react'

const getData = async () => {
    const ret = await API.get(apiName, '/items/getMessages', {})
    const updateRet = ret.map(r => {
        return {
            ...r,
            日時 : new Date(r.日時)
        }
    })

    return updateRet
}

export const useReadFlags = () => {
    const { data : dynamo, isSuccess, dataUpdatedAt } = useDynamoItem("メッセージ既読履歴")
    const { data : messages } = useAllMessages()
    const [flags, setFlags] = useState(null)

    useEffect(() => {
        if (!isSuccess || !messages) return

        const f = messages.reduce((o, r) => {
            const count = dynamo?.find(d => d.レコード番号 === Number(r.$id))?.既読数 || 0
            return {
                ...o,
                [r.$id] : r.回答テーブル.map((t, i) => {
                    if (i + 1 > count) {     
                        return t.送信者 !== '企業担当者'
                    } else {
                        return false
                    }
                })
            }
        }, {})

        setFlags(f)

    }, [dynamo, messages, isSuccess, dataUpdatedAt])

    return flags
}

export const useAllMessages = () => {
    return useQuery(['message'], getData)
}

export const useClientMessages = () => {
    return useQuery(['message'], () => getData(), {
        select: (data) => data.filter(d => d.メッセージ区分 === '企業・メディア間')
    })
}

export const useSupportMessages = () => {
    return useQuery(['message'], getData, {
        select : (data) => {
            const cmsg = data.filter(d => d.メッセージ区分 === '企業・ネタもとサポート間')
            if (cmsg.length === 0) {
                return {
                    "メッセージ区分": "",
                    "企業ID": "",
                    "回答テーブル": [],
                    "日時": ""
                }
            }
            return cmsg[0]
        }
    })
}

export const useNewMessageCount = () => {
    const flags = useReadFlags()

    return Object.keys(flags || {}).reduce((a, n) => a + (flags[n] || []).filter(f => f).length, 0)
}
import { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { CheckboxGroup, SearchWord2, Select } from 'components/Inputs'
import { useFormContext } from 'react-hook-form'
import { useGroupName } from 'hooks/media'

const groupInit = [
    { label : 'グループで絞り込み', value : '' },
] 

const sortList = [
    { label : '媒体種類で絞り込み', value : '' },
    { label : '新聞', value : '新聞' },
    { label : '雑誌', value : '雑誌' },
    { label : 'TV', value : 'TV' },
    { label : 'ラジオ', value : 'ラジオ' },
    { label : 'WEB', value : 'WEB' },
    { label : 'その他', value : 'その他' },
]

const Conditions = () => {
    const { control, handleFiltering } = useFormContext()
    const { data } = useGroupName()
    const [filterList, setFilterList] = useState(groupInit)

    useEffect(() => {
        if (!data) return
        
        setFilterList(groupInit.concat(
            Object.keys(data.groups).filter(n => data.groups[n]).map(n => ({ label : data.groups[n], value : n }))
        ))
    }, [data])

    return (
        <Stack
            onChange={handleFiltering}
            pt={1}
            gap={1}
        >
            <SearchWord2
                name="検索ワード"
                control={control}
                placeholder="テキスト検索（各項目のテキストが検索対象）"
            />
            <Stack
                rowGap={2}
                sx={{
                    flexDirection : { pad : "row", xs : "column" },
                    alignItems : { pad : "center", xs : "flex-start" },
                    pb : { pad : 0, xs : 2 },
                }}
            >
                <Stack
                    direction="row"
                    ml={0.5}
                >
                    <CheckboxGroup
                        name="チェック"
                        control={control}
                        list={['チェックのみ表示']}
                    />
                    <CheckboxGroup
                        name="お気に入り"
                        control={control}
                        list={['お気に入りのみ表示']}
                    />
                </Stack>
                <Stack
                    direction="row"
                    gap={2}
                    sx={{
                        flexDirection : { sm : "row", xs : "column" },
                    }}
                >
                    <Box width={200}>
                        <Select
                            name="絞り込み"
                            control={control}
                            list={filterList}
                            handleChange={handleFiltering}
                        />
                    </Box>
                    <Box width={200}>
                        <Select
                            name="ソート"
                            control={control}
                            list={sortList}
                            handleChange={handleFiltering}
                        />
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Conditions


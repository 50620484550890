import useMyMutation from './useMyMutation'
import { API } from 'lib/api'
import { apiName } from 'config/api'

export const usePersonalMutaion = () => {
    return useMyMutation({
        mutationFn : async (param) => {
            const ret = await API.post(apiName, '/items/switchMailNotice', { body : param })
            return ret
        },
        queryKey : ['loginuser'], 
        to: '/settings/personal'
    })
}
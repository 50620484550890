import { useState } from 'react'
import MoreIcon from '@mui/icons-material/MoreVert'
import { MenuBarIconButton } from 'components/styledButtons'
import MenuList from './MenuList'
import { useManualURL } from 'hooks/variousHooks'

const menu = [
    { l : 'アカウント管理'  , p : '/account' },
    { l : '企業情報設定'    , p : '/settings/company' },
    { l : '個人設定'        , p : '/settings/personal' },
    { l : 'メッセージ'      , p : '/messages' },
    { l : 'お問い合わせ'    , p : '/messages/support' },
    { l : '操作マニュアル'  , p : '/' },
    { l : 'ログアウト'      , p : '/' },
]

const MoreButton = () => {
    const [ menuEl, setMenuEl ] = useState(null)
    const { data: url } = useManualURL()

    return (<>
        <MenuBarIconButton
            onClick={e => setMenuEl(e.currentTarget)}
        >
            <MoreIcon />
        </MenuBarIconButton>
        <MenuList
            menu={menu.map(m => {return m.l === '操作マニュアル' ? ({ ...m, p : url }) : m})}
            menuEl={menuEl}
            setMenuEl={setMenuEl}
            anchorOrigin={{
                vertical : 'bottom',
                horizontal : 'right',
            }}
            transformOrigin={{
                vertical : 'top',
                horizontal : 'right',
            }}
        />
    </>)
}

export default MoreButton

import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api'
import { apiName } from 'config/api'
import { isAfter } from 'date-fns'
import { useCompanyInfo } from 'hooks/company'
import { useDynamoResearch } from 'hooks/dynamo'


const getResearches = async (companyData) => {
    const ret = await API.post(apiName, `/items/getResearch`, {
        // API側でビジネスリクエスト機能がONの時とOFFの時で取得条件を変えるため、bodyにリクエスト_ビジネス_機能を設定する
        body: { リクエスト_ビジネス_機能: companyData.リクエスト_ビジネス_機能 },
    })

    return ret
        .map(r => ({
            ...r,
            募集締切日時: new Date(r.募集締切日時),
            募集開始日時_通常用: new Date(r.募集開始日時_通常用),
        }))
        .reduce((o, r) => {
            o[r.リクエストNo] = r
            return o
        }, {})
}

const getEntries = async () => {
    const entry = []

    for (let i = 0; ; i++) {
        const ret = await Promise.all([...Array(2).keys()]
            .map(n => API.get(apiName, `/items/getEntry?offset=${(i * 2 + n) * 400}`, {})
                .then(r => r?.error || r?.message ? [] : r)
                .catch(() => [])
            )
        )
        entry.push(...ret)
        if (ret[1].length < 400) break
    }

    return entry.flat().map(r => ({
        ...r,
        更新日_データ連携用: new Date(r.更新日_データ連携用)
    }))
}

const getResearchByNo = async (id, companyData) => {
    const ret = await API.post(apiName, `/items/getResearchByNo`, {
        body: {
            requestNo: id,
            リクエスト_ビジネス_機能: companyData.リクエスト_ビジネス_機能,
        }
    })
    ret.募集締切日時 = new Date(ret.募集締切日時)
    ret.募集開始日時_通常用 = new Date(ret.募集開始日時_通常用)

    return ret
}


export const useNewlyResearches = () => {
    const { data: companyData } = useCompanyInfo()
    return useQuery(['newlyResearch'], () => getResearches(companyData), {
        enabled: !!companyData
    })
}

export const useEntries = () => {
    return useQuery(['entry'], getEntries)
}

export const useAllResearches = () => {
    const { data: dynamo, isFetching: isFetchingDynamo, isLoading: isLoadingDynamo } = useDynamoResearch()
    const { data: researches, isFetching: isFetchingResearch, isLoading: isLoadingResearch } = useNewlyResearches()
    const { data: entries, isFetching: isFetchingEntry, isLoading: isLoadingEntry } = useEntries()
    const [list, setList] = useState([])
    const [isLoading, setIsLoding] = useState(true)

    // Dynamoとリクエストのフェッチ開始がエントリーのフェッチ完了より遅くなる（企業情報取得待ち合わせのため）ことを考慮しisFetchingとisLoadingを併用して待ち合わせの判定を行う
    const isFetching = [isFetchingDynamo, isFetchingResearch, isFetchingEntry, isLoadingDynamo, isLoadingResearch, isLoadingEntry].some(l => l === true)

    useEffect(() => {
        if (isFetching) {
            setIsLoding(true)
            return
        }

        Object.keys(researches).forEach(k => dynamo[k] = researches[k])

        setList(Object.values(dynamo)
            .map(r => ({
                ...r,
                エントリー: entries
                    .filter(e => e.リクエストNo === r.リクエストNo)
                    .sort((a, b) => a.エントリーNo - b.エントリーNo),
            }))
            .sort((a, b) => b.募集締切日時 - a.募集締切日時)
        )
        setIsLoding(false)

    },[dynamo, researches, entries, isFetching])

    return { data : list, isLoading : isLoading }
}

export const useResearchByNo = (id) => {
    const { data: companyData } = useCompanyInfo()
    return useQuery(['researchByNo', id], () => getResearchByNo(id, companyData), {
        enabled: !!companyData,
    })
}


export const useResearchById = (id) => {
    const { data } = useAllResearches()
    return { data : data.find(d => d.リクエストNo === id) }
}

export const useNewResearches = () => {
    const { data } = useAllResearches()
    return { data : data
        .filter(d => isAfter(d.募集締切日時, new Date()))
        .sort((a, b) => b.募集開始日時_通常用 - a.募集開始日時_通常用)
        .slice(0, 5)
    }
}

export const useDraftResearches = () => {
    const { data } = useAllResearches()
    return { data : data
        .filter(d => d.エントリー.some(f => f.下書き))
        .filter(d => isAfter(d.募集締切日時, new Date()))
        .sort((a, b) => a.募集締切日時 - b.募集締切日時)
        .slice(0, 5)
    }
}

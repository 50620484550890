import { Avatar, Box } from '@mui/material'
import { yellow } from '@mui/material/colors'
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp'
import clientIcon from 'img/icon-user-client.png'
import mediaIcon from 'img/icon-user-media.png'

const SenderIcon = ({ type }) => {
    if (type === '企業担当者') {
        return <Box component="img" src={mediaIcon} width={50} />
    }
    else if (type === 'ネタもと担当者') {
        return (
            <Avatar sx={{ bgcolor : yellow[500], width : 50, height : 50 }}>
                <SupportAgentSharpIcon sx={{ color : '#000', fontSize : 42 }} />
            </Avatar>
        )
    }
    else {
        return <Box component="img" src={clientIcon} width={50} />
    }
}

export default SenderIcon

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Stack } from '@mui/material' 
import { Body } from 'components/styledFonts'
import { LabeledTextField } from 'components/Inputs'
import Settings from './Settings'
import { Auth } from 'aws-amplify'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAtom } from 'jotai'
import { messageAtom } from 'store/commonAtoms'
import { messages } from 'config/messages'

const defaultValues = {
    現在のパスワード : '',
    新しいパスワード : '',
    新しいパスワード確認用 : '',
}

const schema = yup.object().shape({
    現在のパスワード : yup.string().required(messages.REQUIRED),
    新しいパスワード : yup
        .string()
        .required(messages.REQUIRED)
        .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/,
            { message: messages.PASSWORD_VALIDATION }),
    新しいパスワード確認用 : yup
        .string()
        .required(messages.REQUIRED)
        .oneOf([yup.ref('新しいパスワード'), null], messages.PASSWORD_MATCH_FAILED)
})

const PersonalPassword = () => {
    const { control, handleSubmit, reset } = useForm({ defaultValues : defaultValues, resolver: yupResolver(schema) })
    const [, setMessage] = useAtom(messageAtom)
    const [edit, setEdit] = useState(false)

    const onSubmit = async (submitData) => {
        try {
            // パスワード変更
            const user = await Auth.currentAuthenticatedUser()
            const data = await Auth.changePassword(user, submitData.現在のパスワード, submitData.新しいパスワード)
            console.log("パスワード変更 成功：" + data)
            const msg = { type : 'info', text : messages.OK_UPDATE_SUCCESS}
            setMessage(msg)
            reset()
        } catch(err) {
            console.log(err)
            const msg = { type : 'error', text : messages.PASSWORD_UPDATE_FAILED}
            setMessage(msg)
            reset()
        }
        setEdit(false)
    }

    return (
        <Settings
            category="パスワード"
            currents={[
                { value : <Body>******　※セキュリティの為パスワードは非表示になっています。</Body> },
            ]}
            editor={
                <Stack
                    columnGap={4}
                    rowGap={2}
                    pb={1}
                    maxWidth={500}
                >
                    <input type="text" hidden autoComplete="username" />
                    <LabeledTextField
                        name="現在のパスワード"
                        type="password"
                        control={control}
                        autoComplete="current-password"
                    />
                    <LabeledTextField
                        name="新しいパスワード"
                        type="password"
                        control={control}
                        autoComplete="new-password"
                        helperText="※半角英数大文字小文字数字記号含む8文字以上"
                    />
                    <LabeledTextField
                        name="新しいパスワード確認用"
                        type="password"
                        label="新しいパスワード（確認用）"
                        control={control}
                        autoComplete="new-password"
                        helperText="※半角英数大文字小文字数字記号含む8文字以上"
                    />
                </Stack>
            }
            edit={edit}
            setEdit={setEdit}
            onSubmit={handleSubmit(onSubmit)}
        />
    )
}

export default PersonalPassword

import { useForm } from 'react-hook-form'
import { Stack } from '@mui/material'
import { Body, H2 } from 'components/styledFonts'
import { BlueContainedButton } from 'components/styledButtons'
import { LabeledTextField, RadioGroup } from 'components/Inputs'
import Block from 'components/Block'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { messages } from 'config/messages'
import { useAccountMutaion } from 'hooks/mutation/account'

const defaultValues = {
    姓 : '',
    名 : '',
    メールアドレス : '',
    所属 : '',
    役職 : '',
    メール配信対象 : true,
    種別 : '一般',
}

const schema = yup.object().shape({
    姓 : yup
        .string()
        .required(messages.REQUIRED)
    ,
    名 : yup
        .string()
        .required(messages.REQUIRED)
    ,
    所属 : yup
        .string()
        .required(messages.REQUIRED)
    ,
    メールアドレス : yup
        .string()
        .required(messages.REQUIRED)
        .matches(/^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
            , { message : messages.MAILADDRESS_VALIDATION })
})

const AddAccount = () => {
    const { control, handleSubmit, reset } = useForm({ defaultValues : defaultValues, resolver : yupResolver(schema) })
    const { mutate : mutateAccount } = useAccountMutaion()

    const onSubmit = async (d) => {
        // value値は文字列にキャストされるので、booleanに戻す
        mutateAccount({ ...d, メール配信対象: JSON.parse(d.メール配信対象) })
        reset()
    }

    return (
        <Block>
            <Stack
                pb={1}
            >
                <H2>新規アカウント発行</H2>
                <Stack
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    pt={2}
                    px={1}
                    gap={2}
                >
                    <Stack
                        direction="row"
                        flexWrap="wrap"
                        columnGap={4}
                        rowGap={2}
                        pb={1}
                    >
                        <LabeledTextField 
                            name="姓"
                            control={control}
                            required
                            placeholder="姓をご入力下さい"
                        />
                        <LabeledTextField 
                            name="名"
                            control={control}
                            required
                            placeholder="名をご入力下さい"
                        />
                        <LabeledTextField 
                            name="所属"
                            control={control}
                            required
                            placeholder="所属をご入力下さい"
                        />
                        <LabeledTextField 
                            name="役職"
                            control={control}
                            placeholder="役職をご入力下さい"
                        />
                        <LabeledTextField 
                            name="メールアドレス"
                            control={control}
                            required
                            placeholder="メールアドレスをご入力下さい"
                            sx={{
                                width : 320,
                            }}
                        />
                    </Stack>
                    <Stack>
                        <Body>メール通知設定</Body>
                        <RadioGroup
                            name="メール配信対象"
                            control={control}
                            list={[{ label : '受け取る', value : true }, { label : '受け取らない', value : false }]}
                            row
                        />
                    </Stack>
                    <Stack>
                        <Body>種別</Body>
                        <RadioGroup
                            name="種別"
                            control={control}
                            list={['管理者', '一般']}
                            row
                        />
                    </Stack>
                    <Stack
                        alignItems="flex-end"
                    >
                        <BlueContainedButton
                            label="発行"
                            type="submit"
                            sx={{
                                fontSize : 16,
                                lineHeight : 2.5,
                                minWidth : 170,
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Block>
    )
}

export default AddAccount

import { Divider, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import Block from 'components/Block'
import { BlueOutlinedButton } from 'components/styledButtons'
import { H2, Body } from 'components/styledFonts'
import { useCompanyInfo } from 'hooks/company'

const LV = ({ label, value }) => (
    <Stack
        direction="row"
        alignItems="center"
        py={1.5}
        px={1}
        gap={2.5}
    >
        <Body
            sx={{
                width : 100,
                flexShrink : 0
            }}
        >
            {label}
        </Body>
        <Body
            sx={{ wordBreak: "break-all" }}
        >
            {value}
        </Body>
    </Stack>
)


const Company = ({ overWrite }) => {
    const { data } = useCompanyInfo()

    return (
        <Block
            sx={{
                width: "100%",
                minHeight: 400,
            }}
        >
            {data &&
            <Stack
                gap={2}
            >
                <H2>{data.会社名}</H2>
                <Stack>
                    <Divider />
                    <LV
                        label={overWrite?.問い合わせ担当者部署_上書き || `${data.部署_メディア}　${data.役職_メディア}`}
                        value={overWrite?.問い合わせ担当者_上書き || data.氏名_メディア}
                    />
                    <Divider />
                    <LV label="URL" value={data.Webサイト_URL} />
                    <Divider />
                    <LV label="住所" value={`${data.都道府県_住所}${data.市区町村_住所}${data.町名_番地_住所}　${data.ビル名_住所}`} />
                    <Divider />
                    <LV label="業種" value={data.業種} />
                    <Divider />
                    <LV label="お問い合わせ先" value={data.電話番号_担当_メディア} />
                    <Divider />
                    <Stack
                        direction="row"
                        alignItems="center"
                        flexWrap="wrap"
                        gap={2}
                        margin={2}
                    >
                        {["Facebook", "X", "Instagram", "TikTok"].filter(s => data[s]).map((s, i) => 
                        <BlueOutlinedButton
                            label={s}
                            key={i}
                            component={Link}
                            to={data[s]}
                            sx={{
                                width : "45%",
                                height : 44,
                                textTransform :"none"
                            }}
                            target="_blank"
                        />
                        )}
                    </Stack>
                    <Divider />
                </Stack>
            </Stack>
            }
        </Block>
    )
}

export default Company

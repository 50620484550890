import { Box, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'


const Unregistered = (props) => {

    return (
        <Stack alignItems="center" justifyContent="center">
            <Box
                fontSize={14}
                fontWeight={700}
                color={grey[400]}
                border={`3px solid ${grey[300]}`}
                borderRadius={1}
                px={4}
                py={1.5}
                my={3}
                {...props}
            >
                未登録
            </Box>
        </Stack>
    )
}

export default Unregistered

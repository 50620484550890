import { Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { H1, Body, StyledLink } from 'components/styledFonts'
import Block from 'components/Block'
import { useReports } from 'hooks/report'

const ReportList = () => {
    const { data } = useReports()

    console.log('reportData', data)

    return (
        <Stack
            gap={2}
        >
            <H1>レポート</H1>
            <Block
                sx={{ p: 0 }}
            >
                <Stack>
                    {data?.map((d, i) => (
                        <StyledLink
                            key={i}
                            to={d.レポートURL}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                p: 2,
                                bgcolor: '#fff',
                                borderBottom: i !== data.length ? `1px solid ${grey[300]}` : 0,
                            }}
                        >
                            <Body>{d.タイトル}</Body>
                        </StyledLink>
                    ))}
                </Stack>
            </Block>
        </Stack>
    )
}

export default ReportList

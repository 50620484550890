import { Stack, useMediaQuery } from '@mui/material'
import { Body, H2 } from 'components/styledFonts'
import { GreyLabel, BlueLabel, WhiteLabel } from 'components/styledLabels'
import Block from 'components/Block'
import { format } from 'date-fns'
import PostedPicture from 'components/PostedPicture'
import ResponsivePlayer from 'components/ResponsivePlayer'
import PdfViewer from 'components/PdfViewer'
import { useLoginUser } from 'hooks/loginuser'
import { useLocation } from 'react-router-dom'


const PressReleaseDetail = ({ data }) => {
    const { data: user } = useLoginUser()
    const { pathname } = useLocation()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))

    if (!data) return

    const nums = [1, 2, 3, 4].filter(n => data[`メイン画像${n}`] || data[`本文${n}`] || data[`画像${n}`] || data[`動画${n}URL`] || data[`動画${n}`])

    return (
        <Block
            sx={{
                minHeight : 700,
            }}
        >
            {data &&
            <Stack
                gap={2}
            >
                <H2>{data.タイトル}</H2>
                <Stack
                    direction="column"
                >
                    <Stack
                        direction="row"
                        columnGap={1}
                        rowGap={1}
                        flexWrap="wrap"
                        pb={1}
                    >
                        <BlueLabel>プレスリリース</BlueLabel>
                        {data.ジャンル?.map((e, i) =>
                        <GreyLabel key={i}>{e}</GreyLabel>
                        )}
                        {data.カテゴリ &&
                        <GreyLabel>{data.カテゴリ}</GreyLabel>
                        }
                    </Stack>
                    <Stack
                        direction="row"
                        columnGap={1}
                        rowGap={1}
                        flexWrap="wrap"
                        pb={1}
                    >
                        <WhiteLabel>{`公開日：${data.リリース日時 ? format(data?.リリース日時, 'yyyy/MM/dd HH:mm') : ""}`}</WhiteLabel>
                        {!/\/preview$/.test(pathname) && !isMobile && <>
                        <WhiteLabel>{`更新日：${data.更新日 ? format(data?.更新日, 'yyyy/MM/dd HH:mm') : ""}`}</WhiteLabel>
                        <WhiteLabel>{`更新者：${data.更新者 || user?.姓 + user?.名}`}</WhiteLabel>
                        </>}
                    </Stack>
                </Stack>
                <Stack
                    gap={4}
                >
                    {!data.PDF入稿 && <>
                    {data.リード文 &&
                    <Body whiteSpace="pre-wrap">{data.リード文}</Body>
                    }
                    {nums.map(i => (
                    <Stack
                        key={i}
                        gap={4}
                    >
                        <PostedPicture
                            data={data[`メイン画像${i}`]}
                            caption={data[`メイン画像${i}キャプション`]}
                        />
                        {data[`本文${i}`] &&
                        <Body className="innerHTML"
                            dangerouslySetInnerHTML={{ __html: data[`本文${i}`] }}
                        />
                        }
                        <PostedPicture
                            data={data[`画像${i}`]}
                            caption={data[`画像${i}キャプション`]}
                        />
                        {(data[`動画${i}`]?.name) &&
                        <Stack
                            pb={1}
                            sx={{
                                px: { mobile: 2, xs: 0 }
                            }}
                        >
                            <ResponsivePlayer
                                data={data[`動画${i}`]}
                            />
                        </Stack>
                        }
                        {data[`動画${i}URL`] &&
                        <Stack
                            pb={1}
                            sx={{
                                px: { mobile: 2, xs: 0 }
                            }}
                        >
                            <ResponsivePlayer
                                data={data[`動画${i}URL`]}
                            />
                        </Stack>
                        }
                    </Stack>
                    ))}
                    </>}
                    {data.PDF入稿 && 
                    <Stack
                        gap={4}
                    >
                        <PostedPicture
                            data={data[`メイン画像1`]}
                            caption={data[`メイン画像1キャプション`]}
                        />
                        {data.PDF &&
                        <Stack>
                            <PdfViewer
                                data={data[`PDF`]}
                            />
                        </Stack>
                        }
                        {data[`動画1`] &&
                        <Stack
                            pb={1}
                            sx={{
                                px: { mobile: 2, xs: 0 }
                            }}
                        >
                            <ResponsivePlayer
                                data={data[`動画1`]}
                            />
                        </Stack>
                        }
                        {data[`動画1URL`] &&
                        <Stack
                            pb={1}
                            sx={{
                                px: { mobile: 2, xs: 0 }
                            }}
                        >
                            <ResponsivePlayer
                                data={data[`動画1URL`]}
                            />
                        </Stack>
                        }
                        <PostedPicture
                            data={data[`画像1`]}
                            caption={data[`画像1キャプション`]}
                        />
                    </Stack>}
                </Stack>
            </Stack>
            }
        </Block>
    )
}

export default PressReleaseDetail

import { Box, Link, Stack } from '@mui/material'
import { Body } from 'components/styledFonts'
import { urls } from 'config/urls'

const FooterLink = ({ href, label }) => 
    <Link
        href={href}
        target="_blank"
        underline="hover"
        color="grey.700"
    >
        <Body>{label}</Body>
    </Link>

const Footer = ({ children }) => {
    return (
        <Stack
            alignItems="center"
            px={1}
            sx={{ bgcolor : 'grey.300' }}
        >
            <Stack
                justifyContent="space-between"
                alignItems="center"
                pt={4}
                pb={12}
                width="100%"
                maxWidth={1212}
                rowGap={1}
                sx={{
                    flexDirection : { sm : "row", xs : "column" },
                }}
            >
                <Stack
                    direction="row"
                    gap={4}
                >
                    {Object.keys(urls.footer).map(l => (
                    <FooterLink key={l} label={l} href={urls.footer[l]} />
                    ))}
                </Stack>
                <Box flexGrow={1} />
                <Body>Copyright © Netamoto Inc. All rights reserved.</Body>
            </Stack>
        </Stack>
    )
}

export default Footer

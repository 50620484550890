import { useState } from 'react'
import { Stack, useMediaQuery } from '@mui/material'
import { BlueContainedButton } from 'components/styledButtons'
import Scroll from 'components/Scroll'  
import PageTitleAndButtons from 'components/PageTitleAndButtons'
import AddAccount from './AddAccount'
import AccountList from './AccountList'
import AccountListForPad from './AccountListForPad'
import { useAccounts } from 'hooks/account'
import Block from 'components/Block'
import { H2 } from 'components/styledFonts'
import { useLoginUser } from 'hooks/loginuser'
import { CheckboxGroupBase } from 'components/Inputs'

const AccountMain = () => {
    const [scroll, setScroll] = useState(false)
    const isPad = useMediaQuery((theme) => theme.breakpoints.down('pad'))
    const { data } = useAccounts()
    const [isAllUserView, setIsAllUserView] = useState(false)
    const { data : loginUserData } = useLoginUser()

    return (
        <Stack
            gap={2}
        >
            <PageTitleAndButtons
                title="アカウント管理"
                buttons={[
                    <BlueContainedButton 
                        label="新規発行"
                        sx={{ display : loginUserData?.isAdmin ? 'block' : 'none'}}
                        onClick={() => setScroll(true)}
                    />
                ]}
            />
            <Block>
                <Stack
                    gap={2}
                >
                    <Stack
                        direction="row"
                        rowGap={2}
                        columnGap={4}
                    >
                        <H2>現在紐付いているアカウント</H2>
                        <CheckboxGroupBase
                            list={[{ label : '無効化ユーザー含めて表示', value : isAllUserView }]}
                            onChange={(e) => {
                                setIsAllUserView(e.target.checked)
                            }}
                            value={isAllUserView}
                            boolean={true}
                            sx={{
                                fontSize : 14
                            }}
                        />
                    </Stack>
                    {!isPad && 
                    <AccountList
                        data={data?.filter(d => isAllUserView ? true : !d.アカウント無効化)}
                    />}
                    {isPad &&
                    <AccountListForPad
                        data={data?.filter(d => isAllUserView ? true : !d.アカウント無効化)}
                    />}
                </Stack>
            </Block>
            <Stack mt={-2}>
                <Scroll scroll={scroll} setScroll={setScroll} />
            </Stack>
            {loginUserData?.isAdmin &&
            <AddAccount />
            }
        </Stack>
    )
}

export default AccountMain

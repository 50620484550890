import { useParams } from 'react-router-dom'
import { Stack, useMediaQuery } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { format } from 'date-fns'
import { Body, H5Overflow } from 'components/styledFonts'
import { BlueLabel } from 'components/styledLabels'
import Block from 'components/Block'
import { ThumbnailNoLink } from 'components/Thumbnail'
import { usePreviousNext as usePrsPreviousNext } from 'hooks/pressrelease'
import { NodecorationLink } from 'components/links'
import { usePreviousNext as useFbPreviousNext } from 'hooks/feedback'
import { usePreviousNext as useNewsPreviousNext } from 'hooks/newsletter'


const LinkItem = ({ data, prev, next }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))

    return (
        <Stack
            to={data.to}
            component={NodecorationLink}
            direction="row"
            alignItems="center"
            gap={2}
        >
            {prev &&
            <ArrowBackIcon
                color="grey400"
                sx={{
                    fontSize : 40,
                    ml : -1,
                }}
            />
            }
            <Stack
                width="100%"
                gap={1}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    {!isMobile && (<>
                    <BlueLabel>{data.type}</BlueLabel>
                    <Body>{`公開日：${data.公開日 ? format(data.公開日, 'yyyy/MM/dd HH:mm') : ""}`}</Body>
                    </>)}
                </Stack>
                <Stack
                    direction="row"
                    gap={1}                
                >
                    {!isMobile &&
                    <ThumbnailNoLink
                        width={90}
                        height={60}
                        src={data.サムネイル}
                    />
                    }
                    <H5Overflow>{data.タイトル}</H5Overflow>
                </Stack>
            </Stack>
            {next &&
            <ArrowForwardIcon
                color="grey400"
                sx={{
                    fontSize : 40,
                    mr : -1,
                }}
            />
            }
        </Stack>
    )
}

const PreviousNextBlock = ({ data, prev, next }) => (
    <Block
        sx={{
            maxWidth : 400,
            p : 2,
            visibility : data ? "visible" : "hidden",
        }}
    >
        {data &&
        <LinkItem
            data={data}
            prev={prev}
            next={next}
        />
        }
    </Block>
)

const PreviousNextBase = ({ data }) => {
    return (<>
        {(data?.[0] || data?.[1]) &&
        <Stack
            direction="row"
            alignItems="stretch"
            justifyContent="center"
            gap={1.5}
        >
            <PreviousNextBlock
                data={data?.[0]}
                prev
            />
            <PreviousNextBlock
                data={data?.[1]}
                next
            />
        </Stack>
        }
    </>)
}

export const FbPreviousNext = () => {
    const { no } = useParams()
    const { data } = useFbPreviousNext(no)

    return (
        <PreviousNextBase
            data={data}
        />
    )
}

export const PrsPreviousNext = () => {
    const { no } = useParams()
    const { data } = usePrsPreviousNext(no)

    return (
        <PreviousNextBase
            data={data}
        />
    )
}

export const NewsPreviousNext = () => {
    const { no } = useParams()
    const { data } = useNewsPreviousNext(no)

    return (
        <PreviousNextBase
            data={data}
        />
    )
}

import { useState } from 'react'
import { Box, Stack } from '@mui/material'
import { blueGrey, grey } from '@mui/material/colors'
import { BlueContainedButton } from 'components/styledButtons'
import { useMessagesMutaion } from 'hooks/mutation/message'
import { useLoginUser } from 'hooks/loginuser'
import { useCompanyInfo } from 'hooks/company'
import { TextFieldBase } from 'components/Inputs'

const Entry = ({ entryMessage }) => {
    const [message, setMessage] = useState('')
    const { mutate } = useMessagesMutaion()
    const { data : loginUser } = useLoginUser()
    const { data : companyInfo } = useCompanyInfo()

    const handleButtonClick = () => {
        mutate({
            // メッセージアプリ登録・更新用パラメータ
            登録 : !entryMessage.日時,
            メッセージ区分 : entryMessage.媒体担当者ID ? "企業・メディア間" : "企業・ネタもとサポート間",
            企業ID : companyInfo.企業ID,
            回答テーブル : entryMessage.回答テーブル.map(i => ({ "id": i.id })),
            送信者 : "企業担当者",
            企業担当者ID : loginUser.企業担当者ID,
            回答内容 : message,
            id : entryMessage.$id,
            revision : entryMessage.$revision,
            
            // メール送信用パラメータ
            テンプレート区分 : "メッセージ通知_クライアント",
            送信先_企業名 : entryMessage.媒体名,
            送信先_姓 : entryMessage.媒体担当者_姓,
            送信先_名 : entryMessage.媒体担当者_名,
            送信者_企業名 : companyInfo.会社名,
            送信者_姓 : loginUser.姓,
            送信者_名 : loginUser.名,
            メッセージ : message,
            媒体担当者ID : entryMessage.媒体担当者ID
        })
        setMessage('')
    }

    return (
        <Box
            bgcolor={blueGrey[50]}
            border={1}
            borderColor={grey[400]}
            px={2}
            mb={2}
            sx={{
                borderRadius : { mobile : "0 0 4px 4px", xs : 1 },
                mt : { mobile : "-4px", xs : 0 },
                py : { mobile : 4, xs : 2 },
            }}
        >
            <Stack
                alignItems="center"
                gap={2}
            >
                <TextFieldBase
                    placeholder="こちらにメッセージを入力して下さい"
                    multiline
                    minRows={4}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    sx={{
                        bgcolor : '#fff',
                        width : { mobile : "80%", xs : "100%" },
                    }}
                />
                <BlueContainedButton
                    label="メッセージ送信"
                    sx={{
                        width : { xs : 160 },
                        lineHeight : 2.4,
                    }}
                    onClick={handleButtonClick}
                    disabled={!message}
                />
            </Stack>
        </Box>
    )
}

export default Entry



import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api'
import { apiName } from 'config/api'

const programs = [
    'セミナー機能',
    'PR勉強会機能',
    '特別講座機能',
    'メディア情報交換会機能',
    'PRアドバイザー相談会機能',
    'プレスリリース添削会機能',
]

const getData = async () => {
    const ret = await API.get(apiName, '/items/getCompanyInfo', {})
    ret.契約開始日 = new Date(`${ret.契約開始日} 00:00:00`)
    ret.契約満了日 = new Date(`${ret.契約満了日} 23:59:59`)
    return ret
}

export const useCompanyInfo = () => {
    return useQuery(['company'], getData)
}

export const useAvailableFeatures = () => {
    return useQuery(['company'], getData, {
        select : (data) => {
            const pg = Object.keys(data).filter(k => /メディア交流会機能/.test(k)).concat(programs).some(k => data[k])

            return {
                企業探訪設定 : data.企業探訪機能,
                プレスリリース : true,
                ニュースレター : data.ネタ機能,
                リサーチ : data.リクエスト機能,
                フィードバック : Object.keys(data).filter(k => /^フィードバック機能/.test(k)).some(k => data[k]),
                プログラム申込 : pg, 
                プログラム申込履歴 : pg, 
                オンライン講座 : data.動画配信機能,
                メディアリスト : data.メディアリスト管理機能,
                PR活動診断 : data.PR活動診断機能,
                レポート : true,
            }
        },
    })
}

export const useFeedbackable = () => {
    return useQuery(['company'], getData, {
        select : (data) =>  Object.keys(data)
            .filter(k => /^フィードバック機能/.test(k) && data[k])
            .sort()
            .map(k => k.replace(/^フィードバック機能_0?/, ''))
            .join('、'),
    })
}


import { Link, Outlet, useLocation } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { Stack } from '@mui/material'
import Block from 'components/Block'
import { H1, H3 } from 'components/styledFonts'
import { BlueContainedButton, BlueOutlinedButton } from 'components/styledButtons'
import TwoColumns from 'components/TwoColumns'
import { useAvailableFeatures } from 'hooks/company'
import SubmissionMethodModal from 'modals/SubmissionMethodModal'
import PageSelector from 'pages/post/PageSelector'
import { submissionModalAtom } from 'store/commonAtoms'
import Inquiries from './Inquiries'

const list = [
    { label : 'フィードバック', value : '/post/list/feedback' },
    { label : 'プレスリリース', value : '/post/list/pressrelease' },
    { label : 'ニュースレター', value : '/post/list/newsletter' },
]

const PostListBase = () => {
    const loc = useLocation()
    const setOpen = useSetAtom(submissionModalAtom)
    const { data: features } = useAvailableFeatures()

    return (
        <Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                pb={2}
            >
                <H1>情報発信</H1>
                <BlueOutlinedButton
                    label="メディアリスト"
                    component={Link}
                    to="/medialist"
                    sx={{
                        width : { sm : 280, xs : 200 },
                        height : 44,
                    }}
                />
            </Stack>
            <TwoColumns
                ratio="8fr 4fr"
            >
                <Block>
                    <Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <PageSelector
                                list={list.filter(l => (features || {})[l.label])}
                            />
                            {loc.pathname !== '/post/list/feedback' &&
                            <Stack
                                sx={{
                                    gap : { sm : 1.5, xs : 1 },
                                    flexDirection : { sm : "row", xs : "column" },
                                }}
                            >
                                <BlueContainedButton
                                    onClick={() => setOpen(true)}
                                    label="新規作成"
                                    sx={{ width : { sm : 150, xs : 130 } }}
                                />
                                <BlueOutlinedButton
                                    component={Link}
                                    to={loc.pathname === "/post/list/pressrelease" ? "/post/wordtemplate" : "/post/newslettertemplate"}
                                    target={loc.pathname === '/post/list/pressrelease' ? "_blank" : ""}
                                    label="テンプレート"
                                    sx={{ width : { sm : 150, xs : 130 } }}
                                />
                                <SubmissionMethodModal />
                            </Stack>
                            }
                        </Stack>
                        <Outlet />
                    </Stack>
                </Block>
                {features?.企業探訪設定 &&
                <Block>
                    <H3>企業探訪</H3>
                    <Inquiries />
                </Block>
                }
            </TwoColumns>
        </Stack>
    )
}

export default PostListBase

import ConditionalList from './ConditionalList'
import NoData from './NoData'
import { useAllPressRelease } from 'hooks/pressrelease'
import { prsListCond } from 'store/commonAtoms'

const PressReleaseList = () => {
    const { data } = useAllPressRelease()

    if (data?.length === 0) return <NoData title='プレスリリース' />

    return <ConditionalList data={data} listCond={prsListCond} />
}

export default PressReleaseList

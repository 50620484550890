import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { SelectImage } from 'components/FileUploader'
import PostedPicture, { isPicture } from 'components/PostedPicture'
import { messages } from 'config/messages'
import { useCompanyMutaion } from 'hooks/mutation/company'
import { useCompanyInfo } from 'hooks/company'
import { useLoginUser } from 'hooks/loginuser'
import Settings from './Settings'
import { LogoBox } from 'pages/base/CompanyLogo'

const defaultValues = {
    会社ロゴ: null,
    会社ロゴオリジナルファイル名: '',
    会社名: '',
    マイページ企業情報ID: '',
    $revision: '',
}

const schema = yup.object().shape({
    会社ロゴ: yup
        .mixed()
        .test("fileRequired", messages.SELECTION_REQUIRED,(value) => {
            return value !== ""
        })
})

const CompanyLogo = () => {
    const { control, setValue, handleSubmit, watch } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) })
    const { data } = useCompanyInfo()
    const { mutate: mutateCompany } = useCompanyMutaion()
    const [edit, setEdit] = useState(false)
    const { data: user } = useLoginUser()

    useEffect(() => {
        if (!data) return

        Object.keys(defaultValues).forEach(n => setValue(n, data[n]))

        if (!data.会社名) {
            setValue('会社名', data.企業名)
        }
    }, [data, setValue])

    const onSubmit = async (data) => {
        data.登録区分 = "ロゴマーク"
        mutateCompany(data)
        setEdit(false)
    }

    if (!data) return null

    return (
        <Settings
            category="ロゴマーク"
            currents={[{
                value: data.会社ロゴ ? 
                    <LogoBox component="img" src={data.会社ロゴ} /> 
                    : 
                    <LogoBox bgcolor={grey[400]}>未設定</LogoBox> 
            }]}
            editor={
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                    pb={1}
                >
                    <SelectImage name="会社ロゴ" control={control} setValue={setValue} file={watch('会社ロゴ')} />
                    {isPicture(watch('会社ロゴ')) &&
                    <Stack
                        direction="row"
                        alignItems="center"
                    >
                        <PostedPicture
                            data={watch('会社ロゴ')}
                            maxWidth={158}
                            maxHeight={50}
                        />
                    </Stack>
                    }
                </Stack>
            }
            onSubmit={handleSubmit(onSubmit)}
            edit={edit}
            setEdit={setEdit}
            uneditable={!user?.isAdmin}
        />
    )
}

export default CompanyLogo

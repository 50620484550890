import { Alert, Button, Dialog, DialogContent, DialogActions } from '@mui/material'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { cancelDialogAtom } from 'store/commonAtoms'


export const CancelDialog = () => {
    const [to, setTo] = useAtom(cancelDialogAtom)
    const navi = useNavigate()

    const handleYes = () => {
        navi(to)
        setTo(null)
    }

    return (
        <Dialog
            open={!!to}
            onClose={() => setTo(null)}
        >
            <DialogContent>
                <Alert severity="warning">入力内容がキャンセルされますが、よろしいですか？</Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setTo(null)} variant="outlined" sx={{ minWidth : 100 }}>いいえ</Button>
                <Button onClick={handleYes} variant="contained" sx={{ minWidth : 100 }}>はい</Button>
            </DialogActions>
        </Dialog>
    )
}
    
export default CancelDialog

import { Stack } from '@mui/material'
import { Group } from 'components/styledLabels'
import { useGroupName } from 'hooks/media'


import { red, pink, purple, deepPurple, blue, cyan, green, lime, orange, brown } from '@mui/material/colors'

const colorList = [
    '',
    red[300],
    pink[300],
    purple[300],
    deepPurple[300],
    blue[300],
    cyan[300],
    green[300],
    lime[300],
    orange[300],
    brown[300]
]

const Groups = ({ data : groupInfo }) => {
    const { data } = useGroupName()

    return (
        <Stack
            direction="row"
            flexWrap="wrap"
            gap={1}
        >
            {groupInfo.map((d, i) => data?.groups[d] &&
            <Group
                key={i}
                color={colorList[d]}
            >
                {data.groups[d]}
            </Group>
            )}
        </Stack>
    )
}

export default Groups

import { Stack } from '@mui/material'
import { useSetAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { useCompanyInfo, useFeedbackable } from 'hooks/company'
import { useFeedbackedCount } from 'hooks/feedback'
import { cancelDialogAtom } from 'store/commonAtoms'
import { messageAtom } from 'store/commonAtoms'
import { messages } from 'config/messages'
import { BlueOutlinedButton, LinkButtonLarge } from './styledButtons'

const buttonSize = {
    width: 240,
    height: 44,
}

const PostButtonSet = ({ handleSubmit, watch, saveDraft, pressRelease, preSubmit, isFeedback=false }) => {
    const { data: feedbackable } = useFeedbackable()
    const { data: company } = useCompanyInfo()
    const { data: count } = useFeedbackedCount(company)
    const navi = useNavigate()
    const setTo = useSetAtom(cancelDialogAtom)
    const setMsg = useSetAtom(messageAtom)

    return (
        <Stack
            direction="row"
            justifyContent="center"
            gap={2}
            pt={2}
            pb={1}
            flexWrap="wrap"
            component="form"
            onSubmit={handleSubmit(() => {
                if (isFeedback) {
                    // 契約期間内のフィードバック依頼数チェック
                    if (count >= company.フィードバック提供回数) {
                        setMsg({ type : 'error', text : messages.ERR_FEEDBACK_LIMIT_OVER })
                        return
                    }
                    // 現在日時がフィードバック可能月以外の場合、エラーとする
                    if (!feedbackable.split(/、/).map(d => Number(d.replace(/月/, ''))).includes(new Date().getMonth() + 1)) {
                        setMsg({ type : 'error', text : messages.FEEDBACK_NOT_APPLICABLE })
                        return
                    }
                }
                navi(`/${pressRelease ? "pressrelease" : "newsletter"}/submission/preview`)
            })}
        >
            {watch("下書き") &&
            <BlueOutlinedButton
                label="下書き保存"
                sx={buttonSize}
                onClick={e => {
                    // Trumbowygで入力した内容をformデータに反映させる
                    if (preSubmit) preSubmit()
                    saveDraft()
                }}
            />
            }
            <BlueOutlinedButton
                label="確認"
                sx={buttonSize}
                type="submit"
                onClick={e => {
                    // Trumbowygで入力した内容をsubmitイベント前にformデータに反映させる
                    if (preSubmit) preSubmit()
                }}
            />
            <LinkButtonLarge
                onClick={() => setTo(`/post/list/${pressRelease ? "pressrelease" : "newsletter"}`)}
            >
                キャンセル
            </LinkButtonLarge>
        </Stack>
    )
}

export default PostButtonSet

import { Stack } from '@mui/material'
import { CheckboxGroup } from './Inputs'
import { useFeedbackable } from 'hooks/company'
import { Body, H5 } from './styledFonts'

const FeedbackCheckbox = ({ name, control, disabled, inquiry }) => {
    const { data : feedbackable} = useFeedbackable()

    return (<>
        {feedbackable &&
        <Stack>
            <Stack
                px={inquiry ? 0.5 : 0}
            >
                <CheckboxGroup
                    name={name}
                    control={control}
                    disabled={disabled}
                    boolean
                    sx={inquiry ? {} : {
                        width : { sm : "26%", xs : "52%" },
                        mr : 0,
                        pl : 1,
                    }}
                    list={[{ value: 'フィードバック依頼', label: <H5>フィードバック依頼</H5> }]}
                />
            </Stack>
            {!inquiry &&
            <Body>※フィードバックを依頼し投稿ボタンを押した後は、再編集することはできませんのでご注意ください。</Body>            
            }
        </Stack>
        }
    </>)
}

export default FeedbackCheckbox
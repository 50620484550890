import { Link } from '@mui/material'
import { Body } from 'components/styledFonts'


const MailAddress = ({ children }) => {
    if (!children) return ''

    return (
        <Link
            href="#"
            underline="hover"
            onClick={e => {
                e.preventDefault()
                window.location.href=`mailto:${children}`}
            }
        >
            <Body>{children}</Body>
        </Link>
    )
}

export default MailAddress

import { useQuery } from '@tanstack/react-query'
import { apiName } from 'config/api'
import { API } from 'lib/api'

const getData = async () => {
    const ret = await API.get(apiName, '/items/getVideoHistory', {})
    return ret
}

export const useMediaSeminarCount = () => {
    return useQuery(['history'], getData, {
        select: (data) => new Set(data.filter(d => d.動画カテゴリ === 'メディアセミナー').map(d=>d.動画ストリーミング管理名)).size
    })
}

import { Stack, useMediaQuery } from '@mui/material'
import { H2 } from 'components/styledFonts'
import { GreyLabel, BlueLabel, WhiteLabel } from 'components/styledLabels'
import Block from 'components/Block'
import { format } from 'date-fns'
import { useLoginUser } from 'hooks/loginuser'
import NewsLetterDetailHtml from './NewsLetterDetailHtml'
import NewsLetterDetailPdf from './NewsLetterDetailPdf'
import NewsLetterDetailText from './NewsLetterDetailText'
import TemplateTitle from 'components/TemplateTitle'

const NewsLetterDetail = ({ data }) => {
    const { data: user } = useLoginUser()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))

    if (!data) return

    return (
        <Block
            sx={{
                minHeight : 700,
            }}
        >
            <Stack
                gap={2}
                sx={{ wordBreak : "break-word" }}
            >
                {data.ネタテーマタイトル &&
                <TemplateTitle text={data.ネタテーマタイトル} />
                }
                <H2>{data.ネタたねタイトル}</H2>
                <Stack
                    direction="column"
                >
                    <Stack
                        direction="row"
                        columnGap={1}
                        rowGap={1}
                        flexWrap="wrap"
                        pb={1}
                    >
                        <BlueLabel>ニュースレター</BlueLabel>
                        {data.ジャンル?.map((e, i) =>
                        <GreyLabel key={i}>{e}</GreyLabel>
                        )}
                        {data.カテゴリ &&
                        <GreyLabel>{data.カテゴリ}</GreyLabel>
                        }
                    </Stack>
                    <Stack
                        direction="row"
                        columnGap={1}
                        rowGap={1}
                        flexWrap="wrap"
                        pb={1}
                    >
                        <WhiteLabel>{`公開日：${data.リリース日時 ? format(data?.リリース日時, 'yyyy/MM/dd HH:mm') : ""}`}</WhiteLabel>
                        {!isMobile && <>
                        <WhiteLabel>{`更新日：${data.更新日 ? format(data?.更新日, 'yyyy/MM/dd HH:mm') : ""}`}</WhiteLabel>
                        <WhiteLabel>{`更新者：${data.更新者 || user?.姓 + user?.名}`}</WhiteLabel>
                        </>}
                    </Stack>
                </Stack>
                {data.入稿方法 === "HTML入稿" && 
                <NewsLetterDetailHtml data={data}/>
                }
                {(data.入稿方法 === "PDF入稿" || data.入稿方法 === "PDF入稿（テンプレートから遷移）") && 
                <NewsLetterDetailPdf data={data}/>
                }
                {data.入稿方法 === "テキスト入稿（テンプレートから遷移）" && 
                <NewsLetterDetailText data={data}/>
                }
            </Stack>
        </Block>
    )
}

export default NewsLetterDetail

import { Stack } from '@mui/material'
import { genreList } from 'config/options'
import { CheckboxGroup, LabeledInputField } from './Inputs'

const GenreSelect = ({ control }) => {

    return (
        <Stack>
            <LabeledInputField
                label="ジャンル"
                note="※3つまで選択可"
                required
            />
            <CheckboxGroup
                name="ジャンル"
                control={control}
                row
                max={3}
                sx={{
                    width : { sm : "26%", xs : "100%" },
                    mr : 0,
                    pl : 1,
                }}
                list={genreList}
            />
        </Stack>
    )
}

export default GenreSelect
import { Stack } from '@mui/material'
import { H1 } from 'components/styledFonts'
import VideoModal from 'modals/VideoModal'
import PickupMovies from './PickupMovies'
import MovieList from './MovieList'
import MovieHistory from './MovieHistory'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import MovieListMobile from './MovieListMobile'


const MovieTop = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

    return (<>
        <Stack
            gap={2}
        >
            <H1>オンライン講座</H1>
            <PickupMovies />
            {isMobile ?
            <MovieListMobile />
            :
            <MovieList />
            }
            <MovieHistory />
        </Stack >
        <VideoModal />
    </>)
}

export default MovieTop

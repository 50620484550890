import { useParams } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { blue } from '@mui/material/colors'
import { H4 } from 'components/styledFonts'
import flagIcon from 'img/icon_flag.png'
import flagFrameIcon from 'img/icon_flag_frame.png'
import { useDynamoItem } from 'hooks/dynamo'
import { useDynamoMutation } from 'hooks/mutation/useDynamoMutation'


const CheckButton = () => {
    const { requestNo } = useParams()
    const { data: dynamoData } = useDynamoItem("リサーチ")
    const { mutate } = useDynamoMutation("リサーチ")
    const check = !!dynamoData?.some(r => r === requestNo)

    const handleClick = (check) => {
        // DynamoDBへチェック内容を反映する
        if (check) {
            mutate({ content: [...new Set([...dynamoData || [], requestNo])] })
        } else {
            mutate({ content: dynamoData?.filter(a => a !== requestNo) })
        }
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            color={blue[400]}
            bgcolor="#fff"
            width={150}
            height={38}
            border={`3px solid ${blue[400]}`}
            borderRadius={9999}
            onClick={() => handleClick(!check)}
            sx={{
                cursor : "pointer",
                '&:hover' : {
                    opacity : 0.8,
                },
            }}
        >
            <Box
                component="img"
                src={check ? flagIcon : flagFrameIcon}
                width={18}
            />
            <H4>{check ? "チェック済" : "チェック"}</H4>
        </Stack>
    )
}

export default CheckButton

import { Stack, useMediaQuery } from '@mui/material'
import Information from './Information'
import TopPC from './TopPC'
import TopMobile from './TopMobile'


const Top = () => {
    const upmd = useMediaQuery((theme) => theme.breakpoints.up('md'))

    return (
        <Stack>
            <Information />
            {upmd ?
            <TopPC />
            :
            <TopMobile />
            }
        </Stack>
    )
}

export default Top

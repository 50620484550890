import { purple, red, blue, teal, lightGreen, yellow, orange } from '@mui/material/colors'
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer,
    Legend,
    Line,
} from 'recharts'
import { usePRRadarChart } from 'hooks/pranalysis'

const colorList = [
    purple[300],
    blue[300],
    teal[300],
    lightGreen[300],
    yellow[300],
    orange[300],
    red[300]
]

const PRRadarChart = ({ targetPrIndex }) => {
    const { data } = usePRRadarChart(targetPrIndex)

    if (!data) return null

    const repeatedColorList = Array.from({ length: data.pranalysis.length }, (_, i) => colorList[i % colorList.length])

    return (
        <ResponsiveContainer
            height={600}
        >
            <RadarChart
                data=
                {data.titles.map((t, i) => {
                    const result = {}
                    result.subject = `${i+1}. ${t}`
                    data.pranalysis.forEach((p, i) => result[`score${i+1}`] = p[t]?.合計点数 || 0)
                    return result
                })}
                cx="50%"
            >
                <PolarGrid />
                <PolarAngleAxis 
                    dataKey="subject" 
                />
                <PolarRadiusAxis 
                    domain={[0, data.domain]}
                />

                {[...repeatedColorList]
                    .map((c, i, a) => 
                        <Radar 
                            key={i}
                            name={`${data.pranalysis[i]?.診断回}回目`}
                            dataKey={`score${i + 1}`}
                            stroke={i !== a.length-1 ? c : red[300]}
                            fill={i !== a.length-1 ?  c :  red[300]}
                            fillOpacity={0.1}
                            legendType="rect"
                            dot={i === a.length-1}
                            strokeWidth={i !== a.length-1 ? 1 : 2} 
                            type="basisOpen"
                        />
                    )
                }
                <Line 
                    type="step" 
                    dataKey="score7" 
                    stroke="#ff0092" 
                    strokeWidth={2} 
                />
                <Legend />
            </RadarChart>
        </ResponsiveContainer>
    )
}

export default PRRadarChart

import { Box, Stack } from '@mui/material'
import { format } from 'date-fns'
import ComponentBase from './ComponentBase'
import { useDraftResearches, useNewResearches } from 'hooks/research'
import { WhiteLabel } from 'components/styledLabels'
import { H3Underline, H5 } from 'components/styledFonts'
import { UnderlineLink } from 'components/links'

const path = (d, subtitle) => {
    let ret = ''
    if (subtitle === '新着のリサーチ') {
        ret = `${d.エントリー.length < 3 ? '/entry' : ''}/${d.リクエストNo}`
    } else {
        ret = `/entry/${d.リクエストNo}/${d.エントリー[0].エントリーNo}`
    }
    return `/research${ret}`
}

const ResearchItem = ({subtitle, data}) => {
    return (
        <Stack
            gap={2}
        >
            <H3Underline>{subtitle}</H3Underline>
            <Stack
                gap={1.5}
            >
                {data?.map((d, i) => (
                <Stack
                    gap={0.5}
                    key={i}
                    alignItems="flex-start"
                >
                    <Stack
                        direction="row"
                        columnGap={1}
                    >
                        <WhiteLabel>{d.媒体名_企業公開用}</WhiteLabel>
                        <WhiteLabel>締切　{format(d.募集締切日時, 'M月d日 HH:mm')}</WhiteLabel>
                    </Stack>
                    <UnderlineLink
                        to={path(d, subtitle)}
                    >
                        <H5>{d.リクエストタイトル}</H5>
                    </UnderlineLink>
                </Stack>
                ))}
                {(!data || data.length === 0) &&
                <H5>{subtitle === '新着のリサーチ' ? '新着のリサーチはありません' : '下書き中のエントリーはありません'}</H5>
                }
            </Stack>
        </Stack>
    )
}

const Research = () => {
    const { data : newResearches } =  useNewResearches()
    const { data : draftResearches } =  useDraftResearches()

    return (
        <ComponentBase
            title="リサーチ"
            buttons={[{ label : 'リサーチ 一覧', to : '/research' }]}
        >
            <Stack
                gap={2}
                sx={{
                    flexDirection : { md : "row", xs : "column" },
                    alignItems : { md : "flex-start", xs : "center" },
                }}
            >
                <Box
                    display="grid"
                    columnGap={6}
                    rowGap={4}
                    width="100%"
                    sx={{
                        gridTemplateColumns : { sm : "1fr 1fr", xs : "1fr" },
                    }}
                >
                    <Box>
                        <ResearchItem
                            subtitle="新着のリサーチ"
                            data={newResearches}
                        />
                    </Box>
                    <Box>
                        <ResearchItem
                            subtitle="下書き中のリサーチ"
                            data={draftResearches}
                        />
                    </Box>
                </Box>
            </Stack>
        </ComponentBase>
    )
}

export default Research

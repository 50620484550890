import { Stack } from '@mui/material'
import { messages } from 'config/messages'

const NoData = ({ title }) => {
    return (
        <Stack
            pt={4}
            px={1}
        >
            {messages.NO_DATA(title)}
        </Stack>
    ) 
}

export default NoData
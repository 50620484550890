import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { MenuBarIconButton } from 'components/styledButtons'
import MenuList from './MenuList'
import { useAvailableFeatures } from 'hooks/company'

const menu = [
    { l : '企業探訪設定'        , p : '/inquiry/top' },
    { l : 'プレスリリース'      , p : '/post/list/pressrelease' },
    { l : 'ニュースレター'      , p : '/post/list/newsletter' },
    { l : 'リサーチ'            , p : '/research' },
    { l : 'フィードバック'      , p : '/post/list/feedback' },
    { l : 'プログラム申込'      , p : '/program/list' },
    { l : 'プログラム申込履歴'  , p : '/program/history', nest : true },
    { l : 'オンライン講座'      , p : '/movie/list' },
    { l : 'メディアリスト'      , p : '/medialist' },
    { l : 'PR活動診断'          , p : '/pranalysis' },
    { l : 'レポート'            , p : '/report/list' },
]

const MenuButton = () => {
    const [ menuEl, setMenuEl ] = useState(null)
    const { data } = useAvailableFeatures()

    return (<>
        <MenuBarIconButton
            onClick={e => setMenuEl(e.currentTarget)}
        >
            <MenuIcon />
        </MenuBarIconButton>
        <MenuList
            menu={menu.filter(m => (data || {})[m.l])}
            menuEl={menuEl}
            setMenuEl={setMenuEl}
        />
    </>)
}

export default MenuButton

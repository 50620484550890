import { useTheme } from '@mui/material/styles'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { H1, H2 } from 'components/styledFonts'
import Block from 'components/Block'
import PRBarChart from 'pages/pranalysis/PRBarChart'
import PRRadarChart from './PRRadarChart'
import PRQuestions from './PRQuestions'
import { usePRAnalysisHistory } from 'hooks/pranalysis'
import { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { SelectBase } from 'components/Inputs'

const PRAnalysisHistory = () => {
    const questionUseForm = useForm()
    const theme = useTheme()
    const notNarrow = useMediaQuery(theme.breakpoints.between('mobile', 'md'))
    const [targetPrIndex, setTargetPrIndex] = useState(1)
    const { data } = usePRAnalysisHistory()

    useEffect(() => {
        if (data) {
           setTargetPrIndex(data.at(-1).value)
        }
     }, [data])
     if (!data) return

    const handleGraghChange = (e) => {
        setTargetPrIndex(e.target.value)
    }

    return (
        <Stack
            gap={2}
        >
            <H1>PR活動診断</H1>
            <Block>
                <Stack
                    gap={3}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={2}
                    >
                        <H2>診断の結果</H2>
                        <SelectBase
                            sx={{
                                fontSize: 14,
                                witdh: "200px",
                                backgroundColor: "white",
                            }}
                            value={targetPrIndex}
                            onChange={(e) => handleGraghChange(e)}
                            list={data}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box
                            width="90%"
                            sx={{
                                display : { md : "block", xs : "none" },
                            }}
                        >
                            <PRRadarChart targetPrIndex={targetPrIndex} />
                        </Box>
                        <Box
                            sx={{
                                width : { md : 350, xs : "100%" },
                            }}
                        >
                            <PRBarChart narrow={!notNarrow} targetPrIndex={targetPrIndex} />
                        </Box>
                    </Stack>
                </Stack>
            </Block>
            <FormProvider {...questionUseForm}>
                <PRQuestions targetPrIndex={targetPrIndex} />
            </FormProvider>
        </Stack>
    )
}

export default PRAnalysisHistory

import { useTheme } from '@mui/material/styles'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { H1, H5, Body } from 'components/styledFonts'
import { LinkButtonLarge, LinkButtonMiddle, BlueContainedButton, BlueOutlinedButton } from 'components/styledButtons'
import Block from 'components/Block'
import PRBarChart from 'pages/pranalysis/PRBarChart'
import PRRadarChart from './PRRadarChart'
import PRQuestions from './PRQuestions'
import PageTitleAndButtons from 'components/PageTitleAndButtons'
import { Link } from 'react-router-dom'
import { usePRAnalysisAll } from 'hooks/pranalysis'
import { useNavigate } from "react-router-dom"
import { useForm, FormProvider } from 'react-hook-form'
import { usePranalysisMutaion } from 'hooks/mutation/pranalysis'
import { useLoginUser } from 'hooks/loginuser'
import format from 'date-fns/format'
import { useEffect } from 'react'

export const defaultValues = {
    下書き : false
}

const PRAnalysis = () => {
    const questionUseForm = useForm({ defaultValues : defaultValues })
    const theme = useTheme()
    const notNarrow = useMediaQuery(theme.breakpoints.between('mobile', 'md'))
    const navigate = useNavigate()
    const { mutate : mutatePranalysis } = usePranalysisMutaion()
    const { data : loginUser } = useLoginUser()
    const { data } = usePRAnalysisAll()
    
    useEffect(() => {
        if (data && Object.keys(data.master).length === 0){
            navigate("/pranalysis/history")
        }
    }, [data, navigate])

    if (!data) return null

    const handleSubmitAnswer = (answerInfo) => {
        const latestPrData = data.pranalysis.at(-1)

        if (!data.master.レコード番号) { // 一時保存レコードの更新
            mutatePranalysis({
                PR活動診断日 : format(new Date(), 'yyyy-MM-dd'),
                下書き : answerInfo.下書き,
                質問グループ: { question : latestPrData, answer : answerInfo },
                id : latestPrData.$id,
                revision : latestPrData.$revision
            })
        } else { // 新規レコード登録
            mutatePranalysis({
                PR活動診断日 : format(new Date(), 'yyyy-MM-dd'),
                企業ID : loginUser?.企業ID,
                活動診断テンプレート : data.master.レコード番号,
                診断回 : latestPrData?.診断回 ? latestPrData.診断回 + 1 : 1,
                下書き : answerInfo.下書き,
                特殊質問グループ設定 : data.master.特殊質問グループ設定,
                質問グループ: { question : data.master, answer : answerInfo }
            })
        }  
    }

    return (
        <Stack
            gap={2}
        >
            <Stack
                sx={{
                    flexDirection : { sm : "row", xs : "column" },
                    pb : { sm : 0, xs : 2 },
                    justifyContent : "space-between",
                    gap : 2,
                }}
            >
                <Stack
                    gap={1}
                >
                    <H1>PR活動診断</H1>
                    <Stack>
                        <H5>■注意事項■</H5>
                        <Body
                            sx={{ whiteSpace : "pre-wrap" }}
                        >
                            {data.master.注意事項}
                        </Body>
                    </Stack>
                </Stack>
                <Stack
                    alignItems="center"
                    flexShrink={0}
                    gap={0.5}
                >
                    <H5>ご不明点等ございましたら、こちらから</H5>
                    <LinkButtonLarge
                        component={Link}
                        to="/messages/support"
                    >
                        お問い合わせ
                    </LinkButtonLarge>
                </Stack>
            </Stack>
            <Block>
                <Stack
                    gap={3}
                >
                    <PageTitleAndButtons
                        title="前回の結果"
                        buttons={[
                            <BlueOutlinedButton
                                label="過去のご回答"
                                component={Link}
                                target="_blank"
                                to="/pranalysis/history"
                            />]}
                    />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box                            
                            width="90%"
                            sx={{
                                display : { md : "block", xs : "none" },
                            }}
                        >
                            <PRRadarChart />
                        </Box>
                        <Box
                            sx={{
                                width : { md : 350, xs : "100%" },
                            }}
                        >
                            <PRBarChart narrow={!notNarrow} />
                        </Box>
                    </Stack>
                </Stack>
            </Block>
            <FormProvider {...questionUseForm}>
                <Stack
                    gap={3}
                    component="form"
                    onSubmit={questionUseForm.handleSubmit(handleSubmitAnswer)}
                >
                    <PRQuestions />
                    <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{ columnGap : { sm : 8, xs : 4 } }}
                    >
                        <LinkButtonMiddle
                            type="submit"
                            sx={{ minWidth : 150 }}
                            onClick={() => questionUseForm.setValue('下書き', true)}
                        >
                            一時保存
                        </LinkButtonMiddle>
                        <BlueContainedButton
                            label="回答完了"
                            type="submit"
                            sx={{ minWidth : 150 }}
                            onClick={() => questionUseForm.setValue('下書き', false)}
                        />
                    </Stack>
                </Stack>
            </FormProvider>
        </Stack>
    )
}

export default PRAnalysis

import { BadgeButton } from 'components/styledButtons'
import { useNewFeedbackCount } from 'hooks/feedback'

const FeedbackButton = () => {
    const fcnt = useNewFeedbackCount()

    return (
        <BadgeButton
            count={fcnt}
            to="/post/list/feedback"
        >
            フィードバック
        </BadgeButton>
    )
}

export default FeedbackButton

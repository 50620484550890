import { getUnixTime } from 'date-fns'
import { useNavigate } from 'react-router'
import { apiName } from 'config/api'
import { useAccounts } from 'hooks/account'
import { useLoginUser } from 'hooks/loginuser'
import { files as fileNames } from 'hooks/pressrelease'
import { useS3Config } from 'hooks/variousHooks'
import { API } from 'lib/api'
import { uploadS3 } from 'lib/common'
import { contentRowItems } from 'pages/pressrelease/PressReleaseSubmissionBase'
import useMyMutation from './useMyMutation'

const payloadMaxSize = 102400

export const usePressreleaseMutation = () => {
    const { data: user } = useLoginUser()
    const { data: accounts } = useAccounts()
    const { data : s3Config } = useS3Config()
    const navi = useNavigate()

    return useMyMutation({
        mutationFn : async (param) => {
            if (new Blob([JSON.stringify(param.form)]).size > payloadMaxSize) {
                return { result: "ERR_PAYLOAD_TOO_LARGE" }
            }
            const form = param.form
            const tmpForm = { ...form }
            fileNames.filter(d => !form[d]?.blob).forEach(d => tmpForm[d] = form[d]?.url || '')

            // S3操作
            const files = fileNames
                .filter(d => form[d]?.blob)
                .reduce((o, d) => ({ ...o, [d] : form[d].blob }), {})
            if (Object.keys(files).length) {
                const prefix = `${user.企業ID}/pressrelease/${getUnixTime(new Date())}`
                // S3にアップロードしデータアクセス時のS3-URLを取得
                const s3Urls = await uploadS3(files, prefix, s3Config)
                Object.keys(s3Urls).forEach(d => tmpForm[d] = s3Urls[d])
            }

            // kintone登録用にデータを変換
            const overWriteAccount = accounts.find(c => c.企業担当者ID === form.問い合わせ担当者_上書き)
            tmpForm.リリース日時 = form.リリース日時 ? form.リリース日時.toISOString() : ""
            tmpForm.公開期限日 = form.公開期限日 ? form.公開期限日.toISOString() : ""
            tmpForm.更新日_データ連携用 = new Date().toISOString()
            tmpForm.問い合わせ担当者_上書き = overWriteAccount?.姓 + overWriteAccount?.名 || ""
            tmpForm.更新者_データ連携用 = user.姓 + user.名
            tmpForm.下書き = form.draft ? ["下書き"] : []
            tmpForm.PDF入稿 = form.PDF入稿 ? ["PDF入稿"] : []
            tmpForm.取引先ID = form.取引先ID || user.企業ID
            
            // コンテンツ数に応じて登録する行を選択
            tmpForm.コンテンツ数 = form.コンテンツ数 || 1
            const deleteContentNum = 4 - tmpForm.コンテンツ数
            const deleteRowItems = contentRowItems.flatMap(r => [...Array(deleteContentNum).keys()].map(k => r.replace(":no", 4 -k)))
            deleteRowItems.forEach(r => tmpForm[r] = "")

            // 不要なパラメタの削除
            delete tmpForm.更新日
            delete tmpForm.コンテンツ数
            delete tmpForm.アップロード方法1
            delete tmpForm.アップロード方法2
            delete tmpForm.アップロード方法3
            delete tmpForm.アップロード方法4
            delete tmpForm.draft
            delete tmpForm.更新者
            delete tmpForm.リリースNo
        
            const ret = await API.post(apiName, "/items/postPressRelease", { body : {
                requestFeedback : form.フィードバック依頼 && !form.draft && !form.フィードバックNo,
                pressreleaseNo : form.リリースNo,
                record : tmpForm
            }})

            return ret
        },
        queryKey : ['pressrelease', 'feedback'],
        onSettledFn : (ret) => {
            let to = ''
            if (/^OK/.test(ret.result)) {
                if (ret.result.includes("DRAFT")) {
                    to = `/pressrelease/submission/${ret.PDF入稿.length ? 'pdf' : 'html'}/${ret.toId}`
                } else {
                    to = '/post/list/pressrelease'
                }
            }
            if (to) navi(to)
        }
    })
}


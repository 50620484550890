import { useCallback, useEffect } from 'react'
import { Outlet, useBeforeUnload, useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { messages } from 'config/messages'
import { useNewsLetterMutation } from 'hooks/mutation/newsletter'
import { useAccounts } from 'hooks/account'
import { useNewsLetterByNo } from 'hooks/newsletter'

export const rowFiles = [
    "イメージ:no",
]

export const rowText = [
    "イメージ:noオリジナルファイル名",
    "イメージ:noキャプション",
]

export const rowAnswers = [
    "質問:no",
    "備考:no",
    "回答:no",
]

const rowAnswerBoolean = [
    "回答必須:no",
]

export const otherFiles = [
    "動画",
    "PDF",
]

export const fileNameArray = [
    'PDFオリジナルファイル名',
    'メインイメージオリジナルファイル名',
    'イメージ1オリジナルファイル名',
    'イメージ2オリジナルファイル名',
    'イメージ3オリジナルファイル名',
    'イメージ4オリジナルファイル名',
    '動画オリジナルファイル名',
]

export const maxLength = { タイトル : 100, リード文 : 400, 本文 : 20000, 回答: 1000, 検索キーワード : 200 }
export const contentRowItems = rowFiles.concat(rowText)

export const defaultValues = {
    $revision : "",
    ネタNo : "",
    コンテンツ数 : 1,
    ネタたねタイトル : "",
    ネタテーマNo : "",
    ネタテーマタイトル : "",
    本文 : "",
    リード文 : "",
    ジャンル : [],
    カテゴリ : "",
    問い合わせ担当者_上書き : "",
    問い合わせ担当者部署_上書き  : "",
    公開日 : "",
    フィードバックNo : "",
    フィードバック依頼 : false,
    リリース日時 : "",
    更新日 : "",
    更新者 : "",
    検索キーワード : "",
    メインイメージ : null,
    メインイメージオリジナルファイル名 : "",
    メインイメージキャプション : "",
    動画 : null,
    動画URL : "",
    動画オリジナルファイル名 : "",
    PDF : null,
    PDFオリジナルファイル名 : "",
    下書き : true,
    アップロード方法 : "動画をアップロード",
    ...rowFiles.flatMap(r => [...Array(4).keys()].map(k => r.replace(":no", k + 1))).reduce((a, c) => ({ ...a, [c] : null}), {}),
    ...rowText.flatMap(r => [...Array(4).keys()].map(k => r.replace(":no", k + 1))).reduce((a, c) => ({ ...a, [c] : ''}), {}),
    ...rowAnswers.flatMap(r => [...Array(10).keys()].map(k => r.replace(":no", k + 1))).reduce((a, c) => ({ ...a, [c] : ''}), {}),
    ...rowAnswerBoolean.flatMap(r => [...Array(10).keys()].map(k => r.replace(":no", k + 1))).reduce((a, c) => ({ ...a, [c] : false}), {}),
}


const schema = yup.lazy(values => {
    const answerYup = [...Array(10).keys()].reduce((a, c) => ({ ...a, [`回答${c + 1}`] : yup
        .string()
        .when(`回答必須${c + 1}`,{
            is: true,
            then: (schema) => schema.required(messages.REQUIRED),
        })
    }), {})
    const commonYupShape = {
        ネタたねタイトル : yup
            .string()
            .required(messages.REQUIRED)
        ,
        メインイメージ : yup
            .object()
            .nullable()
            .test('メインイメージ', messages.SELECTION_REQUIRED, (value) => value?.name)
        ,
        ジャンル : yup
            .array()
            .min(1, messages.SELECTION_REQUIRED)
        ,
        カテゴリ : yup
            .string()
            .required(messages.SELECTION_REQUIRED)
        ,
        ...answerYup
    }
    // PDF入稿の場合のyup
    if (values.入稿方法 === "PDF入稿" || values.入稿方法 === "PDF入稿（テンプレートから遷移）") return yup.object().shape({
        ...commonYupShape,
        PDF : yup
            .object()
            .nullable()
            .test('PDF', messages.SELECTION_REQUIRED, (value) => value?.name)
        ,
        検索キーワード : yup
            .string()
            .required(messages.REQUIRED)
        ,
    })
    // html入稿の場合のyup
    else if (values.入稿方法 === "HTML入稿") return yup.object().shape({
        ...commonYupShape,
        リード文 : yup
            .string()
            .required(messages.REQUIRED)
        ,
        本文 : yup
            .string()
            .required(messages.REQUIRED)
            .max(maxLength.本文, messages.WORD_COUNT_OVER(maxLength.本文))
        ,
    }) 
    else if (values.入稿方法 === "テキスト入稿（テンプレートから遷移）") return yup.object().shape({
        ...commonYupShape,
        リード文 : yup
            .string()
            .required(messages.REQUIRED)
        ,
    })
})

const NewsLetterSubmissionBase = () => {
    const methods = useForm({ defaultValues : defaultValues, resolver : yupResolver(schema) })
    const { mutate } = useNewsLetterMutation()
    const { no } = useParams()
    const { data } = useNewsLetterByNo(no)
    const { data : accountData } = useAccounts()

    useBeforeUnload((e) => {
        e.preventDefault()
    })

    // 編集画面の際の反映処理
    useEffect(() => {
        if (!data || !accountData) return
        if (methods.getValues("ネタNo")) return
        Object.keys(defaultValues).forEach(n => methods.setValue(n, data[n]))
        const inquiryUserId = accountData.find(d => d.姓 + d.名 === data.問い合わせ担当者_上書き) 
        methods.setValue("問い合わせ担当者_上書き", inquiryUserId?.企業担当者ID || "")
    }, [data, accountData, methods])

    methods.onSubmit = useCallback(
        async (data) => {
            methods.setValue('draft', false)
            mutate({
                form : data,
            })
        },
        [mutate, methods]
    )

    methods.saveDraft = useCallback(
        async () => {
            methods.setValue('draft', true)
            mutate({
                form : methods.getValues(),
            })
        },
        [mutate, methods]
    )
    
    return (
        <FormProvider {...methods}>
            <Outlet />
        </FormProvider>
    )
}

export default NewsLetterSubmissionBase

import { Stack, Box } from '@mui/material'
import { Accordion, AccordionDetail } from 'components/Accordion'
import { Body } from 'components/styledFonts'
import { TitledBlock } from 'components/Block'
import { useDynamoVideoHistory } from 'hooks/dynamo'
import { intervalToDuration, format } from 'date-fns'

const ViewingTime = ({ time }) => {
    const { hours, minutes, seconds } = intervalToDuration({ start: 0, end: time * 1000 })
    return (
        <Body>
            {`${hours ? `${hours}時間` : ''}${minutes ? `${minutes}分` : ''}${seconds}秒`}
        </Body>
    )
}

const MovieHistory = () => {

    const { data } = useDynamoVideoHistory()

    if (!data || data.length === 0) return null

    return (
        <TitledBlock
            title="視聴履歴"
        >
            <Box
                sx={{
                    px: { mobile: 4, xs: 0 },
                }}
            >
                <Accordion
                    data={data}
                    keyName="動画ストリーミング管理名"
                    summaryName="タイトル"
                    detail={d => (
                        <Stack
                            gap={3}
                        >
                            {d.担当者リスト.map((u, i) => (
                            <Stack
                                key={i}
                                gap={1}
                            >
                                <AccordionDetail
                                    label="企業担当者"
                                    value={
                                        <Body>{u.企業担当者名}</Body>
                                    }
                                />
                                <AccordionDetail
                                    label="視聴日時"
                                    value={
                                        <Body>{format(new Date(u.視聴日時), 'yyyy/MM/dd HH:mm')}</Body>
                                    }
                                />
                                <AccordionDetail
                                    label="視聴時間"
                                    value={
                                        <ViewingTime time={u.視聴時間} />
                                    }
                                />
                            </Stack>
                            ))}
                        </Stack>
                    )}
                />
            </Box>
        </TitledBlock>
    )
}

export default MovieHistory

import { useCallback } from 'react'
import { Outlet, useBeforeUnload } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { messages } from 'config/messages'
import { usePressreleaseMutation } from 'hooks/mutation/pressrelease'

export const rowFiles = [
    "メイン画像:no",
    "画像:no",
    "動画:no",
]

export const rowText = [
    "メイン画像:noオリジナルファイル名",
    "メイン画像:noキャプション",
    "画像:noオリジナルファイル名",
    "画像:noキャプション",
    "動画:noオリジナルファイル名",
    "動画:noURL",
    "本文:no"
]

export const otherFiles = [
    "添付資料:no",
    "添付資料:noオリジナルファイル名",
]

export const fileNameArray = [
    "メイン画像1オリジナルファイル名",
    "画像1オリジナルファイル名",
    "メイン画像2オリジナルファイル名",
    "画像2オリジナルファイル名",
    "メイン画像3オリジナルファイル名",
    "画像3オリジナルファイル名",
    "メイン画像4オリジナルファイル名",
    "画像4オリジナルファイル名",
    "添付資料1オリジナルファイル名",
    "添付資料2オリジナルファイル名",
    "添付資料3オリジナルファイル名",
    "添付資料4オリジナルファイル名",
    "添付資料5オリジナルファイル名",
    "添付資料6オリジナルファイル名",
    "添付資料7オリジナルファイル名",
    "添付資料8オリジナルファイル名",
    "添付資料9オリジナルファイル名",
    "添付資料10オリジナルファイル名",
    "PDFオリジナルファイル名"
]

export const maxLength = { タイトル : 100, リード文 : 400, 本文 : 20000, 検索キーワード : 200 }
export const contentRowItems = rowFiles.concat(rowText)

export const defaultValues = {
    $revision : "",
    リリースNo : "",
    コンテンツ数 : 1,
    下書き : true,
    タイトル : "",
    リード文 : "",
    PDF : null,
    PDFオリジナルファイル名 : "",
    ジャンル : [],
    カテゴリ : "",
    問い合わせ担当者_上書き : "",
    問い合わせ担当者部署_上書き  : "",
    公開期限日 : "",
    フィードバックNo : "",
    フィードバック依頼 : false,
    リリース日時 : "",
    更新日 : "",
    更新者 : "",
    アップロード方法1 : "画像をアップロード",
    アップロード方法2 : "画像をアップロード",
    アップロード方法3 : "画像をアップロード",
    アップロード方法4 : "画像をアップロード",
    検索キーワード : "",
    ...rowFiles.flatMap(r => [...Array(4).keys()].map(k => r.replace(":no", k + 1))).reduce((a, c) => ({ ...a, [c] : null}), {}),
    ...rowText.flatMap(r => [...Array(4).keys()].map(k => r.replace(":no", k + 1))).reduce((a, c) => ({ ...a, [c] : ''}), {}),
    ...otherFiles.flatMap(r => [...Array(10).keys()].map(k => r.replace(":no", k + 1))).reduce((a, c) => ({ ...a, [c] : null}), {}),
}


const schema = yup.lazy(values => {
    const commonYupShape = {
        タイトル : yup
            .string()
            .required(messages.REQUIRED)
        ,
        メイン画像1 : yup
            .object().shape({
                name : yup
                    .string()
                    .required(messages.SELECTION_REQUIRED)
            })
            .required(messages.SELECTION_REQUIRED)
        ,
        ジャンル : yup
            .array()
            .min(1, messages.SELECTION_REQUIRED)
        ,
        カテゴリ : yup
            .string()
            .required(messages.SELECTION_REQUIRED)
        ,
        リリース日時 : yup
            .date()
            .typeError(messages.SELECTION_REQUIRED)
            .required(messages.SELECTION_REQUIRED)
        ,
    }
    if (values.カテゴリ === "記者発表会" || values.カテゴリ === "読者プレゼント") 
        commonYupShape.公開期限日 = yup
            .date()
            .typeError(messages.SELECTION_REQUIRED)
            .required(messages.SELECTION_REQUIRED)
    // PDF入稿の場合のyup
    if (values.PDF入稿) return yup.object().shape({
        ...commonYupShape,
        PDF : yup
            .object().shape({
                name : yup
                    .string()
                    .required(messages.SELECTION_REQUIRED)
            })
            .required(messages.SELECTION_REQUIRED)
        ,
        検索キーワード : yup
            .string()
            .required(messages.REQUIRED)
        ,
    })
    // html入稿の場合のyup
    else return yup.object().shape({
        ...commonYupShape,
        リード文 : yup
            .string()
            .required(messages.REQUIRED)
        ,
        本文1 : yup
            .string()
            .max(maxLength.本文, messages.WORD_COUNT_OVER(maxLength.本文))
            .required(messages.REQUIRED)
        ,
        本文2 : yup
            .string()
            .max(maxLength.本文, messages.WORD_COUNT_OVER(maxLength.本文))
        ,
        本文3 : yup
            .string()
            .max(maxLength.本文, messages.WORD_COUNT_OVER(maxLength.本文))
        ,
        本文4 : yup
            .string()
            .max(maxLength.本文, messages.WORD_COUNT_OVER(maxLength.本文))
        ,
    })
})

const PressReleaseSubmissionBase = () => {
    const methods = useForm({ defaultValues : defaultValues, resolver : yupResolver(schema) })
    const { mutate } = usePressreleaseMutation()

    useBeforeUnload((e) => {
        e.preventDefault()
    })

    methods.onSubmit = useCallback(
        async (data) => {
            methods.setValue('draft', false)
            mutate({
                form : data,
            })
        },
        [mutate, methods]
    )

    methods.saveDraft = useCallback(
        async () => {
            methods.setValue('draft', true)
            mutate({
                form : methods.getValues(),
            })
        },
        [mutate, methods]
    )
    
    return (
        <FormProvider {...methods}>
            <Outlet />
        </FormProvider>
    )
}

export default PressReleaseSubmissionBase

import React from 'react'
import { Link, Box, Stack } from '@mui/material'
import { TitledBlock } from 'components/Block'
import { useAtom } from 'jotai'
import { reactPlayerModalAtom } from 'store/commonAtoms'
import MovieThumbnail from './MovieThumbnail'
import { useStreamingTop } from 'hooks/streaming'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Body } from 'components/styledFonts'
import { format } from 'date-fns'


const PickupMovies = () => {
    const [, setVideo] = useAtom(reactPlayerModalAtom)
    const { data } = useStreamingTop()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

    if (!data || data.length === 0) return null

    return (
        <TitledBlock
            title="経営PR戦略講座"
        >
            <Stack
                alignItems="center"
                width="100%"
                sx={{
                    px: { mobile: 2, xs: 0.5 }
                }}
            >
                <Stack
                    direction="row"
                    flexWrap="wrap"
                    sx={{
                        columnGap: { mobile: 8, xs: 2 },
                        justifyContent: { mobile: "flex-start", xs: "space-between" },
                        rowGap: 2
                    }}
                >
                    {data?.map((d, i) => (
                    <Stack
                        key={i}
                        gap={0.5}
                        sx={{
                            width: { mobile: 330, xs: 150 },
                        }}
                    >
                        <Box
                            sx={{
                                height: { mobile: 185, xs: 85 },
                            }}
                        >
                            <MovieThumbnail
                                data={d}
                                width="100%"
                                height="100%"
                                click={() => setVideo(d)}
                            />
                        </Box>
                        <Body>
                            {isMobile ? '' : '公開期限 : '}{format(d.表示終了日時, 'yyyy/MM/dd HH:mm')}
                        </Body>
                        <Link
                            key={i}
                            href="#"
                            onClick={() => setVideo(d)}
                            underline="hover"
                        >
                            {d.タイトル}
                        </Link>
                    </Stack>
                    ))}
                </Stack>
            </Stack>
        </TitledBlock >
    )
}

export default PickupMovies

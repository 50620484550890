import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api'
import { apiName } from 'config/api'


const getData = async () => {
    const ret = await API.get(apiName, '/items/getClientUser', {})
    console.log(ret)
    return ret
}

export const useLoginUser = () => {
    return useQuery(['loginuser'], getData)
}




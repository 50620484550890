import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAvailableFeatures } from './company'


// 認可制御対象機能リスト
const list = [
    { label: '企業探訪設定',   part: '^/inquiry' },
    { label: 'ニュースレター', part: '/newsletter(?:/|$)' },
    { label: 'リサーチ',       part: '^/research' },
    { label: 'フィードバック', part: '/feedback(?:/|$)' },
    { label: 'プログラム申込', part: '^/program' },
    { label: 'オンライン講座', part: '^/movie' },
    { label: 'メディアリスト', part: '^/medialist' },
    { label: 'PR活動診断',     part: '^/pranalysis' },
]

const reg = new RegExp(`${list.map(l => `(${l.part})`).join('|')}`, "g")

export const useAuthorization = () => {
    const { pathname } = useLocation()
    const { data: features } = useAvailableFeatures()

    useEffect(() => {
        // 対象ページか判定
        const ret = [...pathname.matchAll(reg)] || []
        if (!ret.length || !features) return

        // 対応する機能がOFFの場合認可エラーのステータスを投げる
        for (const [, ...match] of ret) {
            if (!features[list[match.findIndex(m => m)].label]) throw new Response("forbidden", { status: 403 })
        }
    }, [pathname, features])
}

import { Stack } from '@mui/material'
import { H1, H2 } from 'components/styledFonts'
import Block from 'components/Block'
import CompanyBase from './CompanyBase'
import SNS from './SNS'
import PublicRelations from './PublicRelations'
import CompanyLogo from './CompanyLogo'

const Company = () => {

    return (
        <Stack
            gap={2}
        >
            <H1>企業情報設定</H1>
            <Block>
                <Stack
                    gap={2}
                >
                    <H2>企業情報/広報担当者/ロゴマーク</H2>
                    <CompanyBase />
                    <SNS />
                    <PublicRelations />
                    <CompanyLogo />
                </Stack>
            </Block>
        </Stack>
    )
}

export default Company

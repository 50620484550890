import useMyMutation from './useMyMutation'
import { API } from 'lib/api'
import { apiName } from 'config/api'

export const useMediaMutaion = () => {
    return useMyMutation({
        mutationFn : async (param) => {
            const apiAddress = param.groupData ? '/items/changeGroups' : '/items/postMedialist'
            const ret = await API.post(apiName, apiAddress, { body : param })
            return ret
        },
        queryKey : ['media'],
        to: '/medialist'
    })
}
import { Stack } from '@mui/material'
import { H2, H4, H5, Body } from 'components/styledFonts'
import Block from 'components/Block'
import { RadioGroup } from 'components/Inputs'
import { useFormContext } from 'react-hook-form'
import { usePRQuestions } from 'hooks/pranalysis'
import { useEffect, useState } from 'react'
import TwoColumns from 'components/TwoColumns'

const PRQuestions = ({ targetPrIndex }) => {
    const { control, setValue, getValues } = useFormContext()
    const [answerScore, setAnswerScore] = useState({})
    const { data } = usePRQuestions(targetPrIndex)

    useEffect(() => {
        if (!data) return

        setAnswerScore(data.scores)
        Object.keys(data.answer).forEach((c) => {
        setValue(c, data.answer[c])
        })
    }, [targetPrIndex, data, setValue])

    // 重複回答禁止 handler
    const checkSpecialQuestion = ({ title, queKey, selectedAnswer }) => {
        setValue(queKey, selectedAnswer)
        if (selectedAnswer === 'いいえ' ||
            data.questionData[title].質問リスト.find((d) => d.回答キー === queKey).特殊採点 === '') return
        // 重複回答禁止の質問で「はい」が選択された場合、他の回答は「いいえ」に変更する
        data.questionData[title].質問リスト
            .filter(q => q.特殊採点 === '重複回答禁止' && q.回答キー !== queKey)
            .forEach(q => setValue(q.回答キー, 'いいえ'))
    }

    // 計算 handler
    const calcTotalScore = (title) =>{
        const targetKeyList = Object.keys(getValues())
            .filter((c) => c.startsWith(title))
            .filter((t) => getValues()[t] === 'はい')
        const score = targetKeyList.reduce((a, b) => {
            const queData = data.questionData[title].質問リスト.find((n) => n.回答キー === b)
            return a + queData.配点
        }, 0)
        setAnswerScore((prefScore) => ({ ...prefScore, [title] : score }))
    }

    // 回答 handler
    const handleAnswer = (e) => {
        const queKey = e.target.name
        const title = queKey.split('_')[0]
        const selectedAnswer = e.target.value
        checkSpecialQuestion({ title, queKey, selectedAnswer })
        calcTotalScore(title)
    }

    const Question = ({ quList }) => {
        const targetCount = 5
        const firstSideCount = Math.max(targetCount, Math.ceil(quList.length / 2))
        const Item = ({ i, k }) => (
            <Stack
                gap={1}
                onChange={handleAnswer}
            >
                <Body
                    sx={{
                        overflowWrap : "break-word",   
                    }}
                >
                    {`${i + 1}.`}&nbsp;
                    {k.質問内容}
                </Body>
                <RadioGroup
                    name={k.回答キー}
                    row
                    sx={{ pl : 2 }}
                    control={control}
                    list={['はい', 'いいえ']}
                    disabled={data.isHistory}
                ></RadioGroup>
            </Stack>
        )
        return (
            <TwoColumns
                breakpoint="sm"
                gap={3}
            >
                <Stack
                    gap={3}
                    sx={{ pr : { sm : 2, xs : 0 } }}
                >
                    {quList.slice(0, firstSideCount).map((k, i) => (
                    <Item key={i} i={i} k={k} />
                    ))}
                </Stack>
                <Stack
                    gap={3}
                    sx={{ pl : { sm : 2, xs : 0 } }}
                >
                    {quList.slice(firstSideCount, quList.length).map((k, i) => (
                    <Item key={i} i={firstSideCount +  i} k={k} />
                    ))}
                </Stack>
            </TwoColumns>
        )        
    }

    return (
        <Stack
            gap={2}
        >
            {data.titles?.map((c, ci) => (
            <Block
                key={ci}
                sx={{ p : 1 }}
            >
                <Stack 
                    gap={2} 
                    px={2} 
                    py={1.25}
                >
                    <H2>{`${c.num}. ${c.title}`}</H2>
                    <Stack
                        sx={{ px : { sm : 2.5, xs : 0 } }}
                        gap={2}
                    >
                        <Question quList={data.questionData[c.title]?.質問リスト} />
                        {data.sQuestion[c.title] &&
                        <H5>{`※質問${data.sQuestion[c.title]}は、1つだけ「はい」が選択可能です`}</H5>
                        }
                        <H4
                            sx={{ textAlign : "right" }}
                        >
                            {`合計：${answerScore[c.title]}点`}
                        </H4>
                    </Stack>
                </Stack>
            </Block>
            ))}
        </Stack>
    )              
}

export default PRQuestions

import { Stack } from "@mui/material"
import Block from "components/Block"
import { H1 } from "components/styledFonts"
import { useSupportMessages } from "hooks/message"
import History from "./History"
import Entry from "./Entry"

const SupportForMobile = () => {
    const { data } = useSupportMessages()

    return (
        <Stack gap={2}>
            <H1>お問い合わせ</H1>
            <Block>
                <History messages={data} />
                <Entry entryMessage={data} />
            </Block>
        </Stack>
    )
}

export default SupportForMobile


import { useQuery } from '@tanstack/react-query'
import { apiName } from 'config/api'
import { API } from 'lib/api'
import { files } from 'pages/inquiry/PreviewEditor'

const getData = async () => {
    const inquiry = (await API.get(apiName, "/items/getInquiry", {}))
        .map(r => ({
            ...r,
            投稿日時 : r.投稿日時 ? new Date(r.投稿日時) : "",
            公開日 : r.公開日 ? new Date(r.公開日) : "",
            更新日 : r.更新日_データ連携用 ? new Date(r.更新日_データ連携用) : "",
            更新者 : r.更新者_データ連携用,
            下書き : r.下書き || (!r.下書き && new Date(r.公開日) > new Date()),     // 公開日前のものはすべて下書きとする仕様
            ...files.reduce((a, f) => ({ ...a, [f]: { url: r[f], name: r[`${f}オリジナルファイル名`] } }), {}),
            サムネイル: r.メインイメージ,
            type : "企業探訪",
            to : `/inquiry/${r.カテゴリ === "社長ストーリー" ? "story" : r.カテゴリ === "こんな会社" ? "intoroduction" : "recommendation"}`,
        }))
        .reduce((o, i) => ({ ...o, [i.カテゴリ]: i }), {})
    return inquiry
}

export const useInquiries = () => {
    return useQuery(['inquiry'], getData)
}

export const useInquiriesList = () => {
    return useQuery(['inquiry'], getData, {
        select: (data) => Object.keys(data).map(d => data[d])
    })
}

export const useInquiriesListOnlyFeedback = () => {
    return useQuery(['inquiry'], getData, {
        select: (data) => Object.keys(data).filter(d => data[d].フィードバックNo).map(d => data[d])
    })
}

export const useStory = () => {
    return useQuery(['inquiry'], getData, {
        select: (data) => data.社長ストーリー,
    })
}
export const useIntroduction = () => {
    return useQuery(['inquiry'], getData, {
        select: (data) => data.こんな会社,
    })
}
export const useRecommendation = () => {
    return useQuery(['inquiry'], getData, {
        select: (data) => data['イチオシ！'],
    })
}

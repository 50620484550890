import { Link, useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { red } from '@mui/material/colors'
import { H1, H4 } from 'components/styledFonts'
import { BlueOutlinedButton, LinkButtonLarge } from 'components/styledButtons'
import Block from 'components/Block'
import TwoColumns from 'components/TwoColumns'
import AttachedFiles from 'pages/post/AttachedFiles'
import NewsLetterDetail from 'pages/newsletter/NewsLetterDetail'
import { format } from 'date-fns'
import Company from 'pages/post/Company'
import { FbPreviousNext, NewsPreviousNext } from 'pages/post/PreviousNext'
import { useNewsLetterByNo } from 'hooks/newsletter'
import { fileNameArray } from './NewsLetterSubmissionBase'
import FeedbackResult from 'components/FeedbackResult'

const NewsLetterDetailBase = ({ feedback }) => {
    const { no } = useParams()
    const { data } = useNewsLetterByNo(no)

    if (!data) return

    return (
        <Stack
            gap={2}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                pb={2}
            >
                <H1>情報発信 詳細</H1>
                <BlueOutlinedButton
                    label="メディアリスト"
                    component={Link}
                    to="/medialist"
                    sx={{
                        width: { sm: 280, xs: 200 },
                        height: 44,
                    }}
                />
            </Stack>
            <TwoColumns
                ratio="8fr 4fr"
            >
                <Stack gap={2}>
                    <NewsLetterDetail data={data} />
                    {feedback ?
                        <FbPreviousNext />
                        :
                        <NewsPreviousNext />
                    }
                    <Stack
                        gap={2}
                        pt={2}
                        pb={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <LinkButtonLarge
                            sx={{
                                width: 240,
                                height: 44
                            }}
                            component={Link}
                            to="/post/list/newsletter"
                        >
                            ニュースレター一覧
                        </LinkButtonLarge>
                    </Stack>
                </Stack>
                <Stack
                    alignItems="center"
                    gap={2}
                    sx={{ alignSelf: "start" }}
                >

                    <Block>
                        <H4
                            sx={{
                                pt: 0.5,
                                color: red[400],
                            }}
                        >
                            {`${data?.公開日 ? format(data.公開日, "yyyy/MM/dd") : ""} に公開済み。編集はできません。`}
                        </H4>
                    </Block>
                    {feedback &&
                        <FeedbackResult fbno={data?.フィードバックNo} />
                    }
                    {!feedback && (<>
                        <Company
                            overWrite={{
                                問い合わせ担当者_上書き: data?.問い合わせ担当者_上書き,
                                問い合わせ担当者部署_上書き: data?.問い合わせ担当者部署_上書き,
                            }}
                        />
                        <AttachedFiles files={fileNameArray.map(c => data?.[c]).filter(c => c)} />
                    </>)}
                </Stack>
            </TwoColumns>
        </Stack>
    )
}

export default NewsLetterDetailBase

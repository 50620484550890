import { Checkbox } from '@mui/material'
import { Box } from '@mui/material'
import { blue, grey } from '@mui/material/colors'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import StarIcon from '@mui/icons-material/Star'
import flagIcon from 'img/icon_flag.png'
import flagFrameIcon from 'img/icon_flag_frame.png'

const FlagIcon = () => <Box component="img" src={flagIcon} width={20} height={24} />
const FlagFrameIcon = () => <Box component="img" src={flagFrameIcon} width={20} height={24} />

export const FlagCheck = (props) => {
    return (
        <Checkbox icon={<FlagFrameIcon />} checkedIcon={<FlagIcon />} {...props} />
    )
}

export const CircleCheck = (props) => {
    return (
        <Checkbox
            icon={<RadioButtonUncheckedIcon sx={{ fontSize : 28, color : grey[400] }}/>}
            checkedIcon={<CheckCircleOutlineIcon sx={{ fontSize : 28 }} />}
            {...props}
        />
    )
}

export const StarCheck = (props) => {
    return (
        <Checkbox
            icon={<StarIcon sx={{ fontSize : 28, color : blue[100] }} />}
            checkedIcon={<StarIcon sx={{ fontSize : 28, color : blue[700] }} />}
            {...props}
        />
    )
}




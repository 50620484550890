import { Link, useRouteError } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { lightBlue } from '@mui/material/colors'
import { Body, H3 } from 'components/styledFonts'
import { messages } from 'config/messages'

const ErrorBoundary = () => {
    const error = useRouteError()
    console.log(error)

    return (
        <Box>
            <Stack
                alignItems="flex-start"
                gap={4}
                py={4}
                sx={{
                    bgcolor : "grey.100",
                    minHeight : "100vh",
                    px : { sm : 8, xs : 4 }
                }}
            >
                <H3>
                    {messages[error?.status] || messages.ERR_DEFAULT}
                </H3>
                <Body
                    component={Link}
                    sx={{
                        fontSize : 16,
                        color : lightBlue[700],
                        '& a' : {
                            color : 'inherit',
                        },
                    }}
                    to="/"
                >
                    トップページ画面へ戻る
                </Body>
            </Stack>
        </Box>
    )
}

export default ErrorBoundary


import { useNavigate } from 'react-router'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { messageAtom, spinnerAtom } from 'store/commonAtoms'
import { messages } from 'config/messages'


const useMyMutation = ({ mutationFn, onSettledFn, queryKey, to }) => {
    const [, setSpinner] = useAtom(spinnerAtom)
    const [, setMessage] = useAtom(messageAtom)
    const queryClient = useQueryClient()
    const navi = useNavigate()

    const msg = {}

    return useMutation({
        mutationFn : mutationFn,
        onMutate : () => {
            setSpinner(true)
        },
        onSuccess : (ret) => {
            console.log(`success ${JSON.stringify(ret, null, 4)}`)
            queryKey.forEach(q => queryClient.invalidateQueries({ queryKey : [q] }))

            // エラーハンドリング
            if (ret.hasOwnProperty('error')) {
                msg.type = 'error'
                if (ret.error.name === 'KintoneRestAPIError') { 
                    msg.text = messages[ret.error.code] || messages.ERR_REGISTRATION_FAILED
                } else {
                    msg.text = messages.ERR_REGISTRATION_FAILED
                }
            } else {
                msg.type = /^ERR/.test(ret.result) ? 'error' : 'info'
                msg.text = messages[ret.result]
            }
        },
        onError : (error, data, context) => {
            msg.type = 'error'
            msg.text = messages.ERR_REGISTRATION_FAILED
            console.log(`error ${error} ${data} ${context}`)
        },
        onSettled : (ret) => {
            setSpinner(false) 
            if (Object.keys(msg).length) { 
                setMessage(msg)
            }
            if (onSettledFn) {
                onSettledFn(ret)
            } else if (!ret.hasOwnProperty('error') && to) {
                navi(to)
            }
        },
        retry : 2,
    })
}

export default useMyMutation

import { useEffect, useState } from 'react'
import Thumbnail from 'components/Thumbnail'
import axios from 'axios'

const MovieThumbnail = ({ data, width = 120, height = 80, click }) => {
    const [url, setUrl] = useState(null)

    useEffect(() => {
        (async () => {
            try {
                const ret = await axios({
                    url: 'https://vimeo.com/api/oembed.json',
                    params: {
                        url: data.vimeo_URL,
                    }
                })

                setUrl(ret.data.thumbnail_url)

            } catch (error) {
            }
        })()
    }, [data])

    if (!data || data.length === 0) return null

    return (
        <Thumbnail
            src={url}
            width={width}
            height={height}
            newly={data.newly}
            click={click}
        />
    )
}

export default MovieThumbnail

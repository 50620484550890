import { useCallback, useState } from 'react'
import { Stack, Modal, IconButton, Fade } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAtom } from 'jotai'
import { reactPlayerModalAtom } from 'store/commonAtoms'
import { H2 } from 'components/styledFonts'
import ResponsivePlayer from 'components/ResponsivePlayer'
import { useDynamoMutation } from 'hooks/mutation/useDynamoMutation'
import { useDynamoItem } from 'hooks/dynamo'
import { formatISO } from 'date-fns'
import { useLoginUser } from 'hooks/loginuser'


const VideoModal = () => {
    const [video, setVideo] = useAtom(reactPlayerModalAtom)
    const [viewingTime, setViewingTime] = useState(0)
    const { mutate } = useDynamoMutation("動画視聴履歴", 'dynamo', 'videoHistory')
    const { data: history=[] } = useDynamoItem("動画視聴履歴")
    const { data: loginUserData } = useLoginUser()

    const handleProgress = useCallback(e => {
        setViewingTime(prev => prev + 1)
    }, [])

    const updateVideoHistory = useCallback(() => {
        const updatedData = history.concat([{
            視聴日時: formatISO(new Date()),
            企業担当者ID: loginUserData.企業担当者ID,
            視聴時間: viewingTime,
            タイトル: video.タイトル,
            企業担当者名: loginUserData.姓 + loginUserData.名,
            ライブ配信開始日時: formatISO(video.ライブ配信開始日時),
            動画ストリーミング管理名: video.動画ストリーミング管理名
        }])

        mutate({ content: updatedData })
        setViewingTime(0)

    }, [history, mutate, video, viewingTime, loginUserData])

    const handelClose = useCallback(() => {
        if (viewingTime > 1) updateVideoHistory()
        setVideo(false)
    }, [updateVideoHistory, setVideo, viewingTime])

    const handlePause = useCallback(() => {
        updateVideoHistory()
    }, [updateVideoHistory])

    return (
        <Modal
            open={!!video}
            onClose={handelClose}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Fade in={!!video} timeout={1000}>
                <Stack
                    width="90%"
                    maxWidth={1200}
                    bgcolor="#fff"
                    px={2}
                    py={3}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                    >
                        <H2>{video.タイトル}</H2>
                        <IconButton
                            sx={{ mt: -3 }}
                            onClick={handelClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <ResponsivePlayer
                        data={video.vimeo_URL}
                        onProgress={handleProgress}
                        onPause={handlePause}
                    />
                </Stack>
            </Fade>
        </Modal>
    )
}

export default VideoModal

import { Stack } from '@mui/material'
import { useAvailableFeatures, useCompanyInfo } from 'hooks/company'
import { useContacts } from 'hooks/contact'
import { useProgramsForTop } from 'hooks/program'
import Contact from './Contact'
import Feedback from './Feedback'
import MediaSeminar from './MediaSeminar'
import Post from './Post'
import PRAnalysis from './PRAnalysis'
import Program from './Program'
import Research from './Research'


const TopMobile = () => {
    const { data : company } = useCompanyInfo()
    const { data : features } = useAvailableFeatures()
    const { data : program } = useProgramsForTop()
    const contacts = useContacts()

    return (
        <Stack
            gap={1.5}
        >
            {features?.PR活動診断 &&
            <PRAnalysis />
            }
            {features?.プログラム申込 && !!Object.keys(program || {}).length &&
            <Program />
            }
            {contacts &&
            <Contact />
            }
            {company?.セミナー機能 &&
            <MediaSeminar />
            }
            <Post />
            {features?.フィードバック &&
            <Feedback />
            }
            {features?.リサーチ &&
            <Research />
            }
        </Stack>
    )
}

export default TopMobile

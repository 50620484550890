import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { LabeledTextField } from 'components/Inputs'
import { messages } from 'config/messages'
import { useCompanyMutaion } from 'hooks/mutation/company'
import { useCompanyInfo } from 'hooks/company'
import { useLoginUser } from 'hooks/loginuser'
import Settings from './Settings'

const defaultValues = {
    Instagram: '',
    X: '',
    Facebook: '',
    TikTok: '',
    マイページ企業情報ID: '',
    会社名: '',
    $revision: '',
}

const schema = yup.object().shape({
    Instagram: yup
        .string()
        .url(messages.CORRECT_URL),
    X: yup
        .string()
        .url(messages.CORRECT_URL),
    Facebook: yup
        .string()
        .url(messages.CORRECT_URL),
    TikTok: yup
        .string()
        .url(messages.CORRECT_URL),
})

const SNS = () => {
    const { control, setValue, handleSubmit } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) })
    const { data } = useCompanyInfo()
    const { mutate: mutateCompany } = useCompanyMutaion()
    const [edit, setEdit] = useState(false)
    const { data: user } = useLoginUser()

    useEffect(() => {
        if (!data) return

        Object.keys(defaultValues).forEach(n => setValue(n, data[n]))

        if (!data.会社名) {
            setValue('会社名', data.企業名)
        }
    }, [data, setValue])

    const onSubmit = async (data) => {
        data.登録区分 = "SNS"
        mutateCompany(data)
        setEdit(false)
    }

    if (!data) return null

    return (
        <Settings
            category="SNS"
            currents={[
                { label: 'Instagram', value: data.Instagram, hidden: true },
                { label: 'X', value: data.X, hidden: true },
                { label: 'Facebook', value: data.Facebook, hidden: true },
                { label: 'TikTok', value: data.TikTok, hidden: true },
            ]}
            editor={
                <Stack
                    columnGap={4}
                    rowGap={2}
                    pb={1}
                    maxWidth={500}
                >
                    <LabeledTextField name="Instagram" control={control} />
                    <LabeledTextField name="X" control={control} />
                    <LabeledTextField name="Facebook" control={control} />
                    <LabeledTextField name="TikTok" control={control} />
                </Stack>
            }
            onSubmit={handleSubmit(onSubmit)}
            edit={edit}
            setEdit={setEdit}
            uneditable={!user?.isAdmin}
        />
    )
}

export default SNS

import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { Box, Stack } from '@mui/material'
import PostList from './PostList'
import { isAfter } from 'date-fns'
import { CheckboxGroupBase, SelectBase } from 'components/Inputs'

const sortList = [
    { label : '公開日順', value : '公開日' },
    { label : '更新日順', value : '更新日' },
]

const ConditionalList = ({ data, listCond }) => {
    const [cond, setCond] = useAtom(listCond)
    const [list, setList] = useState()

    useEffect(() => {
        if (!cond) return
        setList(data
            ?.filter(d => {
                if (cond.状態.includes('公開済み') && (d.下書き || isAfter(d.公開日, new Date()))) return false
                if (cond.状態.includes('下書き') && !d.下書き) return false
                return true
            })
            .sort((a, b) => b[cond.並び順] - a[cond.並び順])
        )
    }, [cond, data])

    return (
        <Stack
            pl={1}
            pr={2}
        >
            <Stack
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                columnGap={3}
                rowGap={1}
                pt={4}
                pb={2}
            >
                <Box width={120}>
                <SelectBase
                    value={cond.並び順}
                    onChange={(e) => setCond(c => ({ ...c, 並び順 : e.target.value}))}
                    list={sortList}
                    fullWidth
                />
                </Box>
                <Box ml={0.5}>
                    <CheckboxGroupBase
                        onChange={e => setCond(c => {
                            const tmpCond = e.target.checked ? c.状態.concat(e.target.value) : c.状態.filter(j => j !== e.target.value)
                            return ({ ...c, 状態 : tmpCond})
                        })}
                        row
                        value={cond.状態}
                        list={['下書き', '公開済み']}
                    />
                </Box>
            </Stack>
            <PostList
                data={list}
            />
        </Stack>
    )
}

export default ConditionalList

import { useNavigate, useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { LinkButtonMiddle, LinkButtonLarge } from 'components/styledButtons'
import PageTitleAndButtons from 'components/PageTitleAndButtons'
import Preview from './Preview'
import { useResearchById } from 'hooks/research'


const EntryPreview = () => {
    const { requestNo, entryNo } = useParams()
    const { data } = useResearchById(requestNo)
    const navi = useNavigate()


    return (
        <Stack
            gap={2}
        >
            <PageTitleAndButtons
                title="エントリー プレビュー"
                buttons={[
                    <LinkButtonMiddle
                        onClick={() => navi(-1)}
                    >
                        一覧に戻る
                    </LinkButtonMiddle>
                ]}
            />
            <Preview
                entry={data?.エントリー?.find(e => e.エントリーNo === entryNo)}
            />
            <Stack
                alignItems="center"
                pt={2}
            >
                <LinkButtonLarge
                    onClick={() => navi(-1)}
                >
                    一覧に戻る
                </LinkButtonLarge>
            </Stack>
        </Stack>
    )
}

export default EntryPreview

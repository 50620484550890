import { useEffect, useState } from 'react'
import { Pagination, Stack } from '@mui/material'

const PaginationList = ({ data, gap=0, max=50 }) => {
    const [page, setPage] = useState(1)

    useEffect(() => {
        setPage(1)
    }, [data])

    const handleChange = (e, p) => {
        setPage(p)
        window.scrollTo({
            top : 0,
            behavior : 'smooth',
        })
    }

    const startIndex = (page - 1) * max


    return (
        <Stack
            alignItems="center"
        >
            {data && (<>
            <Stack
                width="100%"
                gap={gap}
            >
                {data.slice(startIndex, startIndex + max)}
            </Stack>
            <Pagination
                count={Math.floor(data.length / max) + (data.length % max ? 1 : 0)}
                variant="outlined"
                shape="rounded"
                page={page}
                onChange={handleChange}
                sx={{ pt : 3 }}
            />
            </>)}
        </Stack>
    )
}

export default PaginationList

import { Stack } from '@mui/material'
import { format } from 'date-fns'
import { H2, Body } from 'components/styledFonts'
import { GreyLabel } from 'components/styledLabels'
import Block from 'components/Block'
import PostedPicture from 'components/PostedPicture'
import ResponsivePlayer from 'components/ResponsivePlayer'

const Preview = ({ watch }) => {
    
    return (
        <Block
            height="100%"
        >
            <Stack
                px={1}
                pb={4}
                gap={4}
                alignItems="flex-start"
                sx={{ wordBreak : "break-word" }}
            >
                <Stack
                    gap={1}
                    alignItems="flex-start"
                >
                    <H2>{watch('タイトル')}</H2>
                    {watch('投稿日時') &&
                    <GreyLabel>{format(watch('投稿日時'), 'yyyy/MM/dd HH:mm')}</GreyLabel>
                    }
                </Stack>
                <Body whiteSpace="pre-wrap">{watch('リード文')}</Body>
                <PostedPicture
                    data={watch('メインイメージ')}
                    caption={watch('メインイメージキャプション')}
                />
                <Body whiteSpace="pre-wrap">{watch('本文')}</Body>
                {(watch('動画')?.name || watch('動画URL')) &&
                <Stack
                    sx={{ width : { sm : "90%", xs : "100%" } }}
                    alignSelf='center'
                >
                    <ResponsivePlayer
                        data={watch('動画')?.name ? watch('動画') : watch('動画URL')}
                    />
                </Stack>
                }
                {[1, 2, 3, 4].map(n => (
                <PostedPicture
                    key={n}
                    data={watch(`イメージ${n}`)}
                    caption={watch(`イメージ${n}キャプション`)}
                />
                ))}
            </Stack>
        </Block>
    )
}

export default Preview

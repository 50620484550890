import { format } from 'date-fns'
import { styled } from '@mui/material/styles'
import { grey, lightBlue } from '@mui/material/colors'
import { Stack } from '@mui/material'
import { TabUnstyled } from '@mui/base'
import { Body, H4, H5 } from 'components/styledFonts'
import Badge from 'components/Badge'


const Tab = styled(TabUnstyled)({
    display: 'block',
    width: '100%',
    padding: '12px',
    color: grey[700],
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: 4,
    position: 'relative',

    '&.Mui-selected': {
        border: `3px solid ${lightBlue[500]}`,

        '&::before': {
            content: '""',
            position: 'absolute',
            top: '20%',
            right: -59,
            border: '20px solid transparent',
            borderLeft: '46px solid #fff',
            zIndex: 1000,
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '20%',
            right: -66,
            border: '20px solid transparent',
            borderLeft: `46px solid ${lightBlue[500]}`,
            zIndex: 999,
        }
    },
})


const ContactTab = ({ count, value, name, date, text, onClick }) => {

    return (
        <Badge count={count} showCount>
            <Tab value={value} onClick={onClick}>
                <Stack
                    alignItems="flex-start"
                    gap={0.2}
                >
                    <H4>{name}</H4>
                    <H5>{format(new Date(date), 'yyyy/MM/dd HH:mm')}</H5>
                    <Body
                        textAlign="left"
                        height={40}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        sx={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            wordBreak : "break-all"
                        }}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                </Stack>
            </Tab>
        </Badge>
    )
}

export default ContactTab

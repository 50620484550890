import { useNavigate } from 'react-router-dom'
import { apiName } from 'config/api'
import { useLoginUser } from 'hooks/loginuser'
import { useS3Config } from 'hooks/variousHooks'
import { API } from 'lib/api'
import { uploadS3 } from 'lib/common'
import { files as fileNames } from 'pages/research/ResearchEntryBase'
import useMyMutation from './useMyMutation'
import { useAccounts } from 'hooks/account'


export const useEntryMutation = () => {
    const { data: user } = useLoginUser()
    const { data: s3Config } = useS3Config()
    const { data: accounts } = useAccounts()
    const navi = useNavigate()

    return useMyMutation({
        mutationFn : async (param) => {
            const record = { ...param.entry }
            fileNames.filter(d => !record[d]?.blob).forEach(d => record[d] = record[d]?.url || '')

            const files = fileNames
                .filter(d => record[d]?.blob)
                .reduce((o, d) => ({ ...o, [d] : record[d].blob }), {})
            if (Object.keys(files).length) {
                const prefix = `${user.企業ID}/entry`
                // S3にアップロードしデータアクセス時のS3-URLを取得
                const s3Urls = await uploadS3(files, prefix, s3Config)
                Object.keys(s3Urls).forEach(d => record[d] = s3Urls[d])
            }

            // 現在日時設定
            const currentDate = new Date()
            // kintoneへの登録・更新に必要な内容を設定
            const overWriteAccount = accounts.find(c => c.企業担当者ID === record.問い合わせ担当者_上書き)
            record.リクエストNo = param.リクエストNo
            record.クライアント企業ID = user.企業ID
            record.企業担当者ID = user.企業担当者ID
            record.問い合わせ担当者_上書き = overWriteAccount?.姓 + overWriteAccount?.名 || ""
            record.エントリー日時 = record.下書き ? null : currentDate
            record.更新日_データ連携用 = currentDate
            record.更新者_データ連携用 = `${user.姓}${user.名}`
            // 新規の場合は以下を追加する
            if (!record.$id) {
                record.作成日_データ連携用 = currentDate
                record.作成日時_データ連携用 = currentDate
                record.作成者_データ連携用 = `${user.姓}${user.名}`
            }
            // API登録・更新時は必須チェックの処理で使用する質問、ネタもとからの質問フラグは不要のためパラメータから削除する
            delete record.質問
            delete record.ネタもとからの質問
            // エントリーアプリ登録更新APIの呼び出し
            const ret = await API.post(apiName, "/items/postEntry", {
                body : {
                    id : record.$id,
                    revision : record.$revision,
                    record : record,
                }
            })
            if (!Object.keys(ret).length) {
                throw new Error()
            }
            return ret
        },
        queryKey : ['entry', 'newlyResearch'],
        onSettledFn : (ret) => {
            if (/^OK_POST/.test(ret.result)) {
                navi('/research/list')
            }
            if (/^OK_DRAFT/.test(ret.result) && ret.revision === '1') {
                navi(`/research/entry/${ret.requestNo}/${ret.entryNo}`)
            }
        }
    })
}

import { Stack } from '@mui/material'
import TwoColumns from 'components/TwoColumns'
import { useAvailableFeatures, useCompanyInfo } from 'hooks/company'
import { useContacts } from 'hooks/contact'
import { useProgramsForTop } from 'hooks/program'
import Contact from './Contact'
import Feedback from './Feedback'
import MediaSeminar from './MediaSeminar'
import Post from './Post'
import Program from './Program'
import PRAnalysis from './PRAnalysis'
import Research from './Research'


const TopPC = () => {
    const { data : company } = useCompanyInfo()
    const { data : features } = useAvailableFeatures()
    const { data : program } = useProgramsForTop()
    const contacts = useContacts()

    return (
        <Stack
            gap={1.5}
        >
            <TwoColumns>
                {features?.PR活動診断 &&
                <PRAnalysis />
                }
                {features?.プログラム申込 && !!Object.keys(program || {}).length &&
                <Program />
                }
            </TwoColumns>
            <TwoColumns>
                {contacts &&
                <Contact />
                }
                {company?.セミナー機能 &&
                <MediaSeminar />
                }
            </TwoColumns>
            <TwoColumns
                ratio="8fr 4fr"
            >
                <Post />
                {features?.フィードバック &&
                <Feedback />
                }
            </TwoColumns>
            {features?.リサーチ &&
            <Research />
            }
        </Stack>
    )
}

export default TopPC

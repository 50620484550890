import { Box } from '@mui/material'


const S3Image = ({ url, width="100%", height="100%" }) => {
    return (
        <Box
            component="img"
            width={width}
            height={height}
            src={url}
            sx={{
                objectFit : "contain",
            }}
        />
    )
}

export default S3Image

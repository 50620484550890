import { useCallback, useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useAtom, useSetAtom } from 'jotai'
import { format } from 'date-fns'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { grey } from '@mui/material/colors'
import { BlueContainedButton, BlueOutlinedButton } from 'components/styledButtons'
import { messageAtom } from 'store/commonAtoms'
import { selectedDataAtom, mlEditModalAtom, mlGroupNameModalAtom, deleteDialogAtom } from 'pages/medialist/MediaMain'
import Block from 'components/Block'
import PageTitleAndButtons from 'components/PageTitleAndButtons'
import GroupNameModal from 'modals/GroupNameModal'
import MediaListEditModal from 'modals/MediaListEditModal'
import Conditions from './Conditions'
import MediaList from './MediaList'
import MediaListForPad from './MediaListForPad'
import CsvButton from './CsvButton'
import { messages } from 'config/messages'
import { useMergedMedias, useAllMedias } from 'hooks/media'
import { useLoginUser } from 'hooks/loginuser'
import { useDynamoItem } from 'hooks/dynamo'
import { useDynamoMutation } from 'hooks/mutation/useDynamoMutation'

const defaultValues = {
    検索ワード : "",
    チェック : [],
    お気に入り : [],
    絞り込み : '',
    ソート : '',
    チェックリスト: []
}

const MediaListMain = () => {
    const conditionForm = useForm({ defaultValues : defaultValues })
    const { data } = useMergedMedias()
    const { data : allMedias } = useAllMedias()
    const { data : loginUserData } = useLoginUser()
    const { data : dynamoData } = useDynamoItem("メディアリストお気に入り")
    const { mutate } = useDynamoMutation("メディアリストお気に入り")
    const [filteredData, setFilteredData] = useState([])
    const [first, setFirst] = useState(true)
    const setOpen = useSetAtom(deleteDialogAtom)
    const setEdit = useSetAtom(mlEditModalAtom)
    const setGroup = useSetAtom(mlGroupNameModalAtom)
    const setSelectedMlData = useSetAtom(selectedDataAtom)
    const [message, setMessage] = useAtom(messageAtom)
    const isPad = useMediaQuery((theme) => theme.breakpoints.down('pad'))

    useEffect(() => {
        if ((data && first) || (data && message?.type === 'info')) {
            if (message?.type === 'info') {
                conditionForm.reset()
            }
            setFirst(false)
            setFilteredData(data.map(d => ({ ...d })))
        }
    }, [data, message, first, conditionForm])

    const handleCreateMail = useCallback(() => {
        const bcc = conditionForm.getValues().チェックリスト

        if (bcc.length > 50) {
            setMessage({
                type : 'error',
                text : messages.MEDIALIST_SELECT_OVER,
            })
        }
        else if (bcc.length === 0) {
            setMessage({
                type : 'error',
                text : messages.MEDIALIST_SELECT_ZERO,
            })
        }
        else {
            window.location.href = `mailto:${loginUserData.メールアドレス}?bcc=${bcc.join(',')}`
        }

    }, [conditionForm, setMessage, loginUserData])

    conditionForm.handleFiltering = () => {
        const cond = conditionForm.getValues()

        setFilteredData(data
            .filter(d => {
                if (cond.チェック.length) {
                    return !!cond.チェックリスト?.some(r => r === d.メール)
                }
                return true
            })
            .filter(d => {
                if (cond.お気に入り.length) {
                    return !!dynamoData?.some(r => r === d.メール)
                }
                return true
            })
            .filter(d => {
                if (cond.絞り込み.length) {   
                    return d.グループ.includes(cond.絞り込み)
                }
                return true
            })
            .filter(d => {
                if (cond.ソート.length) {
                    return d.媒体種類 === cond.ソート
                }
                return true
            })
            .filter(d => {
                if (cond.検索ワード.length) {
                    const approachDate = d.アプローチ日 ? format(d.アプローチ日, 'yyyy-MM-dd') : ''
                    return (`${d.姓} ${d.名}`.includes(cond.検索ワード) ||
                        d.媒体種類?.includes(cond.検索ワード) ||
                        d.媒体名?.includes(cond.検索ワード) ||
                        d.部署名?.includes(cond.検索ワード) ||
                        d.TEL?.includes(cond.検索ワード) ||
                        d.メール.includes(cond.検索ワード) ||
                        d.携帯電話?.includes(cond.検索ワード) ||
                        approachDate.includes(cond.検索ワード)
                    )
                }
                return true
            })
        )
    }

    const handleMlEditModal = (d) => {
        if (!d) {
            // 新規作成の場合
            const clMediaList = allMedias.find(a => a.リスト種類 === '企業作成')
            d = { $id : clMediaList?.$id, $revision : clMediaList?.$revision, リスト種類 : '企業作成', 都道府県 : "東京都" }
        }
        setSelectedMlData(d)
        setEdit(true)
    }

    const handleCheck = (e, d, idx) => {
        // チェックしたメールを保存
        if (e.target.checked) conditionForm.setValue('チェックリスト', [...new Set([...conditionForm.getValues().チェックリスト || [], d.メール])])
        else conditionForm.setValue('チェックリスト', conditionForm.getValues().チェックリスト.filter(a => a !== d.メール))
        
        // 絞り込むデータにチェック値保存
        filteredData[idx].チェック = e.target.checked
        setFilteredData([...filteredData])
    }

    const handleDynamoCheck = (e, d) => {
        if (e.target.checked) mutate({ content : [...new Set([...dynamoData || [], d.メール])] })
        else mutate({ content : dynamoData?.filter(a => a !== d.メール) })
    }

    const handleOpenDialog = (d) => {
        setOpen(true)
        setSelectedMlData(d)
    }

    const csvKeys = [
        '姓', '名', '媒体種類', '会社名', '媒体名', '部署名', '役職名', 'TEL', 'FAX', 'メール', 
        '携帯電話', 'URL', '郵便番号', '都道府県', '市区郡', '町名番地', '登録日', 'アプローチ日', '備考'
    ]

    return (
        <FormProvider { ...conditionForm }>
            <Stack
                gap={2}
            >
                <PageTitleAndButtons
                    title="メディアリスト"
                    buttons={[
                        <BlueContainedButton
                            label="新規作成"
                            onClick={() => handleMlEditModal()}
                        />,
                        isPad ? null :
                        <CsvButton
                            headers={csvKeys.map((h) => (h === '町名番地' ? '町名・番地' : h))}
                            dataList={filteredData?.map((d) => csvKeys.map((h) => d[h]))}
                            label="エクスポート"
                            fileName={'メディアリスト_' + format(new Date(), 'yyyyMMddHHmmss')}
                        />,
                        <BlueOutlinedButton
                            label="グループ名設定"
                            onClick={() => setGroup(true)}
                        />,
                    ].filter(m => m)}
                />
                <Block
                    sx={{ px : { mobile : 3, xs : 1 } }}
                >
                    <Stack
                        gap={1}
                    >
                        <Conditions />
                        <BlueContainedButton
                            disabled={conditionForm.getValues().チェックリスト.length === 0}
                            type="submit"
                            label="メール作成"
                            onClick={handleCreateMail}
                        />
                    </Stack>
                    <Box
                        mt={1}
                        borderBottom={`1px solid ${grey[400]}`}
                    />
                    {!isPad &&
                    <MediaList
                        filteredData={filteredData} 
                        handleMlEditModal={handleMlEditModal}
                        handleCheck={handleCheck}
                        handleDynamoCheck={handleDynamoCheck}
                        handleOpenDialog={handleOpenDialog}
                    />
                    }
                    {isPad &&
                    <MediaListForPad
                        filteredData={filteredData} 
                        handleMlEditModal={handleMlEditModal}
                        handleCheck={handleCheck}
                        handleDynamoCheck={handleDynamoCheck}
                        handleOpenDialog={handleOpenDialog}
                    />
                    }
                </Block>
            </Stack>
            <GroupNameModal />
            <MediaListEditModal />
        </FormProvider>
    )
}

export default MediaListMain

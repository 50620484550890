import { useLocation, useNavigate } from 'react-router-dom'
import { newsListCond, prsListCond } from 'store/commonAtoms'
import { useSetAtom } from 'jotai'
import { SelectBase } from 'components/Inputs'

const PageSelector = ({ list }) => {
    const loc = useLocation()
    const nav = useNavigate()
    const setNewsCond = useSetAtom(newsListCond)
    const setPrsCond = useSetAtom(prsListCond)

    const handleChangeList = e => {
        setNewsCond({
            並び順 : '公開日',
            状態 : [],
        })
        setPrsCond({
            並び順 : '公開日',
            状態 : [],
        })
        nav(e.target.value)
    }

    return (
        <SelectBase
            value={loc.pathname}
            size="small"
            sx={{
                fontSize : 20,
                fontWeight : 700,
                maxWidth : 200,
                '& .MuiOutlinedInput-input' : {
                    pb : 0.75, 
                },
            }}
            onChange={handleChangeList}
            list={list}
        />
    )
}

export default PageSelector

import { useParams } from 'react-router-dom'
import { Stack, useMediaQuery } from '@mui/material'
import { H1, Body } from 'components/styledFonts'
import Block from 'components/Block'
import { useFormContext } from 'react-hook-form'
import {
    CharacterLimitField,
    DatePicker,
    ImageCaption,
    LabeledInputField,
    RadioGroupBase,
    Required,
    TextField,
} from 'components/Inputs'
import { SelectMovie } from 'components/FileUploader'
import { maxLength } from './NewsLetterSubmissionBase'
import InquiryUserInput from 'components/InquiryUserInput'
import TemplateTitleEdit from 'components/TemplateTitleEdit'
import { useEffect } from 'react'
import { useTemplateByNo } from 'hooks/template'
import FeedbackCheckbox from 'components/FeedbackCheckbox'
import GenreSelect from 'components/GenreSelect'
import CategorySelect from 'components/CategorySelect'
import PostButtonSet from 'components/PostButtonSet'

const NewsLetterTextSubmission = () => {
    const { control, setValue, getValues, handleSubmit, watch, saveDraft } = useFormContext()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))
    const { themeId } = useParams()
    const { data : template } = useTemplateByNo(themeId || watch("ネタテーマNo"))
    
    // ネタテーマ取得時反映処理
    useEffect(() => {
        setValue("入稿方法", "テキスト入稿（テンプレートから遷移）")
        if (!template) return
        setValue("ネタテーマNo", getValues("ネタテーマNo") || themeId)
        const answerArray = [...Array(10).keys()]
        answerArray.forEach(c => {
            if (!getValues("ネタNo") && !getValues(`回答${c + 1}`)) setValue(`回答${c + 1}`, template[`回答${c + 1}テンプレート`] || "")
            setValue(`質問${c + 1}`, template[`質問${c + 1}`] || "")
            setValue(`回答必須${c + 1}`, template[`回答必須${c + 1}`] || "")
        })
    }, [template, themeId, getValues, setValue])

    return (
        <Stack
            gap={2}
        >
            <H1>{watch("ネタNo") ? "ニュースレターの下書きを編集" : "テーマを用いてニュースレターを投稿"}</H1>
            <Block>
                <Stack
                    component="form"
                    gap={4}
                    pb={2}
                    sx={{ 
                        pl : { mobile : 2, xs : 0 },
                        pr : { mobile : 8, xs : 0 },
                        wordBreak : "break-word",
                    }}
                >
                    {template &&
                    <TemplateTitleEdit template={template} />
                    }
                    <Body sx={{ pb : 2 }}><Required />がついている項目は必須です</Body>
                    <CharacterLimitField
                        label="タイトル"
                        name="ネタたねタイトル"
                        limit={maxLength.タイトル}
                        control={control}
                        required
                        multiline
                        disableEnter
                        watch={watch('ネタたねタイトル')}
                    />
                    <CharacterLimitField
                        name="リード文"
                        limit={maxLength.リード文}
                        control={control}
                        required
                        multiline
                        minRows={3}
                        watch={watch("リード文")}
                    />
                    <LabeledInputField
                        label="メイン画像"
                        note="※一覧ページでサムネイルとしても表示されます。画像は最大5MBまでアップロード可能です。"
                        required
                    >
                        <ImageCaption
                            name="メインイメージ"
                            captionName="メインイメージキャプション"
                            pict={watch('メインイメージ')}
                            control={control}
                            setValue={setValue}
                        />
                    </LabeledInputField>
                {[...Array(10).keys()].map(k => {
                    if (!template?.[`質問${k + 1}`]) return null
                    return (
                    <CharacterLimitField
                        key={k}
                        name={`回答${k + 1}`}
                        label={template[`質問${k + 1}`]}
                        note={template[`備考${k + 1}`]} 
                        limit={maxLength.回答}
                        control={control}
                        required={template[`回答必須${k + 1}`]}
                        multiline
                        minRows={3}
                        watch={watch(`回答${k + 1}`)}
                    />
                )})}
                    <LabeledInputField
                        label="画像"
                        note="※最大4つまで掲載可、画像は最大5MBまでアップロード可能です。"
                    >
                    {[...Array(4).keys()].map((k, i, a) => {
                    return (
                        <ImageCaption
                            key={i}
                            name={`イメージ${i + 1}`}
                            captionName={`イメージ${i + 1}キャプション`}
                            label="画像"
                            pict={watch(`イメージ${i + 1}`)}
                            control={control}
                            setValue={setValue}
                        />
                    )})}
                    </LabeledInputField>
                    <LabeledInputField
                        label="動画"
                        note="※動画は最大50MBまでアップロード可能です。"
                    >
                        <RadioGroupBase
                            value={watch(`アップロード方法`)}
                            onChange={e => setValue(`アップロード方法`, e.target.value)}
                            row={!isMobile}
                            sx={{
                                columnGap : 0,
                            }}
                            list={[
                                { label : '動画をアップロード', value : '動画をアップロード', disabled : !!watch(`動画URL`) },
                                { label : '動画の埋め込みタグを入力', value : '動画の埋め込みタグを入力', disabled : !!(watch(`動画`)?.url || watch(`動画`)?.blob) }
                            ]}
                        />
                        {watch(`アップロード方法`) === "動画をアップロード" &&
                        <SelectMovie
                            name={`動画`}
                            file={watch(`動画`)}
                            control={control}
                            setValue={setValue}
                            showName
                            removable
                        />      
                        }
                        {watch(`アップロード方法`) === "動画の埋め込みタグを入力" && <>
                        <TextField
                            name={`動画URL`}
                            control={control}
                            watch={watch(`動画URL`)}
                            placeholder="YouTube動画の埋め込みタグを貼り付けてください。"
                        />
                        <Body>{"YouTube動画の「共有メニュー > 埋め込む」から取得できる、埋め込み用タグをコピーアンドペーストしてください。"}</Body>
                        </>}
                    </LabeledInputField>
                    <GenreSelect control={control} />
                    <CategorySelect control={control} />
                    <InquiryUserInput
                        control={control}
                        setValue={setValue}
                        note="企業情報設定と異なる方を問い合わせ担当者にする際は入力してください"
                    />
                    <LabeledInputField
                        label="投稿日時"
                    >
                        <DatePicker
                            name="公開日"
                            control={control}
                            showTimeInput
                        />
                    </LabeledInputField>
                    <FeedbackCheckbox
                        name={"フィードバック依頼"}
                        disabled={!!watch("フィードバックNo")}
                        control={control}
                    />
                </Stack>
            </Block>
            <PostButtonSet
                handleSubmit={handleSubmit}
                watch={watch}
                saveDraft={saveDraft}
                isFeedback={watch("フィードバック依頼") && !watch("フィードバックNo")}
            />
        </Stack>
    )
}

export default NewsLetterTextSubmission

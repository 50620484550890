import { Stack } from '@mui/material'
import { featureCategory, newsletterCategories, pressreleaseCategories } from 'config/options'
import { LabeledInputField, RadioGroup } from './Inputs'

const CategorySelect = ({ control, setValue, pressRelease }) => {

    return (
        <Stack>
            <LabeledInputField
                label="カテゴリ"
                required
                note={pressRelease ? '※メディア向けイベントも記者発表会にチェックを入れてください' : ''}
            />
            <RadioGroup
                name="カテゴリ"
                control={control}
                row
                sx={{
                    columnGap : 0,
                    '& .MuiFormControlLabel-root' : {
                        width : { sm : "24%", xs : "100%" },
                    },
                }}
                handleChange={pressRelease ? e => {
                    if (!featureCategory.some(c => c === e.target.value)) setValue("リリース公開期限", "")
                } : null}
                list={pressRelease ? pressreleaseCategories : newsletterCategories}
            />
        </Stack>
    )
}

export default CategorySelect
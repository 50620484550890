import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { useResearchByNo } from 'hooks/research'
import EntryComponent from './EntryComponent'


const NewEntry = () => {
    const { requestNo } = useParams()
    const { data } = useResearchByNo(requestNo)
    const { setValue, getValues } = useFormContext()

    useEffect(() => {
        if (!data) return

        [...Array(20).keys()].forEach(n => {
            if (!getValues(`回答${n + 1}`)) setValue(`回答${n + 1}`, data[`回答${n + 1}テンプレート`])
            if (n < 5 && !getValues(`回答${n + 1}_ネタもとからの質問`)) {
                setValue(`回答${n + 1}_ネタもとからの質問`, data[`回答${n + 1}テンプレート_ネタもとからの質問`])
            }
        })
    }, [data, getValues, setValue])

    return <EntryComponent data={data} />
}

export default NewEntry

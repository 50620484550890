import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { red, grey } from '@mui/material/colors'
import { Stack } from '@mui/material'
import { H4, Body, SmallBody } from 'components/styledFonts'
import Block from 'components/Block'
import { useResearchById } from 'hooks/research'
import RequestComponent from './RequestComponent'


export const formatDate = d => format(d, 'M月d日 HH:mm')

export const Strong = ({ children }) => (
    <SmallBody
        component="span"
        sx={{
            color : red[400],
            fontWeight : 700,
        }}
    >
        {children}
    </SmallBody>
)

export const LV = ({ label, value }) => (
    <Stack
        direction="row"
        alignItems="flex-end"
        gap={1.5}
    >
        <SmallBody sx={{ color : grey[500], pb : '1.5px' }}>{label}</SmallBody>
        <Body>{value}</Body>
    </Stack>
)

export const Section = ({ title, value }) => (
    <Stack
        gap={1}
    >
        <H4>{title}</H4>
        <Block>
            <Body
                sx={{
                    pl : { sm : 2, xs : 0 },
                    whiteSpace : "pre-wrap",
                    wordBreak : "break-all",
                }}
            >
                {value}
            </Body>
        </Block>
    </Stack>
)

const Request = () => {
    const { requestNo } = useParams()
    const { data } = useResearchById(requestNo)

    if (!data) return <Stack minHeight={700} /> 

    return <RequestComponent data={data} />
}

export default Request

import { useQuery } from '@tanstack/react-query'
import { apiName } from 'config/api'
import { API } from 'lib/api'

const getData = async () => {
    const ret = await API.get(apiName, "/items/getInformation")
    return ret
}

export const useAllInformation = () => {
    return useQuery(['information'], getData, {
        select: (data) => data.map(d => ({
            ...d,
            タイトル: d.重要なお知らせ ? "重要なご連絡" : "株式会社ネタもとのご連絡",
        }))
    })
}


import { Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import { H4, H5 } from 'components/styledFonts'
import { DraftLabel, PublicLabel } from 'components/styledLabels'
import { EditButton } from 'components/styledButtons'
import { ThumbnailNoLink } from 'components/Thumbnail'
import Unregistered from 'components/Unregistered'


const InquiryItem = ({ category, data, link }) => {

    return (
        <Stack
            gap={1}
            px={1.5}
            justifyContent="space-between"
            height="100%"
        >
            <Stack
                direction="row"
                gap={2}
            >
                <H4>{category}</H4>
                {data &&
                <Stack
                    direction="row"
                    gap={0.5}
                >
                    {!data.下書き &&
                    <PublicLabel>{"公開済"}</PublicLabel>
                    }
                    {data.下書き &&
                    <DraftLabel>下書き</DraftLabel>
                    }
                </Stack>
                }
            </Stack>
            <Stack
                alignItems="center"
            >
                {data &&
                <ThumbnailNoLink
                    width={210}
                    height={140}
                    src={data.サムネイル}
                />
                }
                {!data &&
                <Unregistered />
                }
            </Stack>
            <H5 sx={{ wordBreak : "break-word" }} >{data?.タイトル}</H5>
            <Stack
                pt={1}
                alignItems="center"
            >
                <EditButton
                    component={Link}
                    to={link}
                >
                    {data ? "編集" : "作成"}
                </EditButton>
            </Stack>
        </Stack>
    )
}

export default InquiryItem


import { useCallback, useEffect } from 'react'
import { useBeforeUnload, useNavigate, useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { Stack } from '@mui/material'
import PageTitleAndButtons from 'components/PageTitleAndButtons'
import { BlueContainedButton, LinkButtonMiddle, LinkButtonLarge } from 'components/styledButtons'
import { useEntryMutation } from 'hooks/mutation/research'
import Preview from './Preview'


const EditingEntryPreview = () => {
    const { getValues, setValue, handleSubmit } = useFormContext()
    const navi = useNavigate()
    const { requestNo } = useParams()
    const { mutate } = useEntryMutation()

    useEffect(() => {
        if (!getValues('タイトル')) navi('/research/list')
    }, [getValues, navi])

    useBeforeUnload((e) => {
        e.preventDefault()
    })

    const onSubmit = useCallback(
        async (data) => {
            mutate({
                リクエストNo : requestNo,
                entry : data,
            }) 
        },
        [mutate, requestNo]
    )

    return (
        <Stack
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            gap={2}
        >
            <PageTitleAndButtons
                title="エントリー プレビュー"
                buttons={[
                    <LinkButtonMiddle
                        sx={{ minWidth : 150 }}
                        onClick={() => navi(-1)}
                    >
                        作成画面に戻る
                    </LinkButtonMiddle>
                    ,
                    <BlueContainedButton
                        label="エントリー"
                        type="submit"
                        onClick={() => setValue('下書き', false)}
                    />
                ]}
            />
            <Preview
                entry={getValues()}
            />
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    flexDirection : { sm : "row", xs : "column" },
                }}
                gap={2}
                pt={2}
                pb={2}
            >
                <LinkButtonLarge
                    onClick={() => navi(-1)}
                >
                    作成画面に戻る
                </LinkButtonLarge>
                <BlueContainedButton
                    label="エントリー"
                    type="submit"
                    onClick={() => setValue('下書き', false)}
                    sx={{ width : 240, height : 44 }}
                />
            </Stack>
        </Stack>
    )
}

export default EditingEntryPreview

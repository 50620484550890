import { Stack } from '@mui/material'
import { UnderlineLink } from 'components/links'
import { H3Underline, H5 } from 'components/styledFonts'
import { WhiteLabel } from 'components/styledLabels'
import { useInquiries } from 'hooks/inquiry'
import { useAvailableFeatures } from 'hooks/company'
import { useNewsLetterForTop } from 'hooks/newsletter'
import { usePressReleaseForTop } from 'hooks/pressrelease'
import ComponentBase from './ComponentBase'


const PostItem = ({ subtitle, children }) => {
    return (
        <Stack
            gap={1}
        >
            <H3Underline>{subtitle}</H3Underline>
            {children}
        </Stack>
    )
}

const PostNews = ({ subtitle, data }) => {
    return (
        <PostItem
            subtitle={subtitle}
        >
            <Stack
                gap={1}
                alignItems="flex-start"
            >
            {data?.map((d, i) => (
            <UnderlineLink
                key={i}
                to={`${subtitle === 'プレスリリース' ? `/pressrelease/${d.リリースNo}` : `/newsletter/${d.ネタNo}`}`}
            >
                <H5>{d.タイトル}</H5>
            </UnderlineLink>
            ))}
            {!data?.length &&
            <H5>{`公開している${subtitle}はありません`}</H5>
            }
            </Stack>
        </PostItem>
    )
}


const categories = [
    { type: 'story',          name: '社長ストーリー' },
    { type: 'intoroduction',  name: 'こんな会社' },
    { type: 'recommendation', name: 'イチオシ！' },
]

const Post = () => {
    const { data : inq } = useInquiries()
    const { data : pr } = usePressReleaseForTop()
    const { data : nl } = useNewsLetterForTop()
    const { data : features } = useAvailableFeatures()

    return (
        <ComponentBase
            title="情報発信"
            buttons={[{ label : '情報発信', to : '/post/list/pressrelease' }]}
        >
            <Stack
                gap={4}
            >
                {features?.企業探訪設定 &&
                <PostItem
                    subtitle="企業探訪"
                >
                    {categories.map((c, i) => (
                    <Stack
                        key={i}
                        direction="row"
                        alignItems="flex-start"
                        gap={1}
                    >
                        <Stack
                            direction="row"
                            flexShrink={0}
                        >
                            <WhiteLabel>{c.name}</WhiteLabel>
                        </Stack>
                        <UnderlineLink
                            to={`/inquiry/${c.type}`}
                        >
                            <H5>{inq?.[c.name] && !inq[c.name].下書き ? inq[c.name].タイトル : "未公開"}</H5>
                        </UnderlineLink>
                    </Stack>
                    ))}
                </PostItem>
                }
                <PostNews
                    subtitle="プレスリリース"
                    data={pr}
                />
                {features?.ニュースレター &&
                <PostNews
                    subtitle="ニュースレター"
                    data={nl}
                />
                }
            </Stack>
        </ComponentBase>
    )
}

export default Post

import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Body, TH } from 'components/styledFonts'

const TableList = ({ headers, data }) => {

    return (
        <Stack
            alignItems="center"
        >
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {headers.map((h, i) =>
                        <TH
                            key={i}
                            sx={{ px : 1.5, ...h.sx }}
                        >
                            {h.noLabel ? '' : h.label}
                        </TH>
                        )}
                    </TableRow>
                </TableHead>
                {data &&
                <TableBody>
                    {data.map((d, i) => (
                    <TableRow key={i}>
                        {headers.map((h, j) => (
                        <TableCell key={j} sx={{ px : 1.5, ...h.sx }}>
                            <Body
                                sx={{ whiteSpace : "pre-wrap" }}
                            >
                                {d[h.label]}
                            </Body>
                        </TableCell>
                        ))}
                    </TableRow>
                    ))}
                </TableBody>
                }
            </Table>
        </Stack>
    )
}

export default TableList

import { Button, Stack } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { useClientMessages, useReadFlags, useSupportMessages } from 'hooks/message'
import { H1 } from 'components/styledFonts'
import { PaginationAccordion } from 'components/Accordion'
import Badge from 'components/Badge'
import History from './History'
import Entry from './Entry'

const max = 5
const MessageForMobile = () => {
    const { data } = useClientMessages()
    const { data : support } = useSupportMessages()
    const { id } = useParams()
    const flags = useReadFlags()

    if (!data) return

    return (
        <Stack gap={2}>
            <H1>メッセージ履歴</H1>
            <PaginationAccordion
                data={data}
                keyName="媒体担当者ID"
                summary={d => (
                    <Badge
                        count={flags?.[d.$id]?.filter(f => f).length}
                        showCount
                        menuList
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                        >
                            {`${d.媒体名} ${d.媒体担当者_姓}${d.媒体担当者_名}`}
                        </Stack>
                    </Badge>
                )}
                detail={d => (
                    <Stack>
                        <History messages={d} />
                        <Entry entryMessage={d} />
                    </Stack>
                )}
                max={max}
                defaultValue={id}
                calcPage={() => {
                    if (id) {
                        const index = data.findIndex(m => m.媒体担当者ID === id)
                        return Math.floor(index / max) + 1
                    }
                    return 1
                }}
            />
            <Badge
                count={flags?.[support?.$id]?.some(f => f)}
                sx={{ alignSelf : 'center' }}
            >
                <Button
                    variant="outlined"
                    color='inherit'
                    sx={{ bgcolor : '#e0e0e0' }}
                    component={Link}
                    to={"/messages/support"}
                >
                    サポートデスクへのお問い合わせ
                </Button>
            </Badge>
        </Stack>
    )
}

export default MessageForMobile


import { Link } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { BadgeButton } from 'components/styledButtons'
import { MenuBarIconButton } from 'components/styledButtons'
import Badge from 'components/Badge'
import { useNewMessageCount } from 'hooks/message'


const MessageButton = () => {
    const mcnt = useNewMessageCount()
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))

    if (isSm) {
        return (
            <Badge
                count={mcnt}
                sx={{ ml : 5 }}
            >
                <MenuBarIconButton
                    component={Link}
                    to="/messages"
                >
                    <MailOutlineIcon />
                </MenuBarIconButton>
            </Badge>
        )
    }
    else {
        return (
            <BadgeButton
                count={mcnt}
                to="/messages"
            >
                メッセージ
            </BadgeButton>
        )
    }
}

export default MessageButton

import { Box } from '@mui/material'


const TwoColumns = ({ children, breakpoint="md", ratio="1fr 1fr", gap=1.5 }) => {
    return (<>
        {children.some(c => !c) ?
        <Box>
            {children}
        </Box>
        :
        <Box
            display="grid"
            gap={gap}
            sx={{
                gridTemplateColumns : { [breakpoint] : ratio, xs : "1fr" }
            }}
            gridTemplateRows="auto"
        >
            {children}
        </Box>
        }
    </>)
}

export default TwoColumns

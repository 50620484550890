import { atom } from 'jotai'
import { defaultValues } from 'pages/research/ResearchList'

// スピナー、フラッシュメッセージ
export const spinnerAtom = atom(false)
export const messageAtom = atom(null)

// 新規作成・編集画面のキャンセル確認ダイアログ
export const cancelDialogAtom = atom(null)

// プレスリリース／ニュースレターの入稿方法選択モーダル
export const submissionModalAtom = atom(false)

// 動画視聴モーダル
export const reactPlayerModalAtom = atom(false)

// 情報発信画面の一覧ソートフィルター条件(プレスリリース)
export const prsListCond = atom({
    並び順 : '公開日',
    状態 : [],
})

// 情報発信画面の一覧ソートフィルター条件(ニュースレター)
export const newsListCond = atom({
    並び順 : '公開日',
    状態 : [],
})

// リサーチ一覧画面の検索条件
export const searchCondAtom = atom(defaultValues)

// リサーチ一覧画面のページ
export const searchPageAtom = atom(1)

import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { useAccounts } from 'hooks/account'
import { useResearchById } from 'hooks/research'
import { defaultValues, files } from './ResearchEntryBase'
import EntryComponent from './EntryComponent'


const Entry = () => {
    const { requestNo, entryNo } = useParams()
    const { data } = useResearchById(requestNo)
    const { setValue, getValues } = useFormContext()
    const { data: accountData } = useAccounts()

    useEffect(() => {
        if (!data || !accountData) return
        // 初回読み込み時以外はフォームの値が上書きされないようリターンする
        if (getValues('エントリーNo')) return

        const entry = data.エントリー.find(e => e.エントリーNo === entryNo)
        if (entry) {
            Object.keys(defaultValues).filter(n => !files.includes(n)).forEach(n => setValue(n, entry[n]))
            const overWriteUserId = accountData.find(d => d.姓 + d.名 === entry.問い合わせ担当者_上書き)
            setValue("問い合わせ担当者_上書き", overWriteUserId?.企業担当者ID || "")
            files.forEach(n => setValue(n, { url: entry[n], name: entry[`${n}オリジナルファイル名`] }))
        } else {
            [...Array(20).keys()].forEach(n => {
                if (!getValues(`回答${n + 1}`)) setValue(`回答${n + 1}`, data[`回答${n + 1}テンプレート`])
                if (n < 5 && !getValues(`回答${n + 1}_ネタもとからの質問`)) {
                    setValue(`回答${n + 1}_ネタもとからの質問`, data[`回答${n + 1}テンプレート_ネタもとからの質問`])
                }
            })
        }
    }, [data, entryNo, getValues, setValue, accountData])

    return <EntryComponent data={data} />
}

export default Entry

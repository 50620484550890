import { Stack } from '@mui/material'
import { H1, H2 } from 'components/styledFonts'
import Block from 'components/Block'
import PersonalBase from './PersonalBase'
import PersonalMail from './PersonalMail'
import PersonalPassword from './PersonalPassword'
import PersonalNotification from './PersonalNotification'

const Personal = () => {

    return (
        <Stack
            gap={2}
        >
            <H1>個人設定</H1>
            <Block>
                <Stack
                    gap={2}
                >
                    <H2>個人情報</H2>
                    <PersonalBase />
                    <PersonalMail />
                    <PersonalPassword />
                    <PersonalNotification />
                </Stack>
            </Block>
        </Stack>
    )
}

export default Personal

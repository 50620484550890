import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api'
import { htmlBeautify } from 'lib/common'
import { apiName } from 'config/api'
import { isAfter, isBefore } from 'date-fns'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { newsListCond } from 'store/commonAtoms'

const uploadOption = [
    ["動画", "動画をアップロード"],
    ["動画URL", "動画の埋め込みタグを入力"]
]

export const files = [
    'PDF',
    'メインイメージ',
    '動画',
]
.concat([...Array(4).keys()].flatMap(i => [
    `イメージ${i + 1}`,
]))

const getData = async () => {
    const ret = await API.get(apiName, '/items/getNewsLetter', {})
    return ret.map(c => ({
        ...c,
        公開日 : c.公開日 ? new Date(c.公開日) : "",
        更新日 : c.更新日_データ連携用 ? new Date(c.更新日_データ連携用) : "", 
        リリース日時 : c.公開日 ? new Date(c.公開日) : "",
        更新者 : c.更新者_データ連携用,
        フィードバック依頼 : c.フィードバック依頼,
        タイトル : c.ネタたねタイトル,
        アップロード方法 : uploadOption.find(u => c[u[0]])?.[1] || "動画をアップロード",
        本文 : htmlBeautify(c.本文),
        ...files.reduce((a, f) => ({...a, [f] : { url : c[f], name : c[`${f}オリジナルファイル名`] }}), {}),
        サムネイル : c.メインイメージ,
        type : 'ニュースレター',
        to : c.下書き || isAfter(c.公開日 ? new Date(c.公開日) : "", new Date()) ? `/newsletter/submission/${/PDF入稿/.test(c.入稿方法) ? "pdf" : c.入稿方法 === "HTML入稿" ? "html" : "text"}/${c.ネタNo}` : `/newsletter/${c.ネタNo}`,
    }))
}

export const useAllNewsLetter = () => {
    return useQuery(['newsletter'], getData, {
        select : (data) => data.sort((a, b) => b.公開日 - a.公開日)
    })
}

export const useAllNewsLetterOnlyFeedback = () => {
    return useQuery(['newsletter'], getData, {
        select : (data) => data.filter(d => d.フィードバックNo).sort((a, b) => b.公開日 - a.公開日)
    })
}

export const useNewsLetterByNo = (no) => {
    return useQuery(['newsletter'], getData, {
        select : data => data.find(d => d.ネタNo === no)
    })
}

export const usePreviousNext = (no) => {
    const cond = useAtomValue(newsListCond)
    const { data } = useAllNewsLetter()
    const [conditionalList, setConditionalList ] = useState([])

    useEffect(() => {
        if (!data || !cond) return
        setConditionalList(
            data?.filter(d => {
                if (cond.状態.includes('公開済み') && (d.下書き || isAfter(d.公開日, new Date()))) return false
                if (cond.状態.includes('下書き') && !d.下書き) return false
                return true
            })
            .sort((a, b) => b[cond.並び順] - a[cond.並び順])
        )
    }, [cond, data, setConditionalList])

    return {
        data : [
            conditionalList[conditionalList.findIndex(d => d.ネタNo === no) - 1],
            conditionalList[conditionalList.findIndex(d => d.ネタNo === no) + 1]
        ]
    }
}

export const useNewsLetterForTop = () => {
    return useQuery(['newsletter'], getData, {
        select : (data) => data
            .filter(d => !d.下書き && isBefore(d.公開日, new Date()))
            .sort((a, b) => b.公開日 - a.公開日)
            .slice(0, 3)
    })
}


import PreviewEditor from './PreviewEditor'
import { useIntroduction, useRecommendation, useStory } from 'hooks/inquiry'


const EditIntoroduction = () => {
    const { data : story } = useStory()
    const { data : intoro } = useIntroduction()
    const { data : recomm } = useRecommendation()

    return (
        <PreviewEditor
            category="こんな会社"
            data={intoro}
            leftCategory="社長ストーリー"
            leftData={story}
            rightCategory="イチオシ！"
            rightData={recomm}
        />
    )
}

export default EditIntoroduction

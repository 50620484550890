import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api'
import { htmlBeautify } from 'lib/common'
import { apiName } from 'config/api'
import { isAfter } from 'date-fns'
import { useAtomValue } from 'jotai'
import { prsListCond } from 'store/commonAtoms'
import { useEffect, useState } from 'react'

const uploadOption = [
    ["画像{no}", "画像をアップロード"],
    ["動画{no}", "動画をアップロード"],
    ["動画{no}URL", "動画の埋め込みタグを入力"]
]

export const files = [
    'PDF'
]
.concat([...Array(4).keys()].flatMap(i => [
    `メイン画像${i + 1}`,
    `画像${i + 1}`,
    `動画${i + 1}`,
]))
.concat([...Array(10).keys()].flatMap(i => [
    `添付資料${i + 1}`,
]))

const getData = async () => {
    const ret = await API.get(apiName, '/items/getPressRelease', {})
    return ret.map(c => ({
        ...c,
        公開日 : c.リリース日時 ? new Date(c.リリース日時) : "",
        更新日 : c.更新日_データ連携用 ? new Date(c.更新日_データ連携用) : "", 
        リリース日時 : c.リリース日時 ? new Date(c.リリース日時) : "",
        公開期限日 : c.公開期限日 ? new Date(c.公開期限日) : "",
        更新者 : c.更新者_データ連携用,
        フィードバック依頼 : c.フィードバック依頼,
        アップロード方法1 : uploadOption.find(u => c[u[0].replace("{no}", "1")])?.[1] || "画像をアップロード",
        アップロード方法2 : uploadOption.find(u => c[u[0].replace("{no}", "2")])?.[1] || "画像をアップロード",
        アップロード方法3 : uploadOption.find(u => c[u[0].replace("{no}", "3")])?.[1] || "画像をアップロード",
        アップロード方法4 : uploadOption.find(u => c[u[0].replace("{no}", "4")])?.[1] || "画像をアップロード",
        ...[...Array(4).keys()]
            .filter(i => c[`本文${i}`])
            .reduce((o, i) => ({ ...o, [`本文${i}`] : htmlBeautify(c[`本文${i}`]) }), {}),
        ...files.reduce((a, f) => ({...a, [f] : { url : c[f], name : c[`${f}オリジナルファイル名`] }}), {}),
        サムネイル : c.メイン画像1,
        type : 'プレスリリース',
        to : c.下書き || isAfter(c.リリース日時 ? new Date(c.リリース日時) : "", new Date()) ? `/pressrelease/submission/${c.PDF入稿 ? "pdf" : "html"}/${c.リリースNo}` : `/pressrelease/${c.リリースNo}`,
    }))
}

export const useAllPressRelease = () => {
    return useQuery(['pressrelease'], getData, {
        select : (data) => data.sort((a, b) => b.公開日 - a.公開日)
    })
}

export const usePressReleaseByNo = (no) => {
    return useQuery(['pressrelease'], getData, {
        select : data => data.find(d => d.リリースNo === no)
    })
}

export const useAllPressReleaseOnlyFeedback = () => {
    return useQuery(['pressrelease'], getData, {
        select : data => data.filter(d => d.フィードバックNo)
    })
}

export const usePreviousNext = (no) => {
    const cond = useAtomValue(prsListCond)
    const { data } = useAllPressRelease()
    const [conditionalList, setConditionalList ] = useState([])

    useEffect(() => {
        if (!data || !cond) return
        setConditionalList(
            data?.filter(d => {
                if (cond.状態.includes('公開済み') && (d.下書き || isAfter(d.公開日, new Date()))) return false
                if (cond.状態.includes('下書き') && !d.下書き) return false
                return true
            })
            .sort((a, b) => b[cond.並び順] - a[cond.並び順])
        )
    }, [cond, data, setConditionalList])

    return {
        data : [
            conditionalList[conditionalList.findIndex(d => d.リリースNo === no) - 1],
            conditionalList[conditionalList.findIndex(d => d.リリースNo === no) + 1]
        ]
    }
}

export const usePressReleaseForTop = () => {
    return useQuery(['pressrelease'], getData, {
        select : (data) => data
            .filter(d => !d.下書き && isAfter(new Date(), d.公開日))
            .sort((a, b) => b.リリース日時 - a.リリース日時)
            .slice(0, 3)
    })
}

export const useFeedbacksForTop = () => {
    return useQuery(['pressrelease'], getData, {
        select : (data) => data
            .sort((a, b) => b.リリース日時 - a.リリース日時)
            .slice(0, 2)
            .map((d, i) => {
                if (i === 0) {
                    d.type = 'プレスリリース'
                    d.FBあり = true
                    d.FB未読 = true
                }
                else {
                    d.type = 'ニュースレター'
                    d.FBあり = false
                }
                return d
            })
    })
}


export const useNewFeedbackCount = () => {
    return useQuery(['pressrelease'], getData, {
        select : (data) => data.filter(d => d.feedbacks.some(f => f.新着)).length,
    })
}

export const useNewFeedbackList = () => {
    return useQuery(['pressrelease'], getData, {
        select : (data) => data.filter(d => d.feedbacks.some(f => f.新着))
    })
}


import { useFormContext } from 'react-hook-form'
import { format } from 'date-fns'
import { Stack } from '@mui/material'
import { DeleteButton, EntryButton } from 'components/styledButtons'
import { CircleCheck, StarCheck } from 'components/styledChecks'
import { AccordionDetail, PaginationAccordion } from 'components/Accordion'
import MailAddress from './MailAddress'
import Groups from './Groups'
import DeleteDialog from './DeleteDialog'
import { useDynamoItem } from 'hooks/dynamo'

const MediaListForPad = ({ filteredData, handleMlEditModal, handleCheck, handleDynamoCheck, handleOpenDialog }) => {
    const { data: dynamoData } = useDynamoItem("メディアリストお気に入り")
    const { getValues } = useFormContext()

    return (<>
        <PaginationAccordion
            data={filteredData}
            keyName="メール"
            summaryName="姓名"
            detail={(d, i) => (
                <Stack
                    gap={0.5}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Stack
                            direction="row"
                        >
                            <CircleCheck 
                                checked={getValues().チェックリスト?.some(a => a === d.メール) || false} 
                                onChange={e => handleCheck(e, d, i)} 
                            />
                            <StarCheck
                                checked={dynamoData?.some(m => m === d.メール) || false}
                                onChange={e => handleDynamoCheck(e, d)}
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            gap={1}
                        >
                            {/* メディアリスト設定モーダルをオープンする */}
                            <EntryButton onClick={() => handleMlEditModal(d)}>編集</EntryButton>
                            {d.リスト種類 === '企業作成' &&
                            <DeleteButton onClick={() => handleOpenDialog(d)}>削除</DeleteButton>
                            }
                        </Stack>
                    </Stack>
                        <AccordionDetail label="媒体種類" value={d.媒体種類} />
                        <AccordionDetail label="媒体名" value={d.媒体名} />
                        <AccordionDetail label="TEL" value={d.TEL} />
                        <AccordionDetail label="メール" value={<MailAddress>{d.メール}</MailAddress>} />
                        <AccordionDetail label="携帯電話" value={d.携帯電話} />
                        <AccordionDetail label="部署名" value={d.部署名} />
                        <AccordionDetail label="アプローチ日" value={d.アプローチ日 ? `${format(d.アプローチ日, 'yyyy年')}\n${format(d.アプローチ日, 'MM月dd日')}` : ''} />
                        <AccordionDetail label="グループ" value={<Groups data={d.グループ} />} />
                </Stack>
            )}
        >
        </PaginationAccordion>
        <DeleteDialog />
    </>)
}

export default MediaListForPad

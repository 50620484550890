import { Stack } from '@mui/material'
import { NodecorationLink } from 'components/links'
import S3Image from 'components/S3Image'
import { CountBadge } from 'components/styledLabels'

export const ThumbnailNoLink = ({ src, width=99, height=66, newly, midoku }) => (
    <Stack
        position="relative"
    >
        <Stack
            alignItems="center"
            justifyContent="center"
            width={width}
            height={height}
            flexShrink={0}
        >
            <S3Image
                url={src}
            />
        </Stack>
        {(newly || midoku) &&
        <CountBadge
            sx={{
                position : "absolute",
                top : -4,
                left : -4,
            }}
        >
            {newly ? "NEW" : "未読"}
        </CountBadge>
        }
    </Stack>
)

const Thumbnail = ({ src, width=99, height=66, to="#", newly, midoku, click }) => (
    <NodecorationLink
        to={to}
        onClick={click}
    >
        <ThumbnailNoLink 
            src={src}
            width={width}
            height={height}
            newly={newly}
            midoku={midoku}
        />
    </NodecorationLink>
)

export default Thumbnail

import { Stack, Divider } from '@mui/material'
import { useLoginUser } from 'hooks/loginuser'
import { H5 } from 'components/styledFonts'
import { LabelValue } from './Settings'

const PersonalBase = () => {
    const { data } = useLoginUser()

    if (!data) return null

    return (
        <>
            <Stack
                justifyContent="space-between"
                sx={{
                    flexDirection : { sm : "row", xs : "column" },
                }}
                rowGap={1}
            >
                <Stack
                    justifyContent="center"
                    sx={{
                        width : { sm : "25%", xs : "100%" },
                    }}
                >
                    <H5>基本情報</H5>
                </Stack>
                <Stack
                    sx={{
                        width : { sm : "75%", xs : "100%" }
                    }}
                >
                    <LabelValue label="氏名" value={`${data.姓} ${data.名}`} />
                    <LabelValue label="所属部署" value={data.所属部署} />
                    <LabelValue label="役職" value={data.役職} />
                </Stack>
            </Stack>
            <Divider />
        </>
    )
}

export default PersonalBase

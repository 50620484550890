import { Link, Stack, Box } from '@mui/material'
import {
    TabsUnstyled as Tabs,
    TabPanelUnstyled as TabPanel,
} from '@mui/base'
import { H3, Body } from 'components/styledFonts'
import { TitledBlock } from 'components/Block'
import MovieThumbnail from './MovieThumbnail'
import { useStreamingList } from 'hooks/streaming'
import { reactPlayerModalAtom } from 'store/commonAtoms'
import { useAtom } from 'jotai'
import VideoTab from 'components/VideoTab'
import { format } from 'date-fns'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

const MovieListMobile = () => {
    const [, setVideo] = useAtom(reactPlayerModalAtom)
    const { data } = useStreamingList()

    if (!data || data.length === 0) return null

    return (
        <TitledBlock
            title={'動画一覧'}
        >
            <Stack>
                <Tabs
                    defaultValue={0}
                >
                    <Stack
                        gap={2}
                    >
                        <VideoTab
                            data={data}
                        />
                        {data?.map((d, i) => (
                        <TabPanel
                            key={i}
                            value={i}
                        >
                            <Stack
                                gap={4}
                            >
                                {d.data.map((d, i) => (
                                <Stack
                                    key={i}
                                    gap={1}
                                >
                                    <H3>{d.カテゴリ}</H3>
                                    <Stack>
                                        <Stack
                                            direction="row"
                                            flexWrap="wrap"
                                            columnGap={2}
                                            justifyContent="space-between"
                                        >
                                            {d.list.map((v, i) => (
                                            <Stack
                                                key={i}
                                                gap={0.5}
                                                width={150}
                                            >
                                                <Box
                                                    height={85}
                                                >
                                                    <MovieThumbnail
                                                        data={v}
                                                        width="100%"
                                                        height="100%"
                                                        click={() => setVideo(v)}
                                                    />
                                                </Box>
                                                <Body>
                                                    {format(v.ライブ配信終了日時, 'yyyy/MM/dd HH:mm')}
                                                </Body>
                                                <Link
                                                    key={i}
                                                    href={"#"}
                                                    onClick={() => setVideo(v)}
                                                    underline="hover"
                                                >
                                                    {v.タイトル}
                                                </Link>
                                                {v.資料ダウンロード先 ?
                                                <Link
                                                    href={v.資料ダウンロード先}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="grey.700"
                                                >
                                                    <SaveAltIcon />
                                                </Link>
                                                : null
                                                }
                                            </Stack>
                                            ))}
                                        </Stack>
                                    </Stack>
                                </Stack>
                                ))}
                            </Stack>
                        </TabPanel>
                        ))}
                    </Stack>
                </Tabs>
            </Stack>
        </TitledBlock>
    )
}

export default MovieListMobile

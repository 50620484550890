import './global.js'
import { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { Box, FormHelperText } from '@mui/material'
import 'react-trumbowyg/dist/trumbowyg.min.css'
import Trumbowyg from 'react-trumbowyg'
import { Body } from 'components/styledFonts'
import { ScrollPosition } from 'components/Inputs'

const WysiwygEditor = ({ name, control, limit, initValue }) => {
    const { field: { ref }, fieldState : { error } } = useController({ name, control })
    const [data, setData] = useState(initValue)
    const [flag, setFlag] = useState(false)
    const [length, setLength] = useState(0)


    // 更新時は初期値が非同期で反映されるので、1度だけstateに設定するようにする
    useEffect(() => {
        if (!initValue || flag) return
        setFlag(true)
        setData(initValue)
        setLength(initValue.length)
    }, [initValue, flag])

    // autogrowの指定が効かないので、style属性で設定されているheightを削除
    useEffect(() => {
        window.$(`#${name}`).removeAttr('style')
    })

    const handleChange = e => {
        // 入力操作が重くなるため、setValueではなくstateに保存

        // 好ましくないが、ハンドリング出来ないためjQueryによりエディタのモード(html/rich)を判定
        const $this = window.$(`#${name}`)
        if ($this.parent().find('.trumbowyg-viewHTML-button').hasClass('trumbowyg-active')) {
            const html = $this.prev().val()
            setLength(html.length)
            $this.empty().html(html)
        }
        else {
            setLength(e.target.innerHTML.length)
        }
    }


    return (
        <Box
            className={`innerHTML ${!!error && 'error'}`}
            position="relative"
            // 日本語入力で文字確定時にはonChangeイベントが発火しないので、IMEオフタイミングでstateに保存する
            onCompositionEnd={handleChange}
        >
            <ScrollPosition ref={ref} sx={{ top : 250 }} />
            <Trumbowyg
                id={name}
                data={data}
                autogrow={true}
                buttons={[
                    ['viewHTML'],
                    ['undo', 'redo'],
                    ['formatting'],
                    ['strong', 'em', 'del'],
                    ['superscript', 'subscript'],
                    ['link'],
                    ['insertImage'],
                    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                    ['unorderedList', 'orderedList'],
                    ['horizontalRule'],
                    ['removeformat'],
                    ['fullscreen']
                ]}
                onChange={handleChange}
            />
            {limit > 0 && <Body>※{limit}字以内（残り{limit - length}文字）文字数はhtml形式でのカウントです</Body>}
            {!!error && <FormHelperText error>{error.message}</FormHelperText>}
        </Box>
    )
}

export default WysiwygEditor

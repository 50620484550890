import { Box, Stack } from '@mui/material'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import { Provider } from 'jotai'
import CancelDialog from 'dialogs/CancelDialog'
import { useAuthorization } from 'hooks/authorization'
import { useAllResearches } from 'hooks/research'
import FlashMessage from './FlashMessage'
import Footer from './Footer'
import MenuBar from './MenuBar'
import Notification from './Notification'
import Spinner from './Spinner'

const PageBase = () => {
    useAuthorization()
    useAllResearches()

    return (
        <Provider>
            <MenuBar />
            <Notification />
            <Stack
                alignItems="center"
                sx={{
                    bgcolor : "grey.100",
                    minHeight : { sm : "calc(100vh - 160px)", xs : "calc(100vh - 196px)" },
                    pt : 4,
                    px : 1,
                }}
            >
                <Box
                    sx={{
                        maxWidth : { lg : 1212 },
                        width : "100%",
                        pb : 4,
                    }}
                >
                    <Outlet />
                </Box>
            </Stack>
            <Footer />
            <CancelDialog />
            <FlashMessage />
            <Spinner />
            <ScrollRestoration />
        </Provider>
    )
}

export default PageBase

import { Stack, useMediaQuery } from '@mui/material'
import { grey } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import { useCompanyInfo } from 'hooks/company'

export const LogoBox = styled(Stack)({ 
    fontSize : 30,
    color : 'white',
    width : 158,
    height : 50,
    alignItems : "center",
    justifyContent : "center",
    objectFit : "contain",
})

const CompanyLogo = () => {
    const { data } = useCompanyInfo()
    const narrow = useMediaQuery('@media screen  and (max-width:450px)')

    if (!data) return null

    return (<>
        {!narrow && (data.会社ロゴ ?
        <LogoBox
            component="img"
            src={data.会社ロゴ}
        />
        :
        <LogoBox
            bgcolor={grey[400]}
        >
            未設定
        </LogoBox>
        )}
    </>)
}

export default CompanyLogo


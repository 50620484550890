import { Stack } from '@mui/material'
import { blue } from '@mui/material/colors'
import { Lead, H2 } from 'components/styledFonts'
import ComponentBase from './ComponentBase'
import { useCompanyInfo } from 'hooks/company'
import { useContacts } from 'hooks/contact'


const border = `solid 1px ${blue[200]}`

const LV = ({ data }) => (
    <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        border={border}
        borderRadius={1}
        py={1}
        px={3}
    >
        <Lead
            sx={{
                color : blue[500],
            }}
        >
            {data.label}
        </Lead>
        <H2
            sx={{
                color : blue[300],
            }}
        >
            {`${data.num}名`}
        </H2>
    </Stack>
)

const Contact = () => {
    const data = useContacts()
    const { data: company } = useCompanyInfo()

    return (
        <ComponentBase
            title="獲得したメディア接点"
            buttons={company?.メディアリスト作成機能 ? [{ label : 'メディアリスト', to : '/medialist' }] : []}
        >
            <Stack
                pb={1}
                gap={1.5}
            >
                {data?.map((d, i) => (
                <LV
                    key={i}
                    data={d}
                />
                ))}
            </Stack>
        </ComponentBase>
    )
}

export default Contact

import { Box, Stack } from '@mui/material'
import { H1, H2 } from 'components/styledFonts'
import Block from 'components/Block'
import InquiryItem from 'components/InquiryItem'
import { useIntroduction, useRecommendation, useStory } from 'hooks/inquiry'


const InquiryTop = () => {
    const { data : intoro } = useIntroduction()
    const { data : recomm } = useRecommendation()
    const { data : story } = useStory()

    return (
        <Stack
            gap={2}
        >
            <H1>企業探訪設定</H1>
            <Block>
                <Stack
                    gap={2}
                >
                    <H2>企業探訪</H2>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        rowGap={5}
                        py={2}
                    >
                        <Box width={380}>
                            <InquiryItem
                                category="社長ストーリー"
                                data={story}
                                link="/inquiry/story"
                            />
                        </Box>
                        <Box width={380}>
                            <InquiryItem
                                category="こんな会社"
                                data={intoro}
                                link="/inquiry/intoroduction"
                            />
                        </Box>
                        <Box width={380}>
                            <InquiryItem
                                category="イチオシ！"
                                data={recomm}
                                link="/inquiry/recommendation"
                            />
                        </Box>
                    </Stack>
                </Stack>
            </Block>
        </Stack>
    )
}

export default InquiryTop

import { useQuery } from "@tanstack/react-query"
import { API } from "lib/api"
import { apiName } from "config/api"

const getQAndAInfo = (questionData) => {
    const answer = {}
    const scores = {} 
    const sQuestion = {}

    if (questionData) {
        questionData.質問名
            .forEach((t) => {
                let score = 0
                const questionIndex = []
                questionData[t].質問リスト.forEach((q, i) => {
                    answer[`${q.回答キー}`] = q.回答
                    if (q.回答 === 'はい') score += q.配点
                    if (q.特殊採点 !== '') questionIndex.push(i+1)
                })
                scores[t] = score
                sQuestion[t] = questionIndex.join(' ,')
            })
    }

    return { scores, answer, sQuestion }
}

const getNumberAndTitle = (targetTitles) => {
    return targetTitles.map((t, i) => ({ num: i + 1, title: t }))
}

const getTitles = (pranalysis) => {
    return Array.from(new Set(pranalysis
        .toReversed()
        .flatMap(t => t.質問名)
    ))
}

const getPRData = async () => {
    const ret = await API.get(apiName, "/items/getPRAnalysis", {})
    // PR活動診断：０件（下書き：０件、回答完了：０件）
    ret.noPRData = ret.pranalysis.length === 0 ? true : false
    // 完了されたPR活動診断：０件（下書き：１件、回答完了：０件）
    ret.noAnswerCompletedData = (ret.pranalysis.length === 1 && !(Object.keys(ret.master).length === 0 || ret.master.レコード番号)) ? true : false
    return ret
}

export const usePRAnalysisAll = () => {
    return useQuery(["pranalysis"], getPRData, {})
}

export const usePRRadarChart = (targetPrIndex) => {
    return useQuery(["pranalysis"], getPRData, {
        select : (data) => { 
            // 過去全ての質問の配点の最大値をグラフの頂点の最大点数とする
            const domain = Math.max(...data.pranalysis.flatMap(p => p.質問名.map(q => p[q].合計配点)))

            // PR活動診断：０件
            if (data.noPRData || data.noAnswerCompletedData) return { titles : ['', '', ''], pranalysis : [], domain : 10 } 

            if (targetPrIndex >= 0) { // 過去回答画面
                const titles = getTitles(data.pranalysis.slice(0, targetPrIndex))
                return { titles, pranalysis : data.pranalysis.slice(0, targetPrIndex), domain } 
            } else {
                const pranalysis = data.master.レコード番号 
                ? data.pranalysis // 下書きなし
                : data.pranalysis.slice(0, data.pranalysis.length - 1) // 下書きあり
                const titles = getTitles(pranalysis)
                return { titles, pranalysis, domain } 
            }
        }
    })
}

export const usePRBarChart = (targetPrIndex) => {
    return useQuery(['pranalysis'], getPRData, {
        select: (data) => {
            // PR活動診断：０件
            if (data.noPRData || data.noAnswerCompletedData) return { titles : ['', '', ''], pranalysis : {} } 

            if (targetPrIndex >= 0) { // 過去回答画面
                const titles = getNumberAndTitle(data.pranalysis[targetPrIndex - 1]?.質問名)
                return { titles, pranalysis: data.pranalysis[targetPrIndex - 1] }
            } else {
                // 該当期間内の回答を完了した場合 - マスタ値：なし
                const pranalysis = Object.keys(data.master).length === 0 || data.master.レコード番号
                    ? data.pranalysis[data.pranalysis.length - 1] // 下書きなし
                    : data.pranalysis[data.pranalysis.length - 2] // 下書きあり
                if (!pranalysis) return { titles : ['', '', ''], pranalysis : {} } 

                const titles = getNumberAndTitle(pranalysis.質問名)
                return { titles, pranalysis }
            }
        }
    })
}

export const usePRQuestions = (targetPrIndex) => {
    return useQuery(['pranalysis'], getPRData, {
        select: (data) => {
            if (targetPrIndex >= 0) { // 過去回答画面
                // PR活動診断：０件
                if (data.noAnswerCompletedData) return { titles : [], questionData : [], isHistory : true, scores : {}, answer : {}, sQuestion : {} }

                const titles = getNumberAndTitle(data.pranalysis[targetPrIndex - 1]?.質問名)
                const { scores, answer } = getQAndAInfo(data.pranalysis[targetPrIndex - 1])
                const questionData = data.pranalysis[targetPrIndex - 1]
                return { titles, questionData, isHistory : true, scores, answer, sQuestion : {} }
            } else { 
                const questionData = data.master.レコード番号 ? data.master : data.pranalysis.at(-1) // 下書きなし : 下書きあり
                const titles = questionData?.質問名.map((t, i) => ({ num: i + 1, title: t }))
                const { scores, answer, sQuestion } = getQAndAInfo(questionData)
                return { titles, questionData, isHistory : false, scores, answer, sQuestion }
            }
        }
    })
}

export const usePRAnalysisHistory = () => {
    return useQuery(['pranalysis'], getPRData, {
        select : (data) =>{
            const prList = Object.keys(data.master).length === 0 || data.master.レコード番号 
                ? data.pranalysis // 下書き以外の場合
                : data.pranalysis.slice(0, data.pranalysis.length - 1)// 下書き状態の回答は選択肢に含まれない

            // PR活動診断：０件
            if (prList.length === 0) return [{ value : 0, label : "--------" }]

            return prList.map((p, i) => ({ value : i + 1, label : `${p.診断回}回目(${p.PR活動診断日})` }))
        }          
    })  
 }

import { Stack, useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Block from 'components/Block'
import CategorySelect from 'components/CategorySelect'
import FeedbackCheckbox from 'components/FeedbackCheckbox'
import { SelectPDF, SelectMovie, SelectAttachment } from 'components/FileUploader'
import GenreSelect from 'components/GenreSelect'
import {
    CharacterLimitField,
    DatePicker,
    ImageCaption,
    LabeledInputField,
    RadioGroupBase,
    Required,
    TextField,
} from 'components/Inputs'
import InquiryUserInput from 'components/InquiryUserInput'
import PostButtonSet from 'components/PostButtonSet'
import { H1, Body } from 'components/styledFonts'
import { featureCategory } from 'config/options'
import { useAccounts } from 'hooks/account'
import { usePressReleaseByNo } from 'hooks/pressrelease'
import { defaultValues, maxLength } from './PressReleaseSubmissionBase'

const PressReleasePdfSubmission = () => {
    const { getValues, setValue, handleSubmit, watch, control, onSubmit, saveDraft } = useFormContext()
    const { no } = useParams()
    const { data } = usePressReleaseByNo(no)
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('mobile'))
    const { data : accountData } = useAccounts()

    useEffect(() => {
        if (!data || !accountData) {
            setValue("PDF入稿", true)
            return 
        }
        if (getValues("リリースNo")) return
        Object.keys(defaultValues).forEach(n => setValue(n, data[n]))
        setValue("リリースNo", no)
        setValue("PDF入稿", true)
        const inquiryUserId = accountData.find(d => d.姓 + d.名 === data.問い合わせ担当者_上書き) 
        setValue("問い合わせ担当者_上書き", inquiryUserId?.企業担当者ID || "")
    }, [data, accountData, no, setValue, getValues])

    return (
        <Stack
            gap={2}
        >
            <H1>{`プレスリリース${watch("リリースNo") ? "編集" : "新規作成"}（PDF入稿）`}</H1>
            <Block>
                <Body sx={{ pb : 2 }}><Required />がついている項目は必須です</Body>
                <Stack
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    gap={4}
                    pb={2}
                    sx={{ 
                        pl : { mobile : 2, xs : 0 },
                        pr : { mobile : 8, xs : 0 },
                    }}
                >
                    <CharacterLimitField
                        label="タイトル文"
                        name="タイトル"
                        limit={maxLength.タイトル}
                        control={control}
                        required
                        multiline
                        disableEnter
                        watch={watch('タイトル')}
                    />
                    <LabeledInputField
                        label="メイン画像"
                        note="※一覧ページでサムネイルとしても表示されます。"
                        required
                    >
                        <ImageCaption
                            name="メイン画像1"
                            captionName="メイン画像1キャプション"
                            pict={watch('メイン画像1')}
                            control={control}
                            setValue={setValue}
                        />
                    </LabeledInputField>
                    <LabeledInputField
                        label="PDF"
                        required
                    >
                        <SelectPDF
                            name="PDF"
                            label="PDF"
                            file={watch('PDF')}
                            removable={watch('PDF')}
                            control={control}
                            setValue={setValue}
                            showName
                        />
                    </LabeledInputField>
                    <LabeledInputField
                        label="資料添付"
                    >
                    {[...Array(10).keys()].map((k, i, a) => {
                    return (
                        <SelectAttachment
                            key={i}
                            name={`添付資料${i + 1}`}
                            label="資料添付"
                            file={watch(`添付資料${i + 1}`)}
                            removable={a.length === i + 1 || a.findIndex((c, ci) => !watch(`添付資料${ci + 1}`)?.name) === i + 1}
                            hidden={i !== 0 && !watch(`添付資料${i}`)?.name}
                            control={control}
                            setValue={setValue}
                            showName
                        />
                    )})}
                    </LabeledInputField>
                    <LabeledInputField
                            label="画像 / 動画"
                            note="※画像は最大5MB、動画は最大50MBまでアップロード可能です。"
                        >
                            <RadioGroupBase
                                value={watch(`アップロード方法1`)}
                                onChange={e => setValue(`アップロード方法1`, e.target.value)}
                                row={!isMobile}
                                sx={{
                                    columnGap : 0,
                                }}
                                list={[
                                    { label : '画像をアップロード', value : '画像をアップロード',
                                    disabled : !!(
                                        watch(`動画1`)?.url || watch(`動画1`)?.blob
                                        || watch(`動画1URL`)
                                    ) },
                                    { label : '動画をアップロード', value : '動画をアップロード',
                                    disabled : !!(
                                        watch(`画像1`)?.url || watch(`画像1`)?.blob 
                                        || watch(`動画1URL`)
                                    ) },
                                    { label : '動画の埋め込みタグを入力', value : '動画の埋め込みタグを入力',
                                    disabled : !!(
                                        watch(`画像1`)?.url || watch(`画像1`)?.blob
                                        || watch(`動画1`)?.url || watch(`動画1`)?.blob
                                    ) }
                                ]}
                            />
                        {watch(`アップロード方法1`) === "画像をアップロード" &&
                        <ImageCaption
                            name={`画像1`}
                            captionName={`画像1キャプション`}
                            pict={watch(`画像1`)}
                            control={control}
                            setValue={setValue}
                        />      
                        }
                        {watch(`アップロード方法1`) === "動画をアップロード" &&
                        <SelectMovie
                            name={`動画1`}
                            file={watch(`動画1`)}
                            control={control}
                            setValue={setValue}
                            showName
                            removable
                        />      
                        }
                        {watch(`アップロード方法1`) === "動画の埋め込みタグを入力" && <>
                        <TextField
                            name={`動画1URL`}
                            control={control}
                            watch={watch(`動画1URL`)}
                            placeholder="YouTube動画の埋め込みタグを貼り付けてください。"
                        />
                        <Body>{"YouTube動画の「共有メニュー > 埋め込む」から取得できる、埋め込み用タグをコピーアンドペーストしてください。"}</Body>
                        </>}
                    </LabeledInputField>
                    <CharacterLimitField
                        label="検索キーワード"
                        name="検索キーワード"
                        limit={maxLength.検索キーワード}
                        control={control}
                        required
                        disableEnter
                        watch={watch('検索キーワード')}
                        note="※プレスリリースの内容に関連あるワードのみ半角スペース区切りで入力してください"
                    />
                    <InquiryUserInput
                        control={control}
                        setValue={setValue}
                        note="企業情報設定と異なる方を問い合わせ担当者にする際は入力してください"
                    />
                    <LabeledInputField
                        label="リリース公開日時"
                        required
                    >
                        <DatePicker
                            name="リリース日時"
                            control={control}
                            showTimeInput
                        />
                    </LabeledInputField>
                    {featureCategory.some(c => c === watch("カテゴリ")) &&
                    <LabeledInputField
                        label="リリース公開期限"
                        required
                        note={'※ 公開期限を設定すると、指定した日時まで記事が公開されます。\n※「読者プレゼント」「記者発表会」を選択した場合は必須です。'}
                    >
                        <DatePicker
                            name="公開期限日"
                            control={control}
                            showTimeInput
                        />
                    </LabeledInputField>
                    }
                    <GenreSelect control={control} />
                    <CategorySelect control={control} setValue={setValue} pressRelease />
                    <FeedbackCheckbox
                        name={"フィードバック依頼"}
                        disabled={!!watch("フィードバックNo")}
                        control={control}
                    />
                </Stack>
            </Block>
            <PostButtonSet
                handleSubmit={handleSubmit}
                watch={watch}
                saveDraft={saveDraft}
                isFeedback={watch("フィードバック依頼") && !watch("フィードバックNo")}
                pressRelease
            />
        </Stack>
    )
}

export default PressReleasePdfSubmission

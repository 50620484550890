import { Link, Stack, Box } from '@mui/material'
import { TabsUnstyled as Tabs, TabPanelUnstyled as TabPanel } from '@mui/base'
import { grey } from '@mui/material/colors'
import { format } from 'date-fns'
import { useAtom } from 'jotai'
import { H3, Body } from 'components/styledFonts'
import { TitledBlock } from 'components/Block'
import MovieThumbnail from './MovieThumbnail'
import { useStreamingList } from 'hooks/streaming'
import { reactPlayerModalAtom } from 'store/commonAtoms'
import VideoTab from 'components/VideoTab'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

const border = `1px solid ${grey[400]}`

const Row = ({ title, expiration, dl, isLast, sx }) => (
    <Stack
        direction="row"
        alignItems="center"
        border={border}
        borderBottom={isLast ? border : 0}
        py={1}
        px={2}
        sx={sx}
    >
        <Box flexGrow={1}>
            <Body>{title}</Body>
        </Box>
        <Box width={200}>
            <Body>{expiration}</Body>
        </Box>
        <Box width={60}>
            <Body>{dl}</Body>
        </Box>
    </Stack>
)

const Header = () => (
    <Row
        title="タイトル"
        expiration="公開期限"
        dl="資料DL"
        sx={{ bgcolor: grey[100] }}
    />
)

const MovieList = () => {
    const [, setVideo] = useAtom(reactPlayerModalAtom)
    const { data } = useStreamingList()

    console.log('dataDl', data);


    if (!data || data.length === 0) return null
    return (
        <TitledBlock
            title={'動画一覧'}
        >
            <Stack
                px={2}
            >
                <Tabs
                    defaultValue={0}
                >
                    <Stack
                        gap={2}
                    >
                        <VideoTab
                            data={data}
                        />
                        {data?.map((d, i) => (
                        <TabPanel
                            key={i}
                            value={i}
                        >
                            <Stack
                                gap={4}
                            >
                                {d.data.map((d, i) => (
                                <Stack
                                    key={i}
                                    gap={1}
                                >
                                    <H3>{d.カテゴリ}</H3>
                                    <Stack
                                        px={2}
                                    >
                                        <Header />
                                        {d.list.map((v, i) => (
                                        <Row
                                            key={i}
                                            title={
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    gap={2}
                                                >
                                                    <MovieThumbnail
                                                        click={() => setVideo(v)}
                                                        data={v}
                                                    />
                                                    <Link
                                                        href={'#'}
                                                        onClick={() => setVideo(v)}
                                                    >
                                                        {v.タイトル}
                                                    </Link>
                                                </Stack>
                                            }
                                            expiration={
                                                format(v.ライブ配信終了日時, 'yyyy年MM月dd日 HH:mm')
                                            }
                                            dl={v.資料ダウンロード先 ?
                                                <Link
                                                    href={v.資料ダウンロード先}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    color="grey.700"
                                                >
                                                    <SaveAltIcon />
                                                </Link>
                                                : null
                                            }
                                            isLast={i === d.list.length - 1}
                                        />
                                        ))}
                                    </Stack>
                                </Stack>))}
                            </Stack>
                        </TabPanel>
                        ))}
                    </Stack>
                </Tabs>
            </Stack >
        </TitledBlock >
    )
}

export default MovieList

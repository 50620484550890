import { forwardRef, useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup as MuiRadioGroup,
    Select as MuiSelect,
    Stack,
    TextField as MuiTextField,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { styled } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import { Body, H5 } from 'components/styledFonts'
import { SelectImage } from 'components/FileUploader'

registerLocale('ja', ja)

const StyledText = styled(MuiTextField)({
    '& .MuiOutlinedInput-input': {
        backgroundColor: '#fff',
    },
})

export const Required = () => <Body component="span" color={red[400]} pl={0.3}>*</Body>

export const TextFieldBase = ({ ...props }) => {
    return (
        <StyledText
            size="small"
            {...props}
        />
    )
}

export const TextField = ({ name, control, ...props }) => {
    const { field: { ref, ...rest }, fieldState: { error } } = useController({ name, control })

    return (<>
        <TextFieldBase
            name={name}
            inputRef={ref}
            error={!!error}
            {...props}
            {...rest}
        />
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const LabeledTextField = ({ name, label, remarks, control, required, note, endAdornment, ...props }) => {
    return (
        <Stack
            width={props.fullWidth ? "100%" : "auto"}
            gap={0.5}
        >
            <H5>
                {label || name}
                {required &&
                    <Required />
                }
            </H5>
            <Body>{remarks}</Body>
            {note &&
                <Body>{note}</Body>
            }
            <TextField
                name={name}
                control={control}
                {...props}
                InputProps={{
                    endAdornment: endAdornment && <InputAdornment position="start">{endAdornment}</InputAdornment>,
                }}
            />
        </Stack>
    )
}

export const LabeledInputField = ({ label, children, required, note }) => (
    <Stack
        gap={0.5}
    >
        <H5>{label}{required ? <Required /> : ''}</H5>
        {note &&
            <Body whiteSpace="pre-wrap">{note}</Body>
        }
        {children}
    </Stack>
)

export const CharacterLimitField = ({ limit, disableEnter, watch, ...props }) => {
    const [count, setCount] = useState(0)

    useEffect(() => setCount((watch || '').length), [watch])

    if (disableEnter || limit) {
        const inputProps = {}
        if (limit) inputProps.maxLength = limit
        if (disableEnter) inputProps.onKeyDown = e => {
            if (e.key === 'Enter') {
                e.preventDefault()
            }
        }
        props.inputProps = inputProps
    }

    return (
        <Stack
        >
            <LabeledTextField
                {...props}
            />
            <Body>※{limit}字以内（残り{limit - count}文字）</Body>
        </Stack>
    )
}

export const ImageCaption = ({ name, setValue, captionName, control, pict, hidden }) => {
    return (
        <Stack gap={1}>
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
            >
                <SelectImage
                    name={name}
                    control={control}
                    file={pict}
                    setValue={setValue}
                    showName
                    removable
                    hidden={hidden}
                />
            </Stack>
            {pict?.name &&
                <TextField name={captionName} control={control} placeholder="写真のキャプションを入力してください。"></TextField>
            }
        </Stack>
    )
}

export const SelectBase = forwardRef(({ list, onChange, fullWidth, ...props }, ref) => {
    return (
        <FormControl
            ref={ref}
            size="small"
            fullWidth={fullWidth}
        >
            <MuiSelect
                displayEmpty
                sx={{ fontSize : 14 }}
                onChange={onChange}
                {...props}
            >
                {list?.map((l, i) => (
                <MenuItem
                    key={i}
                    value={l.value}
                    sx={{ fontSize : 14 }}
                >
                    {l.label}
                </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    )
})

export const Select = ({ name, control, list, handleChange, ...props }) => {
    const { field: { ref, onChange, ...rest }, fieldState: { error } } = useController({ name, control })

    return (<>
        <SelectBase
            list={list}
            ref={ref}
            onChange={e => {
                onChange(e)
                if (handleChange) handleChange(e)
            }}
            {...rest}
            {...props}
        />
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const RadioGroupBase = forwardRef(({ list, gap, error, fullWidth, onChange, disabled, ...props }, ref) => {
    return (
        <FormControl
            error={!!error}
            fullWidth={fullWidth}
            ref={ref}
        >
            <MuiRadioGroup
                sx={{ gap : gap }}
                onChange={onChange}
                {...props}
            >
                {list.map((r, i) => (
                <FormControlLabel
                    key={i}
                    value={r.hasOwnProperty('value') ? r.value : r}
                    label={<Body>{r.label || r}</Body>}
                    control={<Radio size="small"/>}
                    disabled={r.hasOwnProperty('disabled') ? r.disabled : disabled}
                />
                ))}
            </MuiRadioGroup>
        </FormControl>
    )
})

export const RadioGroup = ({ name, control, list, handleChange, disabled = false, ...props }) => {
    const { field: { ref, onChange, ...rest }, fieldState: { error } } = useController({ name, control })

    return (<>
        <RadioGroupBase
            list={list}
            gap={1}
            ref={ref}
            error={error}
            fullWidth
            onChange={e => {
                onChange(e)
                if (handleChange) handleChange(e)
            }}
            disabled={disabled}
            {...rest}
            {...props}
        />
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const CheckboxGroupBase = forwardRef(({ list, value, boolean, max, sx, onChange, row=true, ...props }, ref) => {
    return (
        <FormGroup
            ref={ref}
            onChange={onChange}
            row={row}
            {...props}
        >
            {list.map(v => (
            <FormControlLabel
                key={v.value || v}
                label={<Body>{v.label || v}</Body>}
                control={<Checkbox
                    value={v.value || v}
                    size="small"
                    color="primary"
                    checked={boolean ? value : value.includes(v.value || v)}
                    disabled={!boolean && !value.includes(v.value || v) && value.length >= max}
                />}
                {...props}
                sx={{
                    mr : 3,
                    '& .MuiFormControlLabel-label' : {
                        ml : -0.3,
                    },
                    ...sx,
                }}
            />
            ))}
        </FormGroup>
    )
})

export const CheckboxGroup = ({ name, control, list, row = true, max, sx, boolean, ...props }) => {
    const { field: { ref, value, onChange, ...rest }, fieldState: { error } } = useController({ name, control })
    const handleChange = e => {
        if (e.target.checked) {
            return [...new Set([...value, e.target.value])]
        }
        else {
            return value.filter(v => v !== e.target.value)
        }
    }

    const handleBooleanChange = e => {
        return e.target.checked
    }

    return (<>
        <CheckboxGroupBase
            list={list}
            ref={ref}
            row={row}
            value={value}
            boolean={boolean}
            max={max}
            sx={sx}
            onChange={e => onChange(boolean ? handleBooleanChange(e) : handleChange(e))}
            {...rest}
            {...props}
        />
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const DatePicker = ({ name, control, ...props }) => {
    const { field: { ref, value, ...rest }, fieldState: { error } } = useController({ name, control })

    return (<>
        <FormControl error={!!error}
            sx={{
                '& .MuiInputBase-root input': {
                    p: '8.5px 14px',
                },
            }}
        >
            <ReactDatePicker
                locale="ja"
                showIcon
                dateFormat={props.showTimeInput ? 'yyyy/MM/dd HH:mm' : 'yyyy/MM/dd'}
                timeInputLabel="時間："
                selected={value}
                customInput={<StyledText error={!!error} inputRef={ref} />}
                {...rest}
                {...props}
            />
        </FormControl>
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const SearchWord = ({ value, handleChange, handleClick, placeholder, ...props }) => {

    return (
        <Box
            pb={1}
            maxWidth={400}
            {...props}
        >
            <MuiTextField
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClick}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                size="small"
                fullWidth
            />
        </Box>
    )
}

export const SearchWord2 = ({ name, control, ...props }) => {
    const { field: { ref, ...rest } } = useController({ name, control })

    return (
        <Box
            pb={1}
            maxWidth={400}
            {...props}
        >
            <MuiTextField
                name={name}
                inputRef={ref}
                size="small"
                fullWidth
                {...props}
                {...rest}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    )
}

// エラー時のスクロール用
// 親要素はpositon="relative"にする必要あり
// このコンポーネントのrefにuseControllerのrefを設定
// top値をsxにて調整する
export const ScrollPosition = styled('input')({
    position : "absolute",
    top : 100,
    zIndex : -1,
    height : 0,
    width : 0,
})


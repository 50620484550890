import { Link } from 'react-router-dom'
import { useAtom } from 'jotai'
import { format, isWithinInterval } from 'date-fns'
import { Stack } from '@mui/material'
import { EntryButton } from 'components/styledButtons'
import { H3, H5, Body } from 'components/styledFonts'
import { WhiteLabel } from 'components/styledLabels'
import { useMediaSeminarForTop } from 'hooks/program'
import { reactPlayerModalAtom } from 'store/commonAtoms'
import ComponentBase from './ComponentBase'
import MediaSeminarThumbnail from './MediaSeminarThumbnail'


const _format = d => format(d, 'MM月dd日 HH:mm')
const period = d => `公開期間：${_format(d.開始日時)}～${_format(d.終了予定日時)}`
const isShowing = d => isWithinInterval(new Date(), { start: d.開始日時, end: d.終了予定日時 })


const MediaSeminar = () => {
    const data = useMediaSeminarForTop()
    const [, setVideo] = useAtom(reactPlayerModalAtom)

    const clickThumbnail = () => {
        if (isShowing(data[0])) setVideo(data[0].動画)
    }

    return (
        <ComponentBase
            title="メディアセミナー情報"
        >
            {data && (
            <Stack
                gap={5}
            >
                <Stack
                    alignItems="flex-start"
                    gap={1}
                >
                    <H3>{isShowing(data[0]) ? "現在公開中" : "次回開催"}</H3>
                    <WhiteLabel>{period(data[0])}</WhiteLabel>
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        gap={2}
                    >
                        <Stack
                            flexShrink={0}
                            sx={{
                                width : { mobile : 220, xs : 120 }
                            }}
                        >
                            <MediaSeminarThumbnail
                                fileKey={data[0].サムネイル[0]?.fileKey}
                                click={clickThumbnail}
                                to={isShowing(data[0]) ? `/movie/list` : `/program/detail/${data[0].セミナーID}`}
                            />
                        </Stack>
                        <Stack
                            alignItems="flex-start"
                            gap={1}
                        >
                            <H5>{data[0].セミナー名}</H5>
                            <Body>{data[0].スピーカー見出し}</Body>
                        </Stack>
                    </Stack>
                </Stack>
                {data[1] &&
                <Stack
                    alignItems="flex-start"
                    gap={1}
                >
                    <H3>次回公開予定</H3>
                    <WhiteLabel>{period(data[1])}</WhiteLabel>
                    <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                    >
                        <H5>{data[1].セミナー名}</H5>
                        <EntryButton
                            component={Link}
                            to={`/program/detail/${data[1].セミナーID}`}
                        >
                            詳細
                        </EntryButton>
                    </Stack>
                </Stack>
                }
            </Stack>
            )}
        </ComponentBase>
    )
}

export default MediaSeminar

import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'


export const NodecorationLink = styled(Link)({
    color : grey[700],
    textDecoration : 'none',
    '&:focus, &:hover, &:visited, &:active' : {
        color : grey[700],
        textDecoration : 'none',
    },
})

export const UnderlineLink = styled(Link)({
    textDecoration : 'none',
    color : 'inherit',
    '&:hover' : {
        textDecoration : 'underline',
    }
})


import { Button, Stack, useMediaQuery } from '@mui/material'
import { blue } from '@mui/material/colors'
import { useParams } from 'react-router-dom'
import Block from 'components/Block'
import { H2 } from 'components/styledFonts'
import TableList from 'components/TableList'
import { useProgramMutaion } from 'hooks/mutation/program'
import { useReservations } from 'hooks/program'


const headers = [
    { label : '氏名', sx : {} },
    { label : '部署', sx : {} },
    { label : '役職', sx : {} },
    { label : 'メールアドレス', sx : {} },
    { label : '申込状況', sx : { width : 120 }, noLabel : true },
    { label : 'リンク', sx : { width : 120 }, noLabel : true },
]

const headersPad = [
    { label : '氏名', sx : {} },
    { label : '申込状況', sx : { width : 100 }, noLabel : true },
    { label : 'リンク', sx : { width : 100 }, noLabel : true },
]

const Reservations = ({ data }) => {
    const { id: seminarId } = useParams()
    const reservations = useReservations(seminarId)
    const isPad = useMediaQuery((theme) => theme.breakpoints.down('pad'))
    const { mutate } = useProgramMutaion()

    const clickLink = (d) => {

        const params = {}
        let dialog = ''
        params.seminarId = seminarId
        params.cap = data.募集定員
        params.indivCap = data._1社の定員
        params.seminarName = data.セミナー名
        params.start = data.開始日時
        params.end = data.終了予定日時
        params.open = data.開場日時
        params.venueName = data.会場名

        if (data.申込時の注意文言) {
            dialog = `●運営よりお知らせ●\n\n${data.申込時の注意文言}\n\n${d.参加者氏名}様の参加申し込みを進めてもよろしいでしょうか？`
        } else {
            dialog = `${d.参加者氏名}様の参加申し込みを行います`
        }
        if (d.リンク === '申し込み' && !d.状況) {
            params.apply = true
            params.applicantId = d.企業担当者ID
        } else {
            params.id = d.id
            params.revision = d.revision
            if (d.リンク === 'キャンセル') {
                dialog = `${d.参加者氏名}様の参加をキャンセルします`
                params.cancel = true
            } else {
                params.cancel = false
            }
        }
        if (window.confirm(dialog)) mutate(params)
    }
        
    return (
        <Block>
            <Stack>
                <H2>予約確認</H2>
                <TableList
                    headers={isPad ? headersPad : headers}
                    data={reservations?.map((d, i) => ({
                        氏名 : d.参加者氏名,
                        部署 : d.部署,
                        役職 : d.役職,
                        メールアドレス : d.メールアドレス,
                        申込状況 : `${d.状況}${d.状況 === '申込' ? '済み' : ''}`,
                        リンク : d.リンク ? 
                            <Button
                                sx={{
                                    p : 0,
                                    color : blue[400],
                                    '&:hover' : {
                                        textDecoration : 'underline',
                                    },
                                }}
                                onClick={() => clickLink(d)}
                            >
                                {d.リンク}
                            </Button>
                            : '',
                    }))}
                />
            </Stack>
        </Block>
    )
}

export default Reservations

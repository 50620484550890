import PreviewEditor from './PreviewEditor'
import { useIntroduction, useRecommendation, useStory } from 'hooks/inquiry'


const EditRecommendation = () => {
    const { data : story } = useStory()
    const { data : intoro } = useIntroduction()
    const { data : recomm } = useRecommendation()

    return (
        <PreviewEditor
            category="イチオシ！"
            data={recomm}
            leftCategory="社長ストーリー"
            leftData={story}
            rightCategory="こんな会社"
            rightData={intoro}
        />
    )
}

export default EditRecommendation

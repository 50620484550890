import { useEffect, useState } from 'react'
import { useAllStreaming } from './streaming'
import { useCompanyInfo } from './company'
import { useApplicationHistory } from './program'
import { useMediaSeminarCount } from './videoHistory'

export const useContacts = () => {
    const [contacts, setContacts] = useState(null)
    const { data: company } = useCompanyInfo()
    const { data: msCnt } = useMediaSeminarCount()
    const { data: seminars } = useApplicationHistory()
    const { data: stream } = useAllStreaming()

    useEffect(() => {
        if (!company || !msCnt || !stream || !seminars) return

        const contactType = [
            { label: 'メディアセミナー',           type: 'メディアセミナー', num: msCnt, enabled: company.セミナー機能 || company.セミナー機能_ビジネス },
            { label: 'メディア交流会',             type: '交流会',           num: 0,     enabled: Object.keys(company).filter(d => /月メディア交流会機能/.test(d)).some(d => company[d]) },
            { label: '経営者向けメディアセミナー', type: '経営者講座',       num: 0,     enabled: company.経営者機能 },
            { label: '経営PR戦略講座',             type: '経営PR戦略講座',   num: 0,     enabled: company.経営PR戦略講座機能 },
        ]

        seminars
            .filter(d => ['交流会', '経営者講座','経営PR戦略講座'].includes(d.セミナー種類))
            .forEach(d => {
                contactType.find(f => f.type === d.セミナー種類).num += d.メディア接点数
            })

        setContacts(contactType.filter(d => d.enabled))
    }, [company, msCnt, stream, seminars])

    return contacts
}

import { Body, H2 } from 'components/styledFonts'
import { Stack } from '@mui/material'
import { format } from 'date-fns'

const TemplateTitleEdit = ({ template }) => {

    if (!template) return

    return (<>
        <Stack
            gap={2}
            backgroundColor="#cbd5e7"
            borderRadius="4px"
            px={{ mobile : 3, xs : 2 }}
            py={3}
        >
            <H2>{template.タイトル}</H2>
            <Body
                whiteSpace="pre-wrap"
            >
                {template.リード文}
            </Body>
            <Body
                textAlign="right"
            >
                {`投稿期限 ${template.投稿期限 ? format(template.投稿期限, 'yyyy年MM月dd日 HH:mm') : ""}`}
            </Body>
        </Stack>
    </>)
}

export default TemplateTitleEdit
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import { Body, H3Underline, StyledLink, TH } from 'components/styledFonts'
import { EntryProgramButton } from 'components/styledButtons'
import ProgramListTableForPad from './ProgramListTableForPad'
import { Link } from 'react-router-dom'


const ProgramListTable = ({ title, data }) => {
    const isPad = useMediaQuery((theme) => theme.breakpoints.down('pad'))

    if (!data || data.length === 0) return null

    if (isPad) return <ProgramListTableForPad title={title} data={data} />

    return (
        <Stack>
            <Box
                sx={{ pt : 2, pb : 2 }}
            >
                <H3Underline>
                    {title}
                </H3Underline>
            </Box>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TH sx={{ width : 330 }}>プログラム</TH>
                        <TH sx={{ width : 200 }}>開催日時</TH>
                        <TH sx={{ width : 200 }}>会場</TH>
                        <TH sx={{ width : 120 }}>参加予定者</TH>
                        <TH sx={{ width : 80, pr : 0 }}>受付状況</TH>
                    </TableRow>
                </TableHead>
                {data &&
                <TableBody>
                    {data.map((d, i) => (
                    <TableRow key={i}>
                        <TableCell>
                            {d.セミナー名}
                        </TableCell>
                        <TableCell>
                            <StyledLink
                                to={`/program/detail/${d.セミナーID}`}
                            >
                                {`${format(d.開始日時, 'yyyy年MM月dd日 HH:mm')}～${format(d.終了予定日時, 'HH:mm')}`}
                            </StyledLink>
                        </TableCell>
                        <TableCell>
                            {d.会場名}
                        </TableCell>
                        <TableCell>
                            <Stack>
                                {d.自社申込者.map((u, i) => (
                                <Body key={i}>{u.参加者氏名}</Body>
                                ))}
                            </Stack>
                        </TableCell>
                        <TableCell sx={{ pr : 0 }}>
                            <Stack>
                                {d.受付状況}
                                {d.受付状況 === '受付中' &&
                                <EntryProgramButton 
                                    to={`/program/detail/${d.セミナーID}`}
                                    component={Link}
                                />
                                }
                            </Stack>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                }
            </Table>
        </Stack>
    )
}

export default ProgramListTable

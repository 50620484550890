import { Stack } from '@mui/material'
import PostedPicture from 'components/PostedPicture'
import ResponsivePlayer from 'components/ResponsivePlayer'
import PdfViewer from 'components/PdfViewer'

const NewsLetterDetailPdf = ({ data }) => {

    if (!data) return

    return (
        <Stack
            gap={4}
        >
            <Stack
                gap={4}
            >
                <PostedPicture
                    data={data[`メインイメージ`]}
                    caption={data[`メインイメージキャプション`]}
                />
                {data.PDF &&
                <Stack>
                    <PdfViewer
                        data={data[`PDF`]}
                    />
                </Stack>
                }
                {data[`動画`]?.name &&
                <Stack
                    pb={1}
                    sx={{
                        px: { mobile: 2, xs: 0 }
                    }}
                >
                    <ResponsivePlayer
                        data={data[`動画`]}
                    />
                </Stack>
                }
                {data[`動画URL`] &&
                <Stack
                    pb={1}
                    sx={{
                        px: { mobile: 2, xs: 0 }
                    }}
                >
                    <ResponsivePlayer
                        data={data[`動画URL`]}
                    />
                </Stack>
                }
            </Stack>
        </Stack>
    )
}

export default NewsLetterDetailPdf

import { Box, Stack } from '@mui/material'
import { H2 } from 'components/styledFonts'

const Block = ({ children, sx, ...props }) => {
    return (
        <Box
            bgcolor="#fff"
            border={1}
            width="100%"
            sx={{
                pt : 2,
                pb : 2.5,
                pl : 2,
                pr : 2,
                flexGrow : 1,
                borderColor : 'grey.400',
                borderRadius : 1,
                ...sx,
            }}
            {...props}
        >
            {children}
        </Box>
    )
}

export const TitledBlock = ({ title, children, sx, ...props }) => {
    return (
        <Block
            sx={sx}
            {...props}
        >
            <Stack
                gap={2}
            >
                <H2>{title}</H2>
                {children}
            </Stack>
        </Block>
    )
}


export default Block

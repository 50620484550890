import { useEffect } from 'react'
import { format } from 'date-fns'
import { Stack } from '@mui/material'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import { Body, H5 } from 'components/styledFonts'
import Badge from 'components/Badge'
import Block from 'components/Block'
import { useDynamoMutation } from 'hooks/mutation/useDynamoMutation'
import { useCompanyInfo } from 'hooks/company'
import { useDynamoItem } from 'hooks/dynamo'
import { useReadFlags } from 'hooks/message'
import SenderIcon from './SenderIcon'


const History = ({ messages }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))
    const { data } = useCompanyInfo()
    const { data : dynamoData = [], isSuccess } = useDynamoItem("メッセージ既読履歴")
    const { mutate } = useDynamoMutation("メッセージ既読履歴")
    const flags = useReadFlags()

    useEffect(() => {
        if (!isSuccess || !messages?.$id) return

        const tid = setTimeout(() => {
            const row = dynamoData.find(m => m.レコード番号 === Number(messages.$id))
            if (row) {
                row.既読数 = messages.回答テーブル.length
                mutate({ content : [...dynamoData] })
            }
            else {
                mutate({ content : [...dynamoData, { レコード番号: Number(messages.$id), 既読数: messages.回答テーブル.length }] })
            }
        }, 2000)
        return () => clearTimeout(tid)
    }, [dynamoData, isSuccess, messages, mutate])

    return (
        <Stack
            gap={2}
            pb={2}
        >
            {messages?.回答テーブル.map((m, i) => (
            <Stack
                key={i}
                alignItems={m.送信者 === '企業担当者' ? "flex-end" : "flex-start"}
            >
                <Badge
                    sx={{ width: "90%" }}
                    count={flags?.[messages.$id]?.[i]}
                >
                    <Block
                        bgcolor={m.送信者 !== '企業担当者' ? grey[200] : grey[50]}
                    >
                        <Stack 
                            gap={2} 
                            sx={{ wordBreak : "break-all" }}
                        >
                            <Stack
                                direction="row"
                                alignItems={isMobile ? "center" : "flex-start"}
                                gap={2}
                            >
                                <SenderIcon type={m.送信者} />
                                <Stack gap={2}>
                                    <H5>
                                        {format(new Date(m.日時), 'yyyy/MM/dd HH:mm')}{'　'}
                                        {m.送信者 === 'ネタもと担当者' &&
                                        "株式会社ネタもと　クライアントサポート"
                                        }
                                        {m.送信者 === '企業担当者' &&
                                        `${data?.会社名}　${m.企業担当者_姓}　${m.企業担当者_名}`
                                        }
                                        {m.送信者 === '媒体担当者' &&
                                        `${messages?.媒体名}　${messages.媒体担当者_姓}　${messages.媒体担当者_名}`
                                        }
                                    </H5>
                                    {!isMobile &&
                                    <Body whiteSpace="pre-wrap" dangerouslySetInnerHTML={{ __html: m.回答内容 }}></Body>
                                    }
                                </Stack>
                            </Stack>
                            {isMobile &&
                            <Body whiteSpace="pre-wrap" dangerouslySetInnerHTML={{ __html: m.回答内容 }}></Body>
                            }
                        </Stack>
                    </Block>
                </Badge>
            </Stack>
            ))}
        </Stack>
    )

}

export default History

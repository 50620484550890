import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { LabeledTextField, Required, Select } from 'components/Inputs'
import { H5 } from 'components/styledFonts'
import { messages } from 'config/messages'
import { industry } from 'config/options'
import { useCompanyMutaion } from 'hooks/mutation/company'
import { useCompanyInfo } from 'hooks/company'
import { useLoginUser } from 'hooks/loginuser'
import Settings from './Settings'


const defaultValues = {
    会社名: '',
    会社名_フリガナ: '',
    都道府県_住所: '',
    市区町村_住所: '',
    町名_番地_住所: '',
    ビル名_住所: '',
    代表者: '',
    電話番号_代表: '',
    Webサイト_URL: '',
    業種: '',
    事業内容: '',
    代表的な商品やサービス: '',
    設立年: '',
    資本金: '',
    売上高: '',
    従業員数: '',
    開示: '',
    非上場_上場: '',
    マイページ企業情報ID: '',
    $revision: '',
}

const schema = yup.object().shape({
    会社名: yup
        .string()
        .required(messages.REQUIRED),
    会社名_フリガナ: yup
        .string()
        .required(messages.REQUIRED),
    都道府県_住所: yup
        .string()
        .required(messages.REQUIRED),
    市区町村_住所: yup
        .string()
        .required(messages.REQUIRED),
    町名_番地_住所: yup
        .string()
        .required(messages.REQUIRED),
    代表者: yup
        .string()
        .required(messages.REQUIRED),
    電話番号_代表: yup
        .string()
        .required(messages.REQUIRED)
        .matches(/^[0-9-]+$/, messages.INPUT_NUMBER_HYPHEN),
    // TODO：定期表現の設計と相違がないのか確認必要
    Webサイト_URL: yup
        .string()
        .required(messages.REQUIRED)
        .url(messages.CORRECT_URL),
    業種: yup
        .string()
        .required(messages.REQUIRED),
    事業内容: yup
        .string()
        .required(messages.REQUIRED),
    代表的な商品やサービス: yup
        .string()
        .required(messages.REQUIRED),
    設立年: yup
        .number()
        .typeError(messages.INPUT_NUMBER)
        .min(1000, messages.ENTER_MORE(1000))
        .max(2999, messages.ENTER_LESS(2999))
        .required(messages.REQUIRED),
    資本金: yup
        .number()
        .typeError(messages.INPUT_NUMBER)
        .required(messages.REQUIRED),
    売上高: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
            String(originalValue).trim() === '' ? null : value
        )
        .typeError(messages.INPUT_NUMBER),
    従業員数: yup
        .number()
        .typeError(messages.INPUT_NUMBER)
        .required(messages.REQUIRED),
})

const CompanyBase = () => {
    const { control, setValue, handleSubmit } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) })
    const { data } = useCompanyInfo()
    const { mutate: mutateCompany } = useCompanyMutaion()
    const [edit, setEdit] = useState(false)
    const { data: user } = useLoginUser()

    useEffect(() => {
        if (!data) return

        Object.keys(defaultValues).forEach(n => setValue(n, data[n]))

        if (!data.会社名) {
            setValue('会社名', data.企業名)
        }
    }, [data, setValue])

    const onSubmit = async (data) => {
        data.登録区分 = "基本情報"
        mutateCompany(data)
        setEdit(false)
    }

    if (!data) return null

    return (
        <Settings
            category="基本情報"
            currents={[
                { label: '会社名', value: data.会社名 ? data.会社名 : data.企業名 },
                { label: '住所', value: `${data.都道府県_住所}${data.市区町村_住所}${data.町名_番地_住所} ${data.ビル名_住所 || ''}` },
                { label: '代表者', value: data.代表者 },
                { label: '電話番号', value: data.電話番号_代表 },
                { label: 'Webサイト', value: data.Webサイト_URL },
                { label: '業種', value: data.業種 },
                { label: '事業内容', value: data.事業内容 },
                { label: '商品・サービス', value: data.代表的な商品やサービス },
                { label: '設立年', value: `${data.設立年}年` },
                { label: '資本金', value: `${Number(data.資本金).toLocaleString()}百万円` },
                { label: '売上高', value: `${Number(data.売上高).toLocaleString()}百万円` },
                { label: '従業員数', value: `${Number(data.従業員数).toLocaleString()}名` },
                { label: '非上場/上場', value: data.非上場_上場 },
            ]}
            editor={
                <Stack
                    columnGap={4}
                    rowGap={2}
                    pb={1}
                    maxWidth={500}
                >
                    <LabeledTextField name="会社名" control={control} required />
                    <LabeledTextField name="会社名_フリガナ" label="会社名（フリガナ）" control={control} required />
                    <LabeledTextField name="都道府県_住所" label="都道府県（住所）" control={control} required />
                    <LabeledTextField name="市区町村_住所" label="市区町村（住所）" control={control} required />
                    <LabeledTextField name="町名_番地_住所" label="町名・番地（住所）" control={control} required />
                    <LabeledTextField name="ビル名_住所" label="ビル名（住所）" control={control} />
                    <LabeledTextField name="代表者" control={control} required />
                    <LabeledTextField name="電話番号_代表" label="電話番号（代表）" control={control} required />
                    <LabeledTextField name="Webサイト_URL" label="Webサイト（URL）" control={control} required />
                    <Stack
                        width={"auto"}
                        gap={0.75}
                    >
                        <H5>業種<Required /></H5>
                        <Select name="業種" control={control} list={industry} />
                    </Stack>
                    <LabeledTextField name="事業内容" control={control} required />
                    <LabeledTextField name="代表的な商品やサービス" label="代表的な商品やサービス" control={control} required />
                    <LabeledTextField name="設立年" control={control} required endAdornment={'年'} />
                    <LabeledTextField name="資本金" control={control} required endAdornment={'百万円'} />
                    <LabeledTextField name="売上高" control={control} endAdornment={'百万円'} />
                    <LabeledTextField name="従業員数" control={control} required endAdornment={'名'} />
                    <Stack
                        width={"auto"}
                        gap={0.75}
                    >
                        <H5>非上場/上場</H5>
                        <Select name="非上場_上場" control={control} list={[
                            { label: '--------', value: '' },
                            { label: '上場', value: '上場' },
                            { label: '非上場', value: '非上場' },
                        ]} />
                    </Stack>
                </Stack>
            }
            onSubmit={handleSubmit(onSubmit)}
            edit={edit}
            setEdit={setEdit}
            uneditable={!user?.isAdmin}
        />
    )
}

export default CompanyBase

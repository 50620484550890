import { useNavigate } from 'react-router'
import { addMinutes, getUnixTime } from 'date-fns'
import { apiName } from 'config/api'
import { useAccounts } from 'hooks/account'
import { useLoginUser } from 'hooks/loginuser'
import { files as fileNames } from 'hooks/newsletter'
import { useS3Config } from 'hooks/variousHooks'
import { API } from 'lib/api'
import { uploadS3 } from 'lib/common'
import useMyMutation from './useMyMutation'

export const useNewsLetterMutation = () => {
    const { data: user } = useLoginUser()
    const { data: accounts } = useAccounts()
    const { data : s3Config } = useS3Config()
    const navi = useNavigate()

    return useMyMutation({
        mutationFn : async (param) => {
            const form = param.form
            const tmpForm = { ...form }
            fileNames.filter(d => !form[d]?.blob).forEach(d => tmpForm[d] = form[d]?.url || '')

            // S3操作
            const files = fileNames
                .filter(d => form[d]?.blob)
                .reduce((o, d) => ({ ...o, [d] : form[d].blob }), {})
            if (Object.keys(files).length) {
                const prefix = `${user.企業ID}/newsletter/${getUnixTime(new Date())}`
                // S3にアップロードしデータアクセス時のS3-URLを取得
                const s3Urls = await uploadS3(files, prefix, s3Config)
                Object.keys(s3Urls).forEach(d => tmpForm[d] = s3Urls[d])
            }

            // kintone登録用にデータを変換
            const overWriteAccount = accounts.find(c => c.企業担当者ID === form.問い合わせ担当者_上書き)
            tmpForm.公開日 = form.公開日 ? form.公開日.toISOString() : ""
            if (!form.draft && !form.公開日) tmpForm.公開日 = addMinutes(new Date(), 5).toISOString()
            tmpForm.更新日_データ連携用 = new Date().toISOString()
            tmpForm.問い合わせ担当者_上書き = overWriteAccount?.姓 + overWriteAccount?.名 || ""
            tmpForm.更新者_データ連携用 = user.姓 + user.名
            tmpForm.下書き = form.draft ? ["下書き"] : []
            tmpForm.クライアント企業ID = form.クライアント企業ID || user.企業ID

            // 不要なパラメタの削除
            delete tmpForm.更新日
            delete tmpForm.コンテンツ数
            delete tmpForm.アップロード方法
            delete tmpForm.draft
            delete tmpForm.更新者
            delete tmpForm.ネタNo
        
            const ret = await API.post(apiName, "/items/postNewsLetter", { body : {
                requestFeedback : form.フィードバック依頼 && !form.draft && !form.フィードバックNo,
                newsLetterNo : form.ネタNo,
                record : tmpForm
            }})

            return ret
        },
        queryKey : ['newsletter', 'feedback'],
        onSettledFn : (ret) => {
            let to = ''
            if (/^OK/.test(ret.result)) {
                const method = /^PDF入稿/.test(ret.入稿方法) ? 'pdf' : ret.入稿方法 === "HTML入稿" ? "html" : "text"
                if (ret.result.includes("DRAFT")) {
                    to = `/newsletter/submission/${method}/${ret.toId}`
                } else {
                    to = '/post/list/newsletter'
                }
            }
            if (to) navi(to)
        }
    })
}


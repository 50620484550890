import { format } from 'date-fns'
import { Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Body, H1, H4, SmallBody } from 'components/styledFonts'
import { EditButton } from 'components/styledButtons'
import Block from 'components/Block'
import { useTemplates } from 'hooks/template'
import { Link } from 'react-router-dom'


const NewsLetterTemplates = () => {
    const { data } = useTemplates()

    return (
        <Stack
            gap={2}
        >
            <H1>ニュースレター テンプレート</H1>
            <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent="space-between"
                rowGap={2}
            >
                {data?.map((d, i) => (
                <Block
                    key={i}
                    sx={{
                        maxWidth : { sm : "49.5%", xs : "100%" },
                        minWidth : { sm : "49.5%", xs : "100%" },
                    }}
                >
                    <Stack
                        justifyContent="space-between"
                        height="100%"
                    >
                        <Stack
                            gap={2}
                            pb={4}
                            sx={{ wordBreak : "break-word" }}
                        >
                            <H4>{d.タイトル}</H4>
                            <Body
                                whiteSpace="pre-wrap"
                            >
                                {d.リード文}
                            </Body>
                            {[...Array(10).keys()].slice(0, d.質問数).map(j => 
                            <Stack
                                key={j}
                            >
                                <Body>{d[`質問${j + 1}`]}</Body>
                                <Body whiteSpace="pre-wrap">{d[`備考${j + 1}`]}</Body>
                                <Body whiteSpace="pre-wrap">{d[`回答${j + 1}テンプレート`]}</Body>
                            </Stack>
                            )}
                        </Stack>
                        <Stack
                            gap={1}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                            >
                                <SmallBody color={grey[500]}>投稿期限</SmallBody>
                                <Body>{d.投稿期限 ? format(d.投稿期限, 'yyyy年MM月dd日 HH:mm') : ""}</Body>
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                gap={2}
                            >
                                <EditButton
                                    component={Link}
                                    to={`/newsletter/submission/text/new/${d.ネタテーマNo}`}
                                    sx={{ minWidth : 140 }}
                                >
                                    テキスト入稿
                                </EditButton>
                                <EditButton
                                    component={Link}
                                    to={`/newsletter/submission/pdf/new/${d.ネタテーマNo}`}
                                    sx={{ minWidth : 140 }}
                                >
                                    PDF入稿
                                </EditButton>
                            </Stack>
                        </Stack>
                    </Stack>
                </Block>
                ))}
            </Stack>
        </Stack>
    )
}

export default NewsLetterTemplates


import { useQuery } from '@tanstack/react-query'
import { API } from "lib/api"
import { apiName } from "config/api"

const getData = async () => {
    const ret = await API.get(apiName, '/items/getMedialist', {})
    console.log(ret)
    return ret.map(d => ({ 
            ...d, 
            メディアリスト : d.メディアリスト.map(m => ({ 
                ...m, 
                姓名 : `${m.姓} ${m.名}`,
                登録日 : m.登録日 ? new Date(m.登録日) : '',
                アプローチ日 :  m.アプローチ日 ? new Date(m.アプローチ日) : '',
            }))
        }))
}

export const useAllMedias = () => {
    return useQuery(['media'], getData)
}

export const useMergedMedias = () => {
    return useQuery(['media'], getData, {
        select : (data) => {
            // ネタもと作成・企業作成のリストをマージ
            const allMediaList = data.flatMap(r =>
                r.メディアリスト.map(m => ({ ...m, $id : r.$id, $revision : r.$revision, リスト種類 : r.リスト種類 }))
            )

            // メールアドレスが重複するデータは登録日が最新のデータでマージ
            const mergedMediaData = {}
            allMediaList.forEach((m) => {
                const email = m.メール
                if (!(email in mergedMediaData) || m.登録日 > mergedMediaData[email].登録日) {
                    mergedMediaData[email] = m
                }
            })

            // 登録日の降順でソート
            const mergedMediaList = Object.values(mergedMediaData).sort((a, b) => a.登録日 - b.登録日)

            return mergedMediaList
        }
    })
}

export const useGroupName = () => {
    return useQuery(['media'], getData, {
        select : (data) => {
            const target = data.find(d => d.リスト種類 === '企業作成') || {}
            return {
                $revision : target.$revision,
                groups : [
                    "",
                    target.グループ1 || '',
                    target.グループ2 || '',
                    target.グループ3 || '',
                    target.グループ4 || '',
                    target.グループ5 || '',
                    target.グループ6 || '',
                    target.グループ7 || '',
                    target.グループ8 || '',
                    target.グループ9 || '',
                    target.グループ10 || '',
                ]
            }
        }
    })
}

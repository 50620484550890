import { yupResolver } from '@hookform/resolvers/yup'
import CancelIcon from '@mui/icons-material/Cancel'
import { Avatar, IconButton, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { SelectImage } from 'components/FileUploader'
import { LabeledTextField } from 'components/Inputs'
import PostedPicture, { isPicture } from 'components/PostedPicture'
import { H5 } from 'components/styledFonts'
import { messages } from 'config/messages'
import { useCompanyMutaion } from 'hooks/mutation/company'
import { useCompanyInfo } from 'hooks/company'
import { useLoginUser } from 'hooks/loginuser'
import Settings from './Settings'

const defaultValues = {
    氏名_メディア: '',
    氏名_フリガナ_メディア: '',
    役職_メディア: '',
    部署_メディア: '',
    電話番号_担当_メディア: '',
    Email_メディア: '',
    広報担当者写真: null,
    広報担当者写真オリジナルファイル名: '',
    マイページ企業情報ID: '',
    会社名: '',
    $revision: '',
}

const schema = yup.object().shape({
    氏名_メディア: yup
        .string()
        .required(messages.REQUIRED),
    氏名_フリガナ_メディア: yup
        .string()
        .required(messages.REQUIRED),
    役職_メディア: yup
        .string()
        .required(messages.REQUIRED),
    部署_メディア: yup
        .string()
        .required(messages.REQUIRED),
    電話番号_担当_メディア: yup
        .string()
        .required(messages.REQUIRED)
        .matches(/^[0-9-]+$/, messages.INPUT_NUMBER_HYPHEN),
    Email_メディア: yup
        .string()
        .required(messages.REQUIRED),
})

const PublicRelations = () => {
    const { control, setValue, handleSubmit, watch } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) })
    const { data } = useCompanyInfo()
    const { mutate: mutateCompany } = useCompanyMutaion()
    const [edit, setEdit] = useState(false)
    const { data: user } = useLoginUser()

    useEffect(() => {
        if (!data) return

        Object.keys(defaultValues).forEach(n => setValue(n, data[n]))

        if (!data.会社名) {
            setValue('会社名', data.企業名)
        }
    }, [data, setValue])

    const onSubmit = async (data) => {
        data.登録区分 = "広報担当者"
        mutateCompany(data)
        setEdit(false)
    }

    if (!data) return null

    return (
        <Settings
            category="広報担当者"
            currents={[
                { label: '名前', value: data.氏名_メディア },
                { label: 'フリガナ', value: data.氏名_フリガナ_メディア },
                { label: '役職', value: data.役職_メディア },
                { label: '所属部署', value: data.部署_メディア },
                { label: '電話番号', value: data.電話番号_担当_メディア },
                { label: 'メールアドレス', value: data.Email_メディア },
                { label: 'プロフィール画像', value: (
                    data.広報担当者写真 ? <Avatar src={data.広報担当者写真} sx={{ width: 56, height: 56 }} /> : ''
                )},
            ]}
            editor={
                <Stack
                    columnGap={4}
                    rowGap={2}
                    pb={1}
                    maxWidth={500}
                >
                    <LabeledTextField name="氏名_メディア" label="名前" control={control} required />
                    <LabeledTextField name="氏名_フリガナ_メディア" label="名前（フリガナ）" control={control} required />
                    <LabeledTextField name="役職_メディア" label="役職" control={control} required />
                    <LabeledTextField name="部署_メディア" label="所属部署" control={control} required />
                    <LabeledTextField name="電話番号_担当_メディア" label="電話番号" control={control} required />
                    <LabeledTextField name="Email_メディア" label="メールアドレス" control={control} required />
                    <Stack>
                        <H5>プロフィール画像</H5>
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={3}
                        >
                            <SelectImage name="広報担当者写真" control={control} setValue={setValue} file={watch('広報担当者写真')} />
                            {isPicture(watch('広報担当者写真')) &&
                            <Stack
                                direction="row"
                                alignItems="center"
                            >
                                <PostedPicture
                                    data={watch('広報担当者写真')}
                                    avatar
                                />
                                <IconButton
                                    onClick={() => {
                                        setValue('広報担当者写真', '')
                                        setValue('広報担当者写真オリジナルファイル名', '')
                                    }}
                                >
                                    <CancelIcon color="grey400" />
                                </IconButton>
                            </Stack>
                            }
                        </Stack>
                    </Stack>
                </Stack>
            }
            onSubmit={handleSubmit(onSubmit)}
            edit={edit}
            setEdit={setEdit}
            uneditable={!user?.isAdmin}
        />
    )
}

export default PublicRelations

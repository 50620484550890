import PRBarChart from 'pages/pranalysis/PRBarChart'
import ComponentBase from './ComponentBase'
import { usePRAnalysisAll } from 'hooks/pranalysis'


const PRAnalysis = () => {
    const { data } = usePRAnalysisAll()

    return (
        <ComponentBase
            title="PR活動診断"
            buttons={[{ label : 'PR活動診断', to : '/pranalysis' }]}
        >
            {data && !(data.noPRData || data.noAnswerCompletedData) && (
            <PRBarChart />
            )}
        </ComponentBase>
    )
}

export default PRAnalysis

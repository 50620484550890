import { Box, Button, Link, Stack } from '@mui/material'
import { styled } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { urls } from 'config/urls'
import logo from 'img/new-logo-g.gif'


const Title = styled(Box)({
    fontSize : 20,
    fontWeight : 700,
    color : grey[600],
})

const FooterLink = ({ href, label }) =>
    <Link
        href={href}
        underline="hover"
        target="_blank"
        sx={{
            color : 'grey.700',
            fontSize : 14,
        }}
    >
        {label}
    </Link>

export const loginComponents = {
    Header() {
        return (
            <Stack
                alignItems="center"
                pb={8}
                sx={{
                    pt : { mobile : 10, xs : 4 },
                    pb : { mobile : 8, xs : 4 },
                }}
            >
                <Box
                    component="img"
                    src={logo}
                    sx={{
                        width : { mobile : 120, xs : 80 },
                    }}
                />
            </Stack>
        )
    },
    Footer() {
        return (
            <Stack
                justifyContent="flex-end"
                width="100%"
                sx={{
                    pt : { mobile : 12, xs : 8 },
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    pt={3}
                    pb={12}
                    borderTop="1px solid #ccc"
                    sx={{ 
                        gap : { mobile : 7, xs : 4 },
                    }}
                >
                    {Object.keys(urls.footer).map(l => (
                    <FooterLink key={l} label={l} href={urls.footer[l]} />
                    ))}
                </Stack>
            </Stack>
        )
    },
    SignIn: {
        Header() {
            return (
                <Stack
                    alignItems="center"
                    mb={-1}
                    sx={{
                        pt : { mobile : 4, xs : 2 },
                    }}
                >
                    <Title>ログイン</Title>
                </Stack>
            )
        },
        Footer() {
            const { toResetPassword } = useAuthenticator()

            return (
                <Stack
                    px={7}
                    pb={2}
                    gap={3}
                    sx={{
                        px : { mobile : 7, xs : 2 },
                    }}
                >
                    <Button
                        onClick={() => window.open("https://netamoto.co.jp/contact/")}
                        variant="outlined"
                        sx={{ fontSize : 16 }}
                    >
                       お問い合わせ
                    </Button>
                    <Button
                        onClick={toResetPassword}
                        sx={{
                            '&:hover' : {
                                background : '#fff',
                                textDecoration : 'underline',
                            },
                        }}
                    >
                        パスワードをお忘れの方
                    </Button>
                </Stack>
            )
        },
    },
    ResetPassword: {
        Header() {
            return (
                <Stack
                    alignItems="center"
                >
                    <Title>パスワードを忘れた方</Title>
                    <Box
                        py={2}
                        fontSize={14}
                        color={grey[700]}
                    >
                        会員登録時に登録されたメールアドレスを入力してください。<br />
                        パスワード再設定ページへのメールをお送りします。
                    </Box>
                </Stack>
            )
        },
    },
}




import { Stack } from '@mui/material'
import { Body, H2 } from 'components/styledFonts'
import Block from 'components/Block'


const AttachedFiles = ({ files }) => {
    return (
        <Block>
            <Stack
                gap={2}
            >
                <H2>添付ファイル</H2>
                <Stack
                    gap={1}
                    pl={2}
                >
                {files?.map((c, i) => 
                    <Body key={i}>{c}</Body>
                )}
                </Stack>
            </Stack>
        </Block>
    )
}

export default AttachedFiles

import { useQuery } from '@tanstack/react-query'
import { apiName } from 'config/api'
import { API } from 'lib/api'

const getData = async () => {
    const ret = await API.get(apiName, '/items/getMonthlyReport', {})
    return ret
}

export const useReports = () => {
    return useQuery(['report'], getData)
}





import { useParams } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Message from './Message'
import MessageForMobile from './MessageForMobile'
import SupportForMobile from './SupportForMobile'


const MessageMain = () => {
    const { id } = useParams()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

    if (isMobile) {
        if (id === "support") {
            return <SupportForMobile />
        }
        else {
            return <MessageForMobile />
        }
    }
    else {
        return <Message />
    }
}

export default MessageMain

import { useEffect, useState } from 'react'
import { Box, Stack, Avatar } from '@mui/material'
import { Body } from 'components/styledFonts'


export const isPicture = d => {
    if (!d) return false
    if (d.url || /^https/.test(d)) return true
    if (d.blob) return true
    return false
}

const PostedPicture = ({ data, caption, maxWidth = 600, maxHeight = 400, avatar, ...props }) => {
    const [pict, setPict] = useState(null)

    useEffect(() => {
        if (!data || !data.blob || /^https/.test(data)) return

        (async () => {
            const load = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = e => {
                        resolve(e.target.result)
                    }
                    reader.readAsDataURL(file)
                })
            }

            setPict(await load(data.blob))
        })()
    }, [data])

    if (!isPicture(data)) return null

    return (
        <Stack
            alignItems="center"
            width="100%"
            gap={1.5}
        >
            <Stack
                maxWidth={maxWidth}
                alignItems="center"
            >
                {avatar ?
                <Avatar src={/^https/.test(data) ? data : pict || data.url} sx={{ width: 56, height: 56 }} />
                :
                <Box
                    component="img"
                    maxWidth="100%"
                    maxHeight={maxHeight}
                    src={/^https/.test(data) ? data : pict || data.url}
                    sx={{
                        objectFit: "contain",
                    }}
                />
                }
            </Stack>
            {caption &&
            <Body>{caption}</Body>
            }
        </Stack>
    )
}

export default PostedPicture

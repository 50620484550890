import MediaListMain from './MediaListMain'
import { Provider, atom } from 'jotai'

// メディアリスト画面用モーダル
export const mlEditModalAtom = atom(false)
export const selectedDataAtom = atom({})
export const mlGroupNameModalAtom = atom(false)
export const deleteDialogAtom = atom(false)


const MediaMain = () => {
    return (
        <Provider>
            <MediaListMain/>
        </Provider>
    )
}

export default MediaMain
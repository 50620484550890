import { useEffect, useState } from 'react'
import { Box } from '@mui/material'

const viewer = "https://s3-ap-northeast-1.amazonaws.com/netamoto/static/pdfjs/web/viewer.html?file="

export const isPDF = d => {
    if (!d) return false
    if (d.url || d.blob) return true
    return false
}

const PdfViewer = ({ data }) => {
    const [pdf, setPDF] = useState(null)

    useEffect(() => {
        if (!data || !data.blob) return
        setPDF(URL.createObjectURL(data.blob))
    }, [data])

    // アンアマウント時にURLを解放する
    useEffect(() => {
        if (!pdf) return
        return () => URL.revokeObjectURL(pdf)
    }, [pdf])

    if (!isPDF(data)) return null

    return (
        <Box
            sx={{
                height: { sm: 1000, xs: 500 },
            }}
        >
            <Box
                component="iframe"
                src={pdf || `${viewer}${data.url}#page=1&pagemode=none`}
                type="application/pdf"
                height="100%"
                width="100%"
            />
        </Box>
    )
}

export default PdfViewer

import { useFormContext } from 'react-hook-form'
import { format } from 'date-fns'
import { Stack } from '@mui/material'
import { Body } from 'components/styledFonts'
import { CircleCheck, StarCheck } from 'components/styledChecks'
import { DeleteButton, EntryButton } from 'components/styledButtons'
import TablePaginationList from 'components/TablePaginationList'
import MailAddress from './MailAddress'
import Groups from './Groups'
import DeleteDialog from './DeleteDialog'
import { useDynamoItem } from 'hooks/dynamo'

const headers = [
    { label : 'チェック', noLabel : true, sx : { px : 0 } },
    { label : '氏名', sx : {} },
    { label : '媒体種類', sx : {} },
    { label : '媒体名', sx : { width : 160 } },
    { label : '部署名', sx : { width : 160 } },
    { label : 'TEL/メール/携帯電話', sx : { width : 230, whiteSpace : "pre-wrap" } },
    { label : 'アプローチ日', sx : { whiteSpace : "pre-wrap" } },
    { label : 'グループ', sx : { width : 180 } },
    { label : '編集', noLabel : true, sx : { px : 0 } },
]

const Contacts = ({ data }) => {
    return (
        <Stack>
            <Body>{data.TEL || ''}</Body>
            <MailAddress>{data.メール || ''}</MailAddress>
            <Body>{data.携帯電話 || ''}</Body>
        </Stack>
    )
}

const MediaList = ({ filteredData, handleMlEditModal, handleCheck, handleDynamoCheck, handleOpenDialog }) => {
    const { data : dynamoData } = useDynamoItem("メディアリストお気に入り")
    const { getValues } = useFormContext()

    return (<>
        <TablePaginationList
            headers={headers}
            data={filteredData.map((d, i) => ({
                チェック : (
                    <Stack
                        direction="row"
                    >
                        <CircleCheck 
                            checked={getValues().チェックリスト?.some(a => a === d.メール) || false} 
                            onChange={e => handleCheck(e, d, i)} 
                        />
                        <StarCheck
                            checked={dynamoData?.some(m => m === d.メール) || false}
                            onChange={e => handleDynamoCheck(e, d, i)}
                        />
                    </Stack>
                ),
                氏名 : `${d.姓} ${d.名}`,
                媒体種類 : d.媒体種類,
                'TEL/メール/携帯電話' : <Contacts data={d} />,
                媒体名 : d.媒体名,
                部署名 : d.部署名,
                アプローチ日 : d.アプローチ日 ? `${format(d.アプローチ日, 'yyyy年')}\n${format(d.アプローチ日, 'MM月dd日')}` : '',
                グループ : <Groups data={d.グループ} />,
                編集 : (
                    <Stack
                        gap={1}
                    >
                        <EntryButton onClick={() => handleMlEditModal(d)}>編集</EntryButton>
                        {d.リスト種類 === '企業作成' &&
                        <DeleteButton onClick={() => handleOpenDialog(d)}>削除</DeleteButton>
                        }
                    </Stack>
                ),
            }))}
        />
        <DeleteDialog />
    </>)
}

export default MediaList

import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { red } from '@mui/material/colors'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import Block from 'components/Block'
import { LabeledTextField } from 'components/Inputs'
import { H2 } from 'components/styledFonts'
import { BlueContainedButton } from 'components/styledButtons'
import { SmallBody } from 'components/styledFonts'
import { messages } from 'config/messages'
import { useProgramMutaion } from 'hooks/mutation/program'
import { useReservations } from 'hooks/program'


const defaultValues = {
    姓 : '',
    名 : '',
    メールアドレス : '',
    部署 : '',
    役職 : '',
}

const schema = (params) =>yup.object().shape({
    姓 : yup
        .string()
        .required(messages.REQUIRED)
    ,
    名 : yup
        .string()
        .required(messages.REQUIRED)
    ,
    メールアドレス : yup
        .string()
        .required(messages.REQUIRED)
        .matches(/^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
            , { message : messages.MAILADDRESS_VALIDATION })
        .test('メールアドレス', messages.ERR_MAILADDRESS_EXISTS, (value) => !params.some(d => d.メールアドレス === value))
    ,
})

const AddApplicant = ({ data }) => {
    const { id: seminarId } = useParams()
    const reservations = useReservations(seminarId)
    const { control, handleSubmit, getValues, reset } = useForm({ defaultValues : defaultValues, resolver : yupResolver(schema(reservations)) })
    const { mutateAsync } = useProgramMutaion()

    const onSubmit = async (d) => {
        const form = Object.keys(d).reduce((o, f) => ({ ...o, [`申込_${f}`]: d[f] }), {})
        if (window.confirm(`${getValues('姓')} ${getValues('名')}様の参加申し込みを行います`)) {
            const ret = await mutateAsync({
                apply: true,
                seminarId: seminarId,
                cap: data.募集定員,
                indivCap: data._1社の定員,
                seminarName: data.セミナー名,
                start: data.開始日時,
                end: data.終了予定日時,
                open: data.開場日時,
                venueName: data.会場名,  
                form: form,
            })
            if (!ret.error) reset()
        }
    }

    return (
        <Block>
            <Stack
                pb={1}
            >
                <H2>申込者の追加</H2>
                <Stack
                    direction="row"
                    pt={2}
                    pb={1}
                    pl={1}
                >
                    <SmallBody sx={{ color : red[400] }}>*</SmallBody>
                    <SmallBody>がついている項目は必須です</SmallBody>
                </Stack>
                <Stack
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                        flexDirection : { md : "row", xs : "column" },
                        justifyContent : "space-between",
                        alignItems : "center",
                        pl : 2,
                        pr : 3,
                        gap : 2,
                    }}
                >
                    <Stack
                        direction="row"
                        flexWrap="wrap"
                        columnGap={4}
                        rowGap={1}
                    >
                        <LabeledTextField
                            name="姓"
                            control={control}
                            required
                        />
                        <LabeledTextField
                            name="名"
                            control={control}
                            required
                        />
                        <LabeledTextField
                            name="メールアドレス"
                            control={control}
                            required
                            sx={{
                                width : 320,
                            }}
                        />
                        <LabeledTextField
                            name="部署"
                            control={control}
                        />
                        <LabeledTextField
                            name="役職"
                            control={control}
                        />
                    </Stack>
                    <BlueContainedButton
                        label="申し込み"
                        type="submit"
                        sx={{
                            fontSize : 16,
                            lineHeight : 2.5,
                            minWidth : 170,
                        }}
                    />
                </Stack>
            </Stack>
        </Block>
    )
}

export default AddApplicant

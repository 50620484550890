import { Stack, Divider } from '@mui/material'
import { useLoginUser } from 'hooks/loginuser'
import { Body, H5 } from 'components/styledFonts'

const PersonalMail = () => {
    const { data } = useLoginUser()

    if (!data) return null

    return (
        <>
            <Stack
                justifyContent="space-between"
                sx={{
                    flexDirection : { sm : "row", xs : "column" },
                }}
                rowGap={1}
            >
                <Stack
                    justifyContent="center"
                    sx={{
                        width : { sm : "25%", xs : "100%" },
                    }}
                >
                    <H5>登録メールアドレス</H5>
                </Stack>
                <Stack
                    sx={{
                        width : { sm : "75%", xs : "100%" },
                        justifyContent : "space-between",
                        alignItems : "center",
                    }}
                >
                    <Stack direction="row" alignItems="center" width="100%">
                        <Body
                            sx={{
                                wordBreak : "break-all",
                            }}
                        >
                            {data.メールアドレス}
                        </Body>
                    </Stack>
                </Stack>
            </Stack>
            <Divider />
        </>
    )
}

export default PersonalMail

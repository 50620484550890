import Thumbnail from 'components/Thumbnail'
import { useMediaSeminarThumbnail } from 'hooks/program'

const MediaSeminarThumbnail = ({ fileKey, width='100%', height='100%', click, to }) => {
    const { data: blob } = useMediaSeminarThumbnail(fileKey)

    if (!blob) return null

    return (
        <Thumbnail
            src={URL.createObjectURL(blob)}
            width={width}
            height={height}
            click={click}
            to={to}
        />
    )
}

export default MediaSeminarThumbnail

export const categories = [
    { value : "商品" },
    { value : "サービス" },
    { value : "店舗" },
    { value : "イベント・キャンペーン" },
    { value : "企業情報・IR" },
    { value : "調査" },
    { value : "記者発表会", sp : true },
    { value : "読者プレゼント", sp : true },
    { value : "人物" },
    { value : "その他" },
    { value : "ロケ地" },
]

// ニュースレターカテゴリー
export const newsletterCategories = categories.filter(c => !c.sp).map(c => c.value)

// プレスリリースカテゴリ
export const pressreleaseCategories = categories.map(c => c.value)

// 特殊カテゴリ
export const featureCategory = categories.filter(c => c.sp).map(c => c.value)

// ジャンル
export const genreList = [
    "IT",
    "ゲーム・玩具",
    "建築・不動産",
    "金融・保険",
    "技術・テクノロジー",
    "医療・介護・福祉",
    "アート・デザイン",
    "音楽",
    "食",
    "美容・健康",
    "スポーツ・エンタメ",
    "経営情報",
    "社会・経済",
    "地域",
    "教育",
    "くらし",
    "ファッション",
    "文具・日用品",
    "その他"
]

// 業種
export const industry = [{ label : '--------', value : ''}].concat([
    '資源・エネルギー',
    '素材',
    '機械・エレクトロニクス',
    '輸送機器',
    '食品',
    '生活',
    '医療医薬・バイオ',
    '建設・不動産',
    '小売',
    '商社・卸売',
    '外食・飲食サービス',
    '金融',
    '物流・運輸',
    '情報・通信・広告',
    'サービス',
    '公務・団体',
    'メーカー・製造',
    'その他',
].map(v => ({ label : v, value : v })))

// 都道府県
export const prefectures = [{ label : '--------', value : ''}].concat([
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
].map(v => ({ label : v, value : v })))

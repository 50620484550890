import { useQuery } from '@tanstack/react-query'
import { API } from 'lib/api'
import { apiName } from 'config/api'

const questionItems10 = [
    "質問:no",
].flatMap(c => [...Array(10).keys()].map(k => c.replace(":no", k + 1)))

const getData = async () => {
    const ret = await API.get(apiName, '/items/getNewsLetterTemplate', {})
    console.log(ret)
    return ret.map(c => ({
        ...c,
        投稿期限 : c.投稿期限 ? new Date(c.投稿期限) : "",
        質問数 : questionItems10.findLastIndex(q => c[q]) + 1,
    }))
}

export const useTemplates = () => {
    return useQuery(['template'], getData)
}

export const useTemplateByNo = (no) => {
    return useQuery(['template'], getData, {
        select : data => data.find(d => d.ネタテーマNo === no)
    })
}


import { Link } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import logo from 'img/menu_logo.png'


const NetamotoLogo = () => {
    return (
        <Stack
            component={Link}
            to="/"
            alignItems="center"
            sx={{
                '&:hover' : {
                    opacity : 0.5,
                },
            }}
        >
            <Box
                component="img"
                src={logo}
            />
        </Stack>
    )
}

export default NetamotoLogo
